<template>
    <div id="kit-creation_container" class="container noselect">
        <div class="row mb-5">
            <div class="col px-0 px-md-25">

                <picture  >
                    <source srcset="/storage/images/kitcreations/mdd_m.webp" media="(max-width: 430px)" />
                    <source srcset="/storage/images/kitcreations/mdd_md.webp" media="(max-width: 600px)" />
                    <img src="/storage/images/kitcreations/mdd.webp" class="img-fluid" style="box-shadow: rgba(0, 0, 0, 0.9) 0px 10px 20px -5px" />
                </picture>

            </div>
        </div>

        <transition name="fade" mode="out-in">

            <div v-if="!finished" id="kit-creation_form" :key="'kit-creation_form'">
                <div class="row justify-content-center mb-5">
                    <div class="col col-11 col-md-9 text-center">
                        <p class="mb-0 fs-4">
                            Mach Dein Ding! Und wir helfen Dir dabei. Du suchst ein Angebot, das genau auf dich zugeschnitten ist? Frag uns einfach.
                            Wähle deine Traumkamera, dazu deine Wunschobjektive, schick das Formular ab und freu dich auf ein individuelles Angebot.
                            Wir beraten dich auch gerne, um herauszufinden, was am besten zu dir passt.
                        </p>
                    </div>
                </div>

                <div class="row mb-4">
                    <div class="col text-center text-uppercase">
                        <p class="mb-0 fw-bold text-primary display-3 lhd">1</p>
                        <p class="mb-0 fs-3">Wähle deine Kamera</p>
                    </div>
                </div>

                <div class="row pb-4">
                    <div class="col d-flex justify-content-center overflow-hidden">
                        <div class="p-card-carousel">
                            <div class="p-card-container" v-for="(primaryItem, index) in primaryItems.items">
                                <div class="p-card"  @touchstart="touchStartSwipe" @touchEndMethod="touchEndSwipe">
                                    <div class="card-img text-center">
                                        <img class="img-fluid" :src="'/storage/img/uploads/products/' + primaryItem.item_id + '/' + primaryItem.image"  style="height: 200px">
                                    </div>
                                    <div class="card-body">
                                        <h4 class="card-title">{{ primaryItem.name }}</h4>
                                        <p class="card-text"></p>
                                    </div>
                                </div>
                            </div>
                            <button class="p-card-carousel_nav left" v-if="primaryItems.selected > 0" @click.prevent="prevSlide"><i class="fas fa-chevron-left"></i></button>
                            <button class="p-card-carousel_nav right" v-if="primaryItems.selected + 1 < primaryItems.cardCount" @click.prevent="nextSlide"><i class="fas fa-chevron-right"></i></button>
                        </div>
                    </div>
                </div>

                <div class="row mt-5 pt-3 mb-4">
                    <div class="col text-center text-uppercase">
                        <p class="mb-0 fw-bold text-primary display-3 lhd">2</p>
                        <p class="mb-0 fs-3">Wähle dein(e) Objektiv(e)</p>
                    </div>
                </div>

                <div class="row justify-content-center">
                    <div class="col-11 col-md-10 col-sm-6 col-md-5 col-lg-4 col-xl-3 mb-45" v-for="(selection, index) in secondaryItems.selections">
                        <div class="card kc-card select-box">
                            <div class="card-body d-flex flex-column">
                                <div class="row mb-4">
                                    <div class="col form-floating">
                                        <multiselect v-model="selection.selected"
                                                     :options="secondaryItems.options"
                                                     :hide-selected="true"
                                                     :searchable="true"
                                                     :selectLabel="''"
                                                     label="name"
                                                     track-by="id"
                                                     placeholder="Objektiv auswählen"
                                                     @select="updateOptions">

                                            <template #option="props">
                                                <div class="row align-items-center">
                                                    <div class="col-2 ps-1 pe-0">
                                                        <img class="option__image img-fluid" style="max-width: 2rem" :src="'/storage/img/uploads/products/' + props.option.item_id + '/' + props.option.image" />
                                                    </div>
                                                    <div class="col-10 ps-0">
                                                        <span class="option__title" style="white-space: break-spaces">{{ props.option.name }}</span>
                                                    </div>
                                                </div>
                                            </template>
                                        </multiselect>
                                        <span class="badge bg-danger badge-select-clear" v-if="selection.selected" @click.prevent="clearSelection(selection)">
                                        <i class="fas fa-times"></i>
                                    </span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col text-center text-danger mt-5" v-if="hasErrors && errors.hasOwnProperty('secondaryItems') && index === 0">
                                        <div class="row">
                                            <div class="col display-1">
                                                <i class="fas fa-exclamation-circle"></i>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">
                                                <p>Bitte wähle mindestens ein Objektiv aus.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col" v-else>
                                        <transition name="fade" mode="out-in">
                                            <img :key="'kcloaded'" v-if="selection.selected" class="img-fluid" :src="'/storage/img/uploads/products/' + selection.selected.item_id + '/' + selection.selected.image">
                                            <div v-else class="row text-center display-2">

                                                <div class="col-12">
                                                    <img :key="'kcunloaded'" :src="'/storage/images/kitcreations/acf_lens_' + selection.imgNo + '.svg'" alt="" class="img-fluid kc-placeholder">
                                                </div>
                                            </div>
                                        </transition>
                                    </div>
                                </div>
                                <div class="row mt-auto">
                                    <div class="col text-center">
                                        <button @click.prevent="removeSecondaryItem(index)" class="btn btn-sm btn-outline-danger" v-if="secondaryItems.selections.length > 1">entfernen</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-10 col-sm-6 col-md-5 col-lg-4 col-xl-3 mb-45" v-if="this.secondaryItems.selections.length + 1 <= this.secondaryItems.maxSelections">
                        <div class="card kc-card select-box cursor-pointer" @click.prevent="addSecondaryItem">
                            <div class="card-body justify-content-center d-flex flex-column">
                                <div class="row mt-5">
                                    <div class="col text-center" style="font-size: 5rem">
                                        <i class="fas fa-plus-circle"></i>
                                    </div>
                                </div>
                                <div class="row mb-5">
                                    <div class="col text-center fs-4">
                                        <p class="mb-0">hinzufügen</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt-5 mb-3">
                    <div class="col text-center text-uppercase">
                        <p class="mb-0 fw-bold text-primary display-3 lhd">3</p>
                        <p class="mb-0 fs-3">Deine Kontaktdaten</p>
                    </div>
                </div>

                <div class="row justify-content-center pb-45">
                    <div class="col-11 col-md-9">
                        <div class="card kc_info-card">
                            <div class="card-body p-45">
                                <div class="row">
                                    <div class="col-12 col-md-6 form-floating mb-3">
                                        <input type="text" id="prename" v-model="customer.prename" class="form-control is-required" placeholder="Vorname" :class="{'is-invalid': (errors.hasOwnProperty('prename'))}" required>
                                        <label for="prename">Vorname</label>
                                        <div v-if="hasErrors && errors.hasOwnProperty('prename')" class="invalid-feedback">
                                            {{ errors['prename'][0] }}
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6 form-floating mb-3">
                                        <input type="text" id="lastname" v-model="customer.lastname" class="form-control is-required" placeholder="Nachname" :class="{'is-invalid': (errors.hasOwnProperty('lastname'))}" required>
                                        <label for="lastname">Nachname</label>
                                        <div v-if="hasErrors && errors.hasOwnProperty('lastname')" class="invalid-feedback">
                                            {{ errors['lastname'][0] }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 col-md-6 form-floating mb-3 mb-md-0">
                                        <input type="email" id="email" v-model="customer.email" class="form-control is-required" placeholder="E-Mail-Adresse" :class="{'is-invalid': (errors.hasOwnProperty('email'))}" required>
                                        <label for="email">E-Mail-Adresse</label>
                                        <div v-if="hasErrors && errors.hasOwnProperty('email')" class="invalid-feedback">
                                            {{ errors['email'][0] }}
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6 form-floating">
                                        <input type="text" id="phone" v-model="customer.phone" class="form-control" placeholder="Telefonnummer" >
                                        <label for="email">Telefonnummer</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt-5 mb-3">
                    <div class="col text-center text-uppercase">
                        <p class="mb-0 fw-bold text-primary display-3 lhd">4</p>
                        <p class="mb-0 fs-3">Zusatzinformationen</p>
                    </div>
                </div>

                <div class="row justify-content-center">
                    <div class="col-11 col-md-9">
                        <div class="card kc_info-card">
                            <div class="card-body p-45">
                                <div class="row">
                                    <div class="col-12 col-md-6">
                                        <div class="col-12 mb-3">
                                            <div class="form-check form-check-lg d-flex align-items-center gap-2">
                                                <input type="checkbox" id="wantsAdvice" name="wantsAdvice" value="1" v-model="customer.wantsAdvice" class="form-check-input">
                                                <label for="wantsAdvice" class="form-check-label pt-1 fw-bold">Ich möchte mich gerne telefonisch beraten lassen</label>
                                            </div>
                                            <transition name="fade">
                                                <div v-if="customer.wantsAdvice" class="alert alert-warning p-2 mt-2">
                                                    <p class="mb-0 fw-bold">Bitte denke daran, deine Telefonnummer anzugeben</p>
                                                </div>
                                            </transition>
                                        </div>
                                        <div class="col-12 mb-3">
                                            <div class="form-check form-check-lg d-flex align-items-center gap-2">
                                                <input type="checkbox" id="profiVoucher" name="profiVoucher" value="1" v-model="customer.profiVoucher" class="form-check-input">
                                                <label for="profiVoucher" class="form-check-label pt-1 fw-bold">Ich habe einen Profi-Voucher und möchte ihn nutzen</label>
                                            </div>
                                            <transition-group name="fade">
                                                <template v-if="customer.profiVoucher" >
                                                    <div :key="'uploadLabel'" class="alert alert-warning p-2 mt-2">
                                                        <p class="mb-0 fw-bold">Bitte lade deinen Profi-Voucher direkt hier hoch</p>
                                                    </div>
                                                    <div :key="'codeInput'" class="mb-3 form-floating">
                                                        <input type="text" id="voucherCode" v-model="customer.voucherCode" class="form-control is-required" placeholder="Code eingabe" :class="{'is-invalid': (errors.hasOwnProperty('voucherCode'))}" required>
                                                        <label for="voucherCode">Code eingabe</label>
                                                        <div v-if="hasErrors && errors.hasOwnProperty('voucherCode')" class="invalid-feedback">
                                                            {{ errors['voucherCode'][0] }}
                                                        </div>
                                                    </div>
                                                    <div :key="'uploadInput'" class="mb-3">
                                                        <input class="form-control" :class="{'is-invalid': (errors.hasOwnProperty('document'))}" type="file" id="document" @change="setDocument">
                                                        <div v-if="errors.hasOwnProperty('document')" class="invalid-feedback fs-6">{{ errors['document'][0] }}</div>
                                                    </div>
                                                </template>
                                            </transition-group>
                                        </div>

                                    </div>
                                    <div class="col-12 d-md-none mb-3 px-0">
                                        <hr>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <div class="col-12 form-floating mb-3">
                                            <textarea name="" id="" cols="30" rows="10" class="form-control" placeholder="Hier ist noch Platz für deine Fragen" style="height: 10rem;" v-model="customer.message"></textarea>
                                            <label for="email">Hier ist noch Platz für deine Fragen</label>
                                        </div>
                                        <div class="col-12 acf-multiselect">
                                            <multiselect v-model="customer.associations"
                                                         :options="associationOptions"
                                                         :multiple="true"
                                                         :searchable="true"
                                                         :hide-selected="true"
                                                         :taggable="true"
                                                         label="value"
                                                         track-by="name"
                                                         @tag="addAssociation"
                                                         tagPlaceholder="Enter oder klicken zum hinzufügen"
                                                         selectLabel="Enter oder klicken zum hinzufügen"
                                                         placeholder="Ich bin Mitglied im Verband/in Verbänden"
                                                         :class="{'is-invalid': hasErrors && (errors.hasOwnProperty('associations'))}">
                                            </multiselect>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt-5 mb-5 pb-5 justify-content-center">
                    <div class="col-9 text-center">
                        <button class="btn btn-primary btn-lg text-uppercase" @click.prevent="store">jetzt individuelles angebot erhalten</button>
                    </div>
                </div>
            </div>
            <div v-else id="kit-creation_response" :key="'creation_response'" class="py-5">
                <div class="row mb-3">
                    <div class="col text-center text-uppercase">
                        <p class="mb-0 fw-bold text-success display-3"><i class="far fa-check-circle"></i></p>
                        <p class="mb-0 fs-3">Anfrage eingegangen</p>
                    </div>
                </div>
                <div class="row justify-content-center mb-5 pb-5">
                    <div class="col-9">
                        <div class="card kc_info-card">
                            <div class="card-body p-45">
                                <div class="row">
                                    <div class="col fs-4 text-center fw-bold">
                                        <p class="mb-1">Deine Anfrage ist bei uns unter {{ response.requestNo }} eingegangen.</p>
                                        <p class="mb-0">Sobald wir diese bearbeitet haben, erhältst du von uns eine E-Mail.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>

</template>

<script>
    export default {
        data () {
            return {
                hasErrors: false,
                errors: [],

                finished: false,
                response: {
                    requestNo: ''
                },

                initialLoading: true,

                primaryItems: {
                    isLoading: true,
                    items: [],
                    selected: 2,
                    cards: [],
                    cardCount: 0
                },

                secondaryItems: {
                    isLoading: true,
                    options: [],
                    maxSelections: 3,
                    selections: [
                        {
                            selected: null,
                            imgNo: 1
                        }
                    ],
                },
                currentImgNo: 1,

                secondaryItemsInitial: [],

                customer: {
                    prename: '',
                    lastname: '',
                    email: '',
                    phone: '',
                    wantsAdvice: false,
                    profiVoucher: false,
                    message: '',
                    associations: [],
                    voucherCode: ''
                },
                profiVoucherUpload: {
                    file: null,
                },

                associationOptions: [
                    { name: 'null',     value: "Kein Mitglied eines Verbandes" },
                    { name: 'BFF',      value: "BFF (Berufsverband Freie Fotografen)" },
                    { name: 'BPP',      value: "BPP (Bund Professioneller Portraitfotografen)" },
                    { name: 'CV',       value: "CV Centralverband Deutscher Berufsfotografen" },
                    { name: 'DVF',      value: "DVF (Deutscher Verband für Fotografie)" },
                    { name: 'GDT',      value: "GDT (Gesellschaft für Naturfotografie) e.V." },
                    { name: 'PPC',      value: "PPC (Profi-Portrait-Club)" },
                    { name: 'VDS',      value: "VDS (Verband Deutscher Sportjournalisten)" },
                    { name: 'FREELENS', value: "FREELENS" },
                    { name: "other",    value: 'Anderer, nicht genannt' }
                ],

            }
        },
        mounted () {
            this.fetch()
            this.updateCarousel()
        },
        unmounted () {

        },
        methods: {
            imgNo() {
                return Math.floor(Math.random() * (3 - 1 + 1) + 1)
            },
            setDocument(event) {
                this.profiVoucherUpload.file = event.target.files[0]
            },
            store() {
                let formData = new FormData
                formData.append('customer', JSON.stringify(this.customer))
                formData.append('primaryItem', this.primaryItems.items[this.primaryItems.selected].item_id)
                formData.append('secondaryItems', JSON.stringify(this.secondaryItemsArr))
                if (this.customer.profiVoucher && this.profiVoucherUpload.file !== null) {
                    formData.append('document', this.profiVoucherUpload.file)
                }

                axios.post('/mach-dein-ding', formData,{headers: {'Content-Type': 'multipart/form-data'}})
                    .then(response => {
                        this.finished = true
                        this.response.requestNo = response.data.requestNo

                        this.$nextTick(() => {
                            document.getElementById('kit-creation_response').scrollIntoView();
                        })
                    })
                    .catch(err => {
                        this.hasErrors = true
                        this.errors = err.response.data.errors ?? []
                    })
                    .finally(() => {
                        this.isLoading = false
                    })
            },
            fetch() {
                axios.get('/api/kit-creations/items')
                    .then((response) => {
                        this.primaryItems.items = response.data.primary
                        this.primaryItems.cardCount = this.primaryItems.items.length
                        this.secondaryItems.options = response.data.secondary
                        this.secondaryItems.maxSelections = response.data.secondary.length
                        this.secondaryItemsInitial = response.data.secondary
                    })
                    .catch()
                    .finally(() => {
                        this.initialLoading = false
                    })
            },
            updateCarousel() {
                let active = this.primaryItems.selected
                let cardCount = this.primaryItems.cardCount
                this.primaryItems.cards.forEach((card, index) => {
                    const offset = ((active - index) % cardCount) / 5
                    const direction = Math.sign(active - index)
                    const absOffset = Math.abs(active - index) / 5
                    const isActive = index === active ? 1 : 0
                    const opacity = Math.abs(active - index) <= 2 ? 1 : 0

                    if (isActive) {
                        card.classList.add('active')
                    } else {
                        card.classList.remove('active')
                    }

                    card.style.setProperty('--offset', offset)
                    card.style.setProperty('--direction', direction)
                    card.style.setProperty('--abs-offset', absOffset)
                    card.style.setProperty('--active', isActive)
                    card.style.setProperty('--opacity', opacity)
                })
            },
            prevSlide() {
                this.primaryItems.selected = (this.primaryItems.selected - 1 + this.primaryItems.items.length) % this.primaryItems.items.length
                this.updateCarousel()
            },

            nextSlide() {
                this.primaryItems.selected = (this.primaryItems.selected + 1) % this.primaryItems.items.length
                this.updateCarousel()
            },
            addSecondaryItem() {
                if (this.secondaryItems.selections.length + 1 <= this.secondaryItems.maxSelections) {
                    this.secondaryItems.selections.push({ options: [], selected: null, imgNo: this.imgNo() })
                }
            },
            removeSecondaryItem(index) {
                this.secondaryItems.selections.splice(index, 1)
                this.updateOptions()
            },
            clearSelection(selection) {
                selection.selected = null
                this.updateOptions()
            },
            addAssociation (newTag) {
                const tag = {
                    value: newTag,
                    name: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
                }
                this.associationOptions.push(tag)
                this.associations.push(tag)
            },
            updateOptions() {
                this.$nextTick(() => {
                    this.secondaryItems.options = [...this.secondaryItemsInitial]
                    this.secondaryItems.selections.forEach((val, key) => {
                        if (val.selected !== null) {
                            this.secondaryItems.options.splice(this.secondaryItems.options.findIndex(function (i) {
                                return i.id === val.selected.id
                            }), 1)
                        }
                    })
                })
            },
            touchStartSwipe (touchEvent) {
                if (touchEvent.changedTouches.length !== 1) {
                    return
                }
                const posXStart = touchEvent.changedTouches[0].clientX
                addEventListener('touchend', (touchEvent) => this.touchEndSwipe(touchEvent, posXStart), {once: true})
            },
            touchEndSwipe (touchEvent, posXStart) {
                if (touchEvent.changedTouches.length !== 1) {
                    return
                }
                const posXEnd = touchEvent.changedTouches[0].clientX
                if (posXStart < posXEnd) {
                    if (this.primaryItems.selected > 0) {
                        this.prevSlide()
                    }
                } else if (posXStart > posXEnd) {
                    if(this.primaryItems.selected + 1 < this.primaryItems.cardCount) {
                        this.nextSlide()
                    }
                }
            }
        },
        computed: {
            secondaryItemsArr () {
                let arr = []
                this.secondaryItems.selections.forEach((selection) => {
                    if (selection.selected !== null) {
                        arr.push(selection.selected.item_id)
                    }
                })
                return arr
            },

        },
        watch: {
            'primaryItems.items': {
                handler() {
                    this.$nextTick(() => {
                        this.primaryItems.cards = document.querySelectorAll('.p-card-container')
                        this.updateCarousel()
                    })
                },
                deep: true
            },

        }
    }
</script>
<style scoped>

.fade-enter-active, .fade-leave-active {
    transition: opacity .3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>
