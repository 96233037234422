<template>
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-12">

            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <div class="form-check">
                            <input class="form-check-input" type="checkbox" name="bulkActivated" id="bulkActivated" v-model="bulkActivated">
                            <label class="form-check-label" for="bulkActivated"> Für alle Artikel dieses Herstellers die selbe Promotion erstellen</label>
                        </div>
                    </div>
                    <div class="card-body" v-if="bulkActivated">
                        <div class="mb-3">Es sind <b>{{ unlinkedItems }}</b> Artikel nicht mit dieser Promotion verlinkt. Promotion speichern um Artikel neu zu verlinken.</div>
                        <div class="form-group row">
                            <label for="bulkDiscountPercent" class="col-auto col-form-label">Preissenkung in Prozent</label>
                            <div class="col-auto">
                                <input type="number" id="bulkDiscountPercent" class="form-control" name="bulkDiscountPercent" v-model="bulkDiscountPercent">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: [
            'bulkActivatedInit',
            'bulkDiscountPercentInit',
            'promotionId',
            'oldData'
        ],

        data: () => ({
            isLoading: true,
            fetched: false,
            bulkActivated: 0,
            unlinkedItems: 0,
            bulkDiscountPercent: 0,
        }),

        methods: {
            init() {
                this.isLoading = true
                axios.get('/api/promotions/' + this.promotionId + '/bulk/check')
                    .then(response => {
                        if (response.data !== undefined) {
                            this.unlinkedItems = response.data
                        }

                    })
                    .catch()
                    .finally(() => {
                        this.isLoading = false
                        this.fetched = true
                    })
            }
        },

        beforeMount() {
            this.bulkActivated = this.bulkActivatedInit
            this.bulkDiscountPercent = this.bulkDiscountPercentInit
        },

        mounted() {
            if (this.bulkActivated) {
                this.init()
            }
        },
        watch: {
            bulkActivated: function () {
                if (this.bulkActivated && !this.fetched) {
                    this.init();
                }
            }
        }
    }
</script>
