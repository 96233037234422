<template>
    <div class="card">
        <div class="card-header">
            <div class="row">
                <div class="col-8"><h5>Zugewiesene {{ mainText }}-Produkte</h5></div>
                <div class="col-4 text-end"><a class="btn btn-sm btn-outline-success" href="#" data-bs-toggle="modal" :data-bs-target="'#addItemModal-' + key">Produkt zuweisen</a></div>
            </div>
        </div>
        <div class="card-body p-0">
            <template v-if="items.length">
                <table class="table table-striped mb-0 noselect">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Bezeichnung</th>
                            <th class="text-center">online</th>
                            <th></th>
                        </tr>
                    </thead>
                    <draggable tag="tbody" v-model="items" item-key="id" @change="reSort" handle=".handle">
                        <template #item="{ element, index }">
                            <tr>
                                <td><span class="handle draggable"><i class="fas fa-bars"></i></span></td>

                                <template v-if="element.item === null">
                                    <td class="fw-bold text-danger">Gelöschtes Produkt !</td>
                                </template>
                                <template v-else>
                                    <td>{{ element.item.brand.name }} {{ element.item.name }}</td>
                                </template>

                                <td class="text-center">
                                    <span v-if="element.active" class="dot dot-success"></span>
                                    <span v-else class="dot dot-danger"></span>
                                </td>

                                <td style="width: 1px;white-space: nowrap;">
                                    <a href="#" @click.prevent="destroy(element.id)"><i class="fa fa-times" aria-hidden="true"></i></a>
                                </td>
                            </tr>
                        </template>
                    </draggable>
                </table>
            </template>
            <template v-else>
                <div class="p-4 text-center">
                    Hier wurden noch keine Produkte hinzugefügt
                </div>
            </template>
        </div>
        <div :id="'addItemModal-' + key" class="modal fade" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ mainText }}-Produkt hinzufügen</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row mb-2">
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="ps-1 pb-1" for="inputItemSearch">{{ mainText }}-Produkt</label>
                                    <template v-if="selected === null">
                                        <input class="form-control" type="text" id="inputItemSearch" name="item_search" v-model="search" @focus="entriesVisible = true" @blur="entriesVisible = false" @click="entriesVisible = true" autocomplete="off">
                                        <span class="loading form-control-feedback" v-show="isLoading"></span>
                                        <div class="autocomplete-results" v-if="entries.length && entriesVisible">
                                            <div class="list-group">
                                                <div class="list-group-item" v-for="entry in entries" @mousedown.prevent @click="select(entry)">
                                                    <div class="row">
                                                        <div class="col-2">
                                                            <template v-if="entry.images.length">
                                                                <img :src="'/storage/img/uploads/products/' + entry.id + '/' + entry.images[0].webp_medium" alt="" class="img-fluid">
                                                            </template>
                                                            <template v-else>
                                                                <img src="/storage/img/general/brokenimage.png" alt="" class="img-fluid">
                                                            </template>
                                                        </div>
                                                        <div class="col-10 justify-content-center align-self-center">
                                                            {{ entry.brand.name }} {{ entry.name }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <small id="amountHelp" class="form-text text-muted">Verwende '%' als Platzhalter</small>
                                    </template>
                                    <template v-else>
                                        <div class="row align-items-center">
                                            <div class="col-10">
                                                <input type="text" class="form-control" disabled :value="selected.brand.name + ' ' + selected.name">
                                            </div>
                                            <div class="col-2">
                                                <a href="#" @click.prevent="selected = null"><i class="fa fa-2x fa-trash" aria-hidden="true"></i></a>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" @click.prevent="store" :disabled="isLoading || selected === null">Speichern & Hinzufügen</button>
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" :disabled="isLoading">Abbrechen</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import draggable from "vuedraggable";

    export default {
        components: {draggable},

        props: [
            'primary'
        ],

        data() {
            return {
                canSave: false,
                isLoading: false,
                items: {},

                search: '',
                entries: {},
                entriesVisible: false,

                selected: null,

                addItemModal: null,
            }
        },

        mounted() {
            this.fetch();
            this.addItemModal = bootstrap.Modal.getOrCreateInstance('#addItemModal-' + this.key)

        },

        methods: {
            store() {
                this.isLoading = true
                axios.post('/api/backend/kit-creations/items', {
                    primary: this.primary,
                    item_id: this.selected.id,
                })
                    .then(response => {
                        if (response.status === 200) {
                            this.addItemModal.hide()
                            this.selected = null
                        }
                    })
                    .catch(error => {})
                    .finally(() => {
                        this.fetch()
                        this.isLoading = false
                    })
            },
            fetch() {
                axios.get('/api/backend/kit-creations/items?primary=' + this.primary)
                    .then(response => {
                        this.items = response.data
                    })
                    .catch(error => {})
                    .finally(() => ( this.isLoading = false ))
            },
            destroy(itemId) {
                this.isLoading = true
                axios.delete('/api/backend/kit-creations/items/' + itemId)
                    .then(response => {})
                    .catch(error => {
                        if (error.response.data.hasOwnProperty('errors')) {
                        }
                    })
                    .finally(() => {
                        this.fetch();
                        this.isLoading = false
                    })
            },
            onContentChange(e) {
                this.canSave = true;
            },
            select(entry) {
                this.selected = entry
            },
            reSort(e) {
                this.isLoading = true
                axios.put('/api/backend/kit-creations/items/resort', {
                    items: this.items
                })
                .then(response => {
                    if (response.status === 200) {}
                })
                .catch(error => {
                    if (error.response.data.hasOwnProperty('errors')) {
                        this.hasErrors = true
                        this.errors = error.response.data.errors
                    }
                })
                .finally(() => {
                    this.isLoading = false
                })
            }
        },

        watch: {
            search (val) {
                if (this.search.length < 3) return
                this.isLoading = true

                axios.get('/api/items/search', {params: {term: this.search, filterCategory: JSON.stringify(this.filterCategory)}})
                    .then(response => {
                        this.entries = response.data;
                    })
                    .catch(err => {})
                    .finally(() => (this.isLoading = false))
            },
        },
        computed: {
            mainText () {
                return this.primary ? 'Primär' : 'Sekundär'
            },
            key () {
                return this.primary ? 'primary' : 'secondary'
            },
            btnExecuteText () {

            },
            btnCancelText () {

            }
        },
    }
</script>
