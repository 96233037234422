<template>
    <div :class="setClass" v-click-outside="closePicker">

        <input type="text" :id="id" class="form-control is-required"

               autocomplete="new-code"
               :class="{'is-invalid': hasError}"
               :placeholder="label"
               :name="name"
               :readonly="partnerDisable"
               v-model="input"
               @keydown.enter = 'enter'
               @keydown.down = 'down'
               @keydown.up = 'up'
               @input = 'change'
               @focus="openPicker"
               @click="openPicker"
               >
        <label :for="id" class="required">{{label}}</label>

        <ul class="dropdown-menu dropdown-menu-light" style="width:100%; cursor: pointer" v-bind:class="{'d-block': open}">
                <li class="dropdown-item" v-for="(suggestion, index) in matches"
                @click.prevent="suggestionClick(index)"
            >
                <a href="#" style="color: #ffffff;font-weight: bold;">{{ suggestion }}</a>
            </li>
        </ul>

        <div v-if="hasError" class="invalid-feedback">
            {{ error }}
        </div>

    </div>
</template>
<script>
export default {
    data() {
        return {
            open: false,
            current: 0,
            input: ''
        }
    },
    props: {
        value: {
            type: String,
            default: null
        },
        name: {
            type: String,
            required: true
        },
        id: {
            type: String,
            required: false
        },
        setClass: {
            type: String,
            required: false
        },
        error: {
            type: String
        },
        hasError: {
            type: Boolean,
            required: true
        },
        partnerDisable: {
            type: Boolean,
            required: false,
            default: false
        },
        label: {
            type: String,
            required: true
        },
        suggestions: {
            type: Array,
            required: true
        },
    },
    watch: {
        value(val) {
            this.input = val
        }
    },
    mounted() {
        this.input = this.value
    },
    computed: {
        matches() {
            return this.suggestions.filter((str) => {
                return str.indexOf(this.input) >= 0;
            });
        },
        openSuggestion() {
            return this.input !== "" &&
                this.matches.length !== 0 &&
                this.open === true;
        }
    },
    methods: {
        togglePicker() {
            if (this.open)
                this.open = false
            else
                this.open = true
        },
        openPicker() {
            if (this.matches.length !== 0)
                this.open = true
        },
        closePicker() {
            this.open = false
        },
        enter() {
            this.input = this.matches[this.current];
            this.open = false;
        },
        up() {
            if(this.current > 0)
                this.current--;
        },
        down() {
            if(this.current < this.matches.length - 1)
                this.current++;
        },
        isActive(index) {
            return index === this.current;
        },
        change() {
            if (this.open === false) {
                this.open = true;
                this.current = 0;
            }
            this.$emit('input', this.input)
        },
        suggestionClick(index) {
            this.$emit('input', this.matches[index])
            this.input = this.matches[index]
            this.open = false;
        },
    }
}
</script>
