<template>
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-12">

            </div>
        </div>
        <div class="row">
            <div class="col-5">
                <combo-input-card name="combi-items-primary" :init-selected="entriesPrimary"></combo-input-card>
            </div>
            <div class="col-2 text-center">
                <span style="font-size: 4rem">+</span>
            </div>
            <div class="col-5">
                <combo-input-card name="combi-items-secondary" :init-selected="entriesSecondary"></combo-input-card>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: [
            'promotionId',
            'oldData'
        ],

        data: () => ({
            isLoading: false,
            entriesPrimary: [],
            entriesSecondary: []
        }),

        methods: {
            fetch() {
                axios.get('/api/promotions/' + this.promotionId + '/combo/items')
                    .then(response => {
                        if(response.data.primary !== undefined && response.data.secondary !== undefined) {
                            this.entriesPrimary = response.data.primary
                            this.entriesSecondary = response.data.secondary
                        }
                    })
            }
        },

        mounted() {
            if (this.notEmptyObject(this.oldData)) {
                if (this.oldData.hasOwnProperty('combi-items-primary')) {
                    for (let obj in this.oldData['combi-items-primary']) {
                        axios.get('/api/items/' + this.oldData['combi-items-primary'][obj])
                            .then(response => {
                                this.entriesPrimary.push(response.data)
                            })

                    }
                }
                if (this.oldData.hasOwnProperty('combi-items-secondary')) {
                    for (let obj in this.oldData['combi-items-secondary']) {
                        axios.get('/api/items/' + this.oldData['combi-items-secondary'][obj])
                            .then(response => {
                                this.entriesSecondary.push(response.data)
                            })

                    }
                }
            } else {
                this.fetch()
            }
        },
    }
</script>