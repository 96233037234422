<template>
    <div class="calc-result">
        <template v-if="promo.type === 'combi_cb_v2' && promo.secondaries === null">
            <promo-calculation-result-line
                :name="promoName"
                :text="'Sparen'"
                :discount="promo.credit"
            ></promo-calculation-result-line>
        </template>
        <template v-if="promo.type === 'combi_cb_v2' && promo.secondaries !== null">
            <promo-calculation-result-line
                :name="promoName"
                :text="'Sparen*'"
                :text-secondary="'*zum Beispiel bei Kauf in Kombination mit ' + promo.secondaries[0].name"
                :item-add="promo.secondaries[0].id"
                :discount="promo.credit"
                :in-percent="!!promo.in_percent"
                @add-item="addItem"
            ></promo-calculation-result-line>
        </template>
        <template v-if="promo.type === 'voucher'">
            <promo-calculation-result-line
                :name="promoName"
                :text="'Einkaufsgutschein'"
                :discount="promo.discount"
            ></promo-calculation-result-line>
        </template>
        <template v-if="promo.type === 'free_items'">
            <promo-calculation-result-line
                :name="promoName"
                :text="'Gratisartikel'"
                :free-items="promo.freeItems"
            ></promo-calculation-result-line>
        </template>
        <template v-if="promo.type === 'trade_in_manual' || promo.type === 'trade_in'">
            <promo-calculation-result-line
                :name="promoName"
                :text="'Trade-In'"
                :discount="promo.discount"
                :in-percent="!!promo.in_percent"
            ></promo-calculation-result-line>
        </template>
        <template v-if="promo.type === 'cb'">
            <promo-calculation-result-line
                :name="promoName"
                :text="'Cashback'"
                :discount="promo.discount"
                :in-percent="!!promo.in_percent"
            ></promo-calculation-result-line>
        </template>
        <template v-if="promo.type === 'combi_cbdisabled'">
            <promo-calculation-result-line
                :name="promoName"
                :text="'Kombi-Cashback, mit den hier ausgewählten Produkten'"
                :discount="promo.discount"
                :in-percent="!!promo.in_percent"
            ></promo-calculation-result-line>
        </template>
        <template v-if="promo.type === 'instant_discount'">
            <promo-calculation-result-line
                :name="promoName"
                :text="'Sofortrabatt'"
                :discount="promo.discount"
                :in-percent="!!promo.in_percent"
            ></promo-calculation-result-line>
        </template>
        <template v-if="promo.type === 'voucher_uploaddisa'">
            <promo-calculation-result-line
                :name="promoName"
                :text="'Sofortrabatt möglich*'"
                :text-secondary="'* wenn du einen Canon-Profi-Voucher hast und diesen im Warenkorb eingibst'"
                :discount="promo.discount"
                :in-percent="!!promo.in_percent"
            ></promo-calculation-result-line>
        </template>
        <template v-if="promo.type === 'multi_combi_instant_cashback'">
            <promo-calculation-result-line
                :name="promoName"
                :text="'Sofortrabatt möglich*'"
                :discount="promo.discount"
                :in-percent="!!promo.in_percent"
            ></promo-calculation-result-line>
        </template>
        <template v-if="promo.type === 'combi_instant_discount_voucher'">
            <template v-if="promo.primaryCombos === null">
            </template>
            <template v-else>
                <promo-calculation-result-line
                    :name="promoName"
                    :text="'Sofortrabatt möglich*'"
                    :text-secondary="'*zum Beispiel bei Kauf in Kombination mit ' + promo.primaryCombos.bestItem.name"
                    :item-add="promo.primaryCombos.bestItem.id"
                    :discount="promo.primaryCombos.bestDiscount"
                    :in-percent="!!promo.in_percent"
                    @add-item="addItem"
                ></promo-calculation-result-line>
            </template>
        </template>
    </div>
</template>

<script>

export default {

    props: [
        'promo'
    ],

    data () {
        return {}
    },
    mounted () {},
    unmounted () {},
    methods: {
        addItem(id) {
            this.$emit('add-item', id)
        }
    },
    computed: {
        promoName() {
            if (this.promo.brandPromotion !== null) {
                return this.promo.brandPromotion.brand + ' ' +this.promo.brandPromotion.name
            } else {
                return null
            }
        }
    },
    watch: {}
}
</script>
