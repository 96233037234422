<template>
    <div class="tradein-container">
        <button class="btn btn-primary" @click.prevent="openModalSelect">Formular direkt ausfüllen oder hochladen</button>

        <!-- Modal -->
        <div :id="'modal-tradein-select-' + key" class="modal fade" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Trade-In beanspruchen</h5>
                        <button type="button" class="btn-close" @click.prevent="modalCloseSelect" aria-label="Close"></button>
                    </div>
                    <div class="modal-body text-center">
                        <p class="mb-0">Bitte wähle aus, wie du am Trade-In teilnehmen möchten.</p>
                        <p class="mb-3">Bei beiden Optionen wird der Trade-In-Betrag im Anschluss noch im Warenkorb abgezogen.</p>

                        <button type="button" @click.prevent="openModalOnline" class="btn btn-primary w-100 mb-3">Direkt online ausfüllen und absenden</button>
                        <button type="button" @click.prevent="openModalDocument" class="btn btn-secondary w-100">Formular herunterladen und anschließend hochladen</button>
                    </div>
                    <div class="modal-footer d-block text-center">
                        <button type="button" class="btn btn-secondary" @click.prevent="modalCloseSelect">Abbrechen und kein Trade-In nutzen</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal -->
        <!-- Modal -->
        <div :id="'modal-tradein-document-' + key" class="modal fade" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Trade-In Formular hochladen</h5>
                        <button type="button" class="btn-close" @click.prevent="modalCloseDocument" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <p class="mb-3">Natürlich kannst du das Trade-In Formular auch per Hand ausfüllen, unterschreiben und als PDF oder Bild-Datei wieder hochladen. Das Formular kannst du dazu im ersten Schritt herunterladen.</p>
                        <p class="mt-0 mb-1 text-center">1. <a :href="'/checkout/cart/download/tradein/' + pid + '/form'" target="_blank">Formular herunterladen</a></p>
                        <p class="mt-0 mb-1 text-center">2. Formular ausfüllen, unterschreiben und digital bereitstellen</p>
                        <p class="mt-0 mb-3 text-center">3. Datei auswählen und hochladen</p>
                        <div class="mb-3">
                            <input class="form-control" :class="{'is-invalid': (errors.hasOwnProperty('document'))}" type="file" id="tradein-document" @change="setDocument">
                            <div v-if="errors.hasOwnProperty('document')" class="invalid-feedback fs-6">{{ errors['document'][0] }}</div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click.prevent="modalCloseDocument">Abbrechen</button>
                        <button type="button" class="btn btn-primary" @click.prevent="submitFormDocument" :disabled="isLoading">Formular absenden</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal -->
        <!-- Modal -->
        <div :id="'modal-tradein-online-' + key" class="modal fade" tabindex="-1">
            <div class="modal-dialog modal-lg modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Trade-In online ausfüllen</h5>
                        <button type="button" class="btn-close" @click.prevent="modalCloseOnline" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <p class="mb-0 text-center">Um das Trade-In zu beanspruchen benötigen wir ein paar Informationen zu deinem Altgerät.</p>
                        <p class="mb-3 text-center">Bitte zögere nicht uns zu kontaktieren, solltest du Fragen zum Vorgang haben oder nicht genau wissen wie oder was du einschicken kannst.</p>

                        <div class="mb-3 form-floating">
                            <input type="text" class="form-control" :class="{'is-invalid': (errors.hasOwnProperty('brand'))}" id="tradein-brand" placeholder="z.B. Canon" required v-model="dataOnline.brand">
                            <label for="tradein-brand" class="form-label mb-1">Hersteller</label>
                            <div id="tradein-brand-help" class="form-text px-75" :class="{'invalid-feedback': (errors.hasOwnProperty('brand'))}">Bitte trage hier den Hersteller deines Eintauschgerätes ein.</div>
                        </div>
                        <div class="mb-3 form-floating">
                            <input type="text" class="form-control" :class="{'is-invalid': (errors.hasOwnProperty('model'))}" id="tradein-model" placeholder="z.B. EOS 850D" required v-model="dataOnline.model">
                            <label for="tradein-model" class="form-label mb-1">Modell</label>
                            <div id="tradein-model-help" class="form-text px-75" :class="{'invalid-feedback': (errors.hasOwnProperty('model'))}">Bitte trage hier die Modellbezeichnung deines Eintauschgerätes ein.</div>
                        </div>
                        <div class="mb-3 form-floating">
                            <input type="text" class="form-control" :class="{'is-invalid': (errors.hasOwnProperty('serial'))}" id="tradein-serial" placeholder="z.B. J026G83" required v-model="dataOnline.serial">
                            <label for="tradein-serial" class="form-label mb-1">Seriennummer</label>
                            <div id="tradein-serial-help" class="form-text px-75" :class="{'invalid-feedback': (errors.hasOwnProperty('serial'))}">Bitte trage hier die Seriennummer deines Eintauschgerätes ein.</div>
                        </div>
                        <div class="card">
                            <div class="card-header py-3 bg-input border-bottom-0">
                                <div class="text-toggle">
                                    <input type="checkbox" id="tradein-company" v-model="dataOnline.company" />
                                    <label for="tradein-company" class="">
                                        <div class="can-toggle__label-text">Bist du Gewerbetreibende:r?</div>
                                        <div class="can-toggle__switch" data-checked="Ja" data-unchecked="Nein"></div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div id="tradein-company-help" class="form-text px-75 mb-3">Bitte gib an ob du Gewerbetreibende:r bist.</div>
                        <div class="card" :class="{'is-invalid': (errors.hasOwnProperty('signature.isEmpty'))}">
                            <div class="card-header bg-input px-75">
                                <p class="mb-1">Unterschrift</p>
                                <div id="tradein-signature-help" class="form-text" :class="{'invalid-feedback d-block p-0': (errors.hasOwnProperty('signature.isEmpty'))}">Bitte unterschreibe mit deiner Maus oder auf Touch-Geräten mit einem Sift oder deinem Finger.</div>
                            </div>
                            <div class="card-body p-0">
                                <div class="signature-container">
                                    <button class="btn btn-sm btn-outline-secondary btn-signature-reset" @click.prevent="resetSignature">Feld zurücksetzen</button>
                                    <VueSignaturePad
                                        id="signature"
                                        width="100%"
                                        height="100%"
                                        ref="signaturePad"
                                        :options="options"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click.prevent="modalCloseOnline" :disabled="isLoading">Abbrechen</button>
                        <button type="button" class="btn btn-primary" @click.prevent="submitFormOnline" :disabled="isLoading">Formular absenden</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal -->
    </div>
</template>
<script>

    export default {
        props: [
            'id',
            'itemId',
            'pid'
        ],
        data() {
            return {
                key: this.id,
                error: false,
                isLoading: false,
                modalSelect: null,
                modalOnline: null,
                modalDocument: null,
                bsModalSelect: null,
                bsModalOnline: null,
                bsModalDocument: null,
                signature: null,
                dataOnline: {
                    type: 'online',
                    pid: this.pid,
                    brand: '',
                    model: '',
                    serial: '',
                    company: false,
                    signature: null
                },
                dataDocument: {
                    type: 'document',
                    pid: this.pid,
                    file: null
                },
                options: {
                    dotSize: (0.5 + 2.5) / 2,
                    minWidth: 0.5,
                    maxWidth: 2.5,
                    throttle: 16,
                    minDistance: 5,
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    penColor: '#131370',
                    velocityFilterWeight: 0.7,
                    onBegin: this.onBegin,
                    onEnd: this.onEnd
                },
                errors: {}
            }
        },
        mounted() {
            this.modalSelect   = document.getElementById('modal-tradein-select-'   + this.key)
            this.modalOnline   = document.getElementById('modal-tradein-online-'   + this.key)
            this.modalDocument = document.getElementById('modal-tradein-document-' + this.key)

            this.bsModalSelect   = new bootstrap.Modal(this.modalSelect,   {backdrop: 'static'})
            this.bsModalOnline   = new bootstrap.Modal(this.modalOnline,   {backdrop: 'static'})
            this.bsModalDocument = new bootstrap.Modal(this.modalDocument, {backdrop: 'static'})

            this.modalOnline.addEventListener('shown.bs.modal', () => {
                this.$refs.signaturePad.resizeCanvas()
                this.$refs.signaturePad.openSignaturePad()
            })
        },
        methods: {
            openModalSelect() {
                this.bsModalSelect.show()
            },
            openModalOnline() {
                this.bsModalOnline.show()
                this.$refs.signaturePad.resizeCanvas()
                this.$refs.signaturePad.openSignaturePad()
            },
            openModalDocument() {
                this.bsModalDocument.show()
            },
            setDocument(event) {
                this.dataDocument.file = event.target.files[0]
            },
            resetSignature() {
                this.$refs.signaturePad.clearSignature();
            },
            submitFormDocument() {
                this.handleDocument()
            },
            submitFormOnline() {
                this.dataOnline.signature = this.$refs.signaturePad.saveSignature();
                if (this.dataOnline.signature !== null && this.dataOnline.signature.isEmpty) {
                    //return
                }
                this.handleOnline()
            },
            handleDocument() {
                this.isLoading = true
                this.errors = {}
                let formData = new FormData();
                formData.append('type', this.dataDocument.type)
                formData.append('pid', this.dataDocument.pid)
                if (this.dataDocument.file !== null) {
                    formData.append('document', this.dataDocument.file)
                }
                axios.post('/checkout/cart/upload/tradein/' + this.itemId, formData,{headers: {'Content-Type': 'multipart/form-data'}})
                    .then(response => {
                        location.reload();
                    })
                    .catch(err => {
                        this.errors = err.response.data.errors
                    })
                    .finally(() => {
                        this.isLoading = false
                    })
            },
            handleOnline() {
                this.isLoading = true
                this.errors = {}
                axios.post('/checkout/cart/upload/tradein/' + this.itemId, this.dataOnline)
                    .then(response => {
                        location.reload();
                    })
                    .catch(err => {
                        this.errors = err.response.data.errors
                        this.modalOnline.scrollTo({ top: 0, behavior: 'smooth' });
                    })
                    .finally(() => {
                        this.isLoading = false
                    })
            },
            modalCloseOnline() {
                this.bsModalOnline.hide()
                this.resetSignature()
            },
            modalCloseDocument() {
                this.bsModalDocument.hide()
            },
            modalCloseSelect() {
                this.bsModalSelect.hide()
            },
        }
    }
</script>
