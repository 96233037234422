<template>
    <date-picker
        :input-class="inputClass"
        format="HH:mm"
        v-model:value="input"
        type="time"
        @change="change"
        :show-time-panel="showTimePanel"
        :placeholder="placeholder"
        :input-attr="{name: name, id: id}"
    >
        <template v-if="floating"  #input="input">
            <div class="form-floating">
                <input type="text"
                       :id="input.props.id"
                       :name="input.props.name"
                       v-model="input.props.value"
                       class="form-control"
                       :placeholder="input.props.placeholder"
                       @input="input.events.input"
                       @change="input.events.change"
                       @keydown="input.events.keydown"
                       @focus="input.events.focus"
                       @blur="input.events.blur"
                >
                <label :for="input.props.id">{{ label }}</label>
            </div>
        </template>
    </date-picker>
</template>

<script>
import {parseDate, parseTime} from "@/composables/parseDateTime.js";

export default {

        props: {
            placeholder: {
                type: String,
                default: ''
            },
            label: {
                type: String,
                default: ''
            },
            name: {
                type: String,
                default: ''
            },
            id: {
                type: String,
                default: ''
            },
            switchable: {
                type: String,
                default: ''
            },
            floating: {
                type: String,
                default: ''
            },
            inputClass: {
                type: String,
                default: ''
            },
            wrapperClass: {
                type: String,
                default: ''
            },
            old: {
                type: String,
                default: null
            },
            oldFormat: {
                type: String,
                default: 'HH:MM'
            },
            val: {
                type: String,
                default: null
            },
            valFormat: {
                type: String,
                default: null
            },
            value: {
            }
        },

        data () {
            return {
                input: '',
                showTimePanel: false,
            }
        },
        mounted () {
            if (this.old !== null && this.old.length) {
                this.input = parseTime(this.old, this.oldFormat)
            } else {
                if (this.val !== null) {
                    this.input = parseTime(this.val, this.valFormat)
                }
            }
        },
        unmounted () {

        },
        methods: {
            change() {
                this.$emit('change')
                this.$emit('update:modelValue', this.input)
            },
            toggleTimePanel() {
                this.showTimePanel = !this.showTimePanel;
            },
        },
        computed: {
            inputVal: {
                get () {
                    return this.value
                },
                set (value) {
                    this.$emit('input', value)
                }
            }
        },
        watch: {
            value(val) {
                if (typeof val === 'string') {
                    val = parseDate(val).toDateString()
                }
                this.input = val
            }
        }
    }
</script>
