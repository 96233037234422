<template>
    <div class="modal fade" id="price-change-modal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Rabatt ändern</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-floating">
                                <input type="text" class="form-control" id="positionName" aria-describedby="positionNameHelp" v-model="editPosition.name" disabled>
                                <label for="positionName">Bezeichnung</label>
                                <small id="positionNameHelp" class="form-text text-muted">Positionsbezeichnung</small>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-floating">
                                <input type="number" class="form-control" id="quantity" aria-describedby="quantityHelp" v-model.number="editPosition.quantity" disabled>
                                <label for="quantity">Menge</label>
                                <small id="quantityHelp" class="form-text text-muted">Menge</small>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-floating">
                                <input type="number" class="form-control" id="price" aria-describedby="priceHelp" v-model.number="discount">
                                <label for="price">Rabatt</label>
                                <small id="priceHelp" class="form-text text-muted">Rabatt</small>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="reset">Abbrechen</button>
                    <button type="button" class="btn btn-primary" @click="handle">Speichern</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: [
          'editPosition'
        ],

        data() {
            return {
                discount: 0,
            }
        },
        methods: {
            handle() {
                this.add()
            },
            add() {
                this.isLoading = true
                this.$emit('update', this.editPosition.id, this.discount)
                this.reset()
                this.isLoading = false
            },
            reset() {

            }
        },
        watch: {
            editPosition() {
                this.discount = this.editPosition.discount
            }
        }
    }
</script>
