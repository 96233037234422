<template>
    <div class="local-search form-floating">
        <input type="text" class="form-control" id="local-search"
               @focus="suggestionEntriesVisible = true"
               @blur="suggestionEntriesVisible = false"
               @click="suggestionEntriesVisible = true"
               :placeholder="placeholder" autocomplete="new-search"
               v-model="searchInput">
        <span class="form-control-feedback" :style="[isSearchLoading ? {} : {'opacity' : '0'}]">
            <i class="fas fa-spinner fa-pulse"></i>
        </span>
        <label class="fw-bold" for="local-search">{{ placeholder }}</label>
        <div class="search-autocomplete-container"
             v-if="(suggestionEntries.queries.length || suggestionEntries.products.length) && suggestionEntriesVisible"
             style="padding-right: 1rem;padding-left: 1rem;">
            <div class="search-autocomplete-results">
                <div class="search-list-group">
                    <template v-if="suggestionEntries.queries.length">
                        <div class="search-list-group-header">Suchvorschläge</div>
                        <div
                            class="search-list-group-item"
                            v-for="entry in suggestionEntries.queries"
                            @mousedown.prevent
                            @click="searchInput = entry.name"
                        >
                            <div class="row">
                                <div class="col-12 d-flex align-items-center">
                                    {{ entry.name }}
                                </div>
                            </div>
                        </div>
                    </template>
                    <div class="search-list-group-header">Produktvorschläge</div>
                    <div
                        class="search-list-group-item py-1"
                        v-for="entry in suggestionEntries.products"
                        @mousedown.prevent
                        @click="emitClick(entry)">
                        <div class="row m-0">
                            <div class="col-1 px-0">
                                <img :src="entry[0].image" class="img-fluid" :alt="entry[0].name + ' Abbildung'" />
                            </div>
                            <div class="col-11">
                                {{ entry[0].name }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            placeholder: {
                type: String,
                default: 'Suchen..'
            }
        },

        data () {
            return {
                axiosCancel: () => {},
                searchInput: '',
                isSearchLoading: false,
                suggestionEntriesVisible: false,
                suggestionEntries: {
                    'products': [],
                    'queries': []
                },
            }
        },
        mounted () {

        },
        unmounted () {

        },
        methods: {
            search() {
                this.isSearchLoading = true;
                this.axiosCancel();

                axios.get('https://api-v3.semknox.com/search/suggestions', {
                    headers: null,
                    cancelToken: new axios.CancelToken(c => this.axiosCancel = c),
                    params: {
                        query: this.searchInput,
                        projectId: 28128,
                    },
                }).then(response => {
                    response.data.searchResults.forEach((entry) => {
                        if (entry.type === 'products') {
                            this.suggestionEntries.products = entry.results
                        } else if (entry.type === 'queries') {
                            this.suggestionEntries.queries = entry.results
                        }
                    })
                })
                    .catch(e => {(axios.isCancel(e))})
                    .finally(() => (this.isSearchLoading = false))
            },
            emitClick(item) {
                this.suggestionEntriesVisible = false
                this.$emit('click', item)
            }
        },
        watch: {
            searchInput() {
                if (this.searchInput.length < 3) return
                this.search()
            }
        }

    }
</script>
