<template>
    <div class="card bg-tertiary mb-3">
        <div class="card-header fw-bold" style="background: rgb(34,34,34);background: linear-gradient(90deg, rgba(34,34,34,1) 60%, rgba(228,0,0,1) 100%);">
            <p class="mb-0 fs-5">{{ index + 1 }}. {{ question.question }}</p>
            <p class="mb-0">{{ question.description }}</p>
            <template v-if="hasErrors && (errors.hasOwnProperty('answers.' + question.id))">
                <div class="d-flex align-items-center gap-2 invalid-feedback p-0 fs-6">
                    <i class="fas fa-2x fa-exclamation-triangle"></i> {{ errors['answers.' + question.id][0] }}
                </div>
            </template>
        </div>
        <div class="card-body" style="letter-spacing: 1px">


            <template v-if="question.type === 'text'">
                <input type="text"
                       class="form-control light fs-65"
                       :class="{'is-invalid border-danger': (hasErrors && (errors.hasOwnProperty('answers.' + question.id)))}"
                       :name="'question_' + question.id"
                       :id="'question_' + question.id"
                       @input="(event) => onAnswerChange(event.target.value)">
            </template>
            <template v-else-if="question.type === 'textarea'">
                <textarea class="form-control light fs-65"
                          :class="{'is-invalid border-danger': (hasErrors && (errors.hasOwnProperty('answers.' + question.id)))}"
                          :name="'question_' + question.id"
                          :id="'question_' + question.id"
                          @input="(event) => onAnswerChange(event.target.value)"></textarea>
            </template>
            <template v-else-if="question.type === 'multiinput'">
                <div  class="row">
                    <div class="col-6 col-md-4" v-for="(optionItem, optionIndex) in question.data.options">
                        <div class="form-floating">
                            <template v-if="optionItem.text === '#genderdropdown#'">
                                <select class="form-select light" :id="'input-' + optionItem.uuid" @change="(event) => onMultiAnswerChange(event, optionItem)">
                                    <option value="keine Angabe">keine Angabe</option>
                                    <option value="weiblich">weiblich</option>
                                    <option value="männlich">männlich</option>
                                    <option value="divers">divers</option>
                                </select>
                                <label :for="'input-' + optionItem">Geschlecht</label>
                            </template>
                            <template v-else>
                                <input type="text"
                                       :id="'input-' + optionItem.uuid"
                                       class="form-control light"
                                       :placeholder="optionItem.text"
                                       @input="(event) => onMultiAnswerChange(event, optionItem)">
                                <label :for="'input-' + optionItem">{{ optionItem.text }}</label>
                            </template>
                        </div>
                    </div>
                </div>
            </template>
            <template v-else-if="question.type === 'radio'">
                <div class="form-check fs-65" v-for="(optionItem, optionIndex) in question.data.options">
                    <input type="radio"
                           class="form-check-input light"
                           :class="{'is-invalid border-danger': (hasErrors && (errors.hasOwnProperty('answers.' + question.id)))}"
                           :name="'question_' + question.id"
                           :id="optionItem.uuid"
                           :value="optionItem.uuid"
                           @change="(event) => onAnswerChange(event.target.value)">
                    <label :for="optionItem.uuid" class="form-check-label">{{ optionItem.text }}</label>
                    <div class="py-2" v-if="optionItem.textfield">
                        <input type="text"
                               class="form-control light"
                               @input="(event) => onIndividualAnswerChange(event, optionItem)">
                    </div>
                </div>
            </template>
            <template v-else-if="question.type === 'checkbox'">
                <div class="form-check fs-65" v-for="(optionItem, optionIndex) in question.data.options">
                    <input type="checkbox"
                           class="form-check-input light"
                           :class="{'is-invalid border-danger': (hasErrors && (errors.hasOwnProperty('answers.' + question.id)))}"
                           :name="'question_' + optionIndex"
                           :id="optionItem.uuid"
                           :value="optionItem.uuid"
                           @change="(event) => onCheckboxChange(event, optionItem)">
                    <label :for="optionItem.uuid" class="form-check-label">{{ optionItem.text }}</label>
                    <div class="py-2" v-if="optionItem.textfield">
                        <input type="text" class="form-control light" @input="(event) => onIndividualAnswerChange(event, optionItem)">
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    export default {
        props: {
            hasErrors: {},
            errors: {},
            question: {},
            index: {},
        },
        data () {
            return {
                selectedOptions: {},
                multiInputs: {}
            }
        },
        methods: {
            onAnswerChange (value) {
                this.$emit('change', value)
            },

            onIndividualAnswerChange (event, option) {
                this.selectedOptions[option.uuid] = event.target.value
                let radioSelect = document.getElementById(option.uuid)
                radioSelect.checked = event.target.value.length > 0;
                this.onAnswerChange(this.selectedOptions)
            },
            onMultiAnswerChange (event, option) {
                this.multiInputs[option.uuid] = event.target.value
                this.onAnswerChange(this.multiInputs)
            },
            onCheckboxChange (event, option) {
                if (event.target.checked) {
                    this.selectedOptions[option.uuid] = null;
                } else {
                    delete this.selectedOptions[option.uuid];
                }

                this.onAnswerChange(this.selectedOptions)
            }
        }
    }
</script>
