<template>
    <div class="modal fade" id="item-modal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Artikel hinzufügen</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row mb-3">
                        <div class="col-12">
                            <div class="form-floating">
                                <select class="form-select" id="itemType" v-model="itemType" :disabled="item.data !== null">
                                    <option value="null" disabled>Auswählen</option>
                                    <option v-for="itemType in itemTypes" :value="itemType.index">{{ itemType.name }}</option>
                                </select>
                                <label for="itemType">Artikel-Typ</label>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <template v-if="item.data === null">
                            <div v-if="itemType === 'item'" class="col-12">
                                <div class="form-floating">
                                    <input type="text" id="searchItem" class="form-control" placeholder="Produkt suchen" v-model="itemSearch" @focus="itemEntriesVisible = true" @blur="itemEntriesVisible = false" @click="itemEntriesVisible = true" autocomplete="off">
                                    <label for="searchItem">Produkt suchen</label>
                                    <span class="loading form-control-feedback" v-show="isLoading"></span>
                                    <div class="autocomplete-results p-0" v-if="itemEntries.length && itemEntriesVisible">
                                        <div class="list-group">
                                            <div class="list-group-item" v-for="entry in itemEntries" @mousedown.prevent @click="selectItem(entry)">
                                                <div class="row">
                                                    <div class="col-2">
                                                        <template v-if="entry.images.length">
                                                            <img :data-src="'/storage/img/uploads/products/' + entry.id + '/' + entry.images[0].webp_medium" alt="" class="lazy img-fluid">
                                                        </template>
                                                        <template v-else>
                                                            <img data-src="/storage/img/general/brokenimage.png" alt="" class="lazy img-fluid">
                                                        </template>
                                                    </div>
                                                    <div class="col-10 justify-content-center align-self-center">
                                                        {{ entry.name }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="itemType === 'itemDemo'" class="col-12">
                                <div class="form-label-group">
                                    <input type="text" id="searchdemo" class="form-control" placeholder="Demo suchen" v-model="demoSearch" @focus="demoEntriesVisible = true" @blur="demoEntriesVisible = false" @click="demoEntriesVisible = true" autocomplete="off">
                                    <label for="searchItem">Demo-Artikel suchen</label>
                                    <span class="loading form-control-feedback" v-show="isLoading"></span>
                                    <div class="autocomplete-results p-0" v-if="demoEntries.length && demoEntriesVisible">
                                        <div class="list-group">
                                            <div class="list-group-item" v-for="entry in demoEntries" @mousedown.prevent @click="selectDemo(entry)">
                                                <div class="row">
                                                    <div class="col-2">
                                                        <template v-if="entry.item.images.length">
                                                            <img :data-src="'/storage/img/uploads/products/' + entry.item.id + '/' + entry.item.images[0].webp_medium" alt="" class="lazy img-fluid">
                                                        </template>
                                                        <template v-else>
                                                            <img data-src="/storage/img/general/brokenimage.png" alt="" class="lazy img-fluid">
                                                        </template>
                                                    </div>
                                                    <div class="col-10 justify-content-center align-self-center">
                                                        {{ entry.item.name }} - {{ entry.type.label }} - {{ entry.condition.label }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="itemType === 'workshop'" class="col-12">
                                <div class="form-label-group">
                                    <input type="text" id="searchWorkshop" class="form-control" placeholder="Workshop suchen" v-model="workshopSearch" @focus="workshopEntriesVisible = true" @blur="workshopEntriesVisible = false" @click="workshopEntriesVisible = true" autocomplete="off">
                                    <label for="searchItem">Workshop suchen</label>
                                    <span class="loading form-control-feedback" v-show="isLoading"></span>
                                    <div class="autocomplete-results" v-if="workshopEntries.length && workshopEntriesVisible">
                                        <div class="list-group">
                                            <div class="list-group-item" v-for="entry in workshopEntries" @mousedown.prevent @click="selectWorkshop(entry)">
                                                <div class="row">
                                                    <div class="col-2">
                                                        <template v-if="entry.list_image !== null">
                                                            <img :data-src="'/storage/img/uploads/workshops/' + entry.id + '/' + entry.list_image" alt="" class="lazy img-fluid">
                                                        </template>
                                                        <template v-else>
                                                            <img data-src="/storage/img/general/brokenimage.png" alt="" class="lazy img-fluid">
                                                        </template>
                                                    </div>
                                                    <div class="col-10 justify-content-center align-self-center">
                                                        {{ entry.name }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else-if="itemType === 'bonus'" class="col-12">
                                <div class="form-label-group">
                                    <input type="text" id="searchBonus" class="form-control" placeholder="Bonus suchen" v-model="bonusSearch" @focus="bonusEntriesVisible = true" @blur="bonusEntriesVisible = false" @click="bonusEntriesVisible = true" autocomplete="off">
                                    <label for="searchBonus">Bonus suchen</label>
                                    <span class="loading form-control-feedback" v-show="isLoading"></span>
                                    <div class="autocomplete-results" v-if="bonusEntries.length && bonusEntriesVisible">
                                        <div class="list-group">
                                            <div class="list-group-item" v-for="entry in bonusEntries" @mousedown.prevent @click="selectBonus(entry)">
                                                <div class="row">
                                                    <div class="col-2">
                                                        <template v-if="entry.logo !== null">
                                                            <img :data-src="'/storage/img/uploads/bonus-items/' + entry.id + '/' + entry.logo" alt="" class="lazy img-fluid">
                                                        </template>
                                                        <template v-else>
                                                            <img data-src="/storage/img/general/brokenimage.png" alt="" class="lazy img-fluid">
                                                        </template>
                                                    </div>
                                                    <div class="col-10 justify-content-center align-self-center">
                                                        {{ entry.name }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="col-12">
                                <div class="form-group">
                                    <input type="text" value="Artikel-Typ auswählen" class="form-control" disabled>
                                </div>
                            </div>
                        </template>
                        <template v-else>
                            <div class="col-12">
                                <div class="row align-items-center mb-3">
                                    <div class="col-10">
                                        <input v-if="item.type === 'item'" type="text" class="form-control" disabled :value="item.data.brand.name + ' ' + item.data.name">
                                        <input v-if="item.type === 'itemDemo'" type="text" class="form-control" disabled :value="item.data.item.brand.name + ' ' + item.data.item.name + ' ' + item.data.type.label + ' ' + item.data.condition.label">
                                        <input v-if="item.type === 'workshop'" type="text" class="form-control" disabled :value="item.data.name">
                                        <input v-if="item.type === 'bonus'" type="text" class="form-control" disabled :value="item.data.name">
                                    </div>
                                    <div class="col-2">
                                        <a href="#" @click.prevent="item.data = null"><i class="fa fa-2x fa-trash" aria-hidden="true"></i></a>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-floating">
                                <input type="number" class="form-control" id="quantity" aria-describedby="quantityHelp" min="1" v-model.number="item.quantity">
                                <label for="quantity">Menge</label>
                                <small id="quantityHelp" class="form-text text-muted">Menge</small>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-floating">
                                <input type="number" class="form-control" id="price" aria-describedby="priceHelp" v-model.number="item.price">
                                <label for="price">Preis</label>
                                <small id="priceHelp" class="form-text text-muted">Preis</small>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="reset">Abbrechen</button>
                    <button type="button" class="btn btn-primary" :disabled="btnSave.disabled" @click="handle">{{ btnSave.label }}</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                axiosCancel: () => {},
                isLoading: false,
                itemTypes: [
                    {index: 'item',     name: 'Artikel'},
                    {index: 'itemDemo', name: 'Demo-Artikel'},
                    {index: 'workshop', name: 'Workshop'},
                    {index: 'bonus',    name: 'Bonus'}
                ],
                itemType: null,

                itemSearch: '',
                itemEntries: {},
                itemEntriesVisible: false,

                demoSearch: '',
                demoEntries: {},
                demoEntriesVisible: false,

                workshopSearch: '',
                workshopEntries: {},
                workshopEntriesVisible: false,

                bonusSearch: '',
                bonusEntries: {},
                bonusEntriesVisible: false,

                item: {
                    data: null,
                    type: null,
                    quantity: 1,
                    price: null
                },
                btnSave: {
                    label: 'Hinzufügen',
                    disabled: true,
                }
            }
        },
        methods: {
            handle() {
                this.add()
            },
            add() {
                this.isLoading = true;
                this.$emit('create', this.item);
                this.reset();
                this.isLoading = false
            },
            reset() {
                this.item.data = null;
                this.item.price = null;
                this.item.quantity = 1;
                this.itemType = null;
                this.itemSearch = '';
                this.demoSearch = '';
                this.workshopSearch = '';
                this.bonusSearch = '';
            },
            selectItem(entry) {
                this.item.data = entry;
                this.item.type = 'item';
            },
            selectDemo(entry) {
                this.item.data = entry;
                this.item.type = 'itemDemo';
            },
            selectWorkshop(entry) {
                this.item.data = entry;
                this.item.type = 'workshop';
            },
            selectBonus(entry) {
                this.item.data = entry;
                this.item.type = 'bonus';
            },
        },
        watch: {
            'item.data'() {
                this.btnSave.disabled = this.item.data === null;
            },
            itemSearch (val) {
                if (this.itemSearch.length < 3) return;
                this.isLoading = true;
                this.axiosCancel();

                axios.get('/api/items/search', {
                    cancelToken: new axios.CancelToken(c => this.axiosCancel = c),
                    params: {term: this.itemSearch, promotions: true}
                }).then(response => {
                    this.itemEntries = response.data;
                })
                .catch(e => {(axios.isCancel(e))})
                .finally(() => (this.isLoading = false))
            },
            demoSearch (val) {
                if (this.demoSearch.length < 3) return;
                this.isLoading = true;
                this.axiosCancel();

                axios.get('/api/items/demos/search', {
                    cancelToken: new axios.CancelToken(c => this.axiosCancel = c),
                    params: {term: this.demoSearch, promotions: true}
                }).then(response => {
                    this.demoEntries = response.data;
                })
                .catch(e => {(axios.isCancel(e))})
                .finally(() => (this.isLoading = false))

            },
            workshopSearch (val) {
                if (this.workshopSearch.length < 3) return;
                this.isLoading = true;
                this.axiosCancel();

                axios.get('/api/workshops/search', {
                    cancelToken: new axios.CancelToken(c => this.axiosCancel = c),
                    params: {term: this.workshopSearch}
                }).then(response => {
                    this.workshopEntries = response.data;
                })
                .catch(e => {(axios.isCancel(e))})
                .finally(() => (this.isLoading = false))

            },
            bonusSearch (val) {
                if (this.bonusSearch.length < 3) return;
                this.isLoading = true;
                this.axiosCancel();

                axios.get('/api/bonuses/search', {
                    cancelToken: new axios.CancelToken(c => this.axiosCancel = c),
                    params: {term: this.bonusSearch}
                }).then(response => {
                    this.bonusEntries = response.data;
                })
                .catch(e => {(axios.isCancel(e))})
                .finally(() => (this.isLoading = false))

            },
        }
    }
</script>
