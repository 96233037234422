<template>
    <transition name="fade">
        <div class="newsletter-popup" v-show="showPopup">
            <div class="newsletter-popup-content p-4 rounded-4">
                <div class="popup-close">
                    <a href="#" class="fw-bold fs-3 a-info" @click.prevent="closePopup()"><i class="far fa-times-circle"></i></a>
                </div>
                <div class="row">
                    <div class="d-none d-md-flex flex-column justify-content-center col-md-4">
                        <img src="/storage/images/RW_MF.png" class="img-fluid" alt="">
                    </div>
                    <div class="col-12 col-md-8 d-flex flex-column py-4">
                        <p class="fs-3 fw-bold">Jetzt Newsletter abonnieren und immer auf dem Laufenden bleiben</p>
                        <p class="fs-5">Als Dankeschön winkt ein Kapitel aus Kyra & Christian Sängers Buch "Makrofotografie - die große Fotoschule".</p>
                        <p class="fs-5">Außerdem erhältst du einen <span class="price-font text-primary fw-bold">10,00€</span> Einkaufsgutschein für deinen nächsten Einkauf bei uns.</p>

                        <div class="mt-auto">
                            <div class="d-flex gap-5 justify-content-center">
                                <a href="#" class="btn btn-secondary text-uppercase fw-bold rounded-3 fs-5" @click.prevent="closePopup()">Nein danke</a>
                                <a href="#" class="btn btn-primary text-uppercase fw-bold rounded-3 fs-5" @click.prevent="goTo()">Jetzt anmelden</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <p class="mb-0 text-info small">*Der Versand des Kapitels und Gutscheins, erfolgt nach erfolgreicher Bestätigung der Newsletteranmeldung per E-Mail.</p>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
export default {
    props: [
        'raffle'
    ],
    data() {
        return {
            showPopup: false,
            evtListener: null,
        }
    },
    mounted() {

        if (!this.getCookie('nlpop')) {
            setTimeout(() => {
                document.addEventListener("mouseout", this.onMouseOut);
            }, 5_000)
        }

    },
    computed: {

    },
    watch: {

    },
    methods: {
        goTo() {
            this.setCookie('nlpop', true, 365)
            window.location.replace('/newsletter?ref=nlp')
        },

        closePopup() {
            this.setCookie('nlpop', true, 365)
            this.showPopup = false
        },
        onMouseOut(event) {
            if (event.clientY < 50 && !this.getCookie('nlpop')) {
                document.removeEventListener("mouseout", this.onMouseOut)
                this.showPopup = true
            }
        },
        setCookie(name, value, days) {
            let expires = '';

            if (days) {
                const date = new Date();
                date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                expires = '; expires=' + date.toUTCString();
            }

            document.cookie = name + '=' + (value || '')  + expires + ';';
        },
        getCookie(name) {
                const cookies = document.cookie.split(';');

                for (const cookie of cookies) {
                    if (cookie.indexOf(name + '=') > -1) {
                        return cookie.split('=')[1];
                    }
                }

                return null;
            }
        }
}
</script>
<style scoped>
    .fade-enter-active, .fade-leave-active {
        transition: opacity 1s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
</style>
