<template>
    <div class="card">
        <div class="card-header">
            <div class="row">
                <div class="col-8"><h5>Zugewiesene Artikel</h5></div>
                <div class="col-4 text-end"><a class="btn btn-sm btn-info" href="#" data-bs-toggle="modal" data-bs-target="#addItemModal">Artikel zuweisen</a></div>
            </div>
        </div>
        <div class="card-body p-0">
            <template v-if="items.length">
                <table class="table table-striped mb-0 noselect" id="partnershop-items">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Produkt-ID</th>
                            <th>Bezeichnung</th>
                            <th>abweichender Preis</th>
                            <th>Rabatt</th>
                            <th class="text-center">online</th>
                            <th></th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in items">
                            <td>{{ item.id }}</td>
                            <td>{{ item.item_id }}</td>
                            <td>{{ item.item.brand.name }} {{ item.item.name }}</td>
                            <td style="white-space: nowrap;">
                                <template v-if="item.price !== null">{{ item.price.toFixed(2).replace(".", ",") }} €</template>
                            </td>
                            <td style="white-space: nowrap;">
                                <template v-if="item.discount !== null">{{ item.discount.toFixed(2).replace(".", ",") }} €</template>
                            </td>
                            <td class="text-center">
                                <span v-if="item.active" class="dot dot-success"></span>
                                <span v-else class="dot dot-danger"></span>
                            </td>
                            <td style="width: 1px;white-space: nowrap;">
                                <a href="#" @click.prevent="edit(index)"><i class="fas fa-edit" aria-hidden="true"></i></a>
                            </td>
                            <td style="width: 1px;white-space: nowrap;">
                                <a href="#" @click.prevent="destroy(item.id)"><i class="fa fa-times" aria-hidden="true"></i></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </template>
            <template v-else>
                <div class="p-4 text-center">
                    Es wurden noch keine Produkte zu diesem Shop hinzugefügt
                </div>
            </template>
        </div>
        <div id="addItemModal" class="modal fade" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Artikel hinzufügen</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row" v-if="hasErrors && errors.hasOwnProperty('exists')">
                            <div class="col">
                                <div class="alert alert-danger">
                                    {{ errors.exists }}
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 mb-3">

                                    <template v-if="selected === null">
                                        <div class="form-floating">
                                            <input class="form-control" type="text" id="inputItemSearch" name="item_search" v-model="search" @focus="entriesVisible = true" @blur="entriesVisible = false" @click="entriesVisible = true" autocomplete="off" placeholder="Artikel">
                                            <label for="inputItemSearch">Artikel</label>
                                            <span class="loading form-control-feedback" v-show="isLoading"></span>
                                            <div class="autocomplete-results" v-if="entries.length && entriesVisible">
                                                <div class="list-group">
                                                    <div class="list-group-item" v-for="entry in entries" @mousedown.prevent @click="select(entry)">
                                                        <div class="row">
                                                            <div class="col-2">
                                                                <template v-if="entry.images.length">
                                                                    <img :data-src="'/storage/img/uploads/products/' + entry.id + '/' + entry.images[0].webp_medium" alt="" class="lazy img-fluid">
                                                                </template>
                                                                <template v-else>
                                                                    <img data-src="/storage/img/general/brokenimage.png" alt="" class="lazy img-fluid">
                                                                </template>
                                                            </div>
                                                            <div class="col-10 justify-content-center align-self-center">
                                                                {{ entry.brand.name }} {{ entry.name }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <small id="amountHelp" class="form-text text-muted">Verwende '%' als Platzhalter</small>
                                    </template>
                                    <template v-else>
                                        <div class="row align-items-center">
                                            <div class="col-10">
                                                <div class="form-floating">
                                                    <input type="text" class="form-control" disabled :value="selected.brand.name + ' ' + selected.name" placeholder="Artikel">
                                                    <label for="inputItemSearch">Artikel</label>
                                                </div>
                                            </div>
                                            <div class="col-2 text-center">
                                                <a href="#" @click.prevent="selected = null"><i class="fa fa-2x fa-trash" aria-hidden="true"></i></a>
                                            </div>
                                        </div>
                                    </template>

                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-12">
                                <div class="form-floating">
                                    <input type="text" id="inputPrice" class="form-control" aria-describedby="codeHelp"
                                           :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('price'))}" name="price"
                                           v-model="newItem.price" placeholder="Abweichender Preis">
                                    <label for="inputPrice">Abweichender Preis</label>
                                    <div v-if="hasErrors && errors.hasOwnProperty('price')" class="invalid-feedback">
                                        {{ errors.price[0] }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-12">
                                <div class="form-floating">
                                    <input type="text" id="newItemDiscount" class="form-control" aria-describedby="discountHelp"
                                           :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('discount'))}" name="discount"
                                           v-model="newItem.discount" placeholder="Abweichender Preis">
                                    <label for="editItemDiscount">Rabatt (Überschreibt einen abweichenden Preis!)</label>
                                    <div v-if="hasErrors && errors.hasOwnProperty('discount')" class="invalid-feedback">
                                        {{ errors.discount[0] }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="form-check form-switch">
                                    <input type="checkbox" class="form-check-input" id="newItemActive" name="active" v-model="newItem.active">
                                    <label class="form-check-label" for="newItemActive">Aktiv/Online</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" @click.prevent="store" :disabled="isLoading || !selected">Speichern & Hinzufügen</button>
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" :disabled="isLoading">Abbrechen</button>
                    </div>
                </div>
            </div>
        </div>
        <div id="editItemModal" class="modal fade" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Eintrag bearbeiten</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row mb-3">
                            <div class="col-12">
                                <div class="form-floating">
                                    <input type="text" class="form-control" disabled :value="editItem.name" placeholder="Artikel">
                                    <label for="inputItemSearch">Artikel</label>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-12">
                                <div class="form-floating">
                                    <input type="text" id="editItemPrice" class="form-control" aria-describedby="codeHelp"
                                           :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('price'))}" name="price"
                                           v-model="editItem.price" placeholder="Abweichender Preis">
                                    <label for="editItemPrice">Abweichender Preis</label>
                                    <div v-if="hasErrors && errors.hasOwnProperty('price')" class="invalid-feedback">
                                        {{ errors.price[0] }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-12">
                                <div class="form-floating">
                                    <input type="text" id="editItemDiscount" class="form-control" aria-describedby="discountHelp"
                                           :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('discount'))}" name="discount"
                                           v-model="editItem.discount" placeholder="Abweichender Preis">
                                    <label for="editItemDiscount">Rabatt (Überschreibt einen abweichenden Preis!)</label>
                                    <div v-if="hasErrors && errors.hasOwnProperty('discount')" class="invalid-feedback">
                                        {{ errors.discount[0] }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="form-check form-switch">
                                    <input type="checkbox" class="form-check-input" id="editItemActive" name="active" :checked="editItem.active" v-model="editItem.active">
                                    <label class="form-check-label" for="editItemActive">Aktiv/Online</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" @click.prevent="update" :disabled="isLoading">Speichern</button>
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" :disabled="isLoading">Abbrechen</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: [
            'affiliateForm'
        ],

        data() {
            return {
                canSave: false,
                hasErrors: false,
                errors: {},
                isLoading: false,

                entries: {},
                search: '',
                entriesVisible: false,
                selected: null,

                items: {},

                newItem: {
                    item_id: null,
                    price: null,
                    discount: null,
                    active: true
                },

                editItem: {
                    item: null,
                    name: null,
                    price: 0,
                    discount: 0,
                    active: false
                },

                editItemModal:  null,
                addItemModal:  null
            }
        },

        mounted() {
            this.fetch();

            this.addItemModal = bootstrap.Modal.getOrCreateInstance(document.getElementById('addItemModal'))
            //this.editItemModal = new bootstrap.Modal(document.getElementById('addItemModal'), options)
            this.editItemModal = bootstrap.Modal.getOrCreateInstance(document.getElementById('editItemModal'))
        },

        methods: {
            store() {
                this.isLoading = true
                this.newItem.item_id = this.selected.id
                axios.post('/api/backend/affiliates/forms/' + this.affiliateForm.id + '/items', {
                    item_id: this.newItem.item_id,
                    price: this.newItem.price,
                    discount: this.newItem.discount,
                    active: this.newItem.active
                })
                    .then(response => {
                        if (response.status === 200) {
                            this.addItemModal.hide();
                            this.selected = null
                        }
                    })
                    .catch(error => {
                        if (error.response.data.hasOwnProperty('errors')) {
                            this.hasErrors = true
                            this.errors = error.response.data.errors
                        }
                        if (error.response.data === 'Already exists') {
                            this.hasErrors = true
                            this.errors = {
                                exists: 'Dieses Produkt ist der Liste bereits hinzugefügt.'
                            }
                        }
                    })
                    .finally(() => {
                        this.fetch()
                        this.isLoading = false
                    })
            },
            update() {
                this.isLoading = true
                axios.put('/api/backend/affiliates/forms/' + this.affiliateForm.id + '/items/' + this.editItem.item.id, {
                    price: this.editItem.price,
                    discount: this.editItem.discount,
                    active: this.editItem.active
                })
                    .then(response => {
                        if (response.status === 200) {
                            this.editItemModal.hide();
                        }
                    })
                    .catch(error => {
                        if (error.response.data.hasOwnProperty('errors')) {
                            this.hasErrors = true
                            this.errors = error.response.data.errors
                        }
                    })
                    .finally(() => {
                        this.fetch();
                        this.isLoading = false
                    })
            },
            fetch() {
                axios.get('/api/backend/affiliates/forms/' + this.affiliateForm.id + '/items')
                    .then(response => {
                        this.items = response.data
                    })
                    .catch(error => {})
                    .finally(() => (this.isLoading = false))
            },
            edit(index) {
                this.editItem.item = this.items[index]
                this.editItem.name = this.items[index].item.brand.name + ' ' + this.items[index].item.name
                this.editItem.price = this.items[index].price
                this.editItem.discount = this.items[index].discount
                this.editItem.voucher = this.items[index].voucher
                this.editItem.featured = this.items[index].featured
                this.editItem.active = this.items[index].active
                this.editItemModal.show();
            },
            destroy(itemId) {
                this.isLoading = true
                axios.delete('/api/backend/affiliates/forms/' + this.affiliateForm.id + '/items/' + itemId)
                    .then(response => {})
                    .catch(error => {
                        if (error.response.data.hasOwnProperty('errors')) {
                            this.hasErrors = true
                            this.errors = error.response.data.errors
                        }
                    })
                    .finally(() => {
                        this.fetch();
                        this.isLoading = false
                    })
            },
            onContentChange(e) {
                this.canSave = true;
            },
            select(entry) {
                this.selected = entry
            },
            reSort(e) {
                this.isLoading = true
                axios.put('/api/backend/partners/shops/' + this.partnerShop.id + '/items/resort', {
                    items: this.items
                })
                .then(response => {
                    if (response.status === 200) {}
                })
                .catch(error => {
                    if (error.response.data.hasOwnProperty('errors')) {
                        this.hasErrors = true
                        this.errors = error.response.data.errors
                    }
                })
                .finally(() => {
                    this.isLoading = false
                })
            }
        },

        watch: {
            search (val) {
                if (this.search.length < 3) return
                this.isLoading = true

                axios.get('/api/items/search', {params: {term: this.search, filterCategory: JSON.stringify(this.filterCategory)}})
                    .then(response => {
                        this.entries = response.data;
                    })
                    .catch(err => {})
                    .finally(() => (this.isLoading = false))
            },
        },
    }
</script>
