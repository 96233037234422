<template>
    <div id="itemRating">
        <template v-if="rating === 0">
            <i class="fas fa-star review-star-empty"></i>
            <i class="fas fa-star review-star-empty"></i>
            <i class="fas fa-star review-star-empty"></i>
            <i class="fas fa-star review-star-empty"></i>
            <i class="fas fa-star review-star-empty"></i>
        </template>
        <template v-else-if="rating === 0.5">
            <i class="fas fa-star review-star-half"></i>
            <i class="fas fa-star review-star-empty"></i>
            <i class="fas fa-star review-star-empty"></i>
            <i class="fas fa-star review-star-empty"></i>
            <i class="fas fa-star review-star-empty"></i>
        </template>
        <template v-else-if="rating === 1">
            <i class="fas fa-star review-star-full"></i>
            <i class="fas fa-star review-star-empty"></i>
            <i class="fas fa-star review-star-empty"></i>
            <i class="fas fa-star review-star-empty"></i>
            <i class="fas fa-star review-star-empty"></i>
        </template>
        <template v-else-if="rating === 1.5">
            <i class="fas fa-star review-star-full"></i>
            <i class="fas fa-star review-star-half"></i>
            <i class="fas fa-star review-star-empty"></i>
            <i class="fas fa-star review-star-empty"></i>
            <i class="fas fa-star review-star-empty"></i>
        </template>
        <template v-else-if="rating === 2">
            <i class="fas fa-star review-star-full"></i>
            <i class="fas fa-star review-star-full"></i>
            <i class="fas fa-star review-star-empty"></i>
            <i class="fas fa-star review-star-empty"></i>
            <i class="fas fa-star review-star-empty"></i>
        </template>
        <template v-else-if="rating === 2.5">
            <i class="fas fa-star review-star-full"></i>
            <i class="fas fa-star review-star-full"></i>
            <i class="fas fa-star review-star-half"></i>
            <i class="fas fa-star review-star-empty"></i>
            <i class="fas fa-star review-star-empty"></i>
        </template>
        <template v-else-if="rating === 3">
            <i class="fas fa-star review-star-full"></i>
            <i class="fas fa-star review-star-full"></i>
            <i class="fas fa-star review-star-full"></i>
            <i class="fas fa-star review-star-empty"></i>
            <i class="fas fa-star review-star-empty"></i>
        </template>
        <template v-else-if="rating === 3.5">
            <i class="fas fa-star review-star-full"></i>
            <i class="fas fa-star review-star-full"></i>
            <i class="fas fa-star review-star-full"></i>
            <i class="fas fa-star review-star-half"></i>
            <i class="fas fa-star review-star-empty"></i>
        </template>
        <template v-else-if="rating === 4">
            <i class="fas fa-star review-star-full"></i>
            <i class="fas fa-star review-star-full"></i>
            <i class="fas fa-star review-star-full"></i>
            <i class="fas fa-star review-star-full"></i>
            <i class="fas fa-star review-star-empty"></i>
        </template>
        <template v-else-if="rating === 4.5">
            <i class="fas fa-star review-star-full"></i>
            <i class="fas fa-star review-star-full"></i>
            <i class="fas fa-star review-star-full"></i>
            <i class="fas fa-star review-star-full"></i>
            <i class="fas fa-star review-star-half"></i>
        </template>
        <template v-else-if="rating === 5">
            <i class="fas fa-star review-star-full"></i>
            <i class="fas fa-star review-star-full"></i>
            <i class="fas fa-star review-star-full"></i>
            <i class="fas fa-star review-star-full"></i>
            <i class="fas fa-star review-star-full"></i>
        </template>
        <template v-if="withText">
            <span class="text-tiny">{{ rating }} von 5 Sternen</span>
        </template>
    </div>
</template>

<script>
export default {
    props: [
        'rating',
        'withText'
    ],
    data() {
        return {


        }
    },
    mounted() {

    },
    methods: {

    },
    watch: {

    }
}
</script>
