<template>
    <div class="container-fluid p-0 herocontent-container mb-5" v-if="heroContents.length">
        <div class="row m-0 border-bottom border-dark herocontent-slider">
            <div class="col-12 p-0">
                <splide :slides="heroContents" :options="primaryOptions" ref="primary" @splide:moved="changeActiveIndex" class="test-padd">
                    <template v-for="(heroContent, index) in heroContents">
                        <template v-if="heroContent.type === 'image'">
                            <template v-if="heroContent.image !== null && heroContent.item_description_hero_content_image_id !== null">
                                <splide-slide :key="index">
                                    <img :data-src="'/storage/images/items/hc/' + heroContent.id + '/' + heroContent.image.medium"
                                         :data-srcset="'/storage/images/items/hc/' + heroContent.id + '/' + heroContent.image.small + ' 800w, ' +
                                                                                  '/storage/images/items/hc/' + heroContent.id + '/' + heroContent.image.medium + ' 1080w, ' +
                                                                                  '/storage/images/items/hc/' + heroContent.id + '/' + heroContent.image.large + ' 1440w, ' +
                                                                                  '/storage/images/items/hc/' + heroContent.id + '/' + heroContent.image.original + ' 2400w'"
                                         class="lazy img-fluid"
                                         :alt="'Hero-Content Image(' + index + ') for Product'">
                                </splide-slide>
                            </template>
                        </template>

                        <template v-if="heroContent.type === 'video'">
                            <template v-if="heroContent.video.platform === 'youtube' && heroContent.video.video_id !== null">
                                <splide-slide>
                                    <iframe title="Hero-Video Player"
                                        :data-src="'https://www.youtube-nocookie.com/embed/' + heroContent.video.video_id"
                                            frameborder="0"
                                            class="lazy"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            style="width: 100%; height: 100%">
                                    </iframe>
                                </splide-slide>
                            </template>
                        </template>
                    </template>
                </splide>
            </div>
        </div>
        <div class="row p-3 mb-3">
            <div class="col-12 col-md-6 px-5 herocontent-infobox">
                <h5 class="herocontent-infobox_header">{{ heroContents[activeIndex].heading }}</h5>
                <p class="herocontent-infobox_text">{{ heroContents[activeIndex].content }}</p>
            </div>
            <div class="col-12 col-md-6 px-5 px-md-0 pe-md-5">
                <splide :slides="heroContents" :options="secondaryOptions" ref="secondary">
                    <template v-for="(heroContent, index) in heroContents">
                        <template v-if="heroContent.type === 'image'">
                            <template v-if="heroContent.image !== null && heroContent.item_description_hero_content_image_id !== null">
                                <splide-slide :key="index">
                                    <img class="lazy" :data-src="'/storage/images/items/hc/' + heroContent.id + '/' + heroContent.image.tiny" :alt="'Hero-Content Thumbnail(' + index + ') for Product'">
                                </splide-slide>
                            </template>
                        </template>

                        <template v-if="heroContent.type === 'video'">
                            <template v-if="heroContent.video.platform === 'youtube' && heroContent.video.video_id !== null">
                                <splide-slide :key="index">
                                    <img class="lazy" :data-src="'https://i3.ytimg.com/vi/' + heroContent.video.video_id + '/maxresdefault.jpg'" :alt="'Hero-Content Video Thumbnail(' + index + ') for Product'">
                                </splide-slide>
                            </template>
                        </template>
                    </template>
                </splide>
            </div>
        </div>
    </div>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
export default {
    components: {
        Splide,
        SplideSlide,
    },
    props: [
        'heroContents'
    ],
    mounted () {
        this.$refs.primary.sync( this.$refs.secondary.splide );

        let items = this.$refs.secondary.splide.length; //Amount of items
        let perPage = this.$refs.secondary.splide.options.perPage; //The perPage value

        if ( items <= perPage ) { //If amount of items is equal or less than perPage value
            this.secondaryOptions.arrows = true
            this.$refs.secondary.splide.refresh()
        }
    },
    data(){
        return {
            activeIndex: 0,
            primaryOptions: {
                type      : 'loop',
                pagination: false,
                arrows    : false,
                crop: 0.5,
            },
            secondaryOptions: {
                arrows: false,
                type        : 'slide',
                perPage     : 3,
                rewind      : true,
                gap         : '1rem',
                pagination  : false,
                fixedWidth  : 150,
                fixedHeight : 100,
                cover       : true,
                focus       : 'center',
                isNavigation: true,
            },
        }
    },
    methods: {
        changeActiveIndex(splide, newIndex, oldIndex, destIndex) {
            this.activeIndex = newIndex
        }
    }
}
</script>
