<template>
    <div class="voucher_upload-container">
        <button class="btn btn-primary" @click.prevent="openModalDocument">Code eingeben & Gutschein hochladen</button>

        <!-- Modal -->
        <div :id="'modal-voucher-document-' + key" class="modal fade" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title"></h5>
                        <button type="button" class="btn-close" @click.prevent="modalCloseDocument" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <p class="mb-3"></p>
                        <div class="mb-3 form-floating">
                            <input type="text" class="form-control" :class="{'is-invalid': (errors.hasOwnProperty('text'))}" id="voucher-text" placeholder="Voucher-Code" required v-model="dataDocument.text">
                            <label for="tradein-brand" class="form-label mb-1">Gutschein-Code</label>
                            <div id="tradein-brand-help" class="form-text px-75" :class="{'invalid-feedback': (errors.hasOwnProperty('text'))}">Bitte trage den Gutscheincode ein.</div>
                        </div>
                        <p class="mt-0 mb-1">Gutschein hochladen</p>
                        <div class="mb-3">
                            <input class="form-control" :class="{'is-invalid': (errors.hasOwnProperty('document'))}" type="file" id="voucher-document" @change="setDocument">
                            <div v-if="errors.hasOwnProperty('document')" class="invalid-feedback fs-6">{{ errors['document'][0] }}</div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click.prevent="modalCloseDocument">Abbrechen</button>
                        <button type="button" class="btn btn-primary" @click.prevent="submitFormDocument" :disabled="isLoading">Formular absenden</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal -->
    </div>
</template>
<script>

    export default {
        props: [
            'id',
            'itemId',
            'pid',
            'name'
        ],
        data() {
            return {
                key: this.id,
                error: false,
                isLoading: false,
                modalDocument: null,
                bsModalDocument: null,
                dataDocument: {
                    type: 'document',
                    text: '',
                    file: null,
                    pid: this.pid
                },
                errors: {}
            }
        },
        mounted() {
            this.modalDocument = document.getElementById('modal-voucher-document-' + this.key)
            this.bsModalDocument = new bootstrap.Modal(this.modalDocument, {backdrop: 'static'})
        },
        methods: {
            openModalDocument() {
                this.bsModalDocument.show()
            },
            setDocument(event) {
                this.dataDocument.file = event.target.files[0]
            },
            submitFormDocument() {
                this.handleDocument()
            },
            handleDocument() {
                this.isLoading = true
                this.errors = {}
                let formData = new FormData();
                formData.append('type', this.dataDocument.type)
                formData.append('text', this.dataDocument.text)
                formData.append('pid', this.dataDocument.pid)
                if (this.dataDocument.file !== null) {
                    formData.append('document', this.dataDocument.file)
                }
                axios.post('/checkout/cart/upload/voucher/' + this.itemId, formData,{headers: {'Content-Type': 'multipart/form-data'}})
                    .then(response => {
                        location.reload();
                    })
                    .catch(err => {
                        this.errors = err.response.data.errors
                    })
                    .finally(() => {
                        this.isLoading = false
                    })
            },
            modalCloseDocument() {
                this.bsModalDocument.hide()
            },
        }
    }
</script>
