<template>
    <div class="ac_alert-modal_backdrop">
        <div class="ac_alert-modal m-4">
            <div class="card">
                <div class="card-header">
                    <p class="mb-0" :class="titleClass">{{ titleText }}</p>
                </div>
                <div class="p-4">
                    <p :class="bodyClass">{{ bodyText }}</p>
                </div>
                <div class="border-0 pb-4 container">
                    <div class="row">
                        <div class="col-12 col-md-6 text-center">
                            <button class="btn m-2" :class="btnExecuteClass" @click.prevent="handle(true)">{{ btnExecuteText }}</button>
                        </div>
                        <div class="col-12 col-md-6 text-center">
                            <button class="btn m-2" :class="btnCancelClass" @click.prevent="handle(false)">{{ btnCancelText }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {

        props: [
            'title',
            'titleClass',
            'body',
            'bodyClass',
            'btnCancel',
            'btnExecute',
            'btnCancelClass',
            'btnExecuteClass'
        ],

        data () {
            return {}
        },
        mounted () {
            document.body.classList.add('alert-modal_overflow');
        },
        unmounted () {
            document.body.classList.remove('alert-modal_overflow');
        },
        methods: {
            execute () {
                this.$emit('execute', true);
            },
            cancel () {
                this.$emit('cancel', false);
            },
            handle (a) {
                this.$emit('handle', a);
                if (a) {
                    this.execute();
                } else {
                    this.cancel();
                }
            }
        },
        computed: {
            titleText () {
                return (this.title !== null || this.title !== '') ? this.title : 'Achtung: Bestätigung erforderlich';
            },
            bodyText () {
                return (this.body !== null || this.body !== '') ? this.body : 'Soll diese Aktion durchgeführt werden?';
            },
            btnExecuteText () {
                return (this.btnExecute !== null || this.btnExecute !== '') ? this.btnExecute : 'Ok';
            },
            btnCancelText () {
                return (this.btnCancel !== null || this.btnCancel !== '') ? this.btnCancel : 'Abbrechen';
            }
        },
        watch: {

        }
    }
</script>

<style>
    .ac_alert-modal_content {
        color: #fff;
    }

    .ac_alert-modal_backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgb(0 0 0 / 80%);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
    }

    .ac_alert-modal {
        background: #141414;
        overflow-x: auto;
        display: flex;
        flex-direction: column;
    }

    @media (min-width: 700px) {
        .ac_alert-modal {
            max-width: 700px;
            margin-right: auto;
            margin-left: auto;
        }
    }
</style>
