<template>
  <div
    class="modal fade"
    id="voucher-modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">Gutschein hinzufügen</h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row mb-3">
            <div class="col-12">
              <div class="form-floating">
                <select
                  class="form-select"
                  placeholder="Wie hinzufügen?"
                  name="selectVoucherType"
                  id="selectVoucherType"
                  v-model="type"
                >
                  <option value="redeem">Gutschein verwenden</option>
                  <option value="create">Gutschein erstellen & verwenden</option>
                  <option value="endPrice">Gutschein zum Endpreis erstellen</option>
                </select>
                <label for="selectVoucherType">Wie hinzufügen?</label>
              </div>
            </div>
          </div>
          <div class="row mb-3" v-if="type === 'redeem'">
            <div class="col-12">
              <div class="form-floating">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Gutschein-Code"
                  id="voucherCode"
                  aria-describedby="voucherCodeHelp"
                  v-model="voucherCode"
                />
                <label for="voucherCode">Gutschein-Code</label>
                <small id="voucherCodeHelp" class="form-text text-muted"
                  >Gutscheincode eingeben</small
                >
              </div>
            </div>
          </div>
          <div class="row" v-if="type === 'create'">
            <div class="col-12 mb-3">
              <div class="form-floating">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Gutschein-Code"
                  id="newVoucherCode"
                  aria-describedby="voucherCodeHelp"
                  v-model="newVoucher.code"
                />
                <label for="voucherCode">Gutschein-Code</label>
                <small id="newVoucherCodeHelp" class="form-text text-muted"
                  >Gutscheincode eingeben</small
                >
              </div>
            </div>
            <div class="col-6 mb-3">
              <div class="form-floating">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Rabatt"
                  id="discountAmount"
                  aria-describedby="discountAmountHelp"
                  v-model="newVoucher.discount.amount"
                />
                <label for="voucherCode">Rabatt</label>
                <small id="discountAmountHelp" class="form-text text-muted"
                  >Punkt statt Komma verwenden</small
                >
              </div>
            </div>
            <div class="col-6 mb-3">
              <div class="form-floating">
                <select
                  class="form-select"
                  name="discountType"
                  id="discountType"
                  placeholder="Rabatt Art"
                  v-model="newVoucher.discount.type"
                >
                  <option value="euro">in Euro</option>
                  <option value="percent">in Prozent</option>
                </select>
                <label for="voucherCode">Rabatt Art</label>
              </div>
            </div>
            <div class="col-12 mb-3">
              <div class="row">
                <div class="col-6">
                  <div class="form-check form-switch">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="combinable"
                      v-model="newVoucher.combinable"
                    />
                    <label class="form-check-label" for="combinable">Kombinierbar</label>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 mb-3">
              <div class="form-floating">
                <textarea
                  placeholder="Kommentar"
                  class="form-control"
                  name="voucherComment"
                  id="voucherComment"
                  cols="30"
                  rows="2"
                  v-model="newVoucher.comment"
                ></textarea>
                <label for="voucherComment">Kommentar</label>
              </div>
            </div>
          </div>
          <div class="row" v-if="type === 'endPrice'">
            <div class="col-12 mb-3">
              <div class="form-floating">
                <input
                  type="text"
                  class="form-control"
                  id="voucherEndPriceCode"
                  aria-describedby="voucherEndPriceCodeHelp"
                  v-model="newVoucher.code"
                />
                <label for="voucherCode">Gutschein-Code</label>
              </div>
            </div>
            <div class="col-6 mb-3">
              <div class="form-floating">
                <input
                  type="text"
                  class="form-control"
                  id="voucherEndPrice"
                  aria-describedby="voucherEndPriceHelp"
                  v-model="voucherEndPrice"
                />
                <label for="voucherCode">Zu berechnender End-Preis</label>
                <small id="voucherEndPriceHelp" class="form-text text-muted"></small>
              </div>
            </div>
            <div class="col-6 mb-3 d-flex flex-column justify-content-center">
              <div class="form-check form-switch">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="combinable"
                  v-model="newVoucher.combinable"
                />
                <label class="form-check-label" for="combinable">Kombinierbar</label>
              </div>
            </div>
            <div class="col-12 mb-3">
              <label for="voucherComment">Kommentar</label>
              <textarea
                class="form-control"
                name="voucherComment"
                id="voucherComment"
                cols="30"
                rows="2"
                v-model="newVoucher.comment"
              ></textarea>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-secondary"
            data-bs-dismiss="modal"
            @click="reset"
          >
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            :disabled="type === 'redeem' && btnSave.disabled"
            @click="handle"
          >
            {{ btnSave.label }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      hasErrors: false,
      errors: {},
      type: "redeem",
      voucherCode: "",
      newVoucher: {
        code: null,
        combinable: true,
        comment: null,
        discount: {
          amount: null,
          type: "euro",
        },
      },
      voucherEndPrice: 0,
      resetVoucher: {},
      btnSave: {
        label: "Prüfen und einsetzen",
        disabled: true,
      },
    };
  },
  methods: {
    handle() {
      if (this.type === "redeem" && this.voucherCode !== "") {
        this.checkVoucher();
      } else if (this.type === "create") {
        this.createVoucher();
      } else if (this.type === "endPrice") {
        this.createEndPrice();
      }
    },
    checkVoucher() {
      this.isLoading = true;
      axios
        .get("/api/vouchers/check", { params: { voucher: this.voucherCode } })
        .then((response) => {
          if (this.notEmptyObject(response.data)) {
            this.$emit("redeem", response.data);
            this.reset();
          }
        })
        .catch((err) => {})
        .finally(() => {
          this.isLoading = false;
        });
    },
    createVoucher() {
      this.isLoading = true;
      axios
        .post("/api/vouchers/doability", this.newVoucher)
        .then((response) => {
          if (response.data) {
            this.$emit("create", response.data);
            this.reset();
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.hasErrors = true;
            this.errors = error.response.data.errors;
          }
        })
        .finally(() => (this.isLoading = false));
    },
    createEndPrice() {
      this.isLoading = true;
      axios
        .post("/api/vouchers/checkcode", this.newVoucher)
        .then((response) => {
          if (response.data) {
            this.$emit("endPrice", {
              data: response.data,
              endPrice: this.voucherEndPrice,
            });
            this.reset();
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.hasErrors = true;
            this.errors = error.response.data.errors;
          }
        })
        .finally(() => (this.isLoading = false));
    },
    reset() {
      Object.assign(this.$data, this.$options.data());
    },
  },
  watch: {
    voucherCode() {
      this.btnSave.disabled = this.voucherCode === "";
    },
    type() {
      if (this.type === "redeem") {
        this.btnSave.label = "Prüfen und einsetzen";
      } else {
        this.btnSave.label = "Erstellen und einsetzen";
      }
    },
  },
};
</script>
