<template>
    <div class="pcalc-searchbox">
        <div class="form-floating" v-if="item.selected === null">
            <input type="text" class="form-control" :id="key" :placeholder="placeholder" v-model="item.searchParam" @focus="item.entriesVisible = true" @blur="item.entriesVisible = false" @click="item.entriesVisible = true" autocomplete="off">
            <label for="prod-item-1">{{ placeholder }}</label>
            <span class="loading form-control-feedback" v-show="item.isLoading"></span>
            <div class="autocomplete-results p-0" v-if="item.entries.length && item.entriesVisible">
                <div class="list-group">
                    <div class="list-group-item" v-for="entry in item.entries" @mousedown.prevent @click="selectItem(entry)">
                        <div class="row">
                            <div class="col-2">
                                <template v-if="entry.image">
                                    <img :src="entry.image" alt="" class="lazy img-fluid">
                                </template>
                                <template v-else>
                                    <img data-src="/storage/img/general/brokenimage.png" alt="" class="lazy img-fluid">
                                </template>
                            </div>
                            <div class="col-10 justify-content-center align-self-center">
                                {{ entry.name }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-floating" v-else>
            <input type="text" class="form-control" :placeholder="placeholder" :id="key" :value="item.selected.name" disabled>
            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger cursor-pointer" @click.prevent="reset">
                <i class="fas fa-times"></i>
            </span>
            <label for="prod-item-1">{{ placeholder }}</label>
        </div>
    </div>

</template>

<script>
export default {

    props: [
        'placeholder',
        'modelValue'
    ],

    data () {
        return {
            axiosCancel: () => {},
            key: this.$.vnode.key,
            item: {
                isLoading: false,
                searchParam: '',
                entries: [],
                entriesVisible: false,
                selected: null,
            },
        }
    },
    mounted () {
        if (this.modelValue) {
            this.item.selected = this.modelValue
        }
    },
    unmounted () {

    },
    methods: {
        selectItem(entry) {
            this.$emit('update:modelValue', entry)
            this.item.selected = entry
            this.item.entriesVisible = false
        },
        reset() {
            this.$emit('update:modelValue', null)
            this.item.selected = null
            this.item.searchParam = ''
            this.item.entries = []
            this.item.entriesVisible = false
        }
    },
    computed: {

    },
    watch: {
        'item.searchParam' () {
            if (this.item.searchParam.length < 3) return
            this.axiosCancel()

            axios.get('/pcalc/s', {
                cancelToken: new axios.CancelToken(c => this.axiosCancel = c),
                params: {term: this.item.searchParam}
            })
            .then(response => {
                this.item.entries = response.data
            })
            .catch(e => { axios.isCancel(e) })
            .finally(() => {
                this.item.isLoading = false
            })
        },
    }
}
</script>
