<template>
    <div class="card">
        <div class="card-body">
            <template v-if="!submitted">
                <div class="rafflecard-form row">
                    <div class="rafflecard-list col-12 col-lg-6 mb-5 mb-lg-0">
                        <div class="rafflecard-item">
                            <div class="rafflecard-item__side">
                                <div class="rafflecard-item__focus" v-bind:class="{'-active' : focusElementStyle }" v-bind:style="focusElementStyle" ref="focusElement"></div>
                                <div class="rafflecard-item__cover">
                                    <img v-bind:src="'/storage/images/raffle/bgs/' + currentCardBackground + '.webp'" class="rafflecard-item__bg">
                                </div>
                                <div class="rafflecard-item__wrapper">
                                    <div class="rafflecard-item__top">
                                        <div class="row">
                                            <div class="col text-nowrap h3">
                                                Gewinnspiel-Los
                                            </div>
                                            <div class="col-auto">
                                                <img data-src="/storage/images/raffle/raffle_ico.png" class="lazy rafflecard-item__icon">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                        <label for="email" class="rafflecard-item__info" ref="email">
                                            <div class="rafflecard-item__holder">E-Mail-Adresse</div>
                                            <transition name="slide-fade-up">
                                                <div class="rafflecard-item__name" v-if="email.length" key="1">
                                                    <transition-group name="slide-fade-right">
                                                        <span class="rafflecard-item__nameItem" v-for="(n, $index) in email.replace(/\s\s+/g, ' ')" v-if="$index === $index" v-bind:key="$index + 1">{{n}}</span>
                                                    </transition-group>
                                                </div>
                                                <div class="rafflecard-item__name" v-else key="2">Teilnehmer E-Mail</div>
                                            </transition>
                                        </label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6">
                                            <label for="prename" class="rafflecard-item__info" ref="prename">
                                                <div class="rafflecard-item__holder">Vorname</div>
                                                <transition name="slide-fade-down">
                                                    <div class="rafflecard-item__name" v-if="prename.length" key="3">
                                                        <transition-group name="slide-fade-right">
                                                            <span class="rafflecard-item__nameItem" v-for="(n, $index) in prename.replace(/\s\s+/g, ' ')" v-if="$index === $index" v-bind:key="$index + 1">{{n}}</span>
                                                        </transition-group>
                                                    </div>
                                                    <div class="rafflecard-item__name" v-else key="4">Vorname</div>
                                                </transition>
                                            </label>
                                        </div>
                                        <div class="col-6">
                                            <label for="name" class="rafflecard-item__info" ref="name">
                                                <div class="rafflecard-item__holder">Nachname</div>
                                                <transition name="slide-fade-up">
                                                    <div class="rafflecard-item__name" v-if="name.length" key="5">
                                                        <transition-group name="slide-fade-right">
                                                            <span class="rafflecard-item__nameItem" v-for="(n, $index) in name.replace(/\s\s+/g, ' ')" v-if="$index === $index" v-bind:key="$index + 1">{{n}}</span>
                                                        </transition-group>
                                                    </div>
                                                    <div class="rafflecard-item__name" v-else key="6">Nachname</div>
                                                </transition>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="rafflecard-form__inner col-12 col-lg-6">
                        <div class="row mb-3">
                            <div class="col">
                                <p class="fs-4 mb-0">Teilnehmerdaten</p>
                                <p class="text-info fs-6 mb-0">Gebe bitte deine Daten ein um am Gewinnspiel teilzunehmen</p>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="form-floating mb-3">
                                    <input type="text" id="email" class="form-control is-required"
                                           :class="{'is-invalid': hasErrors && errors.hasOwnProperty('email')}" placeholder="E-Mail-Adresse" name="email"
                                           v-model="email" v-on:focus="focusInput" v-on:blur="blurInput" data-ref="email" autocomplete="off">
                                    <label for="email" class="required">E-Mail-Adresse</label>
                                    <div v-if="hasErrors && errors.hasOwnProperty('email')" class="invalid-feedback fw-bold">
                                        {{ errors['email'][0] }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="form-floating mb-3">
                                    <input type="text" id="prename" class="form-control is-required"
                                           :class="{'is-invalid': hasErrors && errors.hasOwnProperty('prename')}" placeholder="Vorname" name="prename"
                                           v-model="prename" v-on:focus="focusInput" v-on:blur="blurInput" data-ref="prename" autocomplete="off">
                                    <label for="prename" class="required">Vorname</label>
                                    <div v-if="hasErrors && errors.hasOwnProperty('prename')" class="invalid-feedback fw-bold">
                                        {{ errors['prename'][0] }}
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-floating mb-3">
                                    <input type="text" id="name" class="form-control is-required"
                                           :class="{'is-invalid': hasErrors && errors.hasOwnProperty('name')}" placeholder="Nachname" name="name"
                                           v-model="name" v-on:focus="focusInput" v-on:blur="blurInput" data-ref="name" autocomplete="off">
                                    <label for="name" class="required">Nachname</label>
                                    <div v-if="hasErrors && errors.hasOwnProperty('name')" class="invalid-feedback fw-bold">
                                        {{ errors['name'][0] }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <div class="form-group form-check">
                                    <input type="checkbox" class="form-check-input" id="dsgvo" name="dsgvo" v-model="checkbox" :class="{'is-invalid': hasErrors && errors.hasOwnProperty('checkbox')}">
                                    <label class="form-check-label" for="dsgvo">Hiermit willige ich den Teilnahmebedingungen, sowie der Datenschutzerklärung ein.</label>
                                    <div v-if="hasErrors && errors.hasOwnProperty('checkbox')" class="invalid-feedback fw-bold">
                                        {{ errors['checkbox'][0] }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>
            <template v-if="!isSending && submitted">
                <div class="col px-4 pt-3 d-flex flex-column justify-content-center text-center">
                    <div class="row mb-3">
                        <div class="col">
                            <p class="mb-5 text-center h4 fw-bold">Vielen Dank für deine Teilnahme!</p>
                            <p class="mb-2 px-3 fw-bold">Wir haben dir eine E-Mail an “{{ email }}” gesendet.</p>
                            <p class="mb-2 px-3">
                                Bitte bestätige deine Teilnahme durch einfaches Klicken des Bestätigungslink in dieser E-mail.<br>Die Bestätigung ist notwendig, um eine missbräuchliche Verwendung deiner Mailaddresse zu verhindern.
                            </p>
                        </div>
                    </div>
                </div>
            </template>

        </div>
        <div v-if="!submitted" class="card-footer border-0 py-4">
            <div class="row">
                <div class="col text-center">
                    <template v-if="isSending">
                        <input type="button" @click.prevent="" class="btn btn-primary btn-lg" value="Bitte warten.." disabled>
                    </template>
                    <template v-else>
                        <input type="button" @click.prevent="submit" class="btn btn-primary btn-lg" value="Jetzt teilnehmen">
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: [
            'raffle'
        ],
        data() {
            return {
                key: this.id,
                hasErrors: false,
                isLoading: false,
                isSending: false,
                submitted: false,
                errors: {},

                currentCardBackground: Math.floor(Math.random()* 30 + 1), // just for fun :D

                email: "",
                prename: "",
                name: "",
                checkbox: false,

                focusElementStyle: null,
                isInputFocused: false
            }
        },
        mounted() {
           /* if(this.notEmptyObject(this.error)) {
                this.hasErrors = true
            }
*/
            document.getElementById("email").focus();
        },
        computed: {

        },
        watch: {
            cardYear () {

            }
        },
        methods: {
            submit() {
                this.isSending = true
                this.hasErrors = false

                axios.post('/gewinnspiel/' + this.raffle.id, {
                    email: this.email,
                    prename: this.prename,
                    name: this.name,
                    checkbox: this.checkbox,
                })
                    .then(response => {
                        if (response.status === 200 && !response.data.error) {
                            setTimeout(() => {
                                this.submitted = true
                                this.isSending = false
                            }, 1500)
                        }
                    })
                    .catch(error => {
                        if (error.response.data.hasOwnProperty('errors')) {
                            this.hasErrors = true
                            this.errors = error.response.data.errors
                            this.isSending = false
                        }
                    })
                    .finally(() => {
                    })
            },
            focusInput (e) {
                this.isInputFocused = true;
                let targetRef = e.target.dataset.ref;
                let target = this.$refs[targetRef];
                this.focusElementStyle = {
                    width: `${target.offsetWidth}px`,
                    height: `${target.offsetHeight}px`,
                    transform: `translateX(${target.offsetLeft}px) translateY(${target.offsetTop}px)`
                }
            },
            blurInput() {
                let vm = this;
                setTimeout(() => {
                    if (!vm.isInputFocused) {
                        vm.focusElementStyle = null;
                    }
                }, 300);
                vm.isInputFocused = false;
            }
        }
    }
</script>
