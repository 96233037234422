<template>
    <div id="create-order">
        <div class="container-fluid p-3" v-if="!order.complete">
            <div class="row mb-2">
                <div class="col-12">
                    <h2>Bestellung erstellen</h2>
                </div>
            </div>
            <div class="row">
                <div class="col-12" v-if="hasErrors">
                    <div class="alert alert-danger alert-dongle">
                        <div class="row align-items-center">
                            <div class="mx-3">
                                <i class="alert-icon fas fa-exclamation-triangle fa-2x m-0"></i>
                            </div>
                            <div>Beim Absenden sind Fehler aufgetreten. Bitte überprüfe die Eingabemaske auf Hinweise.</div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-6 pb-4">
                    <div class="card">
                        <div class="card-header">
                            <h5 class="mb-0">Rechnungsadresse</h5>
                        </div>
                        <div class="card-body">
                            <div class="form-floating mb-3">
                                <input type="email" id="inputEmail" class="form-control is-required" @focus="contactEntriesVisibleEmail = true" @blur="contactEntriesVisibleEmail = false" @click="contactEntriesVisibleEmail = true"
                                       :class="{'is-invalid': (hasErrors && (errors.hasOwnProperty('billing.email') || billing.email === ''))}" placeholder="E-Mail-Adresse" autocomplete="new-email"
                                       v-model="billing.email">
                                <label for="inputEmail" class="required">E-Mail-Adresse</label>
                                <span class="form-control-feedback" :style="[isLoadingSearchEmail ? {} : {'opacity' : '0'}]">
                                        <i class="fas fa-spinner fa-pulse" style="margin-top:65%;"></i>
                                    </span>
                                <div class="mod-autocomplete-container" v-if="contactEntries.length && contactEntriesVisibleEmail">
                                    <div class="mod-autocomplete-results">
                                        <div class="mod-list-group">
                                            <div class="mod-list-group-item" v-for="entry in contactEntries" @mousedown.prevent @click="selectContact(entry)">
                                                <div class="row">
                                                    <div class="col-12">{{ entry.billing_address.salutation }} {{ entry.billing_address.name }}, {{ entry.billing_address.prename }}</div>
                                                    <div class="col-12">{{ entry.billing_address.phone }} - {{ entry.billing_address.email }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="hasErrors && (errors.hasOwnProperty('billing.email'))" class="invalid-feedback">
                                    <i class="alert-icon fas fa-exclamation-triangle m-0"></i>
                                    {{ errors['billing.email'][0] }}
                                </div>
                            </div>
                            <div class="form-floating mb-3">
                                <input type="text" id="inputCustomerNo" class="form-control" placeholder="Kundennummer" @focus="contactEntriesVisibleCNo = true" @blur="contactEntriesVisibleCNo = false" @click="contactEntriesVisibleCNo = true"
                                       v-model="billing.customer_no" maxlength="254">
                                <label for="inputCustomerNo">Kundennummer</label>
                                <span class="form-control-feedback" :style="[isLoadingSearchCNo ? {} : {'opacity' : '0'}]">
                                        <i class="fas fa-spinner fa-pulse" style="margin-top:65%;"></i>
                                    </span>
                                <div class="mod-autocomplete-container" v-if="contactEntries.length && contactEntriesVisibleCNo">
                                    <div class="mod-autocomplete-results">
                                        <div class="mod-list-group">
                                            <div class="mod-list-group-item" v-for="entry in contactEntries" @mousedown.prevent @click="selectContact(entry)">
                                                <div class="row">
                                                    <div class="col-12">{{ entry.billing_address.salutation }} {{ entry.billing_address.name }}, {{ entry.billing_address.prename }}</div>
                                                    <div class="col-12">{{ entry.billing_address.phone }} - {{ entry.billing_address.email }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-floating mb-3">
                                <input type="text" id="inputTelephone" class="form-control is-required"
                                       :class="{'is-invalid': (hasErrors && (errors.hasOwnProperty('billing.phone') || billing.phone === ''))}" placeholder="Telefonnummer"
                                       v-model="billing.phone">
                                <label for="inputTelephone" class="required">Telefonnummer</label>
                                <div v-if="hasErrors && (errors.hasOwnProperty('billing.phone'))" class="invalid-feedback">
                                    <i class="alert-icon fas fa-exclamation-triangle m-0"></i>
                                    {{ errors['billing.phone'][0] }}
                                </div>
                            </div>
                            <div class="form-floating mb-3">
                                <select class="form-select is-required"
                                        :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('billing.salutation'))}" id="inputSalutation"
                                        v-model="billing.salutation">
                                    <option value="null" disabled selected>Auswählen</option>
                                    <option value="female">Frau</option>
                                    <option value="male">Herr</option>
                                </select>
                                <label for="inputSalutation" class="required">Anrede</label>
                                <div v-if="hasErrors && errors.hasOwnProperty('billing.salutation')" class="invalid-feedback">
                                    <i class="alert-icon fas fa-exclamation-triangle m-0"></i>
                                    {{ errors['billing.salutation'][0] }}
                                </div>
                            </div>

                            <div class="row g-3 mb-3">
                                <div class="form-floating col-12 col-md-6">
                                    <input data-was="pattern-" type="text" id="inputPrename" class="form-control is-required"
                                                   :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('billing.prename'))}" placeholder="Vorname"
                                                   :regReplace="''"
                                                   :regExp="/[^a-zA-ZäöüÄÖÜß\- .]/"
                                                   v-model="billing.prename" value="">

                                    <label for="inputPrename" class="required">Vorname</label>
                                    <div v-if="hasErrors && errors.hasOwnProperty('billing.prename')" class="invalid-feedback">
                                        <i class="alert-icon fas fa-exclamation-triangle m-0"></i>
                                        {{ errors['billing.prename'][0] }}
                                    </div>
                                </div>
                                <div class="form-floating col-12 col-md-6">
                                    <input data-was="pattern-" type="text" id="inputName" class="form-control is-required"
                                                   :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('billing.name'))}" placeholder="Nachname"
                                                   :regReplace="''"
                                                   :regExp="/[^a-zA-ZäöüÄÖÜß\- .]/"
                                                   v-model="billing.name" value=""
                                                   @focus="contactEntriesVisibleName = true" @blur="contactEntriesVisibleName = false" @click="contactEntriesVisibleName = true"
                                                   autocomplete="new-name">

                                    <label for="inputName" class="required">Nachname</label>
                                    <span class="form-control-feedback" v-show="isLoadingSearchName">
                                        <i class="fas fa-spinner fa-pulse" style="margin-top:65%;"></i>
                                    </span>
                                    <div class="mod-autocomplete-container-p5" v-if="contactEntries.length && contactEntriesVisibleName">
                                        <div class="mod-autocomplete-results">
                                            <div class="mod-list-group">
                                                <div class="mod-list-group-item" v-for="entry in contactEntries" @mousedown.prevent @click="selectContact(entry)">
                                                    <div class="row">
                                                        <div class="col-12">{{ entry.billing_address.salutation }} {{ entry.billing_address.name }}, {{ entry.billing_address.prename }}</div>
                                                        <div class="col-12">{{ entry.billing_address.phone }} - {{ entry.billing_address.email }}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="hasErrors && errors.hasOwnProperty('billing.name')" class="invalid-feedback">
                                        <i class="alert-icon fas fa-exclamation-triangle m-0"></i>
                                        {{ errors['billing.name'][0] }}
                                    </div>
                                </div>
                            </div>

                            <div class="form-floating mb-3">
                                <input type="text" id="inputCompany" class="form-control" placeholder="Firma (optional)"
                                       v-model="billing.company">
                                <label for="inputCompany">Firma (optional)</label>
                            </div>

                            <div class="row g-3 mb-3">
                                <div class="form-floating col-12 col-md-8">
                                    <input type="text" id="inputStreet" class="form-control is-required"
                                           :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('billing.street'))}" placeholder="Straße"
                                           v-model="billing.street">
                                    <label for="inputStreet" class="required">Straße</label>
                                    <div v-if="hasErrors && errors.hasOwnProperty('billing.street')" class="invalid-feedback">
                                        <i class="alert-icon fas fa-exclamation-triangle m-0"></i>
                                        {{ errors['billing.street'][0] }}
                                    </div>
                                </div>
                                <div class="form-floating col-12 col-md-4">
                                    <input type="text" id="inputStreetNo" class="form-control is-required"
                                           :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('billing.house_no'))}" placeholder="Hausnummer"
                                           v-model="billing.house_no">
                                    <label for="inputStreetNo" class="required">Hausnummer</label>
                                    <div v-if="hasErrors && errors.hasOwnProperty('billing.house_no')" class="invalid-feedback">
                                        <i class="alert-icon fas fa-exclamation-triangle m-0"></i>
                                        {{ errors['billing.house_no'][0] }}
                                    </div>
                                </div>
                            </div>

                            <div class="form-floating mb-3">
                                <input type="text" id="inputAddressAdditional" class="form-control" placeholder="Adresszusatz (optional)" autocomplete="new-AddressAdditional_1"
                                       v-model="billing.additional_address_information">
                                <label for="inputAddressAdditional">Adresszusatz (optional)</label>
                            </div>

                            <div class="row g-3 mb-3">
                                <div class="form-floating col-12 col-md-4">
                                    <input data-was="pattern-" type="text" id="inputPostcode" class="form-control is-required"
                                                   :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('billing.zip'))}" placeholder="Postleitzahl" autocomplete="new-zip"
                                                   :regReplace="''"
                                                   :regExp="patternZip"
                                                   v-model="billing.zip" value="">

                                    <label for="inputPostcode" class="required">Postleitzahl</label>
                                    <div v-if="hasErrors && errors.hasOwnProperty('billing.zip')" class="invalid-feedback">
                                        <i class="alert-icon fas fa-exclamation-triangle m-0"></i>
                                        {{ errors['billing.zip'][0] }}
                                    </div>
                                </div>
                                <div class="form-floating col-12 col-md-8">
                                    <input type="text" id="inputCity" class="form-control is-required"
                                           :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('billing.city'))}" placeholder="Ort"
                                           v-model="billing.city">
                                    <label for="inputCity" class="required">Ort</label>
                                    <div v-if="hasErrors && errors.hasOwnProperty('billing.city')" class="invalid-feedback">
                                        <i class="alert-icon fas fa-exclamation-triangle m-0"></i>
                                        {{ errors['billing.city'][0] }}
                                    </div>
                                </div>
                            </div>

                            <div class="form-floating mb-3">
                                <select class="form-select is-required"
                                        :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('billing.country'))}" id="inputCountry"
                                        v-model="billing.country">
                                    <option disabled selected>Auswählen</option>
                                    <option v-for="country in countries" :value="country.id" :selected="country.id === billing.country ? parseInt(billing.country) : 1" :data-eu="country.eu" :data-code="country.iso_code">{{ country.name }}</option>
                                </select>
                                <label for="inputCountry" class="required">Land</label>
                                <div v-if="hasErrors && errors.hasOwnProperty('billing.country')" class="invalid-feedback">
                                    <i class="alert-icon fas fa-exclamation-triangle m-0"></i>
                                    {{ errors['billing.country'][0] }}
                                </div>
                            </div>

                            <div class="form-floating" v-if="vatId">
                                <input placeholder="USt. ID (optional)" type="text" class="form-control" id="inputVatID"
                                       v-model="billing.vat_id">
                                <label for="inputVatID" class="">USt. ID (optional)</label>
                            </div>
                            <div class="row align-items-center">
                                <div class="col-6">
                                    <p class="mb-0"><i class="fa fa-star required-color" aria-hidden="true"></i> Pflichtfeld</p>
                                </div>
                                <div class="col-6 d-flex justify-content-end">
                                    <div class="form-check">
                                        <input type="checkbox" class="form-check-input" id="inputCreateContact" v-model="contactSave">
                                        <label class="form-check-label" for="inputCreateContact" >
                                            <template v-if="contactSelected === null">Als neuen Kontakt anlegen</template>
                                            <template v-else>Kontakt aktualisieren</template>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-6">
                    <div class="card">
                        <div class="card-header">
                            <h5 class="mb-0">Abweichende Lieferanschrift</h5>
                        </div>
                        <div class="card-body">
                            <div class="form-floating mb-3">
                                <select class="form-select" id="inputDelivery" name="inputDelivery" v-model="deliveryOption" :disabled="deliveryDisabled">
                                    <option v-for="deliveryOption in deliveryOptions" :value="deliveryOption.index">{{ deliveryOption.name }}</option>
                                </select>
                                <label for="inputCountry">Lieferadresse</label>
                            </div>
                            <template v-if="deliveryOption === 'billing-address'">
                                <div class="alert alert-info mb-0">Die Lieferung erfolgt an die Rechnungsadresse.</div>
                                <div class="alert alert-warning mt-3 mb-0" v-if="deliveryDisabled">Bei Angabe einer USt. ID wird an die Rechnungsadresse geliefert.</div>
                            </template>
                            <template v-if="deliveryOption === 'delivery-address'">
                                <div class="form-floating mb-3">
                                    <select class="form-select is-required" id="inputSalutation_delivery"
                                            :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.salutation'))}"
                                            v-model="delivery.salutation">
                                        <option value="null" disabled selected>Auswählen</option>
                                        <option value="female">Frau</option>
                                        <option value="male">Herr</option>
                                    </select>
                                    <label for="inputSalutation_delivery" class="required">Anrede</label>
                                    <div v-if="hasErrors && errors.hasOwnProperty('delivery.salutation')" class="invalid-feedback">
                                        <i class="alert-icon fas fa-exclamation-triangle m-0"></i>
                                        {{ errors['delivery.salutation'][0] }}
                                    </div>
                                </div>
                                <div class="row g-3 mb-3">
                                    <div class="form-floating col-6">
                                        <input data-was="pattern-" type="text" id="inputPrename_delivery" class="form-control is-required" placeholder="Vorname"
                                                       :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.prename'))}"
                                                       :regReplace="''"
                                                       :regExp="/[^a-zA-ZäöüÄÖÜß\- .]/"
                                                       v-model="delivery.prename" value="">

                                        <label for="inputPrename_delivery" class="required">Vorname</label>
                                        <div v-if="hasErrors && errors.hasOwnProperty('delivery.prename')" class="invalid-feedback">
                                            <i class="alert-icon fas fa-exclamation-triangle m-0"></i>
                                            {{ errors['delivery.prename'][0] }}
                                        </div>
                                    </div>
                                    <div class="form-floating col-6">
                                        <input data-was="pattern-" type="text" id="inputName_delivery" class="form-control is-required" placeholder="Nachname"
                                                       :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.name'))}"
                                                       :regReplace="''"
                                                       :regExp="/[^a-zA-ZäöüÄÖÜß\- .]/"
                                                       v-model="delivery.name" value="">

                                        <label for="inputName_delivery" class="required">Nachname</label>
                                        <div v-if="hasErrors && errors.hasOwnProperty('delivery.name')" class="invalid-feedback">
                                            <i class="alert-icon fas fa-exclamation-triangle m-0"></i>
                                            {{ errors['delivery.name'][0] }}
                                        </div>
                                    </div>
                                </div>
                                <div class="form-floating mb-3">
                                    <input type="text" id="inputCompany_delivery" class="form-control" placeholder="Firma (optional)"
                                           v-model="delivery.company">
                                    <label for="inputCompany_delivery">Firma (optional)</label>
                                </div>
                                <div class="row g-3 mb-3">
                                    <div class="form-floating col-9">
                                        <input type="text" id="inputStreet_delivery" class="form-control is-required" placeholder="Straße"
                                               :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.street'))}"
                                               v-model="delivery.street">
                                        <label for="inputStreet_delivery" class="required">Straße</label>
                                        <div v-if="hasErrors && errors.hasOwnProperty('delivery.street')" class="invalid-feedback">
                                            <i class="alert-icon fas fa-exclamation-triangle m-0"></i>
                                            {{ errors['delivery.street'][0] }}
                                        </div>
                                    </div>
                                    <div class="form-floating col-3">
                                        <input type="text" id="inputStreetNo_delivery" class="form-control is-required" placeholder="Hausnummer"
                                               :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.house_no'))}"
                                               v-model="delivery.house_no">
                                        <label for="inputStreetNo_delivery" class="required">Hausnummer</label>
                                        <div v-if="hasErrors && errors.hasOwnProperty('delivery.house_no')" class="invalid-feedback">
                                            <i class="alert-icon fas fa-exclamation-triangle m-0"></i>
                                            {{ errors['delivery.house_no'][0] }}
                                        </div>
                                    </div>
                                </div>
                                <div class="form-floating mb-3">
                                    <input type="text" id="inputAddressAdditional_delivery" class="form-control" placeholder="Adresszusatz (optional)"
                                           v-model="delivery.additional_address_information">
                                    <label for="inputAddressAdditional_delivery">Adresszusatz (optional)</label>
                                </div>
                                <div class="row g-3 mb-3">
                                    <div class="form-floating col-4">
                                        <input type="text" id="inputPostcode_delivery" class="form-control is-required" placeholder="Postleitzahl"
                                               :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.zip'))}"
                                               v-model="delivery.zip">
                                        <label for="inputPostcode_delivery" class="required">Postleitzahl</label>
                                        <div v-if="hasErrors && errors.hasOwnProperty('delivery.zip')" class="invalid-feedback">
                                            <i class="alert-icon fas fa-exclamation-triangle m-0"></i>
                                            {{ errors['delivery.zip'][0] }}
                                        </div>
                                    </div>
                                    <div class="form-floating col-8">
                                        <input type="text" id="inputCity_delivery" class="form-control is-required" placeholder="Ort"
                                               :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.city'))}"
                                               v-model="delivery.city">
                                        <label for="inputCity_delivery" class="required">Ort</label>
                                        <div v-if="hasErrors && errors.hasOwnProperty('delivery.city')" class="invalid-feedback">
                                            <i class="alert-icon fas fa-exclamation-triangle m-0"></i>
                                            {{ errors['delivery.city'][0] }}
                                        </div>
                                    </div>
                                </div>
                                <div class="form-floating mb-3">
                                    <select class="form-select is-required" id="inputCountry_delivery"
                                            :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.country'))}"
                                            v-model="delivery.country">
                                        <option disabled selected>Auswählen</option>
                                        <option v-for="country in countries" :value="country.id" :data-eu="country.eu" :data-code="country.iso_code">{{ country.name }}</option>
                                    </select>
                                    <label for="inputCountry_delivery" class="required">Land</label>
                                    <div v-if="hasErrors && errors.hasOwnProperty('delivery.country')" class="invalid-feedback">
                                        <i class="alert-icon fas fa-exclamation-triangle m-0"></i>
                                        {{ errors['delivery.country'][0] }}
                                    </div>
                                </div>
                            </template>
                            <template v-if="deliveryOption === 'packstation'">
                                <div class="alert alert-warning">
                                    <strong>Bitte beachte:</strong> die Lieferung an eine Packstation ist nur bei Versand mit DHL möglich.
                                    Weiterhin besteht die Möglichkeit, dass Sendungen an eine Postfiliale geliefert werden. Z.B. wenn das Paket zu groß ist für die
                                    Fächer der Packstation, die Kapazität der Packstation erschöpft ist oder die Packstation defekt ist.
                                </div>
                                <div class="form-floating mb-3">
                                    <select class="form-select is-required" id="inputSalutation_delivery-packstation"
                                            :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.salutation'))}"
                                            v-model="delivery.salutation">
                                        <option value="null" disabled selected>Auswählen</option>
                                        <option value="female">Frau</option>
                                        <option value="male">Herr</option>
                                    </select>
                                    <label for="inputSalutation_delivery-packstation" class="required">Anrede</label>
                                    <div v-if="hasErrors && errors.hasOwnProperty('delivery.salutation')" class="invalid-feedback">
                                        <i class="alert-icon fas fa-exclamation-triangle m-0"></i>
                                        {{ errors['delivery.salutation'][0] }}
                                    </div>
                                </div>
                                <div class="row g-3 mb-3">
                                    <div class="form-floating col-6">
                                        <input data-was="pattern-" type="text" id="inputPrename_delivery-packstation" class="form-control is-required" placeholder="Vorname"
                                                       :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.prename'))}"
                                                       :regReplace="''"
                                                       :regExp="/[^a-zA-ZäöüÄÖÜß\- .]/"
                                                       v-model="delivery.prename" value="">

                                        <label for="inputPrename_delivery-packstation" class="required">Vorname</label>
                                        <div v-if="hasErrors && errors.hasOwnProperty('delivery.prename')" class="invalid-feedback">
                                            <i class="alert-icon fas fa-exclamation-triangle m-0"></i>
                                            {{ errors['delivery.prename'][0] }}
                                        </div>
                                    </div>
                                    <div class="form-floating col-6">
                                        <input data-was="pattern-" type="text" id="inputName_delivery-packstation" class="form-control is-required" placeholder="Nachname"
                                                       :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.name'))}"
                                                       :regReplace="''"
                                                       :regExp="/[^a-zA-ZäöüÄÖÜß\- .]/"
                                                       v-model="delivery.name" value="">

                                        <label for="inputName_delivery-packstation" class="required">Nachname</label>
                                        <div v-if="hasErrors && errors.hasOwnProperty('delivery.name')" class="invalid-feedback">
                                            <i class="alert-icon fas fa-exclamation-triangle m-0"></i>
                                            {{ errors['delivery.name'][0] }}
                                        </div>
                                    </div>
                                </div>
                                <div class="form-floating mb-3">
                                    <input type="text" id="inputCompany_delivery-packstation" class="form-control" placeholder="Firma (optional)"
                                           v-model="delivery.company">
                                    <label for="inputCompany_delivery-packstation">Firma (optional)</label>
                                </div>
                                <div class="row g-3 mb-3">
                                    <div class="form-floating col-6">
                                        <input type="text" id="inputPostNo_delivery-packstation" class="form-control is-required" placeholder="Postnummer"
                                               :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.post_no'))}"
                                               v-model="delivery.post_no">
                                        <label for="inputPostNo_delivery-packstation" class="required">Postnummer</label>
                                        <div v-if="hasErrors && errors.hasOwnProperty('delivery.post_no')" class="invalid-feedback">
                                            <i class="alert-icon fas fa-exclamation-triangle m-0"></i>
                                            {{ errors['delivery.post_no'][0] }}
                                        </div>
                                    </div>
                                    <div class="form-floating col-6">
                                        <input type="text" id="inputPackstationNo_delivery-packstation" class="form-control is-required" placeholder="Packstation Nr."
                                               :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.packstation_no'))}"
                                               v-model="delivery.packstation_no">
                                        <label for="inputPackstationNo_delivery-packstation" class="required">Packstation Nr.</label>
                                        <div v-if="hasErrors && errors.hasOwnProperty('delivery.packstation_no')" class="invalid-feedback">
                                            <i class="alert-icon fas fa-exclamation-triangle m-0"></i>
                                            {{ errors['delivery.packstation_no'][0] }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row g-3 mb-3">
                                    <div class="form-floating col-4">
                                        <input type="text" id="inputPostcode_delivery-packstation" class="form-control is-required" placeholder="Postleitzahl"
                                               :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.zip'))}"
                                               v-model="delivery.zip">
                                        <label for="inputPostcode_delivery-packstation" class="required">Postleitzahl</label>
                                        <div v-if="hasErrors && errors.hasOwnProperty('delivery.zip')" class="invalid-feedback">
                                            <i class="alert-icon fas fa-exclamation-triangle m-0"></i>
                                            {{ errors['delivery.zip'][0] }}
                                        </div>
                                    </div>
                                    <div class="form-floating col-8">
                                        <input type="text" id="inputCity_delivery-packstation" class="form-control is-required" placeholder="Ort"
                                               :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.city'))}"
                                               v-model="delivery.city">
                                        <label for="inputCity_delivery-packstation" class="required">Ort</label>
                                        <div v-if="hasErrors && errors.hasOwnProperty('delivery.city')" class="invalid-feedback">
                                            <i class="alert-icon fas fa-exclamation-triangle m-0"></i>
                                            {{ errors['delivery.city'][0] }}
                                        </div>
                                    </div>
                                </div>
                                <div class="form-floating">
                                    <select class="form-select is-required" id="inputCountry_delivery-packstation"
                                            :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.country'))}"
                                            v-model="delivery.country">
                                        <option disabled selected>Auswählen</option>
                                        <option v-for="country in countries" :value="country.id" :data-eu="country.eu" :data-code="country.iso_code">{{ country.name }}</option>
                                    </select>
                                    <label for="inputCountry_delivery-packstation" class="required">Land</label>
                                    <div v-if="hasErrors && errors.hasOwnProperty('delivery.country')" class="invalid-feedback">
                                        <i class="alert-icon fas fa-exclamation-triangle m-0"></i>
                                        {{ errors['delivery.country'][0] }}
                                    </div>
                                </div>
                            </template>
                            <template v-if="deliveryOption === 'post-office'">
                                <div class="form-floating mb-3">
                                    <select class="form-select is-required" id="inputSalutation_delivery-post"
                                            :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.salutation'))}"
                                            v-model="delivery.salutation">
                                        <option disabled selected>Auswählen</option>
                                        <option value="female">Frau</option>
                                        <option value="male">Herr</option>
                                    </select>
                                    <label for="inputSalutation_delivery-post" class="required">Anrede</label>
                                    <div v-if="hasErrors && errors.hasOwnProperty('delivery.salutation')" class="invalid-feedback">
                                        <i class="alert-icon fas fa-exclamation-triangle m-0"></i>
                                        {{ errors['delivery.salutation'][0] }}
                                    </div>
                                </div>
                                <div class="row g-3 mb-3">
                                    <div class="form-floating col-6">
                                        <input data-was="pattern-" type="text" id="inputPrename_delivery-post" class="form-control is-required" placeholder="Vorname"
                                                       :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.prename'))}"
                                                       :regReplace="''"
                                                       :regExp="/[^a-zA-ZäöüÄÖÜß\- .]/"
                                                       v-model="delivery.prename" value="">

                                        <label for="inputPrename_delivery-post" class="required">Vorname</label>
                                        <div v-if="hasErrors && errors.hasOwnProperty('delivery.prename')" class="invalid-feedback">
                                            <i class="alert-icon fas fa-exclamation-triangle m-0"></i>
                                            {{ errors['delivery.prename'][0] }}
                                        </div>
                                    </div>
                                    <div class="form-floating col-6">
                                        <input data-was="pattern-" type="text" id="inputName_delivery-post" class="form-control is-required" placeholder="Nachname"
                                                       :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.name'))}"
                                                       :regReplace="''"
                                                       :regExp="/[^a-zA-ZäöüÄÖÜß\- .]/"
                                                       v-model="delivery.name" value="">

                                        <label for="inputName_delivery-post" class="required">Nachname</label>
                                        <div v-if="hasErrors && errors.hasOwnProperty('delivery.name')" class="invalid-feedback">
                                            <i class="alert-icon fas fa-exclamation-triangle m-0"></i>
                                            {{ errors['delivery.name'][0] }}
                                        </div>
                                    </div>
                                </div>
                                <div class="form-floating mb-3">
                                    <input type="text" id="inputCompany_delivery-post" class="form-control" placeholder="Firma (optional)"
                                           :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.company'))}"
                                           v-model="delivery.company">
                                    <label for="inputCompany_delivery-post">Firma (optional)</label>
                                </div>
                                <div class="row g-3 mb-3">
                                    <div class="form-floating col-6">
                                        <input type="text" id="inputPostNo_delivery-post" class="form-control is-required" placeholder="Postnummer"
                                               :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.post_no'))}"
                                               v-model="delivery.post_no">
                                        <label for="inputPostNo_delivery-post" class="required">Postnummer</label>
                                        <div v-if="hasErrors && errors.hasOwnProperty('delivery.post_no')" class="invalid-feedback">
                                            <i class="alert-icon fas fa-exclamation-triangle m-0"></i>
                                            {{ errors['delivery.post_no'][0] }}
                                        </div>
                                    </div>
                                    <div class="form-floating col-6">
                                        <input type="text" id="inputPostOfficeNo_delivery-post" class="form-control is-required" placeholder="Postfiliale Nr."
                                               :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.post_office_no'))}"
                                               v-model="delivery.post_office_no">
                                        <label for="inputPostOfficeNo_delivery-post" class="required">Postfiliale Nr.</label>
                                        <div v-if="hasErrors && errors.hasOwnProperty('delivery.post_office_no')" class="invalid-feedback">
                                            <i class="alert-icon fas fa-exclamation-triangle m-0"></i>
                                            {{ errors['delivery.post_office_no'][0] }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row g-3 mb-3">
                                    <div class="form-floating col-4">
                                        <input type="text" id="inputPostcode_delivery-post" class="form-control is-required" placeholder="Postleitzahl"
                                               :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.zip'))}"
                                               v-model="delivery.zip">
                                        <label for="inputPostcode_delivery-post" class="required">Postleitzahl</label>
                                        <div v-if="hasErrors && errors.hasOwnProperty('delivery.zip')" class="invalid-feedback">
                                            <i class="alert-icon fas fa-exclamation-triangle m-0"></i>
                                            {{ errors['delivery.zip'][0] }}
                                        </div>
                                    </div>
                                    <div class="form-floating col-8">
                                        <input type="text" id="inputCity_delivery-post" class="form-control is-required" placeholder="Ort"
                                               :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.city'))}"
                                               v-model="delivery.city">
                                        <label for="inputCity_delivery-post" class="required">Ort</label>
                                        <div v-if="hasErrors && errors.hasOwnProperty('delivery.city')" class="invalid-feedback">
                                            <i class="alert-icon fas fa-exclamation-triangle m-0"></i>
                                            {{ errors['delivery.city'][0] }}
                                        </div>
                                    </div>
                                </div>
                                <div class="form-floating">
                                    <select class="form-select is-required" id="inputCountry_delivery-post"
                                            :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.country'))}"
                                            v-model="delivery.country">
                                        <option disabled selected>Auswählen</option>
                                        <option v-for="country in countries" :value="country.id" :data-eu="country.eu" :data-code="country.iso_code">{{ country.name }}</option>
                                    </select>
                                    <label for="inputCountry_delivery-post" class="required">Land</label>
                                    <div v-if="hasErrors && errors.hasOwnProperty('delivery.country')" class="invalid-feedback">
                                        <i class="alert-icon fas fa-exclamation-triangle m-0"></i>
                                        {{ errors['delivery.country'][0] }}
                                    </div>
                                </div>
                            </template>

                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 pb-4">
                    <div class="card">
                        <div class="card-header">
                            <h5 class="mb-3">Positionen</h5>
                            <div class="row">
                                <div class="col-3">
                                    <button class="btn btn-outline-primary w-100" data-bs-toggle="modal" data-bs-target="#item-modal">Artikel hinzufügen</button>
                                </div>
                                <div class="col-3">
                                    <button class="btn btn-outline-primary w-100" data-bs-toggle="modal" data-bs-target="#custom-position-modal">Benutzerdefinierte Position</button>
                                </div>
                                <div class="col-3">
                                    <button class="btn btn-outline-primary w-100" data-bs-toggle="modal" data-bs-target="#voucher-modal">Gutschein einlösen/hinzufügen</button>
                                </div>
                            </div>
                        </div>
                        <div class="card-body pt-2 pb-2">
                            <div class="list-group list-group-flush">
                                <div class="list-group-item">
                                    <div class="row">
                                        <div class="col-1"></div>
                                        <div class="col-6 font-weight-bold">Artikel</div>
                                        <div class="col-1 font-weight-bold text-center">Anzahl</div>
                                        <div class="col-2 font-weight-bold text-end">Preis</div>
                                        <div class="col-2"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="list-group list-group-flush">
                                <template v-for="(entry, key) in cartContent.items">
                                    <div class="list-group-item">
                                        <div class="row">
                                            <div class="col-1">
                                                <template v-if="entry.data.images.length">
                                                    <img :data-src="'/storage/img/uploads/products/' + entry.data.id + '/' + entry.data.images[0].webp_medium" alt="" class="lazy img-fluid">
                                                </template>
                                                <template v-else>
                                                    <img data-src="/storage/img/general/brokenimage.png" alt="" class="lazy img-fluid">
                                                </template>
                                            </div>
                                            <div class="col-6 pb-2 pt-2 justify-content-center align-self-center">
                                                <div class="row"><div class="col-12">{{ entry.data.brand.name }} {{ entry.data.name }}</div></div>
                                                <div class="row"><div class="col-12">Einzelpreis: {{ calculatePrice(entry.data.price).toFixed(2).replace('.', ',') }} €</div></div>
                                            </div>
                                            <div class="col-1 pb-2 pt-2 justify-content-center align-self-center text-center"><input type="text" min="1" class="form-control text-center" v-model="entry.quantity"></div>
                                            <div class="col-2 pb-2 pt-2 justify-content-center align-self-center text-end">
                                                <template v-if="entry.price === null">{{ calculatePrice(entry.quantity * entry.data.price).toFixed(2).replace('.', ',') }} €</template>
                                                <template v-else>{{ calculatePrice(entry.quantity * entry.price).toFixed(2).replace('.', ',') }} €</template>
                                            </div>
                                            <div class="col-2 pb-2 pt-2 text-end justify-content-center align-self-center">
                                                <button class="btn btn-outline-success btn-sm" @click.prevent="decreaseItem(key)" :disabled="entry.quantity <= 1"><i class="fa fa-arrow-down" aria-hidden="true"></i></button>
                                                <button class="btn btn-outline-success btn-sm" @click.prevent="increaseItem(key)"><i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                                                <button class="btn btn-outline-danger btn-sm" @click.prevent="removeItem(key)"><i class="fa fa-times" aria-hidden="true"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="list-group-item py-0" v-for="(promotion, promotionKey) in entry.data.promotions">
                                        <div class="row align-items-center">
                                            <div class="col-1">
                                                <div class="row">
                                                    <div class="col-12 text-end">
                                                        <i v-if="promotion.hasOwnProperty('disabled') && promotion.disabled === true" class="fas fa-unlink text-info rotate-45"></i>
                                                        <i v-else class="fas fa-link text-info rotate-45"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-6 pb-2 pt-2 justify-content-center align-self-center" :class="promotion.hasOwnProperty('disabled') && promotion.disabled === true ? 'disabled-list-item' : ''">
                                                <div class="row" v-if="promotion.hasOwnProperty('brand_promotion') && promotion.brand_promotion !== null">
                                                    <div class="col-12">
                                                        {{ promotion.brand_promotion.label }}
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12">{{ promotionName(promotion.type) }} - Gültig bis: {{ moment(promotion.valid_until).format('DD.MM.YYYY HH:mm') }}</div>
                                                </div>
                                                <div class="row" v-if="promotion.in_percent">
                                                    <div class="col-12">Einzel-Rabatt: -{{ calculatePrice(entry.data.price * ((promotion.discount ? promotion.discount : 0) / 100)).toFixed(2).replace('.', ',') }} € ({{ promotion.discount}}%)</div>
                                                </div>
                                                <div class="row" v-else-if="promotion.special_price">
                                                    <div class="col-12">Einzel-Rabatt: -{{ calculatePrice((entry.data.price - promotion.special_price)).toFixed(2).replace('.', ',') }} € ({{ promotion.special_price.toFixed(2).replace('.', ',') }}€ Sonderpreis)</div>
                                                </div>
                                                <div class="row" v-else-if="promotion.free_items.length > 0">
                                                    <template v-for="(item, key) in promotion.free_items">
                                                        <div class="col-12">{{ item.brand.name }} {{ item.name }}</div>
                                                    </template>
                                                </div>
                                                <div class="row" v-else>
                                                    <div class="col-12">Einzel-Rabatt: -{{ calculatePrice( (promotion.discount ? promotion.discount : 0) ).toFixed(2).replace('.', ',') }} €</div>
                                                </div>
                                            </div>
                                            <div class="col-1 pb-2 pt-2 justify-content-center align-self-center text-center" :class="promotion.hasOwnProperty('disabled') && promotion.disabled === true ? 'disabled-list-item' : ''">{{ entry.quantity }}</div>
                                            <div v-if="promotion.in_percent" :class="promotion.hasOwnProperty('disabled') && promotion.disabled === true ? 'disabled-list-item' : ''" class="col-2 pb-2 pt-2 justify-content-center align-self-center text-end">-{{ calculatePrice(entry.quantity * (entry.data.price * (promotion.discount / 100))).toFixed(2).replace('.', ',') }} €</div>
                                            <div v-else-if="promotion.special_price" :class="promotion.hasOwnProperty('disabled') && promotion.disabled === true ? 'disabled-list-item' : ''" class="col-2 pb-2 pt-2 justify-content-center align-self-center text-end">-{{ calculatePrice(entry.quantity * (entry.data.price - promotion.special_price)).toFixed(2).replace('.', ',') }} €</div>
                                            <div v-else class="col-2 pb-2 pt-2 justify-content-center align-self-center text-end" :class="promotion.hasOwnProperty('disabled') && promotion.disabled === true ? 'disabled-list-item' : ''">-{{ calculatePrice(entry.quantity * promotion.discount).toFixed(2).replace('.', ',') }} €</div>
                                            <div class="col-2 pb-2 pt-2 text-end justify-content-center align-self-center">
                                                <button v-if="promotion.hasOwnProperty('disabled') && promotion.disabled === true" class="btn btn-outline-danger btn-sm" @click.prevent="enableItemPromotion(key, promotionKey)">ist AUS (Click = An)</button>
                                                <button v-else class="btn btn-outline-success btn-sm" @click.prevent="disableItemPromotion(key, promotionKey)">ist AN (Click = Aus)</button>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <template v-for="(entry, key) in cartContent.demos">
                                    <div class="list-group-item">
                                        <div class="row">
                                            <div class="col-1">
                                                <template v-if="entry.data.item.images.length">
                                                    <img :data-src="'/storage/img/uploads/products/' + entry.data.item.id + '/' + entry.data.item.images[0].webp_medium" alt="" class="lazy img-fluid">
                                                </template>
                                                <template v-else>
                                                    <img data-src="/storage/img/general/brokenimage.png" alt="" class="lazy img-fluid">
                                                </template>
                                            </div>
                                            <div class="col-6 pb-2 pt-2 justify-content-center align-self-center">
                                                <div class="row"><div class="col-12">{{ entry.data.item.brand.name }} {{ entry.data.item.name }} - {{ entry.data.type.label }} - {{ entry.data.condition.label }}</div></div>
                                                <div class="row"><div class="col-12">Einzelpreis: {{ calculatePrice(entry.data.price).toFixed(2).replace(".", ",") }} €</div></div>
                                            </div>
                                            <div class="col-1 pb-2 pt-2 justify-content-center align-self-center text-center"><input type="text" min="1" class="form-control text-center" v-model="entry.quantity"></div>
                                            <div class="col-2 pb-2 pt-2 justify-content-center align-self-center text-end">
                                                <template v-if="entry.price === null">{{ calculatePrice(entry.quantity * entry.data.price).toFixed(2).replace('.', ',') }} €</template>
                                                <template v-else>{{ calculatePrice(entry.quantity * entry.price).toFixed(2).replace('.', ',') }} €</template>
                                            </div>
                                            <div class="col-2 pb-2 pt-2 text-end justify-content-center align-self-center">
                                                <button class="btn btn-outline-success btn-sm" @click.prevent="decreaseDemo(key)" :disabled="entry.quantity <= 1"><i class="fa fa-arrow-down" aria-hidden="true"></i></button>
                                                <button class="btn btn-outline-success btn-sm" @click.prevent="increaseDemo(key)"><i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                                                <button class="btn btn-outline-danger btn-sm" @click.prevent="removeDemo(key)"><i class="fa fa-times" aria-hidden="true"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="list-group-item py-0" v-for="(promotion, promotionKey) in entry.data.item.promotions">
                                        <div class="row">
                                            <div class="col-1">

                                            </div>
                                            <div class="col-6 pb-2 pt-2 justify-content-center align-self-center">
                                                <div class="row"><div class="col-12">{{ promotionName(promotion.type) }} - Gültig bis: {{ moment(promotion.valid_until).format('DD.MM.YYYY HH:mm') }}</div></div>
                                                <div class="row" v-if="promotion.in_percent">
                                                    <div class="col-12">Einzel-Rabatt: -{{ calculatePrice(entry.data.price * (promotion.discount / 100)).toFixed(2).replace('.', ',') }} € ({{ promotion.discount}}%)</div>
                                                </div>
                                                <div class="row" v-else-if="promotion.special_price">
                                                    <div class="col-12">Einzel-Rabatt: -{{ calculatePrice((entry.data.price - promotion.special_price)).toFixed(2).replace('.', ',') }} € ({{ promotion.special_price.toFixed(2).replace('.', ',') }}€ Sonderpreis)</div>
                                                </div>
                                                <div class="row" v-else>
                                                    <div class="col-12">Einzel-Rabatt: -{{ calculatePrice(promotion.discount).toFixed(2).replace('.', ',') }} €</div>
                                                </div>
                                            </div>
                                            <div class="col-1 pb-2 pt-2 justify-content-center align-self-center text-center">{{ entry.quantity }}</div>
                                            <div v-if="promotion.in_percent" class="col-2 pb-2 pt-2 justify-content-center align-self-center text-end">-{{ calculatePrice(entry.quantity * (entry.data.price * (promotion.discount / 100))).toFixed(2).replace('.', ',') }} €</div>
                                            <div v-else-if="promotion.special_price" class="col-2 pb-2 pt-2 justify-content-center align-self-center text-end">-{{ calculatePrice(entry.quantity * (entry.data.price - promotion.special_price)).toFixed(2).replace('.', ',') }} €</div>
                                            <div v-else class="col-2 pb-2 pt-2 justify-content-center align-self-center text-end">-{{ calculatePrice(entry.quantity * promotion.discount).toFixed(2).replace('.', ',') }} €</div>
                                            <div class="col-2 pb-2 pt-2 text-end justify-content-center align-self-center">
                                                <button v-if="promotion.hasOwnProperty('disabled') && promotion.disabled === true" class="btn btn-outline-danger btn-sm" @click.prevent="enableItemDemoPromotion(key, promotionKey)"><i class="fa fa-play" aria-hidden="true"></i></button>
                                                <button v-else class="btn btn-outline-danger btn-sm" @click.prevent="disableItemDemoPromotion(key, promotionKey)"><i class="fa fa-ban" aria-hidden="true"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                                <div class="list-group-item" v-for="(entry, key) in cartContent.workshops">
                                    <div class="row">
                                        <div class="col-1">
                                            <template v-if="entry.data.list_image">
                                                <img :data-src="'/storage/img/uploads/workshops/' + entry.data.id + '/' + entry.data.list_image" alt="" class="lazy img-fluid">
                                            </template>
                                            <template v-else>
                                                <img data-src="/storage/img/general/brokenimage.png" alt="" class="lazy img-fluid">
                                            </template>
                                        </div>
                                        <div class="col-6 pb-2 pt-2 justify-content-center align-self-center">
                                            <div class="row"><div class="col-12">{{ entry.data.name }}</div></div>
                                            <div class="row"><div class="col-12">Einzelpreis: {{ calculatePrice(entry.data.price).toFixed(2).replace(".", ",") }} €</div></div>
                                        </div>
                                        <div class="col-1 pb-2 pt-2 justify-content-center align-self-center text-center"><input type="text" min="1" class="form-control text-center" v-model="entry.quantity"></div>
                                        <div class="col-2 pb-2 pt-2 justify-content-center align-self-center text-end">
                                            <template v-if="entry.price === null">{{ calculatePrice(entry.quantity * entry.data.price).toFixed(2).replace('.', ',') }} €</template>
                                            <template v-else>{{ calculatePrice(entry.quantity * entry.price).toFixed(2).replace('.', ',') }} €</template>
                                        </div>
                                        <div class="col-2 pb-2 pt-2 text-end justify-content-center align-self-center">
                                            <button class="btn btn-outline-success btn-sm" @click.prevent="decreaseWorkshop(key)" :disabled="entry.quantity <= 1"><i class="fa fa-arrow-down" aria-hidden="true"></i></button>
                                            <button class="btn btn-outline-success btn-sm" @click.prevent="increaseWorkshop(key)"><i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                                            <button class="btn btn-outline-danger btn-sm" @click.prevent="removeWorkshop(key)"><i class="fa fa-times" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="list-group-item" v-for="(entry, key) in cartContent.custom">
                                    <div class="row">
                                        <div class="col-1">

                                        </div>
                                        <div class="col-6 pb-2 pt-2 justify-content-center align-self-center">
                                            <div class="row"><div class="col-12">{{ entry.data.name }}</div></div>
                                            <div class="row"><div class="col-12">Einzelpreis: {{ calculatePrice(entry.data.price).toFixed(2).replace(".", ",") }} €</div></div>
                                        </div>
                                        <div class="col-1 pb-2 pt-2 justify-content-center align-self-center text-center"><input type="text" min="1" class="form-control text-center" v-model="entry.quantity"></div>
                                        <div class="col-2 pb-2 pt-2 justify-content-center align-self-center text-end">{{ calculatePrice(entry.quantity * entry.data.price).toFixed(2).replace('.', ',') }} €</div>
                                        <div class="col-2 pb-2 pt-2 text-end justify-content-center align-self-center">
                                            <button class="btn btn-outline-success btn-sm" @click.prevent="decreaseCustom(key)" :disabled="entry.quantity <= 1"><i class="fa fa-arrow-down" aria-hidden="true"></i></button>
                                            <button class="btn btn-outline-success btn-sm" @click.prevent="increaseCustom(key)"><i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                                            <button class="btn btn-outline-danger btn-sm" @click.prevent="removeCustom(key)"><i class="fa fa-times" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="list-group-item" v-for="(entry, key) in cartContent.bonuses">
                                    <div class="row">
                                        <div class="col-1">
                                            <template v-if="entry.data.logo">
                                                <img :data-src="'/storage/img/uploads/bonus-items/' + entry.data.id + '/' + entry.data.logo" alt="" class="lazy img-fluid">
                                            </template>
                                            <template v-else>
                                                <img data-src="/storage/img/general/brokenimage.png" alt="" class="lazy img-fluid">
                                            </template>
                                        </div>
                                        <div class="col-6 pb-2 pt-2 justify-content-center align-self-center">
                                            <div class="row"><div class="col-12">{{ entry.data.name }}</div></div>
                                            <div class="row">
                                                <div class="col-12" v-if="entry.price === null">Einzelpreis: 0,00 €</div>
                                                <div class="col-12" v-else>Einzelpreis: {{ calculatePrice(entry.quantity * entry.price).toFixed(2).replace('.', ',') }} €</div>
                                            </div>
                                        </div>
                                        <div class="col-1 pb-2 pt-2 justify-content-center align-self-center text-center"><input type="text" min="1" class="form-control text-center" v-model="entry.quantity"></div>
                                        <div class="col-2 pb-2 pt-2 justify-content-center align-self-center text-end">
                                            <template v-if="entry.price === null">0,00 €</template>
                                            <template v-else>{{ calculatePrice(entry.quantity * entry.price).toFixed(2).replace('.', ',')}} €</template>
                                        </div>
                                        <div class="col-2 pb-2 pt-2 text-end justify-content-center align-self-center">
                                            <button class="btn btn-outline-success btn-sm" @click.prevent="decreaseBonus(key)" :disabled="entry.quantity <= 1"><i class="fa fa-arrow-down" aria-hidden="true"></i></button>
                                            <button class="btn btn-outline-success btn-sm" @click.prevent="increaseBonus(key)"><i class="fa fa-arrow-up" aria-hidden="true"></i></button>
                                            <button class="btn btn-outline-danger btn-sm" @click.prevent="removeBonus(key)"><i class="fa fa-times" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="list-group-item" v-for="(entry, key) in cartContent.vouchers">
                                    <div class="row" v-if="entry.data.hasOwnProperty('discount_percentage') && entry.data.discount_percentage !== null">
                                        <div class="col-1">

                                        </div>
                                        <div class="col-6 pb-2 pt-2 justify-content-center align-self-center">
                                            <div class="row"><div class="col-12">Gutschein: {{ entry.data.code }}</div></div>
                                            <div class="row"><div class="col-12">Einzelrabatt: {{ entry.data.discount_percentage.toFixed(2).replace(".", ",") }} %</div></div>
                                        </div>
                                        <div class="col-1 pb-2 pt-2 justify-content-center align-self-center text-center">{{ entry.quantity }}</div>
                                        <div class="col-2 pb-2 pt-2 justify-content-center align-self-center text-end">-{{ (((totalPositions * entry.data.discount_percentage) / 100) * entry.quantity).toFixed(2).replace(".", ",") }}</div>
                                        <div class="col-2 pb-2 pt-2 text-end justify-content-center align-self-center">
                                            <button class="btn btn-outline-danger btn-sm" @click.prevent="removeVoucher(key)"><i class="fa fa-times" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                    <div class="row" v-else>
                                        <div class="col-1">

                                        </div>
                                        <div class="col-6 pb-2 pt-2 justify-content-center align-self-center">
                                            <div class="row"><div class="col-12">Gutschein: {{ entry.data.code }}</div></div>
                                            <div class="row"><div class="col-12">Einzelrabatt: -{{ calculatePrice(entry.data.discount_amount).toFixed(2).replace(".", ",") }} €</div></div>
                                            <div class="row" v-if="entry.hasOwnProperty('endPrice')">
                                                <div class="col-12 small">Endpreis Gutschein</div>
                                            </div>
                                        </div>
                                        <div class="col-1 pb-2 pt-2 justify-content-center align-self-center text-center">{{ entry.quantity }}</div>
                                        <div class="col-2 pb-2 pt-2 justify-content-center align-self-center text-end">-{{ calculatePrice(entry.data.discount_amount * entry.quantity).toFixed(2).replace(".", ",") }} €</div>
                                        <div class="col-2 pb-2 pt-2 text-end justify-content-center align-self-center">
                                            <button class="btn btn-outline-danger btn-sm" @click.prevent="removeVoucher(key)"><i class="fa fa-times" aria-hidden="true"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <div class="list-group-item text-end">
                                    <div class="row text-end">
                                        <div class="col-9">Versandkosten</div>
                                        <div class="col-3">{{ shippingPrice.toFixed(2).replace('.', ',') }} €</div>
                                    </div>
                                    <div class="row text-end">
                                        <div class="col-9">Gesamtpreis inkl. MwSt.</div>
                                        <div class="col-3">{{ total.toFixed(2).replace('.', ',') }} €</div>
                                    </div>
                                    <div class="row text-end">
                                        <div class="col-9">enthaltene MwSt.:</div>
                                        <div class="col-3">{{ taxes.toFixed(2).replace('.', ',') }} €</div>
                                    </div>
                                    <div class="row text-end">
                                        <div class="col-9">Nettopreis</div>
                                        <div class="col-3">{{ netto.toFixed(2).replace('.', ',') }} €</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-footer">
                            <div class="row">
                                <div class="col">
                                    <button class="btn btn-outline-info btn-sm cursor-default" disabled><i class="fas fa-link rotate-45"></i></button><p class="append-btn">Produkt abhängig</p>
                                    <button class="btn btn-outline-danger btn-sm cursor-default" disabled><i class="fa fa-ban"></i></button><p class="append-btn">ist aktiv</p>
                                    <button class="btn btn-outline-danger btn-sm cursor-default" disabled><i class="fa fa-play"></i></button><p class="append-btn">ist inaktiv</p>
                                    <button class="btn btn-outline-success btn-sm cursor-default" disabled><i class="fa fa-arrow-down"></i></button><p class="append-btn">Anzahl verringern</p>
                                    <button class="btn btn-outline-success btn-sm cursor-default" disabled><i class="fa fa-arrow-up"></i></button><p class="append-btn">Anzahl erhöhen</p>
                                    <button class="btn btn-outline-danger btn-sm cursor-default" disabled><i class="fa fa-times"></i></button><p class="append-btn">Position löschen</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-6 pb-4">
                    <div class="card">
                        <div class="card-header">
                            <h5 class="mb-0">Zahlungsart</h5>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-6" v-for="payment in paymentOptions">
                                    <div class="form-check">
                                        <input type="radio" :id="'paymentOption_' + payment.id" class="form-check-input" :value="payment.id" v-model="paymentSelected"
                                               :disabled="payment.min_order_amount > total || (payment.max_order_amount !== null && payment.max_order_amount < total)"
                                               :class="{'is-invalid': (hasErrors && paymentSelected === null)}">
                                        <label class="form-check-label" :for="'paymentOption_' + payment.id">{{ payment.name }}</label>
                                    </div>
                                </div>
                                <div class="col-12" v-if="hasErrors && paymentSelected === null">
                                    <div class="invalid-feedback d-block">
                                        <i class="alert-icon fas fa-exclamation-triangle m-0"></i>
                                        Es muss eine Zahlungsart ausgewählt werden.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-6 pb-4">
                    <div class="card">
                        <div class="card-header">
                            <h5 class="mb-0">Versand & Paketverfolgung</h5>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-6" v-for="shipping in shippingOptions">
                                    <div class="form-check">
                                        <input type="radio" :id="'shippingOption_' + shipping.id" class="form-check-input" :value="shipping.id" v-model="shippingSelected"
                                               :disabled="!shipping.countries_arr.includes(selectedCountry)"
                                               :class="{'is-invalid': (hasErrors && shippingSelected === null)}">
                                        <label class="form-check-label" :for="'shippingOption_' + shipping.id">{{ shipping.name }}</label>
                                    </div>
                                </div>
                                <div class="col-12" v-if="hasErrors && shippingSelected === null">
                                    <div class="invalid-feedback d-block">
                                        <i class="alert-icon fas fa-exclamation-triangle m-0"></i>
                                        Es muss eine Versandart ausgewählt werden.
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-12">
                                    <div class="form-check form-switch">
                                        <input type="checkbox" class="form-check-input" id="noDeliveryCosts" v-model="settings.no_delivery_costs">
                                        <label class="form-check-label" for="noDeliveryCosts">Keine Versandkosten berechnen</label>
                                    </div>
                                </div>
                            </div>
                            <hr>
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-check">
                                        <input type="radio" id="inputTracking_yes" name="inputTracking" value="1" class="form-check-input"
                                               :class="{'is-invalid': (hasErrors && (errors.hasOwnProperty('tracking') || tracking === null))}"
                                               v-model="tracking">
                                        <label class="form-check-label font-weight-bold" for="inputTracking_yes">E-Mail an Transportdienstleister übertragen</label>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-check">
                                        <input type="radio" id="inputTracking_no" name="inputTracking" value="0" class="form-check-input"
                                               :class="{'is-invalid': (hasErrors && (errors.hasOwnProperty('tracking') || tracking === null))}"
                                               v-model="tracking">
                                        <label class="form-check-label font-weight-bold" for="inputTracking_no">Keine E-Mail übertragung</label>
                                    </div>
                                </div>
                                <div class="col-12" v-if="hasErrors && (errors.hasOwnProperty('tracking') || tracking === null)">
                                    <div class="invalid-feedback d-block">
                                        <i class="alert-icon fas fa-exclamation-triangle m-0"></i>
                                        Es muss ausgewählt werden ob die E-Mail-Adresse des Kunden an den Transportdienstleister übermittelt werden soll.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h5 class="mb-0">Abschließen</h5>
                        </div>
                        <div class="card-body">
                            <div class="row pb-4">
                                <div class="col-7">
                                    <div class="row">
                                        <div class="col-6">
                                            <div class="form-check form-switch">
                                                <input type="checkbox" class="form-check-input" id="sendCustomerNotification" v-model="settings.notifications.customer">
                                                <label class="form-check-label" for="sendCustomerNotification">Bestellbestätigung an Kunden senden</label>
                                            </div>
                                            <div class="form-check form-switch">
                                                <input type="checkbox" class="form-check-input" id="sendCustomerRatingMail" v-model="settings.rating_mail">
                                                <label class="form-check-label" for="sendCustomerRatingMail">Bewertungsanfrage an Kunden senden</label>
                                            </div>
                                            <div class="form-check form-switch">
                                                <input type="checkbox" class="form-check-input" id="addCustomerToNewsletter" v-model="settings.newsletter">
                                                <label class="form-check-label" for="addCustomerToNewsletter">Kunde am Newsletter anmelden</label>
                                            </div>
                                            <div class="form-check form-switch">
                                                <input type="checkbox" class="form-check-input" id="getAdvice" v-model="settings.advice">
                                                <label class="form-check-label" for="getAdvice">Beratungsgutschrift hinzufügen</label>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="form-check form-switch">
                                                <input type="checkbox" class="form-check-input" id="isPayed" v-model="settings.payed">
                                                <label class="form-check-label" for="isPayed">Als "Bezahlt" markieren</label>
                                            </div>
                                            <div class="form-check form-switch">
                                                <input type="checkbox" class="form-check-input" id="isExported" v-model="settings.exported">
                                                <label class="form-check-label" for="isExported">Nicht unter "Neue Bestellungen" anzeigen</label>
                                            </div>
                                            <div class="form-check form-switch">
                                                <input type="checkbox" class="form-check-input" id="isAccepted" v-model="settings.accepted">
                                                <label class="form-check-label" for="isAccepted">Auftragsbestätigung nicht senden</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-5">
                                    <label for="orderComment">Kommentar/Bemerkung</label>
                                    <textarea id="orderComment" class="form-control" cols="10" rows="3" v-model="orderComment"></textarea>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 text-end">
                                    <button class="btn btn-primary" @click.prevent="handleOrder" :disabled="isHandling">Bestellung abschließen</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid p-3" v-else>
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="h4 mb-0">Bestellung abgeschlossen</div>
                        </div>
                        <div class="card-body">
                            <p class="mb-0" v-if="order.no !== null">Die Bestellung <a :href="'/backend/orders/' + order.id">{{ order.no }}</a> wurde abgeschlossen.</p>
                            <p class="mb-0" v-else>Es ist ein Fehler aufgetreten. Die Bestellung wurde nicht abgeschlossen.</p>
                        </div>
                        <div class="card-footer">
                            <a :href="'/backend/orders/' + order.id" class="btn btn-outline-info">zur Bestellung</a>
                            <a href="/backend/orders/create" class="btn btn-outline-primary">weitere Bestellung</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <partials-voucher-modal @redeem="addVoucher" @create="addVoucher" @endPrice="addEndPrice"></partials-voucher-modal>
        <partials-custom-position-modal @create="addCustomPosition"></partials-custom-position-modal>
        <partials-item-modal @create="handleAdd"></partials-item-modal>
        <loading-modal :text="loadingText" :active="isLoading" v-if="isLoading"></loading-modal>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                axiosCancel: () => {},
                countries: {},
                hasErrors: false,
                errors: {},
                isHandling: false,
                isLoading: false,
                loadingText: null,
                firstTry: false,
                vatId: false,

                contactSearchCol: 'email',
                contactEntries: {},
                contactEntriesVisibleEmail: false,
                contactEntriesVisibleName: false,
                contactEntriesVisibleCNo: false,
                isLoadingSearchEmail: false,
                isLoadingSearchName: false,
                isLoadingSearchCNo: false,
                contactSelected: null,
                contactSave: false,

                tracking: null,
                deliveryOption: 'billing-address',
                deliveryBilling: 'billing-address',
                deliveryDisabled: false,
                deliveryOptions: [
                    { index: 'billing-address',  name: 'Lieferung an Rechnungsadresse' },
                    { index: 'delivery-address', name: 'Lieferung an abweichende Hausadresse' },
                    { index: 'packstation',     name: 'Lieferung an Packstation (nur DHL)' },
                    { index: 'post-office',     name: 'Lieferung an Postfiliale (nur DHL)' }
                ],

                paymentOptions: {},
                paymentSelected: null,

                shippingOptions: {},
                shippingSelected: null,
                shippingDiscounts: {},
                freePremiumShipping: false,
                freeShipping: false,

                discount: 0,

                cartContent: {
                    items: {},
                    demos: {},
                    workshops: {},
                    bonuses: {},
                    vouchers: {},
                    custom: {}
                },

                billing: {
                    email: '',
                    customer_no: '',
                    phone: '',
                    salutation: null,
                    prename: '',
                    name: '',
                    company: '',
                    street: '',
                    house_no: '',
                    additional_address_information: '',
                    additional_address_information_2: '',
                    zip: '',
                    city: '',
                    country: 1,
                    vat_id: '',
                    pattern: {
                        zip: /[^0-9]/
                    }
                },

                delivery: {
                    salutation: null,
                    prename: '',
                    name: '',
                    company: '',
                    street: '',
                    house_no: '',
                    additional_address_information: '',
                    additional_address_information_2: '',
                    zip: '',
                    city: '',
                    country: 1,
                    post_no: '',
                    packstation_no: '',
                    post_office_no: ''
                },

                orderComment: '',

                settings: {
                    accepted: false,
                    payed: false,
                    exported: false,
                    newsletter: false,
                    rating_mail: true,
                    isNetto: false,
                    advice: false,
                    no_delivery_costs: false,
                    notifications: {
                        customer: true,
                        company: true,
                    },
                },
                manufacturerPromotionTypes: [
                    'cb',
                    'combi_cb'
                ],
                manufacturerPromotionsDisabled: true,
                order: {
                    id: null,
                    no: null,
                    contactIDs: {
                        contact: null,
                        billing: null,
                        delivery: null
                    },
                    customerIDs: {
                        billing: null,
                        delivery: null
                    },
                    error: false,
                    complete: false
                },
                itemModal: null,
                voucherModal: null,
                customPositionModal: null,
            }
        },

        async mounted() {

            this.itemModal = new bootstrap.Modal(document.getElementById('item-modal'))
            this.voucherModal = new bootstrap.Modal(document.getElementById('voucher-modal'))
            this.customPositionModal = new bootstrap.Modal(document.getElementById('custom-position-modal'))

            this.scrollTop();
            this.isLoading = true;
            this.loadingText = 'Oberfläche wird aufgebaut.';
            await this.fetchCountries();
            await this.fetchPaymentOptions();
            await this.fetchShippingOptions();
            await this.fetchShippingDiscounts();
            this.isLoading = false;
            this.loadingText = '';
        },

        methods: {
            handleAdd(item) {
                switch (item.type) {
                    case 'item':
                        this.addItem(item.data, item.quantity, item.price);
                        break;
                    case 'itemDemo':
                        this.addItemDemo(item.data, item.quantity, item.price);
                        break;
                    case 'workshop':
                        this.addWorkshop(item.data, item.quantity, item.price);
                        break;
                    case 'bonus':
                        this.addBonus(item.data, item.quantity, item.price);
                        break;
                }
                this.itemModal.hide()
            },
            addItem(item, quantity, price) {
                if(!this.cartContent.items.hasOwnProperty(item.id)) {
                    this.cartContent.items[item.id] = {
                        data: item,
                        quantity: 1,
                        price: price
                    }
                    for (let promoKey in item.promotions) {
                        if (this.manufacturerPromotionsDisabled) {
                            if (this.manufacturerPromotionTypes.includes(item.promotions[promoKey].type)) {
                                this.disableItemPromotion(item.id, promoKey);
                            }
                        }
                    }
                } else {
                    this.cartContent.items[item.id].quantity++;
                }
            },
            increaseItem(key) {
                this.cartContent.items[key].quantity++
            },
            decreaseItem(key) {
                this.cartContent.items[key].quantity--
            },
            removeItem(key) {
                this.$delete(this.cartContent.items, key)
            },
            disableItemPromotion(key, promotionKey) {
                this.cartContent.items[key].data.promotions[promotionKey]['disabled'] = true
            },
            enableItemPromotion(key, promotionKey) {
                this.cartContent.items[key].data.promotions[promotionKey]['disabled'] = false
            },
            disableItemDemoPromotion(key, promotionKey) {
                this.cartContent.demos[key].data.item.promotions[promotionKey]['disabled'] = true
            },
            enableItemDemoPromotion(key, promotionKey) {
                this.cartContent.demos[key].data.item.promotions[promotionKey]['disabled'] = false
            },
            addWorkshop(workshop, quantity, price) {
                if(!this.cartContent.workshops.hasOwnProperty(workshop.id)) {
                    this.cartContent.workshops[workshop.id] = {
                        data: workshop,
                        quantity: quantity,
                        price: price
                    }
                } else {
                    this.cartContent.workshops[workshop.id].quantity++;
                }
            },
            increaseWorkshop(key) {
                this.cartContent.workshops[key].quantity++
            },
            decreaseWorkshop(key) {
                this.cartContent.workshops[key].quantity--
            },
            removeWorkshop(key) {
                this.$delete(this.cartContent.workshops, key)
            },

            addItemDemo(itemDemo, quantity, price) {
                if(!this.cartContent.demos.hasOwnProperty(itemDemo.id)) {
                    this.cartContent.demos[itemDemo.id] = {
                        data: itemDemo,
                        quantity: quantity,
                        price: price
                    }
                    for (let promoKey in itemDemo.item.promotions) {
                        if (this.manufacturerPromotionsDisabled) {
                            if (this.manufacturerPromotionTypes.includes(itemDemo.item.promotions[promoKey].type)) {
                                this.disableItemDemoPromotion(itemDemo.id, promoKey);
                            }
                        }
                    }
                } else {
                    this.cartContent.demos[itemDemo.id].quantity++;
                }
            },
            increaseDemo(key) {
                this.cartContent.demos[key].quantity++
            },
            decreaseDemo(key) {
                this.cartContent.demos[key].quantity--
            },
            removeDemo(key) {
                this.$delete(this.cartContent.demos, key)
            },

            addBonus(bonus, quantity, price) {
                if(!this.cartContent.bonuses.hasOwnProperty(bonus.id)) {
                    this.cartContent.bonuses[bonus.id] = {
                        data: bonus,
                        quantity: quantity,
                        price: price
                    }
                } else {
                    this.cartContent.bonuses[bonus.id].quantity++;
                }
            },
            increaseBonus(key) {
                this.cartContent.bonuses[key].quantity++
            },
            decreaseBonus(key) {
                this.cartContent.bonuses[key].quantity--
            },
            removeBonus(key) {
                this.$delete(this.cartContent.bonuses, key)
            },
            async fetchCountries() {
                await axios.get('/api/countries')
                    .then(response => {
                        this.countries = response.data
                    })
                    .catch(error => {})
            },
            async fetchShippingOptions() {
                await axios.get('/api/shippings')
                    .then(response => {
                        this.shippingOptions = response.data
                    })
                    .catch(error => {})
            },
            async fetchShippingDiscounts() {
                await axios.get('/api/shippings/discounts')
                    .then(response => {
                        this.shippingDiscounts = response.data
                    })
                    .catch(error => {})
            },
            async fetchPaymentOptions() {
                await axios.get('/api/payments')
                    .then(response => {
                        this.paymentOptions = response.data
                    })
                    .catch(error => {})
            },
            promotionName(type) {
                switch (type) {
                    case 'special_price':
                        return 'Sonderpreis'
                    case 'instant_discount':
                        return 'Sofortrabatt';
                    case 'trade_in':
                        return 'Trade-In';
                    case 'cb':
                        return 'Cashback';
                    case 'combi_cb':
                        return 'Kombi-Cashback';
                    case 'free_items':
                        return 'Gratis-Beilage';
                    case 'voucher':
                        return 'Gutschein';
                    case 'voucher_discount':
                        return 'Gutschein Sofortrabatt';
                    case 'trade_in_manual':
                        return 'Trade-In (mit Nachweis)';
                    case 'combi_instant_discount_voucher':
                        return 'Kombi-Sofort-Gutschein-Rabatt';
                    case 'item_cashback':
                        return 'Produktzugabe Hersteller';
                    case 'multi_combi_instant_cashback':
                        return 'Multi_Kombi_Sofortrabatt';
                    case 'voucher_upload':
                        return 'Gutschein-Upload';
                    case 'combi_cb_v2':
                        return 'Kombi-Cashback (v2)';
                }
            },
            addVoucher(voucher) {
                if(!this.cartContent.vouchers.hasOwnProperty(voucher.code)) {
                    this.cartContent.vouchers[voucher.code] = {
                        data: voucher,
                        quantity: 1
                    }
                } else {

                }
                this.voucherModal.hide()
            },
            addEndPrice(voucher) {
                if(!this.cartContent.vouchers.hasOwnProperty('endPriceVoucher')) {
                    this.cartContent.vouchers['endPriceVoucher'] = {
                        data: voucher.data,
                        endPrice: parseFloat(voucher.endPrice),
                        quantity: 1
                    }
                } else {

                }
                this.voucherModal.hide()
            },
            removeVoucher(key) {
                this.$delete(this.cartContent.vouchers, key)
            },
            addCustomPosition(item) {
                this.customPositionModal.hide()
                this.cartContent.custom[this.objLength(this.cartContent.custom) + 1] = {                    data: {
                        name: item.name,
                        price: item.price
                    },
                    quantity: item.quantity
                }
            },
            increaseCustom(key) {
                this.cartContent.custom[key].quantity++
            },
            decreaseCustom(key) {
                this.cartContent.custom[key].quantity--
            },
            removeCustom(key) {
                this.$delete(this.cartContent.custom, key)
            },

            selectContact(entry) {
                this.contactEntriesVisibleEmail = false;
                this.contactEntriesVisibleName = false;
                this.contactEntriesVisibleCNo = false;
                this.billing.customer_no = entry.customer_no;
                if (entry.billing_address !== null) {
                    for (let property in entry.billing_address) {
                        if (this.billing.hasOwnProperty(property)) {
                            if (property === 'salutation') {
                                switch (entry.billing_address.salutation) {
                                    case 'Herr':
                                        this.billing.salutation = 'male';
                                        break;
                                    case 'Frau':
                                        this.billing.salutation = 'female';
                                        break;
                                }

                            } else if (property === 'country_id') {
                                this.billing['country'] = parseInt(entry.billing_address.country_id)
                            } else {
                                this.billing[property] = entry.billing_address[property];
                            }
                        } else {
                            switch (property) {
                                case 'address_additional':
                                    this.billing.additional_address_information = entry.billing_address[property]; break;
                                case 'address_additional_2':
                                    this.billing.additional_address_information_2 = entry.billing_address[property]; break;
                                case 'postcode':
                                    this.billing.zip = entry.billing_address[property];
                            }
                        }
                    }
                }
                this.contactSelected = entry.id;
                this.contactEntriesVisible = false;
            },
            scrollTop() {
                window.scrollTo(0, 0);
            },

            async handleOrder() {
                this.isHandling = true;
                this.order.error = false;
                this.resetErrors();

                if (!this.paymentSelected) {
                    this.order.error = true;
                    this.hasErrors = true;
                }


                if (!this.order.error)
                    await this.createOrUpdateContact();
                if (!this.order.error)
                    await this.createCustomer();
                if (!this.order.error)
                    await this.createOrder();
                if (!this.order.error)
                    await this.linkPositions();
                if (!this.order.error)
                    await this.linkPayment();
                if (!this.order.error)
                    await this.linkShipping();
                if (!this.order.error)
                    await this.completeOrder();
                if (!this.order.error)
                    await this.linkOrderCustomerContact();
                if (!this.order.error)
                    await this.notify();
                if (!this.order.error)
                    if (this.settings.newsletter)
                        await this.registerNewsletter();

                if (!this.order.error)
                    this.order.complete = true;

                this.scrollTop();

                this.loadingText = null;

                this.isLoading = false;
                this.isHandling = false;

            },
            async createOrUpdateContact() {
                this.isLoading = true;
                if (this.contactSave && this.contactSelected === null) {
                    this.loadingText = 'Kontakt wird gespeichert';
                } else if (this.contactSave && this.contactSelected !== null) {
                    this.loadingText = 'Kontakt wird aktualisiert';
                } else {
                    return true;
                }
                await axios.post('/api/contacts', {contact: this.contactSelected, billing: this.billing, delivery: this.delivery, delivery_option: this.deliveryOption, tracking: this.tracking})
                    .then((response) => {
                        this.order.contactIDs.contact  = response.data.contact_id;
                        this.order.contactIDs.billing  = response.data.billing_id;
                        this.order.contactIDs.delivery = response.data.delivery_id;
                    })
                    .catch((error) => {
                        if (error.response.status === 422) {
                            this.hasErrors = true;
                            this.errors = error.response.data.errors;
                        }
                        this.isHandling = false;
                        this.isLoading = false;
                        this.order.error = true;
                    })
            },
            async createCustomer() {
                this.isLoading = true;
                this.loadingText = 'Kunde wird angelegt';
                await axios.post('/api/customers', {billing: this.billing, delivery: this.delivery, delivery_option: this.deliveryOption, tracking: this.tracking})
                    .then((response) => {
                        this.order.customerIDs.billing  = response.data.billing_id;
                        this.order.customerIDs.delivery = response.data.delivery_id;
                    })
                    .catch((error) => {
                        if (error.response.status === 422) {
                            this.hasErrors = true;
                            this.errors = error.response.data.errors;
                        }
                        this.isHandling = false;
                        this.isLoading = false;
                        this.order.error = true;
                    })
            },
            async createOrder() {
                this.isLoading = true;
                this.loadingText = 'Bestellung wird erstellt';
                await axios.post('/api/orders/create', {customer: this.order.customerIDs, amounts: {total: (this.settings.isNetto) ? (this.total * 1.19) : this.total, taxes: (this.settings.isNetto) ? ((this.total * 1.19) - this.netto) : this.taxes, netto: this.netto}, comment: this.orderComment, settings: this.settings})
                    .then((response) => {
                        this.order.id = response.data.order_id;
                        this.order.no = response.data.order_no;
                    })
                    .catch((error) => {
                        if (error.response.status === 422) {
                            this.hasErrors = true;
                            this.errors = error.response.data.errors;
                        }
                        this.isHandling  = false;
                        this.isLoading   = false;
                        this.order.error = true;
                    })
            },
            async linkPositions() {
                this.isLoading = true;
                this.loadingText = 'Positionen werden hinterlegt';
                await axios.post('/api/orders/' + this.order.id + '/positions', {positions: this.positions})
                    .then((response) => {})
                    .catch((error) => {
                        if (error.response.status === 422) {
                            this.hasErrors = true;
                            this.errors = error.response.data.errors;
                        }
                        this.isHandling  = false;
                        this.isLoading   = false;
                        this.order.error = true;
                    })
            },
            async linkPayment() {
                this.isLoading = true;
                this.loadingText = 'Zahlungsart wird hinterlegt';
                await axios.post('/api/orders/' + this.order.id + '/payment', {payment: this.paymentSelected, payed: this.settings.payed})
                    .then((response) => {})
                    .catch((error) => {
                        if (error.response.status === 422) {
                            this.hasErrors = true;
                            this.errors = error.response.data.errors;
                        }
                        this.isHandling  = false;
                        this.isLoading   = false;
                        this.order.error = true;
                    })
            },
            async linkShipping() {
                this.isLoading = true;
                this.loadingText = 'Versandart wird hinterlegt';
                await axios.post('/api/orders/' + this.order.id + '/shipping', {shipping: {id: this.shippingSelected, price: (this.settings.isNetto) ? (this.shippingPrice * 1.19) : this.shippingPrice}})
                    .then((response) => {})
                    .catch((error) => {
                        if (error.response.status === 422) {
                            this.hasErrors = true;
                            this.errors = error.response.data.errors;
                        }
                        this.isHandling  = false;
                        this.isLoading   = false;
                        this.order.error = true;
                    })
            },
            async completeOrder() {
                this.isLoading = true;
                this.loadingText = 'Bestellung wird abgeschlossen';
                await axios.post('/api/orders/' + this.order.id + '/complete')
                    .then((response) => {})
                    .catch((error) => {
                        if (error.response.status === 422) {
                            this.hasErrors = true;
                            this.errors = error.response.data.errors;
                        }
                        this.isHandling  = false;
                        this.isLoading   = false;
                        this.order.error = true;
                    })
            },
            async linkOrderCustomerContact() {
                this.isLoading = true;
                this.loadingText = 'Kunde wird mit Bestellung verknüpft';
            },
            async notify() {
                this.isLoading = true;
                this.loadingText = 'Mails werden versendet';
                await axios.post('/api/orders/' + this.order.id + '/notify', {notifications: this.settings.notifications})
                    .then((response) => {})
                    .catch((error) => {
                        if (error.response.status === 422) {
                            this.hasErrors = true;
                            this.errors = error.response.data.errors;
                        }
                        this.isHandling  = false;
                        this.isLoading   = false;
                        this.order.error = true;
                    })
            },
            async registerNewsletter() {
                this.isLoading = true;
                this.loadingText = 'Kunde wird zum Newsletter angemeldet'
            },
            resetErrors() {
                this.hasErrors = false;
                this.errors = {};
            },
            setBillingCountry() {
                for (let i = 0; i < this.countries.length; i++) {
                    if (this.countries[i].id === this.billing.country) {
                        if(this.countries[i].eu && this.countries[i].id !== 1) {
                            this.vatId = true
                        } else {
                            this.vatId = false
                        }
                    }
                }
            },
            calculatePrice(price) {
                return (this.settings.isNetto) ? (price / 1.19) : price;
            }
        },

        computed: {
            totalPositions () {
                let total = 0;
                this.discount = 0;
                for (let itemKey in this.cartContent.items) {
                    if (this.cartContent.items[itemKey].price === null) {
                        total += this.cartContent.items[itemKey].data.price * this.cartContent.items[itemKey].quantity;
                    } else {
                        total += this.cartContent.items[itemKey].price * this.cartContent.items[itemKey].quantity;
                    }
                    if (this.cartContent.items[itemKey].data.hasOwnProperty('promotions')) {
                        for (let promotionKey in this.cartContent.items[itemKey].data.promotions) {
                            if (this.cartContent.items[itemKey].data.promotions[promotionKey].hasOwnProperty('disabled')) {
                                if (this.cartContent.items[itemKey].data.promotions[promotionKey].disabled === false) {
                                    if (this.cartContent.items[itemKey].data.promotions[promotionKey].discount !== null)
                                    {
                                        if (this.cartContent.items[itemKey].data.promotions[promotionKey].in_percent)
                                        {
                                            total -= (this.cartContent.items[itemKey].data.price * (this.cartContent.items[itemKey].data.promotions[promotionKey].discount / 100)) * this.cartContent.items[itemKey].quantity;
                                            this.discount += (this.cartContent.items[itemKey].data.price * (this.cartContent.items[itemKey].data.promotions[promotionKey].discount / 100)) * this.cartContent.items[itemKey].quantity;
                                        }
                                        else
                                        {
                                            total -= this.cartContent.items[itemKey].data.promotions[promotionKey].discount * this.cartContent.items[itemKey].quantity;
                                            this.discount += this.cartContent.items[itemKey].data.promotions[promotionKey].discount * this.cartContent.items[itemKey].quantity
                                        }
                                    }
                                    else if (this.cartContent.items[itemKey].data.promotions[promotionKey].special_price !== null)
                                    {
                                        total -= (this.cartContent.items[itemKey].data.price - this.cartContent.items[itemKey].data.promotions[promotionKey].special_price) * this.cartContent.items[itemKey].quantity;
                                        this.discount += (this.cartContent.items[itemKey].data.price - this.cartContent.items[itemKey].data.promotions[promotionKey].special_price) * this.cartContent.items[itemKey].quantity
                                    } else {
                                        console.log('promotion unhandeled', this.cartContent.items[itemKey].data.promotions[promotionKey]);
                                    }
                                }
                            }
                            else
                            {
                                if (this.cartContent.items[itemKey].data.promotions[promotionKey].discount !== null)
                                {
                                    if (this.cartContent.items[itemKey].data.promotions[promotionKey].in_percent)
                                    {
                                        total -= (this.cartContent.items[itemKey].data.price * (this.cartContent.items[itemKey].data.promotions[promotionKey].discount / 100)) * this.cartContent.items[itemKey].quantity;
                                        this.discount += (this.cartContent.items[itemKey].data.price * (this.cartContent.items[itemKey].data.promotions[promotionKey].discount / 100)) * this.cartContent.items[itemKey].quantity;
                                    }
                                    else
                                    {
                                        total -= this.cartContent.items[itemKey].data.promotions[promotionKey].discount * this.cartContent.items[itemKey].quantity;
                                        this.discount += this.cartContent.items[itemKey].data.promotions[promotionKey].discount * this.cartContent.items[itemKey].quantity
                                    }
                                }
                                else if (this.cartContent.items[itemKey].data.promotions[promotionKey].special_price !== null)
                                {
                                    total -= (this.cartContent.items[itemKey].data.price - this.cartContent.items[itemKey].data.promotions[promotionKey].special_price) * this.cartContent.items[itemKey].quantity;
                                    this.discount += (this.cartContent.items[itemKey].data.price - this.cartContent.items[itemKey].data.promotions[promotionKey].special_price) * this.cartContent.items[itemKey].quantity
                                } else {
                                    console.log('promotion unhandeled', this.cartContent.items[itemKey].data.promotions[promotionKey]);
                                }
                            }

                        }
                    }
                }
                for (let demoKey in this.cartContent.demos) {
                    if (this.cartContent.demos[demoKey].price === null) {
                        total += this.cartContent.demos[demoKey].data.price * this.cartContent.demos[demoKey].quantity
                    } else {
                        total += this.cartContent.demos[demoKey].price * this.cartContent.demos[demoKey].quantity
                    }
                    if (this.cartContent.demos[demoKey].data.item.hasOwnProperty('promotions')) {
                        for (let promotionKey in this.cartContent.demos[demoKey].data.item.promotions) {
                            if (this.cartContent.demos[demoKey].data.item.promotions[promotionKey].hasOwnProperty('disabled')) {
                                if (this.cartContent.demos[demoKey].data.item.promotions[promotionKey].disabled === false) {
                                    total -= this.cartContent.demos[demoKey].data.item.promotions[promotionKey].discount * this.cartContent.demos[demoKey].quantity;
                                    this.discount += this.cartContent.demos[demoKey].data.item.promotions[promotionKey].discount * this.cartContent.demos[demoKey].quantity
                                }
                            } else {
                                total -= this.cartContent.demos[demoKey].data.item.promotions[promotionKey].discount * this.cartContent.demos[demoKey].quantity;
                                this.discount += this.cartContent.demos[demoKey].data.item.promotions[promotionKey].discount * this.cartContent.demos[demoKey].quantity
                            }

                        }
                    }
                }
                for (let workshopKey in this.cartContent.workshops) {
                    if (this.cartContent.workshops[workshopKey].price === null) {
                        total += this.cartContent.workshops[workshopKey].data.price * this.cartContent.workshops[workshopKey].quantity
                    } else {
                        total += this.cartContent.workshops[workshopKey].price * this.cartContent.workshops[workshopKey].quantity
                    }
                }
                for (let bonusKey in this.cartContent.bonuses) {
                    if (this.cartContent.bonuses[bonusKey].price === null) {

                    } else {
                        total += this.cartContent.bonuses[bonusKey].price * this.cartContent.bonuses[bonusKey].quantity;
                    }
                }
                for (let customKey in this.cartContent.custom) {
                    total += this.cartContent.custom[customKey].data.price * this.cartContent.custom[customKey].quantity
                }
                return (this.settings.isNetto) ? (total / 1.19) : total;
            },
            totalVouchers () {
                let discount = 0;
                for (let voucherKey in this.cartContent.vouchers) {
                    let tmpDiscount = 0;
                    if (!this.cartContent.vouchers[voucherKey].data.hasOwnProperty('discount_percentage') || this.cartContent.vouchers[voucherKey].data.discount_percentage === null) {
                        if (voucherKey !== 'endPriceVoucher') {
                            tmpDiscount = this.cartContent.vouchers[voucherKey].data.discount_amount * this.cartContent.vouchers[voucherKey].quantity;
                            discount += (this.settings.isNetto) ? (tmpDiscount / 1.19) : tmpDiscount;
                        }
                    } else {
                        discount += ((this.totalPositions * this.cartContent.vouchers[voucherKey].data.discount_percentage) / 100) * this.cartContent.vouchers[voucherKey].quantity;
                    }
                }
                return discount;
            },
            totalPosShiVou () {
                return this.totalPositions + this.shippingPrice - this.totalVouchers
            },
            total () {
                return  this.totalPosShiVou - this.endPriceVoucher;
            },
            taxes () {
                return (this.total - this.netto)
            },
            netto () {
                return (this.settings.isNetto) ? this.total : (this.total / 1.19);
            },
            patternZip () {
                if (this.billing.country !== 1) {
                    return /[]/
                } else {
                    return this.billing.pattern.zip
                }
            },
            shippingDiscount () {
                let discount = 0;
                for (let key in this.shippingDiscounts) {
                    let discounts = this.shippingDiscounts[key];
                    if (this.freePremiumShipping) {
                        discount += discounts.shipping_discount_premium;
                    } else if (this.freeShipping) {
                        discount += discounts.shipping_discount;
                    } else if (discounts.shipping_discount_limit < this.totalPositions) {
                        discount += discounts.shipping_discount;
                    }
                }
                return discount;
            },
            shippingPrice () {
                let price = 0;
                for (let key in this.shippingOptions) {
                    if (this.shippingSelected !== null && this.shippingOptions[key].id === this.shippingSelected) {
                        for (let ckey in this.shippingOptions[key].countries) {
                            if (this.shippingOptions[key].countries[ckey].id === this.selectedCountry) {
                                price = this.shippingOptions[key].countries[ckey].pivot.price;
                            }
                        }
                    }
                }
                price -= this.shippingDiscount;

                price = (this.settings.isNetto) ? (price / 1.19) : price;

                if (this.settings.no_delivery_costs)
                    price = 0;

                return price < 0 ? 0 : price;
            },
            positions () {
                let positions = {
                    items: {},
                    demos: {},
                    workshops: {},
                    bonuses: {},
                    vouchers: {},
                    custom: {}
                };

                for (let itemKey in this.cartContent.items) {
                    positions.items[itemKey] = {
                        item_id: this.cartContent.items[itemKey]['data']['id'],
                        quantity: this.cartContent.items[itemKey]['quantity'],
                        price: this.cartContent.items[itemKey]['price'],
                        promotions: []
                    }
                    for (let promoKey in this.cartContent.items[itemKey].data.promotions) {
                        if ((this.cartContent.items[itemKey].data.promotions[promoKey].hasOwnProperty('disabled') && !this.cartContent.items[itemKey].data.promotions[promoKey].disabled)
                            || !this.cartContent.items[itemKey].data.promotions[promoKey].hasOwnProperty('disabled'))
                        {
                            positions.items[itemKey].promotions.push(this.cartContent.items[itemKey].data.promotions[promoKey].id)
                        }
                    }
                }
                for (let itemKey in this.cartContent.demos) {
                    positions.demos[itemKey] = {
                        item_id: this.cartContent.demos[itemKey]['data']['id'],
                        quantity: this.cartContent.demos[itemKey]['quantity'],
                        price: this.cartContent.demos[itemKey]['price'],
                        promotions: []
                    }
                    for (let promoKey in this.cartContent.demos[itemKey].data.item.promotions) {
                        if (this.manufacturerPromotionsDisabled) {
                            if (this.manufacturerPromotionsDisabled.includes(this.cartContent.demos[itemKey].data.item.promotions[promoKey].type)) {
                                this.disableItemDemoPromotion(itemKey, promoKey);
                            }
                        }
                        if ((this.cartContent.demos[itemKey].data.item.promotions[promoKey].hasOwnProperty('disabled') && !this.cartContent.demos[itemKey].data.item.promotions[promoKey].disabled)
                            || !this.cartContent.demos[itemKey].data.item.promotions[promoKey].hasOwnProperty('disabled'))
                        {
                            positions.demos[itemKey].promotions.push(this.cartContent.demos[itemKey].data.item.promotions[promoKey].id)
                        }
                    }
                }
                for (let itemKey in this.cartContent.workshops) {
                    positions.workshops[itemKey] = {
                        item_id: this.cartContent.workshops[itemKey]['data']['id'],
                        quantity: this.cartContent.workshops[itemKey]['quantity'],
                        price: this.cartContent.workshops[itemKey]['price']
                    }
                }
                for (let itemKey in this.cartContent.bonuses) {
                    positions.bonuses[itemKey] = {
                        item_id: this.cartContent.bonuses[itemKey]['data']['id'],
                        quantity: this.cartContent.bonuses[itemKey]['quantity'],
                        price: this.cartContent.bonuses[itemKey]['price']
                    }
                }
                for (let voucherKey in this.cartContent.vouchers) {
                    let tmpDiscount = 0;
                    if (!this.cartContent.vouchers[voucherKey].data.hasOwnProperty('discount_percentage') || this.cartContent.vouchers[voucherKey].data.discount_percentage === null) {
                        tmpDiscount = this.cartContent.vouchers[voucherKey].data.discount_amount * this.cartContent.vouchers[voucherKey].quantity;
                        positions.vouchers[voucherKey] = {
                            data: this.cartContent.vouchers[voucherKey]['data'],
                            quantity: this.cartContent.vouchers[voucherKey]['quantity'],
                            price: (this.settings.isNetto) ? (tmpDiscount / 1.19) : tmpDiscount
                        }
                    } else {
                        positions.vouchers[voucherKey] = {
                            data: this.cartContent.vouchers[voucherKey]['data'],
                            quantity: this.cartContent.vouchers[voucherKey]['quantity'],
                            price: ((this.totalPositions * this.cartContent.vouchers[voucherKey].data.discount_percentage) / 100) * this.cartContent.vouchers[voucherKey].quantity
                        }
                    }
                }
                positions.custom = this.cartContent.custom;

                return positions;
            },
            contactSearchByEmail () {
                return this.billing.email;
            },
            contactSearchByName () {
                return this.billing.name;
            },
            contactSearchByCNo () {
                return this.billing.customer_no;
            },
            selectedCountry () {
                if (this.deliveryOption === 'billing-address') {
                    return this.billing.country;
                } else {
                    return this.delivery.country;
                }
            },
            disabledPaymentOptions () {
                let disabled = [];
                for (let paymentKey in this.paymentOptions) {
                    let payment = this.paymentOptions[paymentKey];
                    if (payment.min_order_amount > this.total || (payment.max_order_amount !== null && payment.max_order_amount < this.total)) {
                        disabled.push(payment.id);
                    }
                }
                return disabled;
            },
            endPriceVoucher () {
                if(this.cartContent.vouchers.hasOwnProperty('endPriceVoucher')) {
                    let endPrice = this.cartContent.vouchers.endPriceVoucher.endPrice
                    endPrice = (this.totalPosShiVou - endPrice);
                    if (endPrice < 0) {
                        endPrice = 0
                    }
                    this.cartContent.vouchers.endPriceVoucher.data.discount_amount = endPrice;
                    return endPrice;
                } else {
                    return 0;
                }
            }
        },
        watch: {
            'billing.vat_id' () {
                if (this.billing.vat_id !== '' && this.billing.vat_id !== null && this.vatId) {
                    this.deliveryOption = this.deliveryBilling;
                    this.deliveryDisabled = true;
                    this.settings.isNetto = true;
                } else {
                    this.deliveryDisabled = false;
                    this.settings.isNetto = false;
                }
            },
            'billing.country' () {
                if (!this.firstTry) {
                    this.billing.zip = '';
                    this.billing.city = '';
                }
                this.setBillingCountry();
                this.firstTry = false;
            },
            disabledPaymentOptions: {
                handler() {
                    if (this.disabledPaymentOptions.includes(this.paymentSelected)) {
                        this.paymentSelected = null;
                    }
                },
                deep: true
            },
            initSelected () {
                this.selected = this.initSelected
            },
            contactSearchByEmail (val) {
                if (this.contactSearchByEmail.length < 3) return;
                this.isLoadingSearchEmail = true;
                this.axiosCancel();

                axios.get('/api/contacts/search', {
                    cancelToken: new axios.CancelToken(c => this.axiosCancel = c),
                    params: {term: this.contactSearchByEmail, col: 'email'}
                }).then(response => {
                    this.contactEntries = response.data;
                })
                .catch(e => {(axios.isCancel(e))})
                .finally(() => (this.isLoadingSearchEmail = false))
            },
            contactSearchByName (val) {
                if (this.contactSearchByName.length < 3) return;
                this.isLoadingSearchName = true;
                this.axiosCancel();

                axios.get('/api/contacts/search', {
                    cancelToken: new axios.CancelToken(c => this.axiosCancel = c),
                    params: {term: this.contactSearchByName, col: 'name'}
                }).then(response => {
                    this.contactEntries = response.data;
                })
                .catch(e => {(axios.isCancel(e))})
                .finally(() => (this.isLoadingSearchName = false))
            },
            contactSearchByCNo (val) {
                if (this.contactSearchByCNo !== null && this.contactSearchByCNo.length < 3) return;
                this.isLoadingSearchCNo = true;
                this.axiosCancel();

                axios.get('/api/contacts/search', {
                    cancelToken: new axios.CancelToken(c => this.axiosCancel = c),
                    params: {term: this.contactSearchByCNo, col: 'customer_no'}
                })
                    .then(response => {
                        this.contactEntries = response.data;
                    })
                    .catch(e => {(axios.isCancel(e))})
                    .finally(() => (this.isLoadingSearchCNo = false))
            },
        },
    }
</script>
