<template>
    <div class="ac_alert-modal_backdrop">
        <div class="ac_alert-modal m-4">
            <div class="card">
                <div class="p-4">
                    <template>
                        <p>{{ value.text }}</p>
                        <ul>
                            <li v-for="(answer, index) in value.answers">{{ answer.count }}x {{ answer.text }}</li>
                        </ul>
                    </template>
                </div>
                <div class="border-0 pb-4 container">
                    <div class="row">
                        <div class="col-12 col-md-6 text-center">
                            <button class="btn btn-secondary m-2" @click.prevent="close()">schließen</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {

        props: [
            'title',
            'titleClass',
            'value'
        ],

        data () {
            return {}
        },
        methods: {
            close (a) {
                this.$emit('close');
            }
        },
    }
</script>

<style>
    .ac_alert-modal_backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: rgb(0 0 0 / 80%);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 9999;
    }

    .ac_alert-modal {
        background: #141414;
        overflow-x: auto;
        display: flex;
        flex-direction: column;
    }

    @media (min-width: 700px) {
        .ac_alert-modal {
            max-width: 700px;
            margin-right: auto;
            margin-left: auto;
        }
    }
</style>
