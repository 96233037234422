<template>
    <div class="p-3">
        <div class="row mb-4">
            <div class="col-10"><h1>Promotionkalender Aktion bearbeiten</h1></div>
            <div class="col-2 text-end"><a :href="'/backend/promocalendar/' + calendar.id + '/edit'" class="btn btn-secondary">Zurück</a></div>
        </div>
        <div class="row mb-4">
            <div class="col-8">
                <div class="card h-100">
                    <h5 class="card-header">Details</h5>
                    <div class="card-body">
                        <div class="form-group">
                            <label for="inputName">Bezeichnung</label>
                            <input type="text" id="inputName" class="form-control" aria-describedby="nameHelp" @input="onContentChange"
                                   :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('name'))}" placeholder="Name/Überschrift" name="name"
                                   v-model="promoCalendarPromotion.title">
                            <div v-if="hasErrors && errors.hasOwnProperty('name')" class="invalid-feedback">
                                {{ errors['name'][0] }}
                            </div>
                            <small id="nameHelp" class="form-text text-muted">Name/Überschrift der Aktion</small>
                        </div>
                        <div class="form-group">
                            <label for="inputDescription">Beschreibung <span class="text-muted">optional</span></label>
                            <input type="text" id="inputDescription" class="form-control" @input="onContentChange"
                                   :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('description'))}" placeholder="Beschreibung" name="description"
                                   v-model="promoCalendarPromotion.description">
                            <div v-if="hasErrors && errors.hasOwnProperty('description')" class="invalid-feedback">
                                {{ errors['name'][0] }}
                            </div>
                            <small id="descriptionHelp" class="form-text text-muted">Eine interne Beschreibung der Aktion</small>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="card h-100">
                    <h5 class="card-header">Einstellungen</h5>
                    <div class="card-body">
                        <div class="form-group">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" id="inputActive" class="custom-control-input" @input="onContentChange"
                                       :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('active'))}" name="active"
                                       v-model="promoCalendarPromotion.active">
                                <label class="custom-control-label" for="inputActive">Aktiviert</label>
                            </div>
                        </div>
                        <div class="form-group">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" id="inputRegistration" class="custom-control-input" @input="onContentChange"
                                       :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('registration'))}" name="registration"
                                       v-model="promoCalendarPromotion.registration">
                                <label class="custom-control-label" for="inputRegistration">Registrierung erforderlich</label>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="inputStart">Startzeit</label>
                            <local-date-picker
                                :append-to-body="false"
                                popup-class="mx-fullwidth"
                                floating="true"
                                :id="'promoCalendarPromotion_start'"
                                :placeholder="'Ab'"
                                label="Ab"
                                v-model="promoCalendarPromotion.start"
                                :input-class="'form-control'"
                                @change="onContentChange"
                            ></local-date-picker>
                            <small id="startHelp" class="form-text text-muted">Uhrzeit ab wann die Aktion verfügbar ist</small>
                        </div>
                        <div class="form-group">
                            <label for="inputEnd">Endzeit</label>
                            <local-date-picker
                                :append-to-body="false"
                                popup-class="mx-fullwidth"
                                floating="true"
                                :id="'promoCalendarPromotion_end'"
                                :placeholder="'Bis'"
                                label="Bis"
                                v-model="promoCalendarPromotion.end"
                                :input-class="'form-control'"
                                @change="onContentChange"
                            ></local-date-picker>
                            <small id="endHelp" class="form-text text-muted">Uhrzeit ab wann die Aktion nicht mehr verfügbar ist</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-12 text-end">
                <button :disabled="canSave === false" :class="canSave ? 'btn-primary' : 'btn-success'" @click="save" class="btn">Speichern</button>
                <button :disabled="canSave === false" :class="canSave ? 'btn-secondary' : 'btn-success'" @click="save(null, true)" class="btn">Speichern & Schließen</button>
            </div>
        </div>
        <div class="row mb-4">
            <div class="col-12">
                <promocalendar-promotion-items :promotion="promoCalendarPromotion"></promocalendar-promotion-items>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <promocalendar-promotion-vouchers :promotion="promoCalendarPromotion"></promocalendar-promotion-vouchers>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: [
            'old',
            'items',
            'errors',
            'calendar',
            'promotion'
        ],

        data() {
            return {
                canSave: false,
                hasErrors: false,
                isLoading: false,
                hasOld: false,

                promoCalendarPromotion: {
                    id: this.promotion.id,
                    promocalendar_id: this.promotion.promocalendar_id,
                    title: this.promotion.title,
                    description: this.promotion.description,
                    active: this.promotion.active,
                    registration: this.promotion.registration,
                    start: this.moment(this.promotion.start).format('YYYY-MM-DD HH:mm'),
                    end: this.moment(this.promotion.end).format('YYYY-MM-DD HH:mm')
                }
            }
        },

        mounted() {

            /** mount errors **/
            if(this.notEmptyObject(this.errors)) {
                this.hasErrors = true
            }
            /** mount old Data **/
            if(this.notEmptyObject(this.old)) {
                this.hasOld = true
            }
        },

        methods: {
            save(e, close = false) {
                this.isLoading = true
                axios.patch('/backend/promocalendar/' + this.promotion.promocalendar_id + '/promotion/' + this.promotion.id, {
                    title: this.promoCalendarPromotion.title,
                    description: this.promoCalendarPromotion.description,
                    active: this.promoCalendarPromotion.active,
                    registration: this.promoCalendarPromotion.registration,
                    day: this.promoCalendarPromotion.day,
                    start: this.promoCalendarPromotion.start,
                    end: this.promoCalendarPromotion.end
                })
                    .then(response => {
                        this.canSave = false;
                    })
                    .catch(error => {})
                    .finally(() => {
                        this.isLoading = false
                        if (close) {
                            window.location.href = '/backend/promocalendar/' + this.promoCalendarPromotion.promocalendar_id + '/edit';
                        }
                    })
            },
            onContentChange(e) {
                this.canSave = true;
            },
        },

        watch: {
            'promoCalendarPromotion.start' () {
                this.onContentChange()
            },
            'promoCalendarPromotion.end' () {
                this.onContentChange()
            }
        },
    }
</script>
