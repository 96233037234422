<template>
    <div class="search-autocomplete">
        <form
            @submit.prevent="submit"
            :id="id"
            action="/search"
            method="get"
            class="h-100"
        >
            <input
                type="text"
                class="w-100"
                name="search"
                :id="id + '-input'"
                @focus="suggestionEntriesVisible = true"
                @blur="suggestionEntriesVisible = false"
                @click="suggestionEntriesVisible = true"
                placeholder="Suchen.."
                autocomplete="off"
                v-model="searchInput"
            />
            <span
                class="form-control-feedback"
                :style="[isSearchLoading ? {} : { opacity: '0' }]"
            >
        <i class="fas fa-spinner fa-pulse"></i>
      </span>
            <div
                class="search-autocomplete-container"
                v-if="(suggestionEntries.queries.length || suggestionEntries.products.length) && suggestionEntriesVisible"
            >
                <div class="search-autocomplete-results">
                    <div class="search-list-group">
                        <template v-if="suggestionEntries.queries.length">
                            <div class="search-list-group-header">Suchvorschläge</div>
                            <div
                                class="search-list-group-item"
                                v-for="entry in suggestionEntries.queries"
                                @mousedown.prevent
                                @click="searchInput = entry.name"
                            >
                                <div class="row">
                                    <div class="col-12 d-flex align-items-center">
                                        {{ entry.name }}
                                    </div>
                                </div>
                            </div>
                        </template>
                        <div class="search-list-group-header">Produktvorschläge</div>
                        <div
                            class="search-list-group-item py-1"
                            v-for="entry in suggestionEntries.products"
                            @mousedown.prevent
                            @click="">
                            <a :href="entry[0].link">
                                <div class="row m-0">
                                    <div class="col-1 col-sm-2 col-lg-1 col-xl-2 px-0">
                                        <img :src="entry[0].image" class="img-fluid" :alt="entry[0].name + ' Abbildung'" />
                                    </div>
                                    <div class="col-11 col-sm-10 col-lg-11 col-xl-10 d-flex align-items-center">
                                        {{ entry[0].name }}
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    props: [
        'id',
        'isFiDomain',
        'isEuDomain',
    ],

    data() {
        return {
            axiosCancel: () => {},
            searchInput: "",
            isSearchLoading: false,
            suggestionEntriesVisible: false,
            suggestionEntries: {
                'products': [],
                'queries': []
            },
            csrf: document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
        };
    },
    mounted() {},
    unmounted() {},
    methods: {
        search() {
            this.isSearchLoading = true;
            this.axiosCancel();

            axios
                .get("https://api-v3.semknox.com/search/suggestions", {
                    headers: null,
                    cancelToken: new axios.CancelToken((c) => (this.axiosCancel = c)),
                    params: {
                        query: this.searchInput,
                        projectId: 28128,
                    },
                })
                .then((response) => {
                    response.data.searchResults.forEach((entry) => {
                        if (entry.type === 'products') {
                            if (this.isFiDomain) {
                                entry.results.forEach((result) => {
                                    result[0].link = result[0].link.replace('www.ac-foto.com', 'fi.ac-foto.com')
                                })
                            } else if (this.isEuDomain) {
                                entry.results.forEach((result) => {
                                    result[0].link = result[0].link.replace('www.ac-foto.com', 'en.ac-foto.com')
                                })
                            }
                            this.suggestionEntries.products = entry.results

                        } else if (entry.type === 'queries') {
                            this.suggestionEntries.queries = entry.results
                        }
                    })
                })
                .catch((e) => {
                    axios.isCancel(e);
                })
                .finally(() => (this.isSearchLoading = false));
        },
        submit: function () {
            if (this.searchInput.trim().length < 3) return;
            this.$nextTick(() => {
                const query = document.getElementById(this.id + '-input');
                query.value = this.searchInput.trim();
                const form = document.getElementById(this.id);
                form.submit();
            });
        },
    },
    watch: {
        searchInput() {
            if (this.searchInput.trim().length < 3) return;
            this.search();
        },
    },
};
</script>
