<template>
    <div class="card">
        <div class="card-header">Angebotsartikel</div>
        <div class="card-body">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Anzahl</th>
                        <th scope="col">Preis Regulär</th>
                        <th scope="col">Preis nach Aktionen*1</th>
                        <th scope="col">Preis Reduziert</th>
                        <th scope="col">Rabatt</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                <template v-for="position in offerPositions">
                    <tr>
                        <td class="align-middle">{{ position.item.brand.name }} {{ position.item.name }}</td>
                        <td class="align-middle">{{ position.quantity }}</td>
                        <td class="align-middle">{{ position.item.prices['priceRegular'].toFixed(2) }} €</td>
                        <td class="align-middle">{{ position.item.prices['priceBest'].toFixed(2) }} €</td>
                        <td class="align-middle">{{ (position.item.prices['priceBest'] - position.discount).toFixed(2) }} €</td>
                        <td class="align-middle">{{ position.discount.toFixed(2) }} €</td>
                        <td class="align-middle"></td>
                    </tr>
                    <template v-if="position.item.promotions.length || Object.keys(position.item.promotions).length">
                        <tr>
                            <td class="text-muted" colspan="7" style="box-shadow: unset;background-color: rgb(32 44 49)">
                                <span class="fw-bold">Laufende Aktionen zum Artikel*2</span>
                            </td>
                        </tr>
                        <tr v-for="(promo, index) in position.item.promotions">
                            <td class="text-muted" colspan="7" style="box-shadow: unset;background-color: rgb(32 44 49)">
                                <span class="fw-bold">[AKTION] </span> {{ promo.brand_promotion ? promo.brand_promotion.name : '' }} _ {{ promo.discount ? promo.discount.toFixed(2) : 'NaN' }} €
                            </td>
                        </tr>
                    </template>
                </template>
                </tbody>
            </table>
            <p class="mb-0 text-muted">*1 Preis nach Aktionen, die sofort oder im Warenkorb abgezogen werden. Profi-Voucher nicht enthalten!</p>
            <p class="mb-0 text-muted">*2 werden im Warenkorb abgezogen, wenn Angebot im Zeitraum beansprucht wird!</p>

        </div>
        <loading-modal :text="'Lade..'" :active="isLoading" v-if="isLoading"></loading-modal>
    </div>
</template>
<script>
export default {
    props: [
        'offer'
    ],

    data() {
        return {
            canSave: false,
            hasErrors: false,
            errors: {},
            isLoading: false,

            offerPositions: [],

            message: '',

            requested: false,
            offerNo: null,

            editPosition: {
                name: '',
                quantity: 0,
                discount: 0
            },
            priceChangeModal: null,

            promoTypes: [
                'instant_discount'
            ],
        }
    },

    mounted() {
        this.offerPositions = this.offer.positions
    },

    methods: {
        fetch() {
            axios.get('/api/backend/affiliates/requests/' + this.affiliateRequest.id + '/positions')
            .then(response => {
                if (response.status === 200) {
                    this.affiliateRequestPositions = response.data
                }
            })
        },
        refresh() {
            this.fetch()
        },
        edit(position) {
            this.editPosition = position
            this.priceChangeModal.show()
        },
        update(positionID, discount) {
            this.priceChangeModal.hide()
            axios.patch('/api/backend/affiliates/requests/positions/' + positionID, {
                discount: discount
            })
                .then(response => {
                    if (response.status === 200) {
                        this.fetch()
                    }
                })
        },
        submit() {
            this.isLoading = true
            this.hasErrors = false
            this.error = {}
            axios.post('/affiliate/' + this.affiliateForm.id, {
                items: this.items,
                customer: this.customer,
                message: this.message,
                associations: this.associations
            })
                .then(response => {
                    if (response.status === 200) {
                        this.requested = true
                        this.offerNo = response.data.offerNo
                    }
                })
                .catch(error => {
                    if (error.response.data.hasOwnProperty('errors')) {
                        this.hasErrors = true
                        this.errors = error.response.data.errors
                    }
                })
                .finally(() => {
                    this.selected = null
                    this.isLoading = false
                })


        },
        itemAdd(item) {

            let foundIndex = this.items.findIndex(e => e.id === item.id)

            if (foundIndex >= 0) {
                this.items[foundIndex].quantity++;
            } else {
                this.items.push({
                    id: item.id,
                    image: item.image,
                    name: item.name,
                    quantity: 1
                })
            }
        },
        itemRemove(key) {
            this.$delete(this.items, key)
        },

        addAssociation (newTag) {
            const tag = {
                value: newTag,
                name: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
            }
            this.associationOptions.push(tag)
            this.associations.push(tag)
        }
    },
}
</script>
