<template>
    <div class="card">
        <div class="card-header">
            <div class="row">
                <div class="col-8"><h5>Zugewiesene Produkte</h5></div>
                <div class="col-4 text-end"><a class="btn btn-sm btn-secondary" href="#" data-bs-toggle="modal" data-bs-target="#addItemModal">Produkt zuweisen</a></div>
            </div>
        </div>
        <div class="card-body p-0">
            <template v-if="items.length">
                <table class="table table-striped mb-0 noselect" id="partnershop-items">
                    <thead>
                    <tr>
                        <th></th>
                        <th>ID</th>
                        <th>Produkt-ID</th>
                        <th>Bezeichnung</th>
                        <th class="text-end">Rabatt</th>
                        <th class="text-end">Aktionsrabatt</th>
                        <th class="text-center">als Gutschein</th>
                        <th class="text-center">vorgestellt</th>
                        <th class="text-center">online</th>
                        <th></th>
                        <th></th>
                    </tr>
                    </thead>
                    <draggable tag="tbody" v-model="items" item-key="id" @change="reSort" handle=".handle">
                        <template #item="{ element, index }">
                            <tr>
                                <td><span class="handle draggable"><i class="fas fa-bars"></i></span></td>
                                <td>{{ element.id }}</td>
                                <td>{{ element.item.id }}</td>

                                <template v-if="element.item === null">
                                    <td class="fw-bold text-danger">Gelöschtes Produkt !</td>
                                </template>
                                <template v-else>
                                    <td>{{ element.item.brand.name }} {{ element.item.name }}</td>
                                </template>

                                <td class="text-end" style="white-space: nowrap;">{{ element.discount.toFixed(2).replace(".", ",") }} €</td>

                                <td v-if="(element.campaign_from === null || Date.parse(element.campaign_from) < now) && (element.campaign_until === null || Date.parse(element.campaign_until) > now) && element.campaign_discount > 0" class="text-end" style="white-space: nowrap;">
                                    {{ element.campaign_discount.toFixed(2).replace(".", ",") }} €
                                </td>
                                <td v-else class="text-end">
                                    <span class="dot dot-danger"></span>
                                </td>

                                <td class="text-center">
                                    <span v-if="element.voucher" class="dot dot-success"></span>
                                    <span v-else class="dot dot-warning"></span>
                                </td>
                                <td class="text-center">
                                    <span v-if="element.featured && ( (element.featured_from === null ||Date.parse(element.featured_from) < now) && (element.featured_until === null ||Date.parse(element.featured_until) > now))" class="dot dot-success"></span>
                                    <span v-else class="dot dot-info"></span>
                                </td>
                                <td class="text-center">
                                    <span v-if="element.active && element.active_from === null" class="dot dot-success"></span>
                                    <span v-else-if="element.active && element.active_from !== null" class="dot dot-success-warning"></span>
                                    <span v-else class="dot dot-danger"></span>
                                </td>
                                <td style="width: 1px;white-space: nowrap;">
                                    <a v-if="element.item !== null" href="#" @click.prevent="edit(index)"><i class="fas fa-edit" aria-hidden="true"></i></a>
                                </td>
                                <td style="width: 1px;white-space: nowrap;">
                                    <a href="#" @click.prevent="destroy(element.id)"><i class="fa fa-times" aria-hidden="true"></i></a>
                                </td>
                            </tr>
                        </template>
                    </draggable>
                </table>
            </template>
            <template v-else>
                <div class="p-4 text-center">
                    Es wurden noch keine Produkte zu diesem Shop hinzugefügt
                </div>
            </template>
        </div>
        <div id="addItemModal" class="modal fade" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Produkt hinzufügen</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row mb-2">
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="ps-1 pb-1" for="inputItemSearch">Produkt</label>
                                    <template v-if="selected === null">
                                        <input class="form-control" type="text" id="inputItemSearch" name="item_search" v-model="search" @focus="entriesVisible = true" @blur="entriesVisible = false" @click="entriesVisible = true" autocomplete="off">
                                        <span class="loading form-control-feedback" v-show="isLoading"></span>
                                        <div class="autocomplete-results" v-if="entries.length && entriesVisible">
                                            <div class="list-group">
                                                <div class="list-group-item" v-for="entry in entries" @mousedown.prevent @click="select(entry)">
                                                    <div class="row">
                                                        <div class="col-2">
                                                            <template v-if="entry.images.length">
                                                                <img :data-src="'/storage/img/uploads/products/' + entry.id + '/' + entry.images[0].webp_medium" alt="" class="lazy img-fluid">
                                                            </template>
                                                            <template v-else>
                                                                <img data-src="/storage/img/general/brokenimage.png" alt="" class="lazy img-fluid">
                                                            </template>
                                                        </div>
                                                        <div class="col-10 justify-content-center align-self-center">
                                                            {{ entry.brand.name }} {{ entry.name }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <small id="amountHelp" class="form-text text-muted">Verwende '%' als Platzhalter</small>
                                    </template>
                                    <template v-else>
                                        <div class="row align-items-center">
                                            <div class="col-10">
                                                <input type="text" class="form-control" disabled :value="selected.brand.name + ' ' + selected.name">
                                            </div>
                                            <div class="col-2">
                                                <a href="#" @click.prevent="selected = null"><i class="fa fa-2x fa-trash" aria-hidden="true"></i></a>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col">
                                <label class="ps-1 pb-1" for="inputDiscount">Rabatt <span class="text-muted">optional</span></label>
                                <input type="text" id="inputDiscount" class="form-control" aria-describedby="codeHelp"
                                       :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('discount'))}" name="discount"
                                       v-model="newItem.discount">
                                <div v-if="hasErrors && errors.hasOwnProperty('discount')" class="invalid-feedback">
                                    {{ errors.discount[0] }}
                                </div>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col-12">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="newItemVoucher" name="voucher" checked v-model="newItem.voucher">
                                    <label class="custom-control-label" for="newItemVoucher">Als Gutschein verrechnen*</label>
                                </div>
                            </div>
                            <div class="col-12">
                                <p class="text-muted mb-0">*Partnershop-Rabatt liegt als Gutschein im Warenkorb</p>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-12">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="newItemActive" name="active" v-model="newItem.active">
                                    <label class="custom-control-label" for="newItemActive">Aktiv/Online</label>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col-6 local-datepicker">
                                <local-date-time-picker
                                    :id="'active_from'"
                                    :name="'active_from'"
                                    v-model="newItem.active_from"
                                    floating="true"
                                    label="Aktiv ab"
                                    :placeholder="'Aktiv ab'"
                                    :input-class="(hasErrors && errors.hasOwnProperty('active_from')) ? 'form-control is-invalid' : 'form-control'"
                                    @change=""
                                ></local-date-time-picker>
                            </div>
                            <div class="col-6 local-datepicker">
                                <local-date-time-picker
                                    :id="'active_until'"
                                    :name="'active_until'"
                                    v-model="newItem.active_until"
                                    floating="true"
                                    label="Aktiv bis"
                                    :placeholder="'Aktiv bis'"
                                    :input-class="(hasErrors && errors.hasOwnProperty('active_until')) ? 'form-control is-invalid' : 'form-control'"
                                    @change=""
                                ></local-date-time-picker>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-12">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="newItemFeatured" name="featured" v-model="newItem.featured">
                                    <label class="custom-control-label" for="newItemFeatured">Auf Startseite vorstellen</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 local-datepicker">
                                <local-date-time-picker
                                    :id="'featured_from'"
                                    :name="'featured_from'"
                                    v-model="newItem.featured_from"
                                    floating="true"
                                    label="Vorgestellt ab"
                                    :placeholder="'Vorgestellt ab'"
                                    :input-class="(hasErrors && errors.hasOwnProperty('featured_from')) ? 'form-control is-invalid' : 'form-control'"
                                    @change=""
                                ></local-date-time-picker>
                            </div>
                            <div class="col-6 local-datepicker">
                                <local-date-time-picker
                                    :id="'featured_until'"
                                    :name="'featured_until'"
                                    v-model="newItem.featured_until"
                                    floating="true"
                                    label="Vorgestellt bis"
                                    :placeholder="'Vorgestellt bis'"
                                    :input-class="(hasErrors && errors.hasOwnProperty('featured_until')) ? 'form-control is-invalid' : 'form-control'"
                                    @change=""
                                ></local-date-time-picker>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <hr>
                        </div>
                        <div class="row mb-2">
                            <div class="form-group col">
                                <label class="ps-1 pb-1" for="inputNewCampaignDiscount">Aktionsrabatt <span class="text-muted">optional</span></label>
                                <input type="text" id="inputNewCampaignDiscount" class="form-control"
                                       :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('campaign_discount'))}" name="campaign_discount"
                                       v-model="newItem.campaign_discount">
                                <div v-if="hasErrors && errors.hasOwnProperty('campaign_discount')" class="invalid-feedback">
                                    {{ errors.campaign_discount[0] }}
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 local-datepicker">
                                <local-date-time-picker
                                    :id="'campaign_from'"
                                    :name="'campaign_from'"
                                    v-model="newItem.campaign_from"
                                    floating="true"
                                    label="Von"
                                    :placeholder="'Von'"
                                    :input-class="(hasErrors && errors.hasOwnProperty('campaign_from')) ? 'form-control is-invalid' : 'form-control'"
                                    @change=""
                                ></local-date-time-picker>
                            </div>
                            <div class="col-6 local-datepicker">
                                <local-date-time-picker
                                    :id="'campaign_until'"
                                    :name="'campaign_until'"
                                    v-model="newItem.campaign_until"
                                    floating="true"
                                    label="Bis"
                                    :placeholder="'Bis'"
                                    :input-class="(hasErrors && errors.hasOwnProperty('campaign_until')) ? 'form-control is-invalid' : 'form-control'"
                                    @change=""
                                ></local-date-time-picker>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" @click.prevent="store" :disabled="isLoading || selected === null">Speichern & Hinzufügen</button>
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" :disabled="isLoading">Abbrechen</button>
                    </div>
                </div>
            </div>
        </div>


        <div id="editItemModal" class="modal fade" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Eintrag bearbeiten</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <div class="row mb-2">
                            <div class="col-12">
                                <div class="form-group">
                                    <label class="ps-1 pb-1">Produkt</label>
                                    <input type="text" class="form-control" disabled name="itemName" :value="editItem.name">
                                </div>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col">
                                <label class="ps-1 pb-1" for="inputEditDiscount">Rabatt <span class="text-muted">optional</span></label>
                                <input type="text" id="inputEditDiscount" class="form-control" aria-describedby="codeHelp"
                                       :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('discount'))}" name="discount"
                                       v-model="editItem.discount">
                                <div v-if="hasErrors && errors.hasOwnProperty('discount')" class="invalid-feedback">
                                    {{ errors.discount[0] }}
                                </div>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col-12">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="editItemVoucher" name="voucher" :checked="editItem.voucher" v-model="editItem.voucher">
                                    <label class="custom-control-label" for="editItemVoucher">Als Gutschein verrechnen*</label>
                                </div>
                            </div>
                            <div class="col-12">
                                <p class="text-muted mb-0">*Partnershop-Rabatt liegt als Gutschein im Warenkorb</p>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="editItemActive" name="active" :checked="editItem.active" v-model="editItem.active">
                                    <label class="custom-control-label" for="editItemActive">Aktiv/Online</label>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col-6 local-datepicker">
                                <local-date-time-picker
                                    :id="'active_from'"
                                    :name="'active_from'"
                                    v-model="editItem.active_from"
                                    floating="true"
                                    label="Aktiv ab"
                                    :placeholder="'Aktiv ab'"
                                    :input-class="(hasErrors && errors.hasOwnProperty('active_from')) ? 'form-control is-invalid' : 'form-control'"
                                    @change=""
                                ></local-date-time-picker>
                            </div>
                            <div class="col-6 local-datepicker">
                                <local-date-time-picker
                                    :id="'active_until'"
                                    :name="'active_until'"
                                    v-model="editItem.active_until"
                                    floating="true"
                                    label="Aktiv bis"
                                    :placeholder="'Aktiv bis'"
                                    :input-class="(hasErrors && errors.hasOwnProperty('active_until')) ? 'form-control is-invalid' : 'form-control'"
                                    @change=""
                                ></local-date-time-picker>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-12">
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="custom-control-input" id="editItemFeatured" name="featured" v-model="editItem.featured">
                                    <label class="custom-control-label" for="editItemFeatured">Auf Startseite vorstellen</label>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 local-datepicker">
                                <local-date-time-picker
                                    :id="'featured_from'"
                                    :name="'featured_from'"
                                    v-model="editItem.featured_from"
                                    floating="true"
                                    label="Vorgestellt ab"
                                    :placeholder="'Vorgestellt ab'"
                                    :input-class="(hasErrors && errors.hasOwnProperty('featured_from')) ? 'form-control is-invalid' : 'form-control'"
                                    @change=""
                                ></local-date-time-picker>
                            </div>
                            <div class="col-6 local-datepicker">
                                <local-date-time-picker
                                    :id="'featured_until'"
                                    :name="'featured_until'"
                                    v-model="editItem.featured_until"
                                    floating="true"
                                    label="Vorgestellt bis"
                                    :placeholder="'Vorgestellt bis'"
                                    :input-class="(hasErrors && errors.hasOwnProperty('featured_until')) ? 'form-control is-invalid' : 'form-control'"
                                    @change=""
                                ></local-date-time-picker>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <hr>
                        </div>
                        <div class="row mb-2">
                            <div class="form-group col">
                                <label class="ps-1 pb-1" for="inputEditCampaignDiscount">Aktionsrabatt <span class="text-muted">optional</span></label>
                                <input type="text" id="inputEditCampaignDiscount" class="form-control"
                                       :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('campaign_discount'))}" name="campaign_discount"
                                       v-model="editItem.campaign_discount">
                                <div v-if="hasErrors && errors.hasOwnProperty('campaign_discount')" class="invalid-feedback">
                                    {{ errors.campaign_discount[0] }}
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6 local-datepicker">
                                <local-date-time-picker
                                    :id="'campaign_from'"
                                    :name="'campaign_from'"
                                    v-model="editItem.campaign_from"
                                    floating="true"
                                    label="Von"
                                    :placeholder="'Von'"
                                    :input-class="(hasErrors && errors.hasOwnProperty('campaign_from')) ? 'form-control is-invalid' : 'form-control'"
                                    @change=""
                                ></local-date-time-picker>
                            </div>
                            <div class="col-6 local-datepicker">
                                <local-date-time-picker
                                    :id="'campaign_until'"
                                    :name="'campaign_until'"
                                    v-model="editItem.campaign_until"
                                    floating="true"
                                    label="Bis"
                                    :placeholder="'Bis'"
                                    :input-class="(hasErrors && errors.hasOwnProperty('campaign_until')) ? 'form-control is-invalid' : 'form-control'"
                                    @change=""
                                ></local-date-time-picker>
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" @click.prevent="update" :disabled="isLoading">Speichern</button>
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" :disabled="isLoading">Abbrechen</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import draggable from "vuedraggable";

    export default {
        components: {draggable},

        props: [
            'partnerShop'
        ],

        data() {
            return {
                canSave: false,
                hasErrors: false,
                errors: [],
                isLoading: false,
                now: Date.now(),

                entries: {},
                search: '',
                entriesVisible: false,
                selected: null,

                items: {},

                newItem: {
                    item_id: null,
                    discount: null,
                    voucher: true,
                    featured: false,
                    active: true,
                    active_from: null,
                    active_until: null,
                    campaign_discount: null,
                    campaign_from: null,
                    campaign_until: null,
                    featured_from: null,
                    featured_until: null
                },

                editItem: {
                    item: null,
                    name: null,
                    discount: 0,
                    voucher: true,
                    featured: false,
                    active: false,
                    active_from: null,
                    active_until: null,
                    campaign_discount: 0,
                    campaign_from: null,
                    campaign_until: null,
                    featured_from: null,
                    featured_until: null
                },
                addItemModal: null,
                editItemModal: null,
            }
        },

        mounted() {
            this.fetch();

            this.addItemModal = new bootstrap.Modal(document.getElementById('addItemModal'))
            this.editItemModal = new bootstrap.Modal(document.getElementById('editItemModal'))
        },

        methods: {
            store() {
                this.isLoading = true
                this.newItem.item_id = this.selected.id
                axios.post('/api/backend/partners/shops/' + this.partnerShop.id + '/items', {
                    partner_shop_id: this.partnerShop.id,
                    item_id: this.newItem.item_id,
                    discount: this.newItem.discount,
                    voucher: this.newItem.voucher,
                    featured: this.newItem.featured,
                    active: this.newItem.active,
                    active_from: this.newItem.active_from !== null ? this.newItem.active_from.toLocaleString() : null,
                    active_until: this.newItem.active_until !== null ? this.newItem.active_until.toLocaleString() : null,
                    campaign_discount: this.newItem.campaign_discount,
                    campaign_from: this.newItem.campaign_from !== null ? this.newItem.campaign_from.toLocaleString() : null,
                    campaign_until: this.newItem.campaign_until !== null ? this.newItem.campaign_until.toLocaleString() : null,
                    featured_from: this.newItem.featured_from !== null ? this.newItem.featured_from.toLocaleString() : null,
                    featured_until: this.newItem.featured_until !== null ? this.newItem.featured_until.toLocaleString() : null
                })
                    .then(response => {
                        if (response.status === 200) {
                            this.addItemModal.hide()
                            this.selected = null
                        }
                    })
                    .catch(error => {
                        if (error.response.data.hasOwnProperty('errors')) {
                            this.hasErrors = true
                            this.errors = error.response.data.errors
                        }
                    })
                    .finally(() => {
                        this.fetch()
                        this.isLoading = false
                    })
            },
            update() {
                this.isLoading = true
                axios.put('/api/backend/partners/shops/item/' + this.editItem.item.id, {
                    partner_shop_id: this.partnerShop.id,
                    discount: this.editItem.discount,
                    voucher: this.editItem.voucher,
                    featured: this.editItem.featured,
                    active: this.editItem.active,
                    active_from: this.editItem.active_from !== null ? this.editItem.active_from.toLocaleString() : null,
                    active_until: this.editItem.active_until !== null ? this.editItem.active_until.toLocaleString() : null,
                    campaign_discount: this.editItem.campaign_discount,
                    campaign_from: this.editItem.campaign_from !== null ? this.editItem.campaign_from.toLocaleString() : null,
                    campaign_until: this.editItem.campaign_until !== null ? this.editItem.campaign_until.toLocaleString() : null,
                    featured_from: this.editItem.featured_from !== null ? this.editItem.featured_from.toLocaleString() : null,
                    featured_until: this.editItem.featured_until !== null ? this.editItem.featured_until.toLocaleString() : null
                })
                    .then(response => {
                        if (response.status === 200) {
                            this.editItemModal.hide()
                        }
                    })
                    .catch(error => {
                        if (error.response.data.hasOwnProperty('errors')) {
                            this.hasErrors = true
                            this.errors = error.response.data.errors
                        }
                    })
                    .finally(() => {
                        this.fetch();
                        this.isLoading = false
                    })
            },
            fetch() {
                axios.get('/api/backend/partners/shops/' + this.partnerShop.id + '/items')
                    .then(response => {
                        this.items = response.data
                    })
                    .catch(error => {})
                    .finally(() => (this.isLoading = false))
            },
            edit(index) {
                this.editItem.item = this.items[index]
                this.editItem.name = this.items[index].item.brand.name + ' ' + this.items[index].item.name
                this.editItem.discount = this.items[index].discount
                this.editItem.voucher = this.items[index].voucher
                this.editItem.featured = this.items[index].featured
                this.editItem.active = this.items[index].active
                this.editItem.active_from = this.items[index].active_from
                this.editItem.active_until = this.items[index].active_until
                this.editItem.campaign_discount = this.items[index].campaign_discount
                this.editItem.campaign_from = this.items[index].campaign_from
                this.editItem.campaign_until = this.items[index].campaign_until
                this.editItem.featured_from = this.items[index].featured_from
                this.editItem.featured_until = this.items[index].featured_until
                this.editItemModal.show()
            },
            destroy(itemId) {
                this.isLoading = true
                axios.delete('/api/backend/partners/shops/item/' + itemId)
                    .then(response => {})
                    .catch(error => {
                        if (error.response.data.hasOwnProperty('errors')) {
                            this.hasErrors = true
                            this.errors = error.response.data.errors
                        }
                    })
                    .finally(() => {
                        this.fetch();
                        this.isLoading = false
                    })
            },
            onContentChange(e) {
                this.canSave = true;
            },
            select(entry) {
                this.selected = entry
            },
            reSort(e) {
                this.isLoading = true
                axios.put('/api/backend/partners/shops/' + this.partnerShop.id + '/items/resort', {
                    items: this.items
                })
                .then(response => {
                    if (response.status === 200) {}
                })
                .catch(error => {
                    if (error.response.data.hasOwnProperty('errors')) {
                        this.hasErrors = true
                        this.errors = error.response.data.errors
                    }
                })
                .finally(() => {
                    this.isLoading = false
                })
            }
        },

        watch: {
            search (val) {
                if (this.search.length < 3) return
                this.isLoading = true

                axios.get('/api/items/search', {params: {term: this.search, filterCategory: JSON.stringify(this.filterCategory)}})
                    .then(response => {
                        this.entries = response.data;
                    })
                    .catch(err => {})
                    .finally(() => (this.isLoading = false))
            },
        },
    }
</script>
