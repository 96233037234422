<template>
    <div id="loading-modal">
        <div id="loading-modal_backdrop"></div>
        <div id="loading-modal_dialog">
            <div id="loading-modal_content" class="container-fluid h-100">
                <div id="loading-modal_header" class="row justify-content-center">

                </div>
                <div id="loading-modal_body" class="row h-100 align-items-center justify-content-center">
                    <div class="col-8">
                        <div class="row">
                            <div class="col-12 text-center">
                                <i class="fas fa-cog fa-spin fa-10x"></i>
                            </div>
                        </div>
                        <div class="row" v-if="loaderText !== null">
                            <div class="col-12 text-center">
                                <h3>{{ loaderText }}</h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="loading-modal_footer" class="row justify-content-center">

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {

        props: [
            'text'
        ],

        data () {
            return {

            }
        },
        mounted () {
            document.body.classList.add('loading-modal_overflow');
        },
        unmounted () {
            document.body.classList.remove('loading-modal_overflow');
        },
        methods: {

        },
        computed: {
            loaderText () {
                if (this.text !== null || this.text !== '')
                    return this.text;
                else
                    return null
            }
        },
        watch: {

        }
    }
</script>

<style module>
    .loading-modal_overflow {
        overflow: hidden !important;
    }
    #loading-modal_backdrop {
        opacity: 0.75;
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: #000;
        z-index: 1199;
        transition: opacity .15s linear;
    }
    #loading-modal_dialog {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 1200;
    }
    #loading-modal_content {
        color: #fff;
    }
</style>
