<template>
    <div class="container-fluid p-3">
        <div class="row mb-2">
            <div class="col-12">
                <h1>Bestellungen exportieren</h1>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h4>Export Zeitspanne</h4>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="inputDateFrom">Von</label>
                                    <local-date-time-picker
                                        :append-to-body="false"
                                        popup-class="mx-fullwidth"
                                        floating="true"
                                        :id="'inputDateFrom'"
                                        :placeholder="'Datum & Zeit auswählen'"
                                        label="Datum & Zeit auswählen"
                                        v-model="date.from"
                                        :input-class="'form-control'"
                                        @change="onContentChange"
                                    ></local-date-time-picker>
                                    <small id="inputDateFromHelp" class="form-text text-muted"></small>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="inputDateUntil">Bis</label>
                                    <local-date-time-picker
                                        :append-to-body="false"
                                        popup-class="mx-fullwidth"
                                        floating="true"
                                        :id="'inputDateUntil'"
                                        :placeholder="'Datum & Zeit auswählen'"
                                        label="Datum & Zeit auswählen"
                                        v-model="date.until"
                                        :input-class="'form-control'"
                                        @change="onContentChange"
                                    ></local-date-time-picker>
                                    <small id="inputDateUntilHelp" class="form-text text-muted"></small>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 text-end">
                                <button :disabled="isLoading" class="btn btn-primary" @click.prevent="getExport"><span v-if="isLoading"><i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i></span><span v-else>Exportieren</span></button>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h4>Export CSV Upload</h4>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="input-group mb-3">

                                    <div class="custom-file">
                                        <input type="file" name="export-csv" class="custom-file-input" id="export-csv" @change="getCSV">
                                        <label class="custom-file-label" for="export-csv">Datei auswählen</label>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="row">
                            <div class="col-12 text-end">
                                <button :disabled="isLoading || csv === null" class="btn btn-primary" @click.prevent="getExportFromCSV"><span v-if="isLoading"><i class="fa fa-spinner fa-pulse fa-3x fa-fw"></i></span><span v-else>Exportieren</span></button>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import LocalDateTimePicker from "../../LocalDateTimePicker.vue";

    export default {
        components: {LocalDateTimePicker},
        data() {
            return {
                canSave: false,
                hasErrors: false,
                isLoading: false,
                hasOld: false,

                date: {
                    from: null,
                    until: null
                },

                csv: null
            }
        },

        mounted() {

        },

        methods: {
            onContentChange(e) {
                this.canSave = true;
            },
            getExport() {
                this.isLoading = true
                axios.post('/backend/orders/exports/export', {
                    from: this.date.from,
                    until: this.date.until
                })
                    .then(response => {
                        this.exportCSVFile(this.headers, response.data, 'orders');
                        this.canSave = false;
                    })
                    .catch(error => {})
                    .finally(() => {
                        this.isLoading = false
                    })
            },
            getCSV(e) {
                this.isLoading = true
                let self = this;
                this.$papa.parse(e.target.files[0], {
                    complete: function (results) {
                        self.parseComplete(results)
                    }
                })
            },
            parseComplete(result) {
                this.isLoading = false
                this.csv = result
            },
            getExportFromCSV() {
                if (this.csv !== null) {
                    this.isLoading = true
                    axios.post('/backend/orders/exports/export/csv', {
                        csv: this.csv
                    })
                        .then(response => {
                            this.exportCSVFile(this.headers, response.data, 'orders', true);
                            this.canSave = false;
                        })
                        .catch(error => {})
                        .finally(() => {
                            this.isLoading = false
                        })
                }
            },
            convertToCSV(objArray) {
                let str = '';

                for (let i = 0; i < objArray.length; i++) {
                    let line = '';
                    for (let index in objArray[i]) {
                        if (line !== '') line += ','
                        line += (objArray[i][index] !== null) ? objArray[i][index].replace(/<[^>]*>?/gm, '').replace(/,/g, '.') : '';
                    }
                    str += line + '\r\n';
                }
                return str;
            },
            exportCSVFile(headers, items, fileTitle, fromCSV = false) {

                let csv = this.convertToCSV(items);
                let exportedFilename = '';
                if (!fromCSV) {
                    exportedFilename = 'orders_' + this.moment(this.date.from).format('DD.MM.YYYY') + ' - ' + this.moment(this.date.until).format('DD.MM.YYYY') + '.csv' || 'export.csv';
                } else {
                    exportedFilename = 'orders_' + this.moment().format('DD.MM.YYYY') + '.csv' || 'export.csv';
                }
                let BOM = "\uFEFF";
                let blob = new Blob([BOM + csv], { type: 'text/csv;charset=utf-8;' });
                if (navigator.msSaveBlob) { // IE 10+
                    navigator.msSaveBlob(blob, exportedFilename);
                } else {
                    let link = document.createElement("a");
                    if (link.download !== undefined) {
                        let url = URL.createObjectURL(blob);
                        link.setAttribute("href", url);
                        link.setAttribute("download", exportedFilename);
                        link.style.visibility = 'hidden';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                }
            }
        },

        watch: {

        },
    }
</script>
