<template>

    <date-picker
        :input-class="inputClass"
        :popup-class="popupClass"
        format="DD.MM.YYYY HH:mm"
        v-model:value="input"
        type="datetime"
        :placeholder="placeholder"
        :input-attr="{name: name, id: id}"
    >

        <template v-if="floating" #input="input">
            <div class="form-floating">
                <input type="text"
                       :id="input.id"
                       :name="input.name"
                       v-model="input.value"
                       class="form-control"
                       :placeholder="input.placeholder"
                       autocomplete="off"
                >
                <label :for="input.id">{{ label }}</label>
            </div>
        </template>
    </date-picker>
</template>

<script>
import DatePicker from 'vue-datepicker-next';
import {parseDateTime} from "@/composables/parseDateTime.js";

export default {
    components: {
        DatePicker
    },
    props: {
        placeholder: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        name: {
            type: String,
            default: ''
        },
        id: {
            type: String,
            default: ''
        },
        floating: {
            type: String,
            default: ''
        },
        inputClass: {
            type: String,
            default: ''
        },
        popupClass: {
            type: String,
            default: ''
        },
        wrapperClass: {
            type: String,
            default: ''
        },
        old: {
            type: String,
            default: null
        },
        oldFormat: {
            type: String,
            default: 'dd.mm.yyyy HH:MM'
        },
        val: {
            type: String,
            default: null
        },
        valFormat: {
            type: String,
            default: null
        },
        modelValue: {
            type: String,
            default: null
        },
        value: {}
    },

    data () {
        return {
            input: 'test',
            showTimePanel: false,
            shortcutsDates: [
                {
                    text: 'Heute',
                    onClick() { return new Date(); },
                },
                {
                    text: 'Gestern',
                    onClick() {
                        const date = new Date();
                        date.setTime(date.getTime() - 3600 * 1000 * 24);
                        return date;
                    },
                },
            ],
        }
    },
    mounted () {
        if (this.old !== null && this.old.length) {
            this.input = parseDateTime(this.old, this.oldFormat)
        } else {
            if (this.modelValue !== null && this.modelValue !== '') {
                this.input = parseDateTime(this.modelValue, this.valFormat)
            }
            if (this.val !== null && this.val !== '') {
                this.input = parseDateTime(this.val, this.valFormat)
            }
        }
    },
    unmounted () {

    },
    methods: {
        change() {
            this.$emit('change')
            this.$emit('update:modelValue', this.input)
        },
        toggleTimePanel() {
            this.showTimePanel = !this.showTimePanel;
        },
    },
    computed: {
        inputVal: {
            get () {
                return this.value
            },
            set (value) {
                this.$emit('update:modelValue', this.input)
            }
        }
    },
    watch: {
        modelValue(val) {
            if (typeof val === 'string') {
                val = parseDateTime(val)
            }
            this.input = val
        },
        input(val) {
            this.$emit('update:modelValue', this.input)
        }
    }
}
</script>
