<template>
    <div class="card">
        <div class="card-header">Angebotsartikel</div>
        <div class="card-body">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Anzahl</th>
                        <th scope="col">Preis</th>
                        <th scope="col">Rabatt</th>
                        <th scope="col">Preis reduziert</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                <template v-for="position in kitCreationRequestPositions">
                    <tr class="fw-bold fs-5">
                        <td class="align-middle">{{ position.item.brand.name }} {{ position.item.name }}</td>
                        <td class="align-middle">{{ position.quantity }}</td>
                        <td class="align-middle">{{ position.item.prices['priceBest'].toFixed(2) }} €</td>
                        <td class="align-middle">{{ position.discount.toFixed(2) }} €</td>
                        <td class="align-middle">{{ (position.item.prices['priceBest'] - position.discount).toFixed(2) }} €</td>
                        <td class="align-middle">
                            <button class="btn btn-outline-primary w-100" :disabled="kitCreationRequest.approved_at || kitCreationRequest.declined_at" @click.prevent="edit(position)">
                                <i class="fas fa-edit"></i>
                            </button>
                        </td>
                    </tr>
                    <template v-if="position.item.promotions.length || Object.keys(position.item.promotions).length">
                    <tr>
                        <td class="text-muted" colspan="7" style="box-shadow: unset;background-color: rgb(32 44 49)">
                            <span class="fw-bold">Laufende Aktionen zum Artikel*</span>
                        </td>
                    </tr>
                    <tr v-for="(promo, index) in position.item.promotions">
                        <td class="text-muted" colspan="7" style="box-shadow: unset;background-color: rgb(32 44 49)">
                            <span class="fw-bold">[AKTION] </span> {{ promo.brand_promotion ? promo.brand_promotion.name : '' }} _ {{ promo.discount ? promo.discount.toFixed(2) : 'NaN' }} €
                        </td>
                    </tr>
                    </template>
                </template>
                </tbody>
            </table>
            <p class="mb-0 text-muted">*werden im Warenkorb abgezogen, wenn Angebot im Zeitraum beansprucht wird!</p>
        </div>
        <kit-creation-price-change-modal :editPosition="editPosition" @update="update"></kit-creation-price-change-modal>
        <loading-modal :text="'Lade..'" :active="isLoading" v-if="isLoading"></loading-modal>
    </div>
</template>
<script>
export default {
    props: [
        'kitCreationRequest'
    ],

    data() {
        return {
            canSave: false,
            hasErrors: false,
            errors: {},
            isLoading: false,

            kitCreationRequestPositions: [],

            message: '',

            requested: false,
            offerNo: null,

            editPosition: {
                name: '',
                quantity: 0,
                discount: 0
            },
            priceChangeModal: null,
        }
    },

    mounted() {
        this.kitCreationRequestPositions = this.kitCreationRequest.positions
        this.priceChangeModal  = new bootstrap.Modal(document.getElementById('price-change-modal'))
    },

    methods: {
        fetch() {
            axios.get('/api/backend/kit-creations/requests/' + this.kitCreationRequest.id + '/positions')
            .then(response => {
                if (response.status === 200) {
                    this.kitCreationRequestPositions = response.data
                }
            })
        },
        refresh() {
            this.fetch()
        },
        edit(position) {
            this.editPosition = position
            this.priceChangeModal.show()
        },
        update(positionID, discount) {
            this.priceChangeModal.hide()
            axios.put('/api/backend/kit-creations/requests/positions/' + positionID, {
                discount: discount
            })
                .then(response => {
                    if (response.status === 200) {
                        this.fetch()
                    }
                })
        },
    },
}
</script>
