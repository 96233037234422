<template>
    <div class="container p-5">
        <div class="card">
            <div class="card-header">
                <h4 class="mb-0">Bestellstatus überprüfen</h4>
            </div>
            <div class="card-body">
                <div class="row justify-content-center">
                    <div class="col-sm-12 col-md-8">
                        <p>Hier kannst du schnell und einfach den Status deiner Bestellung abfragen.<br>
                        Trage dazu einfach deine Auftragsnummer sowie die Postleitzahl der Rechnungsanschrift ein.</p>
                    </div>
                </div>
                <div class="row justify-content-center mb-4">
                    <div class="col-sm-12 col-md-8">
                        <div class="form-floating mb-0">
                            <input type="text" id="inputOrderNo" class="form-control"
                                   :class="{'is-invalid': error}" placeholder="Deine Auftragsnummer" name="orderNo"
                                   v-model="orderNo" autocomplete="new-order-no">
                            <label for="inputOrderNo" class="required">Auftragsnummer</label>
                            <div v-if="error" class="invalid-feedback">
                                Zu den angegebenen Daten konnte keine Bestellung gefunden werden.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-center">
                    <div class="col-sm-12 col-md-5">
                        <div class="form-floating mb-0">
                            <input type="text" id="inputOrderPostCode" class="form-control"
                                   placeholder="Deine Postleitzahl" name="orderPostCode"
                                   v-model="orderPostCode" autocomplete="new-post-order-code">
                            <label for="inputOrderPostCode" class="required">Postleitzahl</label>
                        </div>
                    </div>
                    <div class="col-sm-12 col-md-3">
                        <button class="btn btn-primary w-100 h-100" :disabled="btnCheck.disabled || isLoading" @click="handle">
                            <span v-if="isLoading"><i class="fa fa-spinner fa-pulse fa-2x fa-fw"></i></span>
                            <span v-else>{{ btnCheck.label }}</span>
                        </button>
                    </div>
                </div>
                <template v-if="notEmptyObject(status)">
                    <div class="row mt-4 justify-content-center">
                        <div class="col-8"><h5><span class="font-weight-bold">Status der Bestellung</span> ({{ status.orderNo }}):</h5></div>
                    </div>
                    <div class="row mt-4 justify-content-center fa-2x">
                        <div class="col-1 text-center my-auto p-3 shadow-3"
                             :class="{'text-secondary': status.received === null, 'active': status.received !== null}"
                             data-bs-toggle="popover" data-bs-placement="top" data-bs-trigger="hover" data-bs-content="Wir haben deine Bestellung erhalten">
                            <i class="fas fa-file-invoice fa-2x"></i>
                        </div>
                        <div class="col-1 text-center my-auto p-3" :class="{'text-secondary': status.received === null}">
                            <i class="fas fa-arrow-circle-right"></i>
                        </div>
                        <div class="col-1 text-center my-auto p-3 shadow-3"
                             :class="{'text-secondary': status.process === null, 'active': status.process !== null}"
                             data-bs-toggle="popover" data-bs-placement="top" data-bs-trigger="hover" data-bs-content="Deine Bestellung wird bearbeitet">
                            <i class="fas fa-box-open fa-2x"></i>
                        </div>
                        <div class="col-1 text-center my-auto p-3" :class="{'text-secondary': status.process === null}">
                            <i class="fas fa-arrow-circle-right"></i>
                        </div>
                        <div class="col-1 text-center my-auto p-3 shadow-3"
                             :class="{'text-secondary': status.forwarded === null, 'active': status.forwarded !== null}"
                             data-bs-toggle="popover" data-bs-placement="top" data-bs-trigger="hover" data-bs-content="Das Paket wurde dem Zusteller übergeben">
                            <i class="fas fa-truck-loading fa-2x"></i>
                        </div>
                    </div>
                    <div class="row mt-4 justify-content-center">
                        <div class="col-sm-12 col-md-8" v-if="status.canceled !== null">
                            <div class="row">
                                <div class="col-3">{{ status.canceled }}</div>
                                <div class="col-9">Deine Bestellung wurde storniert.</div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-8" v-if="status.forwarded !== null">
                            <div class="row">
                                <div class="col-3">{{ status.forwarded }}</div>
                                <div class="col-9">Das Paket wurde dem Zustelldienst übergeben.</div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-8" v-if="status.process !== null">
                            <div class="row">
                                <div class="col-3">{{ status.process }}</div>
                                <div class="col-9">Wir bearbeiten im Moment deine Bestellung.</div>
                            </div>
                        </div>
                        <div class="col-sm-12 col-md-8" v-if="status.received !== null">
                            <div class="row">
                                <div class="col-3">{{ status.received }}</div>
                                <div class="col-9">Deine Bestellung ist bei uns eingegangen.</div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: [
            'request'
        ],
        data() {
            return {
                error: false,
                isLoading: false,

                orderNo: '',
                orderPostCode: '',

                btnCheck: {
                    label: 'Status prüfen',
                    disabled: true,
                },

                status: {}
            }
        },
        mounted() {
            if (typeof this.request === 'object' ) {
                if (this.request.hasOwnProperty('orderno') && this.request.hasOwnProperty('postcode')) {
                    this.orderNo = this.request.orderno
                    this.orderPostCode = this.request.postcode
                    this.handle()
                }
            }
        },
        methods: {
            handle() {
                this.isLoading = true
                axios.post('/api/order/status', {orderNo: this.orderNo, orderPostCode: this.orderPostCode})
                    .then(response => {
                        if (this.notEmptyObject(response.data)) {
                            this.status = response.data
                            this.error = false
                        } else {
                            this.error = true
                        }
                    })
                    .catch(err => {})
                    .finally(() => {
                        let popoverTriggerList = [].slice.call(document.querySelectorAll('.active[data-bs-toggle="popover"]'))
                        popoverTriggerList.map(function (popoverTriggerEl) {
                            return new bootstrap.Popover(popoverTriggerEl)
                        })
                        this.isLoading = false
                    })
            },
        },
        watch: {
            'orderNo'() {
                this.btnCheck.disabled = (this.orderNo === '' || this.orderPostCode === '')
            },
            'orderPostCode'() {
                this.btnCheck.disabled = (this.orderNo === '' || this.orderPostCode === '')
            }
        }
    }
</script>
