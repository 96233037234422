<template>
    <div class="card">
        <div class="card-header">
            <div class="row pb-2">
                <div class="col">
                    <button @click.prevent="changeFilter(1)" class="btn btn-outline-info btn-sm" :class="{'active': filterCategory.includes(1)}">Kameras</button>
                    <button @click.prevent="changeFilter(2)" class="btn btn-outline-info btn-sm" :class="{'active': filterCategory.includes(2)}">Objektive</button>
                </div>
            </div>
            <input class="form-control" type="text" :name="this.name+'-input'" v-model="search" @focus="entriesVisible = true" @blur="entriesVisible = false" @click="entriesVisible = true" autocomplete="off">
            <span class="loading form-control-feedback" v-show="isLoading"></span>
            <div class="autocomplete-results" v-if="entries.length && entriesVisible">
                <div class="list-group">
                    <div class="list-group-item" v-for="entry in entries" @mousedown.prevent @click="select(entry)">
                        <div class="row">
                            <div class="col-2">
                                <template v-if="entry.images.length">
                                    <img :src="'/storage/img/uploads/products/' + entry.id + '/' + entry.images[0].webp_medium" alt="" class="lazy img-fluid">
                                </template>
                                <template v-else>
                                    <img data-src="/storage/img/general/brokenimage.png" alt="" class="lazy img-fluid">
                                </template>
                            </div>
                            <div class="col-10 justify-content-center align-self-center">
                                {{ entry.name }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body pt-2 pb-2">
            <div class="list-group list-group-flush">
                <div class="list-group-item" v-for="(item, key) in selected">
                    <div class="row">
                        <div class="col-2">
                            <template v-if="item.images.length">
                                <img :src="'/storage/img/uploads/products/' + item.id + '/' + item.images[0].webp_medium" alt="" class="lazy img-fluid">
                            </template>
                            <template v-else>
                                <img data-src="/storage/img/general/brokenimage.png" alt="" class="lazy img-fluid">
                            </template>
                        </div>
                        <div class="col-8 pb-2 pt-2 justify-content-center align-self-center"><input type="hidden" :name="name+'[]'" :value="item.id">{{ item.name }}</div>
                        <div class="col-2 pb-2 pt-2 text-end justify-content-center align-self-center"><button class="btn btn-outline-danger btn-sm" @click.prevent="remove(key)">X</button></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: [
            'name',
            'initSelected'
        ],

        data() {
            return {
                entries: {},
                selected: [],
                isLoading: false,
                search: '',
                entriesVisible: false,

                filterCategory: [1]
            }
        },

        methods: {
            select(entry) {
                if(!this.selected.includes(entry))
                    this.selected.push(entry)
                this.entriesVisible = false;
            },
            remove(key) {
                this.selected.splice(key, 1)
            },
            changeFilter(num) {
                if (!this.filterCategory.includes(num)) {
                    this.filterCategory.push(num)
                } else {
                    this.filterCategory = this.filterCategory.filter(e => e !== num)
                }
            }
        },

        watch: {
            initSelected () {
                this.selected = this.initSelected
            },

            search (val) {
                if (this.search.length < 3) return
                this.isLoading = true

                axios.get('/api/items/search', {params: {term: this.search, filterCategory: JSON.stringify(this.filterCategory)}})
                    .then(response => {
                        this.entries = response.data;
                    })
                    .catch(err => {})
                    .finally(() => (this.isLoading = false))
            },
        },
    }
</script>
