<template>
    <div class="survey-container">
        <div class="container mb-3">
           <div class="row">
               <div class="col">
                    <p class="mb-0">{{ survey.title }}</p>
               </div>
           </div>
        </div>

        <div class="container">
            <div class="row mb-3" v-for="(questionItem, questionIndex) in questionAnswers">
                <div class="col">
                    <div class="card">
                        <div class="card-header">
                            {{ questionItem.question }}
                        </div>
                        <div class="card-body">
                            <template v-if="!['text', 'textarea', 'multiinput'].includes(questionItem.type)">
                                <div class="row mb-3" v-for="(optionItem, optionIndex) in questionItem.options">
                                    <div class="col-12">{{ optionIndex + 1 }}. {{ optionItem.text }}</div>
                                    <div class="col-12" v-if="optionItem.inputs.length"><a href="#" @click.prevent="triggerAnswerModal(optionItem)">Antworten anzeigen</a></div>
                                    <div class="col-12">
                                        <div class="progress mt-2 mx-3" style="height: 1.5rem">
                                            <div class="progress-bar bg-success fw-bold fs-5 text-black" role="progressbar"
                                                 :data-total="questionItem.count"
                                                 :data-count="optionItem.count"
                                                 :style="getWidth(questionItem.count, optionItem.count)" :aria-valuenow="optionItem.count" aria-valuemin="0" :aria-valuemax="questionItem.count">{{ optionItem.count }}</div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-else-if="questionItem.type === 'multiinput'">
                                <div class="row mb-3" v-for="(optionItem, optionIndex) in questionItem.options">
                                    <div class="col-12">{{ optionIndex + 1 }}. {{ optionItem.text }}</div>
                                    <div class="col-12" v-if="Object.keys(optionItem.answers).length"><a href="#" @click.prevent="triggerMultiAnswerModal(optionItem)">Antworten anzeigen</a></div>
                                    <div class="col-12">
                                        <div class="progress mt-2 mx-3" style="height: 1.5rem">
                                            <div class="progress-bar bg-success fw-bold fs-5 text-black" role="progressbar"
                                                 :data-total="questionItem.count"
                                                 :data-count="optionItem.count"
                                                 :style="getWidth(questionItem.count, optionItem.count)" :aria-valuenow="optionItem.count" aria-valuemin="0" :aria-valuemax="questionItem.count">{{ optionItem.count }}</div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-else>
                                <div class="row" v-for="(input, index) in questionItem.inputs">
                                    <div class="col-1">
                                        <p class="mb-0" >x{{ input.count }}</p>
                                    </div>
                                    <div class="col">
                                        <p class="mb-0">{{ input.text }}</p>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <survey-answer-input-modal
            v-if="showAnswerModal"
            :title="'Antworten'"
            v-model="answerModalQuestion"
            @close="showAnswerModal = false"/>
        <survey-multi-answer-input-modal
            v-if="showMultiAnswerModal"
            :title="'Antworten'"
            v-model="multiAnswerModalQuestion"
            @close="showMultiAnswerModal = false"/>
    </div>
</template>

<script>

    export default {
        props: {
            survey: {},
            questionAnswers: {},

        },
        data () {
            return {
                showAnswerModal: false,
                answerModalQuestion: {},
                showMultiAnswerModal: false,
                multiAnswerModalQuestion: {},
            }
        },
        methods: {
            getWidth(total, count) {
                if (total === 0) {
                    return 'width: 0%'
                }
                return 'width: ' + (count / total * 100) + '%'
            },
            triggerAnswerModal(optionItem) {
                this.answerModalQuestion = optionItem
                this.showAnswerModal = true
            },
            triggerMultiAnswerModal(optionItem) {
                this.multiAnswerModalQuestion = optionItem
                this.showMultiAnswerModal = true
            }
        },
    }
</script>
