<template>
    <div class="card">
        <div class="card-header">
            <div class="row">
                <div class="col-8"><h3>{{ partnerShop.name }} Partner Shop Mitglieder verwalten</h3></div>
                <div class="col-4 text-end"><a :href="'/backend/partners/' + partnerShop.partner.id + '/shops'" class="btn btn-primary">Zurück</a></div>
            </div>
        </div>
        <div class="card-body p-0">
            <template v-if="members.length">
                <table class="table table-striped mb-0 noselect" id="partnershop-items">
                    <thead>
                        <tr>
                            <th @click="sort('index', 'int')" class="text-center" :class="{'sort-active': currentSort === 'index'}">
                                #
                                <i v-if="currentSort === 'index' && currentSortDir === 'asc'"  class="fas fa-sort-numeric-up"></i>
                                <i v-else-if="currentSort === 'index' && currentSortDir === 'desc'" class="fas fa-sort-numeric-down"></i>
                            </th>
                            <th @click="sort('member_no')" :class="{'sort-active': currentSort === 'member_no'}">
                                Mitgliednummer
                                <i v-if="currentSort === 'member_no' && currentSortDir === 'asc'" class="fas fa-sort-amount-up"></i>
                                <i v-else-if="currentSort === 'member_no' && currentSortDir === 'desc'" class="fas fa-sort-amount-down"></i>
                            </th>
                            <th @click="sort('name')" :class="{'sort-active': currentSort === 'prename'}">
                                Name
                                <i v-if="currentSort === 'name' && currentSortDir === 'asc'" class="fas fa-sort-alpha-up"></i>
                                <i v-else-if="currentSort === 'name' && currentSortDir === 'desc'" class="fas fa-sort-alpha-down"></i>
                            </th>
                            <th @click="sort('prename')" :class="{'sort-active': currentSort === 'prename'}">
                                Vorname
                                <i v-if="currentSort === 'prename' && currentSortDir === 'asc'" class="fas fa-sort-alpha-up"></i>
                                <i v-else-if="currentSort === 'prename' && currentSortDir === 'desc'" class="fas fa-sort-alpha-down"></i>
                            </th>
                            <th @click="sort('orders')" class="text-center" :class="{'sort-active': currentSort === 'orders'}">
                                Bestellungen
                                <i v-if="currentSort === 'orders' && currentSortDir === 'asc'"  class="fas fa-sort-numeric-up"></i>
                                <i v-else-if="currentSort === 'orders' && currentSortDir === 'desc'" class="fas fa-sort-numeric-down"></i>
                            </th>
                            <th class="text-center">Rechnungskauf</th>
                            <th @click="sort('last_login', 'date')" :class="{'sort-active': currentSort === 'last_login'}">
                                Letze Anmeldung
                                <i v-if="currentSort === 'last_login' && currentSortDir === 'asc'" class="fas fa-sort-amount-up"></i>
                                <i v-else-if="currentSort === 'last_login' && currentSortDir === 'desc'" class="fas fa-sort-amount-down"></i>
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(member, index) in sortedMembers">
                            <td>{{ member.index }}</td>
                            <td>{{ member.member_no }}</td>
                            <td>{{ member.name }}</td>
                            <td>{{ member.prename }}</td>
                            <td class="text-center">{{ member.orders.length }}</td>
                            <td class="text-center">
                                <span v-if="member.payments && member.payments.includes(8)" class="dot dot-success"></span>
                                <span v-else class="dot dot-danger"></span>
                            </td>
                            <td class="">{{ moment(member.last_login).format('DD.MM.YY HH:mm') }}</td>
                            <td style="width: 1px;white-space: nowrap;">
                                <a href="#" @click.prevent="edit(index)"><i class="fas fa-edit"></i></a>
                                <a href="#" @click.prevent="info(index)"><i class="fas fa-info-circle"></i></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </template>
            <template v-else>
                <div class="p-4 text-center">
                    Es hat sich noch kein Mitglied in diesem Shop angemeldet.
                </div>
            </template>
        </div>
        <div id="editMemberModal" class="modal fade" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Mitglied bearbeiten</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label>Mitgliednummer</label>
                                    <input type="text" class="form-control" disabled name="memberName" :value="editMember.member_no">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <label>Name</label>
                                    <input type="text" class="form-control" disabled name="memberName" :value="editMember.name">
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label>Vorname</label>
                                    <input type="text" class="form-control" disabled name="memberPrename" :value="editMember.prename">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label>Zahlungsarten</label>
                                    <div class="row">
                                        <div v-for="(payment, index) in payments" class="col-6">
                                            <div class="custom-control custom-checkbox custom-control-inline">
                                                <input type="checkbox" :id="'payment_option-' + payment.id" class="custom-control-input" :value="payment.id" v-model="editMember.payments">
                                                <label class="custom-control-label" :for="'payment_option-' + payment.id">{{ payment.name }}</label>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <a href="#" @click.prevent="selectAllPayments">Alle anwählen</a>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <p class="text-muted">
                                                Wenn nichts ausgewählt, stehen dem Mitglied <b>alle</b> regulären Methoden zur Verfügung.<br>
                                                Soll z.B Rechnungskauf erlaubt werden, sollten/müssen alle anderen regulären Methoden mit angekreuzt werden.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" @click.prevent="update" :disabled="isLoading">Speichern</button>
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" :disabled="isLoading">Abbrechen</button>
                    </div>
                </div>
            </div>
        </div>
        <div id="infoMemberModal" class="modal fade" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Mitglied Infos</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>                    </div>
                    <div class="modal-body">
                        <div class="card mb-3">
                            <div class="card-header">
                                <a data-bs-toggle="collapse" href="#collapseInfo" class="d-block">
                                    <i class="fa fa-chevron-down float-right"></i>Infos
                                </a>
                            </div>
                            <div id="collapseInfo" class="collapse show">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label>Mitgliedsnummer</label>
                                            <input type="text" class="form-control" disabled :value="infoMember.member_no">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label>Email-Adresse</label>
                                            <input type="text" class="form-control" disabled :value="infoMember.email">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-2">
                                        <div class="form-group">
                                            <label>Anrede</label>
                                            <input type="text" class="form-control" disabled :value="infoMember.salutation">
                                        </div>
                                    </div>
                                    <div class="col-5">
                                        <div class="form-group">
                                            <label>Name</label>
                                            <input type="text" class="form-control" disabled :value="infoMember.name">
                                        </div>
                                    </div>
                                    <div class="col-5">
                                        <div class="form-group">
                                            <label>Vorname</label>
                                            <input type="text" class="form-control" disabled :value="infoMember.prename">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-8">
                                        <div class="form-group">
                                            <label>Anschrift</label>
                                            <input type="text" class="form-control" disabled :value="infoMember.street">
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label>Hausnr.</label>
                                            <input type="text" class="form-control" disabled :value="infoMember.house_no">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-5">
                                        <div class="form-group">
                                            <label>Postleitzahl</label>
                                            <input type="text" class="form-control" disabled :value="infoMember.zip">
                                        </div>
                                    </div>
                                    <div class="col-7">
                                        <div class="form-group">
                                            <label>Stadt</label>
                                            <input type="text" class="form-control" disabled :value="infoMember.city">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label>Land</label>
                                            <input type="text" class="form-control" disabled :value="infoMember.country">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label>Beigetreten am</label>
                                            <input type="text" class="form-control" disabled :value="infoMember.created_at">
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <label>Letzter Login</label>
                                            <input type="text" class="form-control" disabled :value="infoMember.last_login">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div class="card">
                            <div class="card-header">
                                <a class="collapsed d-block" data-bs-toggle="collapse" href="#collapseOrders">
                                    <i class="fa fa-chevron-down float-right"></i> Bestellungen
                                </a>
                            </div>
                            <div id="collapseOrders" class="collapse" role="tabpanel">
                                <div class="card-body">
                                    <table v-if="infoMember.orders.length" class="table table-striped mb-0">
                                        <thead>
                                            <th>Bestellnummer</th>
                                            <th>Bestelldatum</th>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(order) in infoMember.orders">
                                                <td><a :href="'/backend/orders/' + order.id" target="_blank">{{ order.order_no}}</a></td>
                                                <td>{{ moment(order.completed_at).format('DD.MM.YY HH:MM') }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <p v-else class="text-center">Noch keine Bestellungen</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" :disabled="isLoading">Schließen</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: [
            'partnerShop'
        ],

        data() {
            return {
                hasErrors: false,
                isLoading: false,

                members: {},
                payments: {},

                editMember: {
                    id: null,
                    member_no: null,
                    name: null,
                    prename: null,
                    payments: []
                },

                infoMember: {
                    id: null,
                    email: null,
                    member_no: null,
                    salutation: null,
                    name: null,
                    prename: null,
                    street: null,
                    house_no: null,
                    zip: null,
                    city: null,
                    country: null,
                    orders: [],
                    created_at: null,
                    last_login: null
                },

                //Sorting
                currentSort: 'none',
                currentSortDir: 'asc',
                currentSortType: 'none',

                editMemberModal: null,
                infoMemberModal: null,

            }
        },

        mounted() {
            this.fetch();
            this.editMemberModal = new bootstrap.Modal(document.getElementById('editMemberModal'))
            this.infoMemberModal = new bootstrap.Modal(document.getElementById('infoMemberModal'))
        },

        methods: {
            update() {
                this.isLoading = true
                axios.put('/api/backend/partners/shops/' + this.partnerShop.id + '/members/' + this.editMember.id, {
                    payments: this.editMember.payments
                })
                    .then(response => {
                        if (response.status === 200) {
                            this.editMemberModal.hide()
                        }
                    })
                    .catch(error => {
                        if (error.response.data.hasOwnProperty('errors')) {
                            this.hasErrors = true
                            this.errors = error.response.data.errors
                        }
                    })
                    .finally(() => {
                        this.fetch();
                        this.isLoading = false
                    })
            },
            fetch() {
                axios.get('/api/backend/partners/shops/' + this.partnerShop.id + '/members')
                    .then(response => {
                        this.members = response.data.members
                        this.payments = response.data.payments
                    })
                    .catch(error => {})
                    .finally(() => (this.isLoading = false))
            },
            edit(index) {
                this.editMember.id = this.members[index].id
                this.editMember.member_no = this.members[index].member_no
                this.editMember.name = this.members[index].name
                this.editMember.prename = this.members[index].prename
                this.editMember.payments = this.members[index].payments ? this.members[index].payments : []
                this.editMemberModal.show()
            },
            info(index) {
                this.infoMember.id = this.members[index].id
                this.infoMember.email = this.members[index].email
                this.infoMember.member_no = this.members[index].member_no
                this.infoMember.salutation = (this.members[index].salutation) ? this.members[index].salutation : 'N/A'
                this.infoMember.name = this.members[index].name
                this.infoMember.prename = this.members[index].prename
                this.infoMember.street = this.members[index].street
                this.infoMember.house_no = this.members[index].house_no
                this.infoMember.zip = this.members[index].zip
                this.infoMember.city = this.members[index].city
                this.infoMember.country = this.members[index].country.name
                this.infoMember.created_at = this.moment(this.members[index].created_at).format('DD.MM.YYYY HH:mm')
                this.infoMember.last_login = this.moment(this.members[index].last_login).format('DD.MM.YYYY HH:mm')
                this.infoMember.orders = this.members[index].orders
                this.infoMemberModal.show()
            },
            selectAllPayments() {
                let selected = []
                this.payments.forEach(function(payment) {
                    selected.push(payment.id)
                })
                this.editMember.payments = selected
            },
            sort(s, type = 'none') {
                if (s === this.currentSort) {
                    this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc'
                }
                this.currentSortType = type
                this.currentSort = s
            }
        },

        watch: {
        },

        computed: {
            sortedMembers: function() {
                return this.members.sort((a, b) => {
                    let modifier = 1
                    switch(this.currentSortType) {
                        case 'int':
                            if(this.currentSortDir === 'desc') modifier = -1
                            return (a[this.currentSort] - b[this.currentSort]) * modifier
                        case 'char':

                        case 'date':
                            if(this.currentSortDir === 'desc') modifier = -1
                            if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier
                            if(a[this.currentSort] > b[this.currentSort]) return modifier
                            return 0
                        default:
                            if(this.currentSortDir === 'desc') modifier = -1
                            if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier
                            if(a[this.currentSort] > b[this.currentSort]) return modifier
                            return 0
                    }
                });
            }
        }
    }
</script>
