<template>
    <div class="modal fade" id="custom-position-modal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Benutzerdefinierte Position erstellen und hinzufügen</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-floating">
                                <input type="text" class="form-control" id="positionName" aria-describedby="positionNameHelp" v-model="item.name" autocomplete="false">
                                <label for="positionName">Bezeichnung</label>
                                <small id="positionNameHelp" class="form-text text-muted">Positionsbezeichnung</small>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="form-floating">
                                <input type="number" class="form-control" id="quantity" aria-describedby="quantityHelp" v-model.number="item.quantity">
                                <label for="quantity">Menge</label>
                                <small id="quantityHelp" class="form-text text-muted">Menge</small>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-floating">
                                <input type="number" class="form-control" id="price" aria-describedby="priceHelp" v-model.number="item.price">
                                <label for="price">Preis</label>
                                <small id="priceHelp" class="form-text text-muted">Preis</small>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" @click="reset">Abbrechen</button>
                    <button type="button" class="btn btn-primary" :disabled="btnSave.disabled" @click="handle">{{ btnSave.label }}</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                item: {
                    name: '',
                    quantity: 1,
                    price: 0
                },
                btnSave: {
                    label: 'Hinzufügen',
                    disabled: true,
                }
            }
        },
        methods: {
            handle() {
                this.add()
            },
            add() {
                this.isLoading = true
                this.$emit('create', this.item)
                this.reset()
                this.isLoading = false
            },
            reset() {
                this.item.name = ''
                this.item.price = 0
                this.item.quantity = 1
            }
        },
        watch: {
            'item.name'() {
                this.btnSave.disabled = this.item.name === '';
            }
        }
    }
</script>
