<template>
    <div class="col-12 col-sm-12 col-md-6 pb-4">
        <div class="card">
            <div class="card-header">
                <div class="row">
                    <div class="col-6">
                        <h5 class="mb-0">Bestelldaten</h5>
                        <small class="text-muted">Rechnungsadresse</small>
                    </div>
                    <div class="col-6 text-end">
                        <button v-if="auth" @click.prevent="setTestData" class="btn btn-info btn-sm">Testdaten eintragen</button>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="form-label-group">
                    <input type="email" id="inputEmail" class="form-control" autocomplete="email"
                           :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('billing.email'))}" placeholder="E-Mail-Adresse / Email Address" name="billing[email]"
                           v-model="billing.email">
                    <label for="inputEmail" class="required">E-Mail-Adresse / Email Address</label>
                    <div v-if="hasErrors && errors.hasOwnProperty('billing.email')" class="invalid-feedback">
                        {{ errors['billing.email'][0] }}
                    </div>
                </div>
                <div class="form-label-group">
                    <input type="text" id="inputCustomerNo" class="form-control" placeholder="Kundennummer / Customer No." name="billing[customer_no]"
                           v-model="billing.customer_no" maxlength="254">
                    <label for="inputCustomerNo">Kundennummer / Customer No.</label>
                </div>
                <div class="form-label-group">
                    <input type="text" id="inputTelephone" class="form-control" autocomplete="tel"
                           :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('billing.phone'))}" placeholder="Telefonnummer / Phone Number" name="billing[phone]"
                           v-model="billing.phone">
                    <label for="inputTelephone" class="required">Telefonnummer / Phone Number</label>
                    <div v-if="hasErrors && errors.hasOwnProperty('billing.phone')" class="invalid-feedback">
                        {{ errors['billing.phone'][0] }}
                    </div>
                </div>
                <div class="form-label-group">
                    <select class="form-select"
                            :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('billing.salutation'))}" id="inputSalutation" name="billing[salutation]"
                            v-model="billing.salutation">
                        <option value="null" disabled selected>Auswählen / Select</option>
                        <option value="female">Frau</option>
                        <option value="male">Herr</option>
                    </select>
                    <label for="inputSalutation" class="required">Anrede / Salutation</label>
                    <div v-if="hasErrors && errors.hasOwnProperty('billing.salutation')" class="invalid-feedback">
                        {{ errors['billing.salutation'][0] }}
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-label-group col-12 col-md-6">
                        <input data-was="pattern-" type="text" id="inputPrename" class="form-control" autocomplete="given-name"
                                       :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('billing.prename'))}" placeholder="Vorname / First Name" name="billing[prename]"
                                       :regReplace="''"
                                       :regExp="/[^a-zA-ZäöüÄÖÜß\- .]/"
                                       v-model="billing.prename" value="">

                        <label for="inputPrename" class="required">Vorname / First Name</label>
                        <div v-if="hasErrors && errors.hasOwnProperty('billing.prename')" class="invalid-feedback">
                            {{ errors['billing.prename'][0] }}
                        </div>
                    </div>
                    <div class="form-label-group col-12 col-md-6">
                        <input data-was="pattern-" type="text" id="inputName" class="form-control" autocomplete="family-name"
                                       :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('billing.name'))}" placeholder="Nachname / Surname" name="billing[name]"
                                       :regReplace="''"
                                       :regExp="/[^a-zA-ZäöüÄÖÜß\- .]/"
                                       v-model="billing.name" value="">

                        <label for="inputName" class="required">Nachname / Surname</label>
                        <div v-if="hasErrors && errors.hasOwnProperty('billing.name')" class="invalid-feedback">
                            {{ errors['billing.name'][0] }}
                        </div>
                    </div>
                </div>
                <div class="form-label-group">
                    <input type="text" id="inputCompany" class="form-control" placeholder="Firma / Company (optional)" name="billing[company]"
                           v-model="billing.company">
                    <label for="inputCompany">Firma / Company (optional)</label>
                </div>
                <div class="form-row">
                    <div class="form-label-group col-12 col-md-8">
                        <input type="text" id="inputStreet" class="form-control"
                               :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('billing.street'))}" placeholder="Straße / Street" name="billing[street]"
                               v-model="billing.street">
                        <label for="inputStreet" class="required">Straße / Street</label>
                        <div v-if="hasErrors && errors.hasOwnProperty('billing.street')" class="invalid-feedback">
                            {{ errors['billing.street'][0] }}
                        </div>
                    </div>
                    <div class="form-label-group col-12 col-md-4">
                        <input type="text" id="inputStreetNo" class="form-control"
                               :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('billing.house_no'))}" placeholder="Hausnummer / House No." name="billing[house_no]"
                               v-model="billing.house_no">
                        <label for="inputStreetNo" class="required">Hausnummer / House No.</label>
                        <div v-if="hasErrors && errors.hasOwnProperty('billing.house_no')" class="invalid-feedback">
                            {{ errors['billing.house_no'][0] }}
                        </div>
                    </div>
                </div>
                <div class="form-label-group">
                    <input type="text" id="inputAddressAdditional" class="form-control" placeholder="Adresszusatz / Address Additional (optional)" name="billing[additional_address_information]" autocomplete="new-AddressAdditional_1"
                           v-model="billing.additional_address_information">
                    <label for="inputAddressAdditional">Adresszusatz / Address Additional (optional)</label>
                </div>
                <div class="form-row">
                    <div class="form-label-group col-12 col-md-4">
                        <input data-was="pattern-" type="text" id="inputPostcode" class="form-control" autocomplete="postal-code"
                                       :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('billing.zip'))}" placeholder="Postleitzahl / Zip Code" name="billing[zip]"
                                       :regReplace="''"
                                       :regExp="patternZip"
                                       v-model="billing.zip" value="">

                        <label for="inputPostcode" class="required">Postleitzahl / Zip Code</label>
                    </div>
                    <div class="form-label-group col-12 col-md-8">
                        <input type="text" id="inputCity" class="form-control"
                               :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('billing.city'))}" placeholder="Ort / City" name="billing[city]"
                               v-model="billing.city">
                        <label for="inputCity" class="required">Ort / City</label>
                        <p v-if="cityEntries.length > 0 && this.billing.city === this.cityEntry" class="m-0 pr-2 pl-2">Ortsangabe ist auch gültig für die Orts-/Stadtteile:
                            <span class="font-weight-bold" v-for="(city, index) in cityEntries">
                                {{ city }}<template v-if="index !== cityEntries.length - 1">, </template>
                            </span>
                        </p>
                    </div>
                    <div class="form-label-group col-12 d-none">
                        <input type="hidden" class="form-control"
                               :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('billing.city') || errors.hasOwnProperty('billing.zip'))}">
                        <div v-if="hasErrors && errors.hasOwnProperty('billing.zip')" class="invalid-feedback">
                            {{ errors['billing.zip'][0] }}
                        </div>
                        <div v-if="hasErrors && errors.hasOwnProperty('billing.city')" class="invalid-feedback">
                            {{ errors['billing.city'][0] }}
                        </div>
                    </div>
                </div>
                <div class="form-label-group">
                    <select class="form-select"
                            :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('billing.country'))}" id="inputCountry" name="billing[country]"
                            v-model="billing.country">
                        <option disabled selected>Auswählen / Select</option>
                        <option v-for="country in countries" :value="country.id" :selected="country.id === billing.country ? parseInt(billing.country) : 1" :data-eu="country.eu" :data-code="country.iso_code">{{ country.name }}</option>
                    </select>
                    <label for="inputCountry" class="required">Land / Country</label>
                    <div v-if="hasErrors && errors.hasOwnProperty('billing.country')" class="invalid-feedback">
                        {{ errors['billing.country'][0] }}
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <p class="mb-0"><i class="fa fa-star required-color" aria-hidden="true"></i> Pflichtfeld</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: [
            'errors',
            'item',
            'auth',
            'old'
        ],

        data() {
            return {
                hasErrors: false,
                isLoading: false,
                hasOld: false,

                firstTryBilling: true,

                countries: {},
                countryCode: 'DE',

                billing: {
                    email: '',
                    customer_no: '',
                    phone: '',
                    salutation: null,
                    prename: '',
                    name: '',
                    company: '',
                    street: '',
                    house_no: '',
                    additional_address_information: '',
                    additional_address_information_2: '',
                    zip: '',
                    city: '',
                    country: 1,
                    pattern: {
                        zip: /[^0-9]/
                    }
                },

                cityEntry: '',
                cityEntries: {}
            }
        },

        mounted() {
            this.fetchCountries();

            if(this.notEmptyObject(this.errors)) {
                this.hasErrors = true;
            }

            if(this.notEmptyObject(this.old)) {
                this.hasOld = true;

                for ( let property in this.billing) {
                    if (this.old.billing.hasOwnProperty(property)) {
                        this.billing[property] = (this.old.billing[property] === null) ? '' : this.old.billing[property]
                    }
                }
            }
        },
        computed: {
            patternZip () {
                if (this.billing.country !== 1) {
                    return /[]/
                } else {
                    return this.billing.pattern.zip
                }
            }
        },
        methods: {
            fetchCountries() {
                axios.get('/api/countries')
                    .then(response => {
                        this.countries = response.data
                    })
                    .catch(error => {})
                    .finally(() => (this.isLoading = false))
            },
            getCity(zipCode, countryCode = 'de') {
                let city = '';
                axios.get('https://api.ac-foto.com/geodata/city', {
                    params: {
                        zipCode: zipCode,
                        countryCode: countryCode
                    }
                })
                    .then(response => {
                        if (response.data.length)
                            response.data = JSON.parse(response.data)
                            this.billing.city = response.data.entryFirst
                            this.cityEntry = response.data.entryFirst
                            this.cityEntries = response.data.entryList
                    })
            },
            setCountry() {
                for (let i = 0; i < this.countries.length; i++) {
                    if (this.countries[i].id === this.billing.country) {
                        this.countryCode = this.countries[i].iso_code;
                        if(this.countries[i].eu && this.countries[i].id !== 1) {
                            this.vatId = true
                        } else {
                            this.vatId = false
                        }
                    }
                }
            },
            setTestData() {
                this.billing.email = 'entwicklung@ac-foto.com';
                this.billing.phone = '+49 122 345 67 89';
                this.billing.salutation = 'male';
                this.billing.prename = 'Max';
                this.billing.name = 'Mustermann';
                this.billing.company = 'AC-Foto Handels GmbH';
                this.billing.street = 'Musterstraße';
                this.billing.house_no = '123';
                this.billing.zip = '10115';
                this.billing.city = 'Berlin';
                this.billing.country = 1;
            }
        },

        watch: {
            'billing.zip' () {
                if (this.billing.zip.length < 4 || this.billing.zip.length > 5) return;
                this.getCity(this.billing.zip, this.countryCode)
            },
            'billing.city' () {
                if (this.billing.city !== this.cityEntry) {
                   // this.cityEntries = {}
                }
            },
            'billing.country' () {
                if (!this.firstTryBilling) {
                    this.billing.zip = '';
                    this.billing.city = '';
                }
                this.setCountry()
                this.firstTryBilling = false;
            },
            'countries.length' () {
                this.setCountry();
            },
        },
    }
</script>
