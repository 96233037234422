<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <p class="fs-4">{{ affiliate.name }} Produkt Klickzahlen</p>
            </div>
            <div class="col text-end">
                <a href="/backend/affiliates" class="btn btn-secondary text-uppercase">zurück</a>
            </div>
        </div>
        <div class="row mb-3">
            <div class="col">
                <div class="card">
                    <div class="card-body">
                        <div class="row d-flex align-items-center">
                            <div class="col-8">
                                <div class="row">
                                    <div class="col-auto">
                                        <local-date-picker
                                            :append-to-body="false"
                                            popup-class="mx-fullwidth"
                                            floating="true"
                                            :placeholder="'Von'"
                                            label="Von"
                                            v-model="vmDateStart"
                                            :input-class="'form-control'"
                                        ></local-date-picker>
                                    </div>
                                    <div class="col-auto">
                                        <local-date-picker
                                            :append-to-body="false"
                                            popup-class="mx-fullwidth"
                                            floating="true"
                                            :placeholder="'Bis'"
                                            label="Bis"
                                            v-model="vmDateEnd"
                                            :input-class="'form-control'"
                                        ></local-date-picker>
                                    </div>
                                    <div class="col">
                                        <button type="button" @click.prevent="fetch" class="btn btn-lg btn-primary">Aktualisieren</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4 text-end">
                                <p class="mb-0 text-success fw-bold">Preisvergleich AN</p>
                                <p class="mb-0 text-danger fw-bold">Preisvergleich AUS</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col">
                <table class="table table-dark table-striped table-hover align-td-middle">
                    <thead>
                    <tr>
                        <th scope="col" class="col-5">URL</th>
                        <th scope="col" class="col-3">Produkt</th>
                        <th scope="col" class="col-2">Klicks</th>
                        <th scope="col" class="col-1">Bestellungen</th>
                        <th scope="col" class="col-auto"></th>
                        <th scope="col" class="col-auto"></th>
                        <th scope="col" class="col-auto"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(click, index) in vmClicks">
                        <td class="fw-bold">{{ click.path_entry }}</td>
                        <td>{{ click.hasOwnProperty('item') ? click.item.name : ''}}</td>
                        <td>{{ click.clicks }}</td>
                        <td v-if="click.orders === null && click.hasOwnProperty('item')">
                            <div class="spinner-border text-secondary" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </td>
                        <td v-else>{{ click.orders }}</td>
                        <td>
                            <template v-if="click.hasOwnProperty('loading') && click.loading">
                                <div class="spinner-border text-secondary" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </template>
                            <template v-else>
                                <button v-if="click.hasOwnProperty('item') && click.item.price_comparison" @click.prevent="switchAdvertising(index, 'price_comparison', false)" class="btn btn-success">Idealo</button>
                                <button v-if="click.hasOwnProperty('item') && !click.item.price_comparison" @click.prevent="switchAdvertising(index, 'price_comparison', true)" class="btn btn-danger">Idealo</button>
                            </template>
                        </td>
                        <td>
                            <template v-if="click.hasOwnProperty('loading') && click.loading">
                                <div class="spinner-border text-secondary" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div>
                            </template>
                            <template v-else>
                                <button v-if="click.hasOwnProperty('item') && click.item.network_advertising" @click.prevent="switchAdvertising(index, 'network_advertising', false)" class="btn btn-success">Solute</button>
                                <button v-if="click.hasOwnProperty('item') && !click.item.network_advertising" @click.prevent="switchAdvertising(index, 'network_advertising', true)" class="btn btn-danger">Solute</button>
                            </template>
                        </td>
                        <td>
                            <a v-if="click.hasOwnProperty('item')" :href="'/backend/items/' + click.item.id + '/edit'" target="_blank" class="btn btn-outline-info">P</a>
                            <a v-else :href="'/' + click.path_entry" target="_blank" class="btn btn-outline-info">P</a>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'affiliate',
        'dateStart',
        'dateEnd',
        'clicks',
    ],

    data() {
        return {
            vmClicks: {},
            vmDateStart: null,
            vmDateEnd: null
        };
    },
    mounted() {
        this.vmDateStart = this.dateStart
        this.vmDateEnd = this.dateEnd
        this.vmClicks = JSON.parse(JSON.stringify(this.clicks))
        this.loadOrders()
    },
    destroyed() {},
    methods: {
        fetch: function () {
            axios.post('/backend/affiliates/' + this.affiliate.id + '/show-clicks', {
                fetch: true,
                dateStart: this.vmDateStart,
                dateEnd: this.vmDateEnd
            })
                .then((response) => {
                    this.vmClicks = response.data
                    this.loadOrders()
                })
                .catch((e) => {
                })
                .finally()
        },
        loadOrders: async function () {
            for (let i = 0; i < this.vmClicks.length; i++) {
                if (this.vmClicks[i].hasOwnProperty('item')) {

                    await axios.post('/api/backend/affiliates/' + this.affiliate.id + '/get-click-orders/' + this.vmClicks[i].item.id, {
                        dateStart: this.vmDateStart,
                        dateEnd: this.vmDateEnd
                    })
                        .then((response) => {
                            this.vmClicks[i].orders = response.data.count
                        })
                        .catch((e) => {
                        })
                        .finally()
                }
            }
        },
        switchAdvertising: function (clickIndex, property, state) {
            this.vmClicks[clickIndex]['loading'] = true
            let params = []
            params[property] = state
            axios.patch('/api/backend/items/' + this.vmClicks[clickIndex].item.id, {
                params: params
            }).then((response) => {
                if (response) {
                    this.vmClicks[clickIndex].item[property] = state
                }
            }).finally(() => {
                this.vmClicks[clickIndex]['loading'] = false
            })
        },
        submit: function () {
            this.$nextTick(() => {

            });
        },
    },
    watch: {

    },
};
</script>
