window.domReady = (callback) => {
    if (document.readyState != "loading") callback();
    else document.addEventListener("DOMContentLoaded", callback);
}


import './functions/slide.js';
import './functions/serializeArray.js';

import {useLoading} from "@/InertiaComponents/Components/LoadingOverlay/LoadingOverlayAPI.js";


let loadingInstance = null
let loadingTextChanged = false

let $loadingOverlay = useLoading({
    afterText: ''
})

window.showLoadingOverlay = (text = null) => {
    if (text) {
        changeLoadingText(text)
    }
    $loadingOverlay.show()
}

window.hideLoadingOverlay = () => {
    loadingInstance.hide()
}

window.changeLoadingText = (text) => {
    loadingTextChanged = true
    $loadingOverlay = useLoading({
        afterText: text
    })
}


import Sortable from 'sortablejs'
window.Sortable = Sortable;

import LazyLoad from './functions/lazyLoad'
window.LazyLoad = LazyLoad;

import Splide from '@splidejs/splide';
window.Splide = Splide;

import { Video } from '@splidejs/splide-extension-video'
window.SplideVideo = Video

import { popper } from '@popperjs/core';
window.popper = popper;

import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

import * as bootstrap from 'bootstrap'
window.bootstrap = bootstrap;

import GLightBox from 'glightbox';
window.GLightBox = GLightBox;

import axios from 'axios';
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

let token = document.head.querySelector('meta[name="csrf-token"]');

if (token) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
} else {
    console.error('CSRF token not found');
}

import { Notyf } from 'notyf';
import '../sass/components/_notyf.scss'

window.notyf = new Notyf({
    position: { x: 'center', y: 'bottom' },
    dismissible: true,
    ripple: false,
    types: [
        {
            type: 'success',
            duration: 5000
        },
        {
            type: 'error',
            duration: 10000,
        }
    ]
});




import { createApp } from "vue";

import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/locale/de.es'
import moment from 'moment'

import Multiselect from "vue-multiselect";


const app = createApp({})

const components = import.meta.glob("./components/**/*.vue", { eager: true });
const createName = function (str) {
    str = str.replace('./components/', '').replaceAll('/', '').replace('.vue', '');
    str = str.replace(/[A-Z]+(?![a-z])|[A-Z]/g, ($, ofs) => (ofs ? "-" : "") + $.toLowerCase())
    str = Array.from(new Set(str.split('-'))).toString();
    str = str.replaceAll(',', '-')
    return str
}

Object.entries(components).forEach(([path, definition]) => {
    app.component(createName(path), definition.default);
});

app.component('multiselect', Multiselect)

app.use(DatePicker)

app.mixin({
    methods: {
        nl2br (str, replaceMode, isXhtml) {
            let breakTag = (isXhtml) ? '<br />' : '<br>';
            let replaceStr = (replaceMode) ? '$1'+ breakTag : '$1'+ breakTag +'$2';
            return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr);
        },
        notEmptyObject(someObject){
            return Object.keys(someObject).length
        },
        objLength(someObject){
            return Object.keys(someObject).length
        },
        containsKey(obj, key ) {
            return Object.keys(obj).includes(key);
        },
        moment(date) {
            return moment(date)
        },
    },

})

app.mount('#app')


