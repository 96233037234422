<template>
    <div class="card">
        <div class="card-header">
            <div class="row">
                <div class="col-8"><h5>Vorgeschlagene Produkte</h5></div>
                <div class="col-4 text-end"><a class="btn btn-sm btn-secondary" href="#" data-bs-toggle="modal" data-bs-target="#addItemModal">Produkt zuweisen</a></div>
            </div>
        </div>
        <div class="card-body p-0">
            <template v-if="propositions.length">
                <table class="table table-striped mb-0" id="promotion-items">
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Produkt-ID</th>
                        <th>Bezeichnung</th>
                        <th class="text-end">Discount</th>
                        <th class="text-end">Preis</th>
                        <th></th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody class="sortable">
                    <tr v-for="(proposition, index) in propositions">
                        <td>{{ proposition.id }}</td>
                        <td>{{ proposition.item.id }}</td>
                        <td>{{ proposition.item.brand.name }} {{ proposition.item.name }}</td>
                        <td class="text-end">{{ (proposition.discount !== null) ? proposition.discount.toFixed(2).replace(".", ",") : '0,00' }} €</td>
                        <td class="text-end">{{ (proposition.discount !== null) ? (proposition.item.price.effectivePrice - proposition.discount).toFixed(2).replace(".", ",") : proposition.item.price.effectivePrice.toFixed(2).replace(".", ",") }} €</td>
                        <td style="width: 1px;white-space: nowrap;">
                            <a href="#" @click.prevent="edit(index)"><i class="fas fa-edit" aria-hidden="true"></i></a>
                        </td>
                        <td style="width: 1px;white-space: nowrap;">
                            <a href="#" @click.prevent="destroy(proposition.id)"><i class="fa fa-times" aria-hidden="true"></i></a>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </template>
            <template v-else>
                <div class="p-4 text-center">
                    Es wurden noch keine Produkte zu dieser Aktion hinzugefügt
                </div>
            </template>
        </div>
        <div id="addItemModal" class="modal fade" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Produkt hinzufügen</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <label for="inputItemSearch">Produkt</label>
                                    <template v-if="selected === null">
                                        <input class="form-control" type="text" id="inputItemSearch" name="item_search" v-model="search" @focus="entriesVisible = true" @blur="entriesVisible = false" @click="entriesVisible = true" autocomplete="off">
                                        <span class="loading form-control-feedback" v-show="isLoading"></span>
                                        <div class="autocomplete-results" v-if="entries.length && entriesVisible">
                                            <div class="list-group">
                                                <div class="list-group-item" v-for="entry in entries" @mousedown.prevent @click="select(entry)">
                                                    <div class="row">
                                                        <div class="col-2">
                                                            <template v-if="entry.images.length">
                                                                <img :src="'/storage/img/uploads/products/' + entry.id + '/' + entry.images[0].webp_medium" alt="" class="lazy img-fluid">
                                                            </template>
                                                            <template v-else>
                                                                <img data-src="/storage/img/general/brokenimage.png" alt="" class="lazy img-fluid">
                                                            </template>
                                                        </div>
                                                        <div class="col-10 justify-content-center align-self-center">
                                                            {{ entry.brand.name }} {{ entry.name }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <small id="amountHelp" class="form-text text-muted">Verwende '%' als Platzhalter</small>
                                    </template>
                                    <template v-else>
                                        <div class="row">
                                            <div class="col-10">
                                                <input type="text" class="form-control" disabled :value="selected.brand.name + ' ' + selected.name">
                                            </div>
                                            <div class="col-2">
                                                <a href="#" @click.prevent="selected = null" style="font-size: 30px"><i class="fa fa-trash" aria-hidden="true"></i></a>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="inputPrice">Abweichender Produktpreis <span class="text-muted">optional</span></label>
                                    <input type="text" id="inputPrice" class="form-control" aria-describedby="codeHelp"
                                           :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('price'))}" name="price"
                                           v-model="newItem.discount">
                                    <div v-if="hasErrors && errors.hasOwnProperty('price')" class="invalid-feedback">
                                        {{ errors.code[0] }}
                                    </div>
                                    <small id="codeHelp" class="form-text text-muted">Eigener Produktpreis.</small>
                                </div>
                            </div>
                            <div class="col-6">

                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" @click.prevent="store" :disabled="isLoading">Speichern & Hinzufügen</button>
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" :disabled="isLoading">Abbrechen</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: [
            'voucher'
        ],
        data() {
            return {
                canSave: false,
                hasErrors: false,
                isLoading: false,

                entries: {},
                search: '',
                entriesVisible: false,
                selected: null,

                propositions: {},

                newItem: {
                    item_id: null,
                    discount: null
                },

                editItem: {
                    id: null,
                    item_id: null,
                    price: null
                },
                addItemModal: null,
                editItemModal: null,

            }
        },

        mounted() {
            this.fetch();
            this.addItemModal = new bootstrap.Modal(document.getElementById('addItemModal'))
            this.editItemModal = new bootstrap.Modal(document.getElementById('editItemModal'))
        },

        methods: {
            store() {
                this.isLoading = true
                this.newItem.item_id = this.selected.id
                axios.post('/api/backend/vouchers/' + this.voucher.id + '/propositions', {
                    voucher_id: this.voucher.id,
                    item_id: this.newItem.item_id,
                    discount: this.newItem.discount
                })
                    .then(response => {
                        if (response.status === 200) {
                            this.addItemModal.hide()
                        }
                    })
                    .catch(error => {
                        if (error.response.data.hasOwnProperty('errors')) {
                            this.hasErrors = true
                            this.errors = error.response.data.errors
                        }
                    })
                    .finally(() => {
                        this.selected = null
                        this.fetch()
                        this.isLoading = false
                    })
            },
            update() {
                this.isLoading = true
                axios.patch('/api/backend/promocalendars/' + this.promotion.promocalendar_id + '/promotions/' + this.promotion.id + '/items/' + this.editItem.id, {
                    item_id: this.editItem.item_id,
                    price: this.editItem.price
                })
                    .then(response => {
                        if (response.status === 200) {
                            this.editItemModal.hide()
                        }
                    })
                    .catch(error => {
                        if (error.response.data.hasOwnProperty('errors')) {
                            this.hasErrors = true
                            this.errors = error.response.data.errors
                        }
                    })
                    .finally(() => {
                        this.fetch();
                        this.isLoading = false
                    })
            },
            fetch() {
                axios.get('/api/backend/vouchers/' + this.voucher.id + '/propositions')
                    .then(response => {
                        this.propositions = response.data
                    })
                    .catch(error => {})
                    .finally(() => (this.isLoading = false))
            },
            edit(index) {
                this.editItem.id = this.propositions[index].id
                this.editItem.item_id = this.propositions[index].item_id
                this.editItem.price = this.propositions[index].price
                this.editItemModal.show()
            },
            destroy(itemId) {
                this.isLoading = true
                axios.delete('/api/backend/vouchers/' + this.voucher.id + '/propositions/' + itemId)
                    .then(response => {})
                    .catch(error => {
                        if (error.response.data.hasOwnProperty('errors')) {
                            this.hasErrors = true
                            this.errors = error.response.data.errors
                        }
                    })
                    .finally(() => {
                        this.fetch();
                        this.isLoading = false
                    })
            },
            onContentChange(e) {
                this.canSave = true;
            },
            select(entry) {
                this.selected = entry
            }
        },

        watch: {
            search (val) {
                if (this.search.length < 3) return
                this.isLoading = true

                axios.get('/api/items/search', {params: {term: this.search, filterCategory: JSON.stringify(this.filterCategory)}})
                    .then(response => {
                        this.entries = response.data;
                    })
                    .catch(err => {})
                    .finally(() => (this.isLoading = false))
            },
        },
    }
</script>
