<template>
    <div class="row mb-3">
        <div class="col-8">
            <p class="mb-0 text-muted" v-if="name !== null">{{ name }}</p>
            <p class="mb-0">{{ text }}</p>
            <p class="mb-0" v-if="textSecondary">{{ textSecondary }} <a href="#" v-if="itemAdd" @click.prevent="addItem">hinzufügen</a></p>
        </div>
        <div class="col-4 d-flex justify-content-end align-items-end">
            <template v-if="freeItems.length">
                <p class="mb-0" v-for="item in freeItems">{{ item.name }}</p>
            </template>
            <template v-else>
                <p class="mb-0">{{ calcDiscount }}</p>
            </template>
        </div>
    </div>
</template>

<script>
export default {

    props: {
        name: {
            type: String,
            required: false,
            default: null
        },
        text: {
            type: String,
            required: true
        },
        textSecondary: {
            type: String,
            required: false,
            default: null
        },
        discount: {
            type: Number,
            required: false,
        },
        inPercent: {
            type: Boolean,
            required: false,
            default: false
        },
        freeItems: {
            type: Array,
            required: false,
            default: () => []
        },
        itemAdd: {
            type: Number,
            required: false,
            default: null
        }

    },

    data () {
        return {}
    },
    mounted () {},
    unmounted () {},
    methods: {
        addItem() {
            this.$emit('add-item', this.itemAdd)
        }
    },
    computed: {
        calcDiscount() {
            if (this.inPercent) {
                return this.discount + '%'
            } else {
                return this.discount.toFixed(2).replace('.', ',') + '€'
            }
        }
    },
    watch: {}
}
</script>
