<template>
    <div class="survey-value-container border border-2 rounded-2 p-3 bg-tertiary" :class="hasErrors && errors.hasOwnProperty('questions') ? 'border-danger' : 'border-tertiary'">
        <div class="container">
            <div class="row">
                <div class="col">
                    <p class="fs-4 mb-0">
                        <template v-if="value.length === 1">Eine Frage</template>
                        <template v-else-if="value.length === 0">Keine Fragen</template>
                        <template v-else>{{ value.length }} Fragen</template>
                    </p>
                    <div v-if="hasErrors && errors.hasOwnProperty('questions')" class="mb-0">
                        <div class="d-block invalid-feedback p-0">
                            {{ errors['questions'][0] }}
                        </div>
                    </div>
                </div>
                <div class="col-auto">
                    <button class="btn btn-success" @click="addQuestion">Frage hinzufügen</button>
                    <button class="btn btn-info" @click="sortQuestions">Fragen umsortieren</button>
                </div>
            </div>
        </div>
        <table class="container" v-if="value.length">
            <SortableTableBody :lockAxis="'y'" v-model="sortedList" :appendTo="'#sortableList'" id="sortableList" :lockToContainerEdges="true" :helperClass="'testHelperClass'" :useDragHandle="true" @input="reSort">
                <SortableTableBodyItem v-for="(questionItem, questionIndex) in sortedList" :index="questionIndex" :key="questionIndex">
                    <div class="survey-question-list-item my-4 rounded-2 p-4" style="background-color: rgb(42 42 42);border: 2px solid #3c3c3c;" >
                        <div class="row mb-3 fs-5">
                            <div class="col"><p>{{ questionIndex + 1 }}. {{ questionItem.question }}</p></div>
                            <div class="col-auto" v-if="!sortQuestionsView"><button @click="deleteQuestion(questionIndex)" class="btn btn-outline-danger">Frage löschen</button></div>
                            <div class="col-auto" v-else><span v-handle class="handle dragable"><i class="fas fa-bars"></i></span></div>
                        </div>
                        <div :id="'question-' + questionIndex" v-if="!sortQuestionsView">
                            <div class="row mb-3">
                                <div class="col">
                                    <div class="form-floating">
                                        <input type="text" v-model="questionItem.question" :id="'question-' + questionIndex" placeholder="Frage" class="form-control is-required" :class="{'is-invalid': (hasErrors && (errors.hasOwnProperty('questions.' + questionIndex + '.question')))}">
                                        <label class="fw-bold" :for="'question-' + questionIndex">Frage</label>
                                        <div v-if="hasErrors && (errors.hasOwnProperty('questions.' + questionIndex + '.question'))" class="invalid-feedback">{{ errors['questions.' + questionIndex + '.question'][0] }}</div>
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <div class="form-floating">
                                        <select class="form-select" :id="'required-' + questionIndex" v-model="questionItem.required">
                                            <option :value="true">Ja</option>
                                            <option :value="false">Nein</option>
                                        </select>
                                        <label class="fw-bold" :for="'required-' + questionIndex">Pflicht</label>
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <div class="form-floating">
                                        <select class="form-select" :id="'type-' + questionIndex" v-model="questionItem.type" @change="onChangeQuestionType(questionIndex)">
                                            <option value="text">Textzeile</option>
                                            <option value="textarea">Textfeld</option>
                                            <option value="radio">Einzelauswahl</option>
                                            <option value="checkbox">Mehrfachauswahl</option>
                                            <option value="multiinput">Mehrfacheingabe</option>
                                        </select>
                                        <label class="fw-bold" :for="'type-' + questionIndex">Frage-Typ</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="form-floating">
                                        <textarea
                                            placeholder="Beschreibung"
                                            class="form-control is-required"
                                            :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('description'))}"
                                            :id="'description-' + questionIndex"
                                            style="height: 10rem"
                                            v-model="questionItem.description"
                                        ></textarea>
                                        <label :for="'description-' + questionIndex" class="required fw-bold" for="inputText">Beschreibung</label>
                                        <div v-if="hasErrors && errors.hasOwnProperty('text')" class="invalid-feedback">
                                            {{ errors['text'][0] }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-3" v-if="shouldHaveOptions(questionItem.type)">
                                <div class="col-12">
                                    <p class="fs-5 mb-2">Optionen</p>
                                </div>
                                <div class="col">
                                    <div class="row mb-3 align-items-center" v-for="(option, index) in questionItem.data.options">
                                        <div class="col-auto">{{ index + 1}}.</div>
                                        <div class="col">
                                            <input type="text" v-model="option.text" class="form-control">
                                        </div>
                                        <div class="col-2">
                                            <div class="row row justify-content-end">
                                                <div class="col-4">
                                                    <template v-if="questionItem.type !== 'multiinput'">
                                                        <input type="checkbox" class="btn-check" :id="'addTextfield_' + questionIndex + '_' + index" autocomplete="off" v-model="option.textfield">
                                                        <label class="btn btn-primary" :for="'addTextfield_' + questionIndex + '_' + index">
                                                            <i class="far fa-keyboard"></i>
                                                        </label>
                                                    </template>
                                                </div>
                                                <div class="col-4"><button v-if="questionItem.data.options.length - 1 === index" class="btn btn-outline-success" @click="addQuestionOption(questionIndex)"><i class="fas fa-plus"></i></button></div>
                                                <div class="col-4"><button :disabled="questionItem.data.options.length === 1" class="btn btn-outline-danger" @click="questionItem.data.options.splice(index, 1)"><i class="fas fa-trash"></i></button></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SortableTableBodyItem>
            </SortableTableBody>
        </table>
    </div>
</template>

<script>
    import { v4 as uuidv4 } from 'uuid';

    export default {
        props: {
            value: {},
            errors: {},
            hasErrors: {}
        },
        data () {
            return {
                sortQuestionsView: false,
                sortedList: []
            }
        },
        mounted() {
            this.sortedList = this.value
        },
        methods: {
            emitIsDirty() {
                this.$emit('isDirty', true)
            },
            sortQuestions() {
                this.sortQuestionsView = !this.sortQuestionsView
            },
            reSort (value) {
                value.forEach((item, index) => {
                    item.order = index
                })
                this.$emit('input', value)
                this.emitIsDirty()
            },
            addQuestion () {
                this.value.push({
                    id: null,
                    question: '',
                    description: '',
                    type: 'text',
                    required: true,
                    data: {
                        options: []
                    }
                })
                this.emitIsDirty()
            },
            deleteQuestion (index) {
                this.value.splice(index, 1)
                this.emitIsDirty()
            },
            shouldHaveOptions (type) {
                return ['select', 'checkbox', 'radio', 'multiinput'].includes(type)
            },
            onChangeQuestionType (questionIndex) {
                if (this.shouldHaveOptions(this.value[questionIndex].type)) {
                    this.addQuestionOption(questionIndex)
                }
            },
            addQuestionOption (questionIndex) {
                this.value[questionIndex].data.options.push({
                    uuid: uuidv4(),
                    text: ''
                })
                this.emitIsDirty()
            },
        },
    }
</script>
