<template>
    <div id="promo-calculation-container">

        <template v-if="regular">
            <div class="d-flex align-items-center justify-content-center alert alert-info bg-calculator p-5">
                <button type="button" class="btn btn-lg btn-primary btn-round fw-bold text-uppercase" data-bs-toggle="modal" data-bs-target="#promo-calculation-modal">
                    AKTIONSKALKULATOR
                </button>
            </div>
        </template>
        <template v-else>

        </template>

        <div class="modal fade " id="promo-calculation-modal" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered modal-xl">
                <div class="modal-content">
                    <div class="modal-header align-items-start pb-1 border-0">
                        <div class="col-11 col-lg-6">
                            <div class="row d-flex align-items-center">
                                <div class="col-2">
                                    <img src="/storage/img/logo_square.svg" class="img-fluid" alt="">
                                </div>
                                <div class="col fs-2 text-uppercase fw-light">
                                    Aktionskalkulator
                                </div>
                            </div>
                        </div>
                        <div class="col-1 col-lg-6 text-end">
                            <button type="button" data-bs-dismiss="modal" aria-label="Close" class="btn-close"></button>
                        </div>
                    </div>
                    <div class="modal-body">
                        <div id="promo-calculation-searchboxes" v-if="calcResult.items.length < 1 && calcResult.cbCombos.length < 1">
                            <div class="row">
                                <div class="col">
                                    <p class="fs-5">Wähle deine  gewünschten Produkte aus, um einen möglichen Sparvorteil zu berechnen.</p>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-12 col-lg-4 mb-3" v-for="(val, itemKey) in items">
                                    <promo-calculation-search-box :key="'prod-item-' + itemKey" v-model="items[itemKey]" :placeholder="(parseInt(itemKey) + 1) + '. Produkt'"></promo-calculation-search-box>
                                </div>
                                <div class="col-12 col-lg-4 mb-3 d-flex align-items-center justify-content-center">
                                    <button class="btn btn-outline-primary" @click.prevent="addSelect">weitere Auswahl hinzufügen</button>
                                </div>
                            </div>

                            <!--<div class="row">
                                <div class="col">
                                    <div class="alert alert-info">
                                        <div class="form-check noselect">
                                            <input class="form-check-input" type="checkbox" v-model="educational" id="educational">
                                            <label class="form-check-label" for="educational">
                                                Ich bin Schüler:in, Student:in oder Lehrer:in mit gültigem Nachweis.
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>-->
                            <!--
                            <div class="card result-box mb-3">
                                <div class="card-body">
                                    <p class="mb-0">
                                        <span>Im Dschungel der Angebote kann man auch schon mal die Übersicht verlieren. Da hilft unser Aktions-Kalkulator! Einfach die gewünschten Produkte auswählen, und eine Übersicht über alle Aktionen wird angezeigt!</span>
                                    </p>
                                </div>
                            </div>
                            -->
                            <div class="row">
                                <div class="col text-end">
                                    <button class="btn btn-lg btn-primary" @click.prevent="startCalc" :disabled="isLoading || selectedNum < 1">Sparvorteil berechnen</button>
                                </div>
                            </div>
                        </div>

                        <div id="promo-calculation-result" v-else>
                            <div class="alert alert-info py-2">
                                <p class="mb-0 fs-5 fw-light">Diese Sparvorteile haben wir für dich gefunden</p>
                            </div>

                            <div class="card result-box mb-3" v-for="combo in calcResult.cbCombos">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-auto">
                                            <template v-for="combiItem in combo.items">
                                                <template v-if="combiItem.constructor === Array">
                                                    <template v-for="secItem in combiItem">
                                                        <template v-if="secItem.images.length">
                                                            <img :src="'/storage/img/uploads/products/' + secItem.images[0].item_id + '/' + secItem.images[0].webp_tiny" alt="" class="lazy img-fluid">
                                                        </template>
                                                        <template v-else>
                                                            <img data-src="/storage/img/general/brokenimage.png" alt="" class="lazy img-fluid">
                                                        </template>
                                                    </template>
                                                </template>
                                                <template v-else>
                                                    <template v-if="combiItem.images.length">
                                                        <img :src="'/storage/img/uploads/products/' + combiItem.images[0].item_id + '/' + combiItem.images[0].webp_tiny" alt="" class="lazy img-fluid">
                                                    </template>
                                                    <template v-else>
                                                        <img data-src="/storage/img/general/brokenimage.png" alt="" class="lazy img-fluid">
                                                    </template>
                                                </template>
                                            </template>
                                        </div>
                                        <div class="col d-flex align-items-center ps-0">
                                            <p class="fs-5 mb-0">
                                                <template v-for="(combiItem, index) in combo.items">
                                                    <template v-if="combiItem.constructor === Array">
                                                        <template v-for="(secItem, secIndex) in combiItem">
                                                            <template v-if="secItem.images.length">
                                                                {{ secItem.name }} <span v-if="secIndex + 1 < combiItem.length">+</span>
                                                            </template>
                                                        </template>
                                                    </template>
                                                    <template v-else>
                                                        {{ combiItem.name }} <span v-if="index + 1 < combo.items.length">+</span>
                                                    </template>
                                                </template>
                                            </p>
                                        </div>
                                    </div>
                                    <hr class="my-2">
                                    <promo-calculation-result :key="combo.promo.id" :promo="combo.promo"></promo-calculation-result>
                                    <template v-for="(item, itemKey) in combo.items">
                                        <promo-calculation-result v-for="(promo, promoKey) in item.promos" :key="itemKey" :promo="promo"></promo-calculation-result>
                                    </template>
                                </div>
                            </div>

                            <div class="card result-box mb-3" v-for="result in calcResult.items">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-1">
                                            <template v-if="result.images.length">
                                                <img :src="'/storage/img/uploads/products/' + result.images[0].item_id + '/' + result.images[0].webp_tiny" alt="" class="lazy img-fluid">
                                            </template>
                                            <template v-else>
                                                <img data-src="/storage/img/general/brokenimage.png" alt="" class="lazy img-fluid">
                                            </template>
                                        </div>
                                        <div class="col d-flex align-items-center ps-0">
                                            <p class="fs-5 mb-0">{{ result.name }}</p>
                                        </div>
                                    </div>

                                    <hr class="my-2">
                                    <template v-if="result.promos.length">
                                        <promo-calculation-result v-for="promo in result.promos" :key="promo.id" :promo="promo" @add-item="addItem"></promo-calculation-result>
                                    </template>
                                    <template v-else>
                                        <p class="mb-0">Zurzeit gibt es zu diesem Artikel keine laufende Aktion</p>
                                    </template>
                                </div>
                            </div>

                            <div class="card result-box mb-3" v-for="combo in calcResult.combos">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-auto">
                                            <template v-for="combiItem in combo.items">
                                                <template v-if="combiItem.constructor === Array">
                                                    <template v-for="secItem in combiItem">
                                                        <template v-if="secItem.images.length">
                                                            <img :src="'/storage/img/uploads/products/' + secItem.images[0].item_id + '/' + secItem.images[0].webp_tiny" alt="" class="lazy img-fluid">
                                                        </template>
                                                        <template v-else>
                                                            <img data-src="/storage/img/general/brokenimage.png" alt="" class="lazy img-fluid">
                                                        </template>
                                                    </template>
                                                </template>
                                                <template v-else>
                                                    <template v-if="combiItem.images.length">
                                                        <img :src="'/storage/img/uploads/products/' + combiItem.images[0].item_id + '/' + combiItem.images[0].webp_tiny" alt="" class="lazy img-fluid">
                                                    </template>
                                                    <template v-else>
                                                        <img data-src="/storage/img/general/brokenimage.png" alt="" class="lazy img-fluid">
                                                    </template>
                                                </template>
                                            </template>
                                        </div>
                                        <div class="col d-flex align-items-center ps-0">
                                            <p class="fs-5 mb-0">
                                                <template v-for="(combiItem, index) in combo.items">
                                                    <template v-if="combiItem.constructor === Array">
                                                        <template v-for="(secItem, secIndex) in combiItem">
                                                            <template v-if="secItem.images.length">
                                                                {{ secItem.name }} <span v-if="secIndex + 1 < combiItem.length">+</span>
                                                            </template>
                                                        </template>
                                                    </template>
                                                    <template v-else>
                                                        {{ combiItem.name }} <span v-if="index + 1 < combo.items.length">+</span>
                                                    </template>
                                                </template>
                                            </p>
                                        </div>
                                    </div>
                                    <hr class="my-2">
                                    <promo-calculation-result :key="combo.promo.id" :promo="combo.promo"></promo-calculation-result>
                                </div>
                            </div>

                            <div class="alert alert-info py-2">
                                <div class="row fs-5 fw-bold">
                                    <div class="col-6">
                                        <p class="mb-0">Gesamtersparnis</p>
                                    </div>
                                    <div class="col-6 text-end">
                                        <p class="mb-0">{{ resultSummaryDisplay }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <button class="btn btn-lg btn-primary-dark" @click.prevent="stepBack">zurück zur Auswahl</button>
                                </div>
                                <div class="col-6 text-end">
                                    <button class="btn btn-lg btn-primary" @click.prevent="addToCart">Auswahl zum Warenkorb hinzufügen</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="added-cart-modal" tabindex="-1" role="dialog" aria-labelledby="added-to-cart-Label" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content rounded-4 p-2">
                    <div class="modal-header border-0 pb-0">
                        <div class="container-fluid">
                            <div class="row align-items-center">
                                <div class="col">
                                    <div class="modal-title h5" id="added-to-cart-Label">Produkt(e) zum Warenkorb hinzugefügt</div>
                                </div>
                                <div class="col-auto">
                                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-body">
                        <div class="row mb-3" v-for="(cartItem) in cartItems">
                            <div class="col-4 text-center">
                                <img :src="cartItem.image" alt="" class="img-fluid"/>
                            </div>
                            <div class="col-8 d-flex flex-column justify-content-center">
                                <div class="row">
                                    <div class="col-12">
                                        <p class="mb-2 fw-bold fs-65">{{ cartItem.name }}</p>
                                    </div>
                                </div>
                                <div class="row align-items-center">
                                    <div class="col-2">
                                <span class="badge rounded-pill pill-delivery d-flex align-items-center justify-content-center">
                                    <i class="fas fa-truck"></i>
                                </span>
                                    </div>
                                    <div class="col-10 ps-0">
                                        <p class="mb-0 fs-65">{{ cartItem.deliveryTime.text }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer border-0 py-0">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-12 col-sm-6 mb-3">
                                    <button type="button" class="btn btn-secondary w-100" data-bs-dismiss="modal">Weiter shoppen</button>
                                </div>
                                <div class="col-12 col-sm-6 mb-3">
                                    <a href="/checkout/cart" class="btn btn-primary w-100">Warenkorb anzeigen</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

    props: {
        regular: {
            type: Boolean,
            required: false,
            default: true
        },
    },

    data () {
        return {
            modal: null,
            items: [null, null, null],
            educational: false,
            voucherUpload: false,
            isLoading: false,
            calcResult: {
                items: [],
                combos: [],
                cbCombos: []
            },
            resultSummary: 0,
            modalObj: null,
            cartModalObj: null,
            cartItems: []
        }
    },
    mounted () {
        this.modalObj = document.getElementById('promo-calculation-modal')
        this.cartModalObj = document.getElementById('added-cart-modal')
        this.modal = new bootstrap.Modal(this.modalObj)
        this.cartModal = new bootstrap.Modal(this.cartModalObj)
        this.modalObj.addEventListener('hidden.bs.modal', () => {
            this.reset(true)
        })
        this.cartModalObj.addEventListener('hidden.bs.modal', () => {
            this.reset(true)
        })
    },
    unmounted () {
    },
    methods: {
        startCalc() {
            this.isLoading = true
            axios.post('/pcalc/s', {
                items: this.items,
                educational: this.educational,
                voucherUpload: this.voucherUpload
            })
            .then(response => {
                this.calcResult = response.data
                this.calcSummary()
            })
            .catch(e => { axios.isCancel(e) })
            .finally(() => {
                this.isLoading = false
            })

        },
        addToCart() {
            this.isLoading = true
            axios.post('/pcalc/cart', {
                items: this.items,
            })
                .then(response => {
                    this.cartItems = response.data
                })
                .catch(e => { axios.isCancel(e) })
                .finally(() => {
                    this.isLoading = false
                    this.modal.hide()
                    this.cartModal.show()
                })
        },
        calcSummary() {
            this.resultSummary = 0
            this.calcResult.items.forEach(item => {
                item.promos.forEach(promo => {
                    if (promo.type === 'cb' || promo.type === 'combi_cb') {
                        this.resultSummary += promo.discount
                    }
                    else if (promo.type === 'trade_in_manual' || promo.type === 'trade_in' || promo.type === 'instant_discount' || promo.type === 'voucher') {
                        this.resultSummary += promo.discount
                    }
                    else if (promo.type === 'multi_combi_instant_cashback') {

                    }
                    else if (promo.type === 'free_items') {
                        this.resultSummary += promo.fiDiscount
                    }
                    else if (promo.type === 'voucher_upload') {
                        this.resultSummary += promo.discount
                    }
                })
            })

            this.calcResult.combos.forEach(combo => {
                this.resultSummary += combo.promo.discount
            })


            for (const [key, cbCombo] of Object.entries(this.calcResult.cbCombos)) {
                this.resultSummary += cbCombo.promo.credit
                cbCombo.items.forEach(cbComboItem => {
                    cbComboItem.promos.forEach(cbComboItemPromo => {
                        if (cbComboItemPromo.type === 'cb' || cbComboItemPromo.type === 'combi_cb') {
                            this.resultSummary += cbComboItemPromo.discount
                        } else if (cbComboItemPromo.type === 'trade_in_manual' || cbComboItemPromo.type === 'trade_in' || cbComboItemPromo.type === 'instant_discount' || cbComboItemPromo.type === 'voucher') {
                            this.resultSummary += cbComboItemPromo.discount
                        } else if (cbComboItemPromo.type === 'multi_combi_instant_cashback') {

                        } else if (cbComboItemPromo.type === 'free_items') {
                            this.resultSummary += cbComboItemPromo.fiDiscount
                        } else if (cbComboItemPromo.type === 'voucher_upload') {
                            this.resultSummary += cbComboItemPromo.discount
                        }
                    })
                })
            }
        },
        addSelect() {
          this.items.push(null)
        },
        stepBack() {
            this.reset()
        },
        close() {
            this.modal.hide()
            this.reset(true)
        },
        reset(deleteItems = false) {
            this.calcResult = {
                items: [],
                combos: [],
                cbCombos: []
            }
            this.educational = false
            this.resultSummary = 0
            if (deleteItems) {
                this.items = [null, null, null]
            }
        },
        addItem(id) {
            if (this.items.includes(id)) {
                return
            }
            if (this.items[0] === null) {
                this.items[0] = {id: id}
            } else if (this.items[1] === null) {
                this.items[1] = {id: id}
            } else if (this.items[2] === null) {
                this.items[2] = {id: id}
            } else {
                this.items.push({id: id})
            }
            this.startCalc()
        }
    },
    computed: {
        selectedNum() {
            return Object.values(this.items).filter(item => item !== null).length
        },
        resultSummaryDisplay() {
            return this.resultSummary.toFixed(2).replace('.', ',') + ' €'
        }
    },
    watch: {

    }
}
</script>
