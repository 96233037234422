<template>
    <div class="survey-container">
        <div class="container">
            <div class="row mb-3">
                <div class="col">
                    <p class="mb-0 mb-2 fw-bold">Umfrage-Titel</p>
                    <div class="form-floating">
                        <input type="text" id="inputTitle" class="form-control is-required"
                               :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('title'))}" placeholder="Titel"
                               v-model="survey.title">
                        <label for="inputName" class="required fw-bold">Titel</label>
                        <div v-if="hasErrors && errors.hasOwnProperty('title')" class="invalid-feedback">
                            {{ errors['title'][0] }}
                        </div>
                        <p class="text-tiny text-info px-2 mb-0">Titel der Umfrage (Wird öffentlich angezeigt).</p>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col">
                    <p class="mb-0 mb-2 fw-bold">Umfrage-Beschreibung</p>
                    <div class="form-floating">
                        <textarea
                            placeholder="Beschreibung"
                            class="form-control is-required"
                            :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('text'))}"
                            name="text"
                            id="inputDescription"
                            style="height: 10rem"
                            v-model="survey.description"
                        ></textarea>
                        <label class="required fw-bold" for="inputText">Beschreibung</label>
                        <div v-if="hasErrors && errors.hasOwnProperty('text')" class="invalid-feedback">
                            {{ errors['text'][0] }}
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-5">
                <div class="col-4 local-datepicker">
                    <label for="valid_from" class="mb-2 fw-bold">Umfrage-Start</label>
                    <local-date-time-picker
                        :append-to-body="false"
                        popup-class="mx-fullwidth"
                        floating="true"
                        :id="'inputStartsAt'"
                        :placeholder="'Start Datum & Zeit auswählen'"
                        label="Start Datum & Zeit auswählen"
                        v-model="survey.starts_at"
                        :input-class="'form-control'"
                        @change=""
                    ></local-date-time-picker>
                </div>
                <div class="col-4 local-datepicker">
                    <label for="valid_from" class="mb-2 fw-bold">Umfrage-Ende</label>
                    <local-date-time-picker
                        :append-to-body="false"
                        popup-class="mx-fullwidth"
                        floating="true"
                        :id="'inputExpiresAt'"
                        :placeholder="'End Datum & Zeit auswählen'"
                        label="End Datum & Zeit auswählen"
                        v-model="survey.expires_at"
                        :input-class="'form-control'"
                        @change=""
                    ></local-date-time-picker>
                </div>
                <div class="col-4">
                    <div class="row">
                        <div class="col">
                            <p class="mb-0 mb-2 fw-bold">Umfrage-Status</p>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col d-flex gap-2">
                            <input type="checkbox" id="inputOnline" class="form-check-input"
                                   v-model="survey.online">
                            <label class="form-check-label"
                                   for="inputOnline">
                                Umfrage ist aktiv
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-5">
                <div class="col">
                <div class="card">
                    <div class="card-header">
                        <p class="mb-0 fw-bold">Umfrage-Gutschein</p>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-4">
                                <div class="row mt-3">
                                    <div class="col d-flex gap-2">
                                        <input type="checkbox" id="inputVoucherActive" class="form-check-input"
                                               v-model="survey.voucher_active">
                                        <label class="form-check-label"
                                               for="inputVoucherActive">
                                            Gutschein ist aktiv
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-floating">
                                    <input type="text" id="inputVoucherAmount" class="form-control is-required"
                                           :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('voucher_amount'))}" placeholder="Gutscheinwert"
                                           v-model="survey.voucher_amount">
                                    <label for="inputVoucherAmount" class="required fw-bold">Gutscheinwert</label>
                                    <div v-if="hasErrors && errors.hasOwnProperty('voucher_amount')" class="invalid-feedback">
                                        {{ errors['voucher_amount'][0] }}
                                    </div>
                                    <p class="text-tiny text-info px-2 mb-0">Gutscheinwert (in EUR).</p>
                                </div>
                            </div>
                            <div class="col-4">
                                <div class="form-floating">
                                    <input type="text" id="inputVoucherMinOrderAmount" class="form-control is-required"
                                           :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('voucher_min_order_amount'))}" placeholder="Gutschein-Mindestbestellwert"
                                           v-model="survey.voucher_min_order_amount">
                                    <label for="inputVoucherMinOrderAmount" class="required fw-bold">Gutschein-Mindestbestellwert</label>
                                    <div v-if="hasErrors && errors.hasOwnProperty('voucher_min_order_amount')" class="invalid-feedback">
                                        {{ errors['voucher_min_order_amount'][0] }}
                                    </div>
                                    <p class="text-tiny text-info px-2 mb-0">Gutschein-Mindestbestellwert (in EUR).</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>

            <div class="row">
                <div class="col">
                    <label for="headerImage" class="form-label fw-bold">Header-Image</label>
                    <input class="form-control" type="file" accept="image/*" id="headerImage" @change="addImage">
                </div>
            </div>
            <div class="row mb-5">
                <div class="col">
                    <div class="border border-tertiary p-2 mt-3">
                        <template v-if="survey.header_image && headerImagePreview">
                            <p class="mb-0">Dateiname: {{ survey.header_image.name }}</p>
                            <p class="mb-0">Dateigröße: {{ (survey.header_image.size/1024/1024).toFixed(2) }} MB</p>
                            <img :src="headerImagePreview" class="img-fluid" />
                        </template>
                        <template v-else-if="survey.header_image && !headerImagePreview">
                            <img :src="'/storage/img/uploads/surveys/' + surveyId + '/' + survey.header_image" class="img-fluid" />
                        </template>
                    </div>
                </div>
            </div>

            <survey-question-list v-if="survey.questions.length" v-model="survey.questions" :errors="errors" :has-errors="hasErrors" @isDirty="isDirty = true"></survey-question-list>

            <div class="container mt-5 border border-2 border-tertiary rounded-2 p-3 bg-tertiary">
                <div class="row">
                    <div class="col">
                        <button class="btn btn-lg text-uppercase btn-success" @click="storeSurvey">Speichern</button>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

    import moment from "moment";

    export default {
        props: {
            surveyId: {
                type: Number,
                default: null
            }
        },
        data () {
            return {
                isDirty: false,
                hasErrors: false,
                errors: [],

                survey: {
                    id: null,
                    slug: '',
                    title: '',
                    description: '',
                    online: null,
                    starts_at: null,
                    expires_at: null,
                    header_image: null,
                    voucher_active: null,
                    voucher_amount: 0.00,
                    voucher_min_order_amount: 49.99,
                    questions: []
                },

                headerImagePreview: null
            }
        },
        mounted () {
            if (this.surveyId) {
                this.fetchSurvey();
            }
            window.addEventListener('beforeunload', (e) => {
                if (this.isDirty) {
                    e.preventDefault()
                    e.returnValue = ''
                }
            });
        },
        methods: {
            addImage () {
                let input = event.target
                if (input.files) {
                    let reader = new FileReader()
                    reader.onload = (e) => {
                        this.headerImagePreview = e.target.result
                    }
                    this.survey.header_image = input.files[0]
                    reader.readAsDataURL(input.files[0])
                }
            },
            fetchSurvey () {
                axios.get('/api/backend/surveys/' + this.surveyId)
                    .then(response => {
                        this.survey = response.data.data
                    })
                    .catch(error => {
                        if (error.response.status === 419) {
                            notyf.error('Sitzung ist abgelaufen - bitte Seite neu laden und ggf. neu anmelden.')
                        }
                    });
            },
            storeSurvey () {
                let response
                let formData = new FormData()
                formData.append('header_image', this.survey.header_image)
                this.survey.starts_at = moment(this.survey.starts_at).format('YYYY-MM-DD HH:mm:ss')
                this.survey.expires_at = moment(this.survey.expires_at).format('YYYY-MM-DD HH:mm:ss')
                formData.append('survey', JSON.stringify(this.survey))

                if (this.surveyId) {
                    formData.append('_method', 'put')
                    response = axios.post('/api/backend/surveys/' + this.surveyId, formData, { headers: { "Content-Type": "multipart/form-data" } })
                } else {
                    response = axios.post('/api/backend/surveys', formData, { headers: { "Content-Type": "multipart/form-data" } })
                }
                response
                    .then(response => {
                        if (response.config.method === 'post' && response.status === 201) {
                            if (!response.data.errors.length) {
                                window.location.href = '/backend/surveys/' + response.data.survey_id + '/edit'
                            }
                        }
                        if (response.status === 200) {
                            this.survey = response.data.data
                            this.isDirty = false
                            notyf.success('Umfrage wurde erfolgreich gespeichert.')
                        }
                    })
                    .catch(error => {
                        if (error.response.status === 419) {
                            notyf.error('Sitzung ist abgelaufen - bitte Seite neu laden und ggf. neu anmelden.')
                        }
                        if (error.response.data.hasOwnProperty('errors')) {
                            this.hasErrors = true
                            this.errors = error.response.data.errors
                            notyf.error('Es gibt leider ein oder mehrere Fehler.')
                        } else {
                            notyf.error('Something went wrong.')
                        }
                    })
            },
        },
    }
</script>
