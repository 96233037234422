<template>
    <div class="survey-container">
        <template v-if="!finished">
            <div class="container" v-if="survey.header_image">
                <div class="row mb-3">
                    <div class="col">
                        <img :src="'/storage/img/uploads/surveys/' + survey.id + '/' + survey.header_image" class="img-fluid" />
                    </div>
                </div>
            </div>
            <div class="container" v-if="hasErrors">
                <div class="row mb-3">
                    <div class="col">
                        <div class="card bg-tertiary border-danger border-3">
                            <div class="card-body text-danger fs-4 text-center fw-bold p-5">
                                <p class="mb-0">Leider ist beim speichern deiner Antworten ein Fehler aufgetreten, <br> bitte kontrolliere das Formular und versuche es noch einmal.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row mb-3">
                    <div class="col">
                        <div class="card bg-tertiary">
                            <div class="card-body fs-5" v-html="nl2br(survey.description)">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-12" v-for="(questionItem, questionIndex) in survey.questions">
                        <survey-public-question-item
                            :key="questionIndex"
                            :question="questionItem"
                            :index="questionIndex"
                            :errors="errors"
                            :has-errors="hasErrors"
                            @change="(value) => onAnswerChange(questionItem, value)"
                        />
                    </div>
                    <div class="col">
                        <input type="text" class="vh-y" autocomplete="off" name="question-88" @change="(event) => onAnswerChange(fQItems['88'], event.target.value)">
                        <input type="text" class="vh-y" autocomplete="off" name="question-99" @change="(event) => onAnswerChange(fQItems['99'], event.target.value)">
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col">
                        <div class="card bg-tertiary">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12 col-md-8 col-lg-9">
                                        <div class="form-floating">
                                            <input class="form-control light" type="text" ref="surveyEmailAddress" name="emailAddress" v-model="email" placeholder="Deine E-Mail-Adresse" :class="{'is-invalid': hasErrors && errors.hasOwnProperty('email')}">
                                            <label class="font-weight-bold">Deine E-Mail-Adresse</label>
                                            <div v-if="hasErrors && errors.hasOwnProperty('email')" class="invalid-feedback fw-bold fs-6">
                                                {{ errors.email[0] }}
                                            </div>
                                            <p class="mb-0 ps-25" v-if="!survey.voucher_active">Die Angabe deiner E-Mail-Adresse ist natürlich freiwillig.</p>
                                            <p class="mb-0 ps-25" v-else>Die Angabe deiner E-Mail-Adresse ist natürlich freiwillig, sie wird allerdings benötigt, um dir deinen Gutscheincode zuzusenden</p>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-4 col-lg-3 text-center text-md-end mt-2 mt-md-0">
                                        <button class="btn btn-lg btn-success w-100" @click="storeSurvey(false)">Umfrage abschicken</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-else>
            <div class="container" v-if="survey.header_image">
                <div class="row mb-3">
                    <div class="col">
                        <img :src="'/storage/img/uploads/surveys/' + survey.id + '/' + survey.header_image" class="img-fluid" />
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="row mb-3">
                    <div class="col">
                        <div class="card bg-tertiary">
                            <div class="card-body text-center fs-4">
                                <p>Vielen Dank für deine Teilnahme! ❤️</p>
                                <p v-if="survey.voucher_active" class="mb-5">Deinen {{ survey.voucher_amount.toFixed(2).toString().replace('.', ',') }} € Gutschein erhältst du in Kürze per E-Mail.</p>
                                <a href="/" class="btn btn-primary btn-lg text-uppercase">Jetzt bei AC-Foto einkaufen</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <alert-modal
            v-if="survey.voucher_active && emptyEmail"
            :title="'Wirklich ohne Gutschein fortfahren?'"
            :title-class="'fs-4 fw-bold'"
            :body="'Die Angabe deiner E-Mail-Adresse ist freiwillig, aber wenn du deine E-Mail-Adresse nicht angibst, können wir dir nicht deinen Gutschein zusenden.'"
            :body-class="'fs-5'"
            :btn-execute="'Ohne E-Mail-Adresse fortfahren'"
            :btn-cancel="'E-Mail-Adresse eingeben'"
            :btn-execute-class="'btn-secondary'"
            :btn-cancel-class="'btn-primary'"
            @cancel="cancelAddEmail"
            @execute="storeSurvey(true)"
        />
    </div>
</template>

<script>
    export default {
        props: {
            survey: {},
            surveyParticipant: {},
        },
        data () {
            return {
                isDirty: false,
                hasErrors: false,
                errors: [],
                answers: {},
                email: '',
                emptyEmail: false,
                finished: false,

                fQItems: {
                    88: { id: 'z88' },
                    99: { id: 'z99' },
                }
            }
        },
        mounted() {
            window.addEventListener('beforeunload', (e) => {
                if (this.isDirty && !this.finished) {
                    e.preventDefault()
                    e.returnValue = ''
                }
            });

            if (this.surveyParticipant.finished) {
                this.finished = true
            }
        },
        methods: {
            cancelAddEmail() {
                this.emptyEmail = false
                this.$refs.surveyEmailAddress.focus()
            },
            onAnswerChange (question, value) {
                this.isDirty = true
                this.answers[question.id] = value
            },
            storeSurvey (withoutEmail = false) {
                this.emptyEmail = false

                if (this.email && !this.email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
                    this.hasErrors = true
                    this.errors = { email: ['Bitte gib eine gültige E-Mail-Adresse ein.'] }
                    return
                }

                if (this.survey.voucher_active && !withoutEmail && !this.email) {
                    this.emptyEmail = true
                    return
                }

                axios.post('/umfrage/' + this.survey.slug + '/' + this.surveyParticipant.token, { answers: this.answers, email: this.email })
                    .then(response => {
                        this.finished = true
                    })
                    .catch(error => {
                        this.hasErrors = true
                        this.errors = error.response.data.errors
                    })
                    .finally(() => {
                        document.body.scrollTop = 0
                        document.documentElement.scrollTop = 0
                    })
            },
        },
    }
</script>
