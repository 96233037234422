<template>
    <div class="card">
        <div class="card-header">
            <div class="row">
                <div class="col-8"><h5>Zugewiesene Gutscheine</h5></div>
                <div class="col-4 text-end"><a class="btn btn-sm btn-secondary" href="#" data-bs-toggle="modal" data-bs-target="#addVoucherModal">Gutschein zuweisen</a></div>
            </div>
        </div>
        <div class="card-body p-0">
            <template v-if="promoCalendarPromotion.vouchers.length">
                <table class="table table-striped mb-0" id="promotion-vouchers">
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Bezeichnung</th>
                        <th class="text-end">Guthaben</th>
                        <th class="text-end">min. Bestellwert</th>
                        <th class="text-end">Gültigkeit (Tage)</th>
                        <th></th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody class="sortable">
                        <tr v-for="(voucher, index) in promoCalendarPromotion.vouchers">
                            <td>{{ voucher.id }}</td>
                            <td></td>
                            <td class="text-end">{{ voucher.amount.toFixed(2).replace(".", ",") }} €</td>
                            <td class="text-end">{{ voucher.min_order_amount.toFixed(2).replace(".", ",") }} €</td>
                            <td class="text-end">{{ voucher.validity }}</td>
                            <td style="width: 1px;white-space: nowrap;">
                                <a href="#" @click.prevent="edit(index)"><i class="fas fa-edit" aria-hidden="true"></i></a>
                            </td>
                            <td style="width: 1px;white-space: nowrap;">
                                <a href="#" @click.prevent="destroy(voucher.id)"><i class="fa fa-times" aria-hidden="true"></i></a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </template>
            <template v-else>
                <div class="p-4 text-center">
                    Es wurden noch keine Gutscheine zu dieser Aktion hinzugefügt
                </div>
            </template>
        </div>
        <div id="addVoucherModal" class="modal fade" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Gutschein hinzufügen</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="inputAmount">Guthaben</label>
                                    <input type="text" id="inputAmount" class="form-control" aria-describedby="amountHelp"
                                           :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('amount'))}" placeholder="10.00" name="amount"
                                           v-model="newVoucher.amount">
                                    <div v-if="hasErrors && errors.hasOwnProperty('amount')" class="invalid-feedback">
                                        {{ errors.amount[0] }}
                                    </div>
                                    <small id="amountHelp" class="form-text text-muted">Höhe des Gutscheins</small>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="inputMinOrderAmount">Min. Bestellwert</label>
                                    <input type="text" id="inputMinOrderAmount" class="form-control" aria-describedby="minOrderAmountHelp"
                                           :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('min_order_amount'))}" placeholder="10.00" name="min_order_amount"
                                           v-model="newVoucher.min_order_amount">
                                    <div v-if="hasErrors && errors.hasOwnProperty('min_order_amount')" class="invalid-feedback">
                                        {{ errors.min_order_amount[0] }}
                                    </div>
                                    <small id="minOrderAmountHelp" class="form-text text-muted">Mindestbestellwert</small>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="inputCode">Benutzerdefinierter Code <span class="text-muted">optional</span></label>
                                    <input type="text" id="inputCode" class="form-control" aria-describedby="codeHelp"
                                           :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('code'))}" placeholder="meinGutschein_xy-abc" name="code"
                                           v-model="newVoucher.code">
                                    <div v-if="hasErrors && errors.hasOwnProperty('code')" class="invalid-feedback">
                                        {{ errors.code[0] }}
                                    </div>
                                    <small id="codeHelp" class="form-text text-muted">Eigener Code für diesen Gutschein, der vor den Gutscheincode gesetzt wird.</small>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="inputValidity">Gültigkeit</label>
                                    <input type="text" id="inputValidity" class="form-control" aria-describedby="validityHelp"
                                           :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('validity'))}" name="validity"
                                           v-model="newVoucher.validity">
                                    <div v-if="hasErrors && errors.hasOwnProperty('validity')" class="invalid-feedback">
                                        {{ errors.validity[0] }}
                                    </div>
                                    <small id="validityHelp" class="form-text text-muted">Gültigkeit des Gutscheins ab Ausstellung (in Tagen)</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" @click.prevent="store" :disabled="isLoading">Speichern & Hinzufügen</button>
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" :disabled="isLoading">Abbrechen</button>
                    </div>
                </div>
            </div>
        </div>
        <div id="editVoucherModal" class="modal fade" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Gutschein bearbeiten</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="inputEditAmount">Guthaben</label>
                                    <input type="text" id="inputEditAmount" class="form-control" aria-describedby="amountEditHelp"
                                           :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('amount'))}" name="amount"
                                           v-model="editVoucher.amount">
                                    <div v-if="hasErrors && errors.hasOwnProperty('amount')" class="invalid-feedback">
                                        {{ errors.amount[0] }}
                                    </div>
                                    <small id="amountEditHelp" class="form-text text-muted">Höhe des Gutscheins</small>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="inputEditMinOrderAmount">Min. Bestellwert</label>
                                    <input type="text" id="inputEditMinOrderAmount" class="form-control" aria-describedby="minOrderAmountEditHelp"
                                           :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('min_order_amount'))}" name="min_order_amount"
                                           v-model="editVoucher.min_order_amount">
                                    <div v-if="hasErrors && errors.hasOwnProperty('min_order_amount')" class="invalid-feedback">
                                        {{ errors.min_order_amount[0] }}
                                    </div>
                                    <small id="minOrderAmountEditHelp" class="form-text text-muted">Mindestbestellwert</small>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="inputEditCode">Benutzerdefinierter Code <span class="text-muted">optional</span></label>
                                    <input type="text" id="inputEditCode" class="form-control" aria-describedby="codeEditHelp"
                                           :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('code'))}" name="code"
                                           v-model="editVoucher.code">
                                    <div v-if="hasErrors && errors.hasOwnProperty('code')" class="invalid-feedback">
                                        {{ errors.code[0] }}
                                    </div>
                                    <small id="codeEditHelp" class="form-text text-muted">Eigener Code für diesen Gutschein, der vor den Gutscheincode gesetzt wird.</small>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="form-group">
                                    <label for="inputEditValidity">Gültigkeit</label>
                                    <input type="text" id="inputEditValidity" class="form-control" aria-describedby="validityEditHelp"
                                           :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('validity'))}" name="validity"
                                           v-model="editVoucher.validity">
                                    <div v-if="hasErrors && errors.hasOwnProperty('validity')" class="invalid-feedback">
                                        {{ errors.validity[0] }}
                                    </div>
                                    <small id="validityEditHelp" class="form-text text-muted">Gültigkeit des Gutscheins ab Ausstellung (in Tagen)</small>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" @click.prevent="update" :disabled="isLoading">Speichern & Aktualisieren</button>
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" :disabled="isLoading">Abbrechen</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: [
            'promotion'
        ],

        data() {
            return {
                canSave: false,
                hasErrors: false,
                errors: {},
                isLoading: false,

                promoCalendarPromotion: {
                    id: this.promotion.id,
                    promocalendar_id: this.promotion.promocalendar_id,

                    vouchers: {}
                },
                newVoucher: {
                    amount: null,
                    code: null,
                    min_order_amount: null,
                    validity: null
                },
                editVoucher: {
                    id: null,
                    amount: null,
                    code: null,
                    min_order_amount: null,
                    validity: null
                },
                addVoucherModal: null,
                editVoucherModal: null
            }
        },

        mounted() {
            this.fetch();
            this.addVoucherModal = new bootstrap.Modal(document.getElementById('addVoucherModal'))
            this.editVoucherModal = new bootstrap.Modal(document.getElementById('editVoucherModal'))
        },

        methods: {
            update() {
                this.isLoading = true
                axios.patch('/api/backend/promocalendars/' + this.promotion.promocalendar_id + '/promotions/' + this.promotion.id + '/vouchers/' + this.editVoucher.id, {
                    amount: this.editVoucher.amount,
                    code: this.editVoucher.code,
                    min_order_amount: this.editVoucher.min_order_amount,
                    validity: this.editVoucher.validity
                })
                    .then(response => {
                        if (response.status === 200) {
                            this.editVoucherModal.hide()
                        }
                    })
                    .catch(error => {
                        if (error.response.data.hasOwnProperty('errors')) {
                            this.hasErrors = true
                            this.errors = error.response.data.errors
                        }
                    })
                    .finally(() => {
                        this.fetch();
                        this.isLoading = false
                    })
            },
            store() {
                this.isLoading = true
                axios.post('/api/backend/promocalendars/' + this.promotion.promocalendar_id + '/promotions/' + this.promotion.id + '/vouchers', {
                    promocalendar_promotion_id: this.promoCalendarPromotion.id,
                    amount: this.newVoucher.amount,
                    code: this.newVoucher.code,
                    min_order_amount: this.newVoucher.min_order_amount,
                    validity: this.newVoucher.validity
                })
                    .then(response => {
                        if (response.status === 200) {
                            this.addVoucherModal.hide()
                        }
                    })
                    .catch(error => {
                        if (error.response.data.hasOwnProperty('errors')) {
                            this.hasErrors = true
                            this.errors = error.response.data.errors
                        }
                    })
                    .finally(() => {
                        this.fetch();
                        this.isLoading = false
                    })
            },
            fetch() {
                axios.get('/api/backend/promocalendars/' + this.promoCalendarPromotion.promocalendar_id + '/promotions/' + this.promoCalendarPromotion.id + '/vouchers')
                    .then(response => {
                        this.promoCalendarPromotion.vouchers = response.data
                    })
                    .catch(error => {})
                    .finally(() => (this.isLoading = false))
            },
            edit(voucherId) {
                this.editVoucher.id = this.promoCalendarPromotion.vouchers[voucherId].id
                this.editVoucher.amount = this.promoCalendarPromotion.vouchers[voucherId].amount
                this.editVoucher.code = this.promoCalendarPromotion.vouchers[voucherId].code
                this.editVoucher.min_order_amount = this.promoCalendarPromotion.vouchers[voucherId].min_order_amount
                this.editVoucher.validity = this.promoCalendarPromotion.vouchers[voucherId].validity
                this.editVoucherModal.show()
            },
            destroy(voucherId) {
                this.isLoading = true
                axios.delete('/api/backend/promocalendars/' + this.promotion.promocalendar_id + '/promotions/' + this.promotion.id + '/vouchers/' + voucherId)
                    .then(response => {})
                    .catch(error => {
                        if (error.response.data.hasOwnProperty('errors')) {
                            this.hasErrors = true
                            this.errors = error.response.data.errors
                        }
                    })
                    .finally(() => {
                        this.fetch();
                        this.isLoading = false
                    })
            },
            onContentChange(e) {
                this.canSave = true;
            },
        },
    }
</script>
