<template>
    <div class="container">
        <template v-if="!requested">
            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="card">
                        <div class="card-header">
                            <p class="mb-0 fs-5 fw-bold">Deine Daten</p>
                        </div>
                        <div class="card-body">

                            <div class="row mb-3">
                                <div class="col-3">
                                    <div class="form-floating">
                                        <select v-model="customer.salutation" id="salutation" class="form-select is-required" :class="{'is-invalid': (hasErrors && (errors.hasOwnProperty('customer.salutation') || customer.salutation === null))}">
                                            <option :value="null" disabled>Auswählen</option>
                                            <option value="f">Frau</option>
                                            <option value="m">Herr</option>
                                            <option value="d">Divers</option>
                                        </select>
                                        <label for="salutation">Anrede</label>
                                        <div v-if="hasErrors && (errors.hasOwnProperty('customer.salutation'))" class="invalid-feedback">{{ errors['customer.salutation'][0] }}</div>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="form-floating">
                                        <input type="text" v-model="customer.prename" id="prename" placeholder="Vorname" class="form-control is-required" :class="{'is-invalid': (hasErrors && (errors.hasOwnProperty('customer.prename')))}">
                                        <label class="fw-bold" for="prename">Vorname</label>
                                        <div v-if="hasErrors && (errors.hasOwnProperty('customer.prename'))" class="invalid-feedback">{{ errors['customer.prename'][0] }}</div>
                                    </div>
                                </div>
                                <div class="col-5">
                                    <div class="form-floating">
                                        <input type="text" v-model="customer.name" id="name" placeholder="Nachname" class="form-control is-required" :class="{'is-invalid': (hasErrors && (errors.hasOwnProperty('customer.name')))}">
                                        <label class="fw-bold" for="name">Nachname</label>
                                        <div v-if="hasErrors && (errors.hasOwnProperty('customer.name'))" class="invalid-feedback">{{ errors['customer.name'][0] }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-6">
                                    <div class="form-floating">
                                        <input type="email" v-model="customer.email" id="email" placeholder="E-Mail-Adresse" class="form-control is-required" :class="{'is-invalid': (hasErrors && (errors.hasOwnProperty('customer.email')))}">
                                        <label class="fw-bold" for="email">E-Mail-Adresse</label>
                                        <div v-if="hasErrors && (errors.hasOwnProperty('customer.email'))" class="invalid-feedback">{{ errors['customer.email'][0] }}</div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-floating">
                                        <input type="text" v-model="customer.tel" id="tel" placeholder="Telefon/Mobil" class="form-control" :class="{'is-invalid': (hasErrors && (errors.hasOwnProperty('customer.tel')))}">
                                        <label class="fw-bold" for="tel">Telefon/Mobil</label>
                                        <div v-if="hasErrors && (errors.hasOwnProperty('customer.tel'))" class="invalid-feedback">{{ errors['customer.tel'][0] }}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-12 acf-multiselect">
                                    <label class="fw-bold px-2 mb-1" for="">Ich bin Mitglied im Verband/in Verbänden</label>
                                    <multiselect v-model="associations"
                                                 :options="associationOptions"
                                                 :multiple="true"
                                                 :searchable="true"
                                                 :hide-selected="true"
                                                 :taggable="true"
                                                 label="value"
                                                 track-by="name"
                                                 @tag="addAssociation"
                                                 tagPlaceholder="Enter oder klicken zum hinzufügen"
                                                 selectLabel="Enter oder klicken zum hinzufügen"
                                                 placeholder="Eine oder mehrere auswählen"
                                                 :class="{'is-invalid': hasErrors && (errors.hasOwnProperty('associations'))}">
                                    </multiselect>
                                    <div v-if="hasErrors && (errors.hasOwnProperty('associations'))" class="invalid-feedback">{{ errors['associations'][0] }}</div>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-12">
                                    <div class="alert alert-info mb-0">
                                        <p class="mb-0 fs-6">
                                            Bitte verwende das Suchfeld im Angebotskorb, um einen Artikel zu finden und durch anklicken der Angebotsanfrage hinzuzufügen.
                                            Du kannst natürlich auch mehrere Artikel in die Anfrage aufnehmen.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <p class="mb-1 fw-bold">Hast du noch eine Mitteilung an uns?</p>
                                </div>
                                <div class="col-12">
                                    <textarea name="message" id="message" rows="5" v-model="message" class="form-control" placeholder="Deine Nachricht"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="card">
                        <div class="card-header">
                            <p class="mb-0 fs-5 fw-bold">Angebotskorb</p>
                        </div>
                        <div class="card-body">
                            <div v-if="hasErrors && (errors.hasOwnProperty('items'))" class="alert alert-danger">{{ errors['items'][0] }}</div>
                            <div class="row mb-3">
                                <partials-product-search @click="itemAdd" :placeholder="'Artikel zum hinzufügen suchen'"></partials-product-search>
                            </div>
                            <div class="row border-bottom border-info py-2 mx-0">
                                <div class="col-2"></div>
                                <div class="col-7">
                                    <p class="mb-0 fw-bold">Artikel</p>
                                </div>
                                <div class="col-2 text-center">
                                    <p class="mb-0 fw-bold">Anzahl</p>
                                </div>
                                <div class="col-1">

                                </div>
                            </div>
                            <template v-if="Object.keys(items).length">
                                <div class="row align-items-center py-2 px-2 m-0" v-for="(item, key, index) in items" :class="{'border-bottom border-info py-2': index + 1 !== Object.keys(items).length}">
                                    <div class="col-2 text-center">
                                        <img :src="item.image" alt="" class="img-fluid" style="max-width: 100%; width: 100px">
                                    </div>
                                    <div class="col-6">
                                        <p class="mb-0 fs-5 fw-bold">{{ item.name }}</p>
                                    </div>
                                    <div class="col-1 text-center">
                                        <p class="mb-0 fs-5 fw-bold">{{ item.quantity }}</p>
                                    </div>
                                    <div class="col-2">
                                        <div class="btn-group">
                                        <button @click.prevent="increaseQty(key)" class="btn btn-outline-info">
                                            <i class="fas fa-arrow-up"></i>
                                        </button>
                                        <button @click.prevent="decreaseQty(key)" class="btn btn-outline-info">
                                            <i class="fas fa-arrow-down"></i>
                                        </button>
                                            </div>
                                    </div>
                                    <div class="col-1">
                                        <button @click.prevent="itemRemove(key)" class="btn btn-primary">
                                            <i class="fas fa-trash"></i>
                                        </button>
                                    </div>
                                </div>
                            </template>

                            <template v-else>
                                <div class="row pt-3">
                                    <div class="col-12 text-center fw-bold">
                                        <p class="mb-0">Noch kein Artikel zum Angebot hinzugefügt</p>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col">
                    <div class="card">
                        <div class="card-body text-end">
                            <button type="submit" @click.prevent="submit" class="btn btn-lg btn-primary" :disabled="isLoading">
                                <template v-if="isLoading"><i class="fas fa-spinner fa-pulse"></i></template>
                                <template v-else>Anfragen</template>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template v-if="requested">
            <div class="row">
                <div class="col-12">
                    <div class="alert alert-success">
                        <p class="mb-0">Wir haben deine Anfrage erhalten. Diese wird von uns so schnell wie möglich bearbeitet.</p>
                        <p class="mb-0 text-center fw-bold">{{ orderNo }}</p>
                    </div>
                </div>
            </div>
        </template>


    </div>
</template>
<script>
    export default {
        props: [
            'affiliateForm'
        ],

        data() {
            return {
                canSave: false,
                hasErrors: false,
                errors: {},
                isLoading: false,

                items: [],

                message: '',
                customer: {
                    salutation: null,
                    prename: '',
                    name: '',
                    email: '',
                    tel: '',
                },

                associationOptions: [
                    { name: 'null',     value: "Kein Mitglied eines Verbandes" },
                    { name: 'BFF',      value: "BFF (Berufsverband Freie Fotografen)" },
                    { name: 'BPP',      value: "BPP (Bund Professioneller Portraitfotografen)" },
                    { name: 'CV',       value: "CV Centralverband Deutscher Berufsfotografen" },
                    { name: 'DVF',      value: "DVF (Deutscher Verband für Fotografie)" },
                    { name: 'GDT',      value: "GDT (Gesellschaft für Naturfotografie) e.V." },
                    { name: 'PPC',      value: "PPC (Profi-Portrait-Club)" },
                    { name: 'VDS',      value: "VDS (Verband Deutscher Sportjournalisten)" },
                    { name: 'FREELENS', value: "FREELENS" },
                    { name: "other",    value: 'Andere, nicht genannt' }
                ],
                associations: [],


                requested: false,
                offerNo: null
            }
        },

        mounted() {
        },

        methods: {
            onContentChange(e) {
                this.canSave = true;
            },
            submit() {
                this.isLoading = true
                this.hasErrors = false
                this.error = {}
                axios.post('/affiliate/' + this.affiliateForm.id, {
                    items: this.items,
                    customer: this.customer,
                    message: this.message,
                    associations: this.associations
                })
                    .then(response => {
                        if (response.status === 200) {
                            this.requested = true
                            this.offerNo = response.data.offerNo
                        }
                    })
                    .catch(error => {
                        if (error.response.data.hasOwnProperty('errors')) {
                            this.hasErrors = true
                            this.errors = error.response.data.errors
                        }
                    })
                    .finally(() => {
                        this.selected = null
                        this.isLoading = false
                    })


            },
            itemAdd(item) {
                let foundIndex = this.items.findIndex(e => e.id === item[0].identifier)

                if (foundIndex >= 0) {
                    this.items[foundIndex].quantity++;
                } else {
                    this.items.push({
                        id: item[0].identifier,
                        image: item[0].image,
                        name: item[0].name,
                        quantity: 1
                    })
                }
            },
            increaseQty(itemKey) {
                this.items[itemKey].quantity++
            },
            decreaseQty(itemKey) {
                if (this.items[itemKey].quantity === 1) {
                    this.itemRemove(itemKey)
                } else {
                    this.items[itemKey].quantity--
                }
            },
            itemRemove(key) {
                this.$delete(this.items, key)
            },
            clearAllAssociations() {
                this.associations = []
            },
            addAssociation (newTag) {
                const tag = {
                    value: newTag,
                    name: newTag.substring(0, 2) + Math.floor((Math.random() * 10000000))
                }
                this.associationOptions.push(tag)
                this.associations.push(tag)
            }
        },
    }
</script>
