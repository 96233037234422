<template>
    <div class="card">
        <div class="card-header">Angebotsartikel</div>
        <div class="card-body">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Anzahl</th>
                        <th scope="col">Preis</th>
                        <th scope="col">Rabatt</th>
                        <th scope="col">Preis reduziert</th>
                        <th scope="col"></th>
                    </tr>
                </thead>
                <tbody>
                <template v-for="position in affiliateRequestPositions">
                    <tr>
                        <td class="align-middle">{{ position.item.brand.name }} {{ position.item.name }}</td>
                        <td class="align-middle">{{ position.quantity }}</td>
                        <td class="align-middle">{{ position.item.prices['priceDisplay'].toFixed(2) }} €</td>
                        <td class="align-middle">{{ position.discount.toFixed(2) }} €</td>
                        <td class="align-middle">{{ (position.item.prices['priceDisplay'] - position.discount).toFixed(2) }} €</td>
                        <td class="align-middle">
                            <button class="btn btn-outline-primary w-100" :disabled="affiliateRequest.approved_at || affiliateRequest.declined_at" @click.prevent="edit(position)">
                                <i class="fas fa-edit"></i>
                            </button>
                        </td>
                    </tr>
                    <template v-if="position.item.promotions.length || Object.keys(position.item.promotions).length">
                        <tr>
                            <td class="text-muted" colspan="7" style="box-shadow: unset;background-color: rgb(32 44 49)">
                                <span class="fw-bold">Laufende Aktionen zum Artikel*</span>
                            </td>
                        </tr>
                        <tr v-for="(promo, index) in position.item.promotions">
                            <td class="text-muted" colspan="7" style="box-shadow: unset;background-color: rgb(32 44 49)">
                                <span class="fw-bold">[AKTION] </span> {{ promo.brand_promotion ? promo.brand_promotion.name : '' }} _ {{ promo.discount ? promo.discount.toFixed(2) : 'NaN' }} €
                            </td>
                        </tr>
                    </template>
                </template>
                </tbody>
            </table>
            <p class="mb-0 text-muted">*werden im Warenkorb abgezogen, wenn Angebot im Zeitraum beansprucht wird!</p>
        </div>
        <affiliates-price-change-modal :editPosition="editPosition" @update="update"></affiliates-price-change-modal>
        <loading-modal :text="'Lade..'" :active="isLoading" v-if="isLoading"></loading-modal>
    </div>
</template>
<script>
export default {
    props: [
        'affiliateRequest'
    ],

    data() {
        return {
            canSave: false,
            hasErrors: false,
            errors: {},
            isLoading: false,

            affiliateRequestPositions: [],

            message: '',

            requested: false,
            offerNo: null,

            editPosition: {
                name: '',
                quantity: 0,
                discount: 0
            },
            priceChangeModal: null,
            promoTypes: [
                'instant_discount'
            ],
        }
    },

    mounted() {
        this.affiliateRequestPositions = this.affiliateRequest.positions
        this.priceChangeModal  = new bootstrap.Modal(document.getElementById('price-change-modal'))
    },

    methods: {
        fetch() {
            axios.get('/api/backend/affiliates/requests/' + this.affiliateRequest.id + '/positions')
            .then(response => {
                if (response.status === 200) {
                    this.affiliateRequestPositions = response.data
                }
            })
        },
        refresh() {
            this.fetch()
        },
        edit(position) {
            this.editPosition = position
            this.priceChangeModal.show()
        },
        update(positionID, discount) {
            this.priceChangeModal.hide()
            axios.patch('/api/backend/affiliates/requests/positions/' + positionID, {
                discount: discount
            })
                .then(response => {
                    if (response.status === 200) {
                        this.fetch()
                    }
                })
        },
        submit() {
            this.isLoading = true
            this.hasErrors = false
            this.error = {}
            axios.post('/affiliate/' + this.affiliateForm.id, {
                items: this.items,
                customer: this.customer,
                message: this.message,
                associations: this.associations
            })
                .then(response => {
                    if (response.status === 200) {
                        this.requested = true
                        this.offerNo = response.data.offerNo
                    }
                })
                .catch(error => {
                    if (error.response.data.hasOwnProperty('errors')) {
                        this.hasErrors = true
                        this.errors = error.response.data.errors
                    }
                })
                .finally(() => {
                    this.selected = null
                    this.isLoading = false
                })


        },
        itemAdd(item) {

            let foundIndex = this.items.findIndex(e => e.id === item.id)

            if (foundIndex >= 0) {
                this.items[foundIndex].quantity++;
            } else {
                this.items.push({
                    id: item.id,
                    image: item.image,
                    name: item.name,
                    quantity: 1
                })
            }
        },
        itemRemove(key) {
            this.$delete(this.items, key)
        },
    },
}
</script>
