<template>
    <div style="margin-bottom: -1.25rem;">
        <div class="container-fluid bg-primary">
            <div class="container pt-3 pb-2 cursor-pointer" data-bs-toggle="collapse" data-bs-target="#reviewsCollapse" role="button" aria-expanded="false" aria-controls="reviewsCollapse">
                <div class="row" id="itemReviewsStart">
                    <div class="col text-center fw-bold h6">
                        <i class="fas fa-star review-star-full"></i>
                        <i class="fas fa-star review-star-full"></i>
                        <i class="fas fa-star review-star-full"></i>
                        <i class="fas fa-star review-star-full"></i>
                        <i class="fas fa-star review-star-full"></i>
                    </div>
                </div>
                <div class="row">
                    <div class="col text-center fw-bold h5">Produktbewertungen anzeigen</div>
                </div>
            </div>
        </div>
        <div class="container-fluid bg-primary collapse px-0" id="reviewsCollapse">
            <div class="hr-arrow-down"></div>
            <div class="container pb-3 pt-3">
                <div class="row">
                    <div class="col">
                        <p class="fw-bold h3 text-uppercase mb-0">Produktbewertungen</p>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-lg-6">
                        <div class="fw-bold display-6 py-3">
                            <item-rating-stars :rating="rating" :with-text="true"/>
                        </div>
                        <button class="btn btn-secondary btn-lg w-100 mt-4 review-btn" @click.prevent="openNewReviewModal">Jetzt bewerten</button>
                    </div>
                    <div class="col-12 col-lg-6">
                        <div class="row align-items-center fw-light">
                            <div class="col-3 col-xl-2">
                                <p class="mb-0 review-star-txt">5 Sterne</p>
                            </div>
                            <div class="col-7 col-xl-8 px-0">
                                <div class="progress" style="height: 1rem;">
                                    <div class="progress-bar" role="progressbar" :style="'width: ' + ratings[5]['percent'] + '%'" :aria-valuenow="ratings[5]['percent']" aria-valuemin="0" aria-valuemax="100" aria-label="Anzahl fünf Sterne"></div>
                                </div>
                            </div>
                            <div class="col-2">
                                <p class="mb-0 review-star-txt">({{ ratings['5']['count'] }})</p>
                            </div>
                        </div>
                        <div class="row align-items-center fw-light">
                            <div class="col-3 col-xl-2">
                                <p class="mb-0 review-star-txt">4 Sterne</p>
                            </div>
                            <div class="col-7 col-xl-8 px-0">
                                <div class="progress" style="height: 1rem;">
                                    <div class="progress-bar" role="progressbar" :style="'width: ' + ratings[4]['percent'] + '%'" :aria-valuenow="ratings[4]['percent']" aria-valuemin="0" aria-valuemax="100" aria-label="Anzahl vier Sterne"></div>
                                </div>
                            </div>
                            <div class="col-2">
                                <p class="mb-0 review-star-txt">({{ ratings['4']['count'] }})</p>
                            </div>
                        </div>
                        <div class="row align-items-center fw-light">
                            <div class="col-3 col-xl-2">
                                <p class="mb-0 review-star-txt">3 Sterne</p>
                            </div>
                            <div class="col-7 col-xl-8 px-0">
                                <div class="progress" style="height: 1rem;">
                                    <div class="progress-bar" role="progressbar" :style="'width: ' + ratings[3]['percent'] + '%'" :aria-valuenow="ratings[3]['percent']" aria-valuemin="0" aria-valuemax="100" aria-label="Anzahl drei Sterne"></div>
                                </div>
                            </div>
                            <div class="col-2">
                                <p class="mb-0 review-star-txt">({{ ratings['3']['count'] }})</p>
                            </div>
                        </div>
                        <div class="row align-items-center fw-light">
                            <div class="col-3 col-xl-2">
                                <p class="mb-0 review-star-txt">2 Sterne</p>
                            </div>
                            <div class="col-7 col-xl-8 px-0">
                                <div class="progress" style="height: 1rem;">
                                    <div class="progress-bar" role="progressbar" :style="'width: ' + ratings[2]['percent'] + '%'" :aria-valuenow="ratings[2]['percent']" aria-valuemin="0" aria-valuemax="100" aria-label="Anzahl zwei Sterne"></div>
                                </div>
                            </div>
                            <div class="col-2">
                                <p class="mb-0 review-star-txt">({{ ratings['2']['count'] }})</p>
                            </div>
                        </div>
                        <div class="row align-items-center fw-light">
                            <div class="col-3 col-xl-2">
                                <p class="mb-0 review-star-txt">1 Stern</p>
                            </div>
                            <div class="col-7 col-xl-8 px-0">
                                <div class="progress" style="height: 1rem;">
                                    <div class="progress-bar" role="progressbar" :style="'width: ' + ratings[1]['percent'] + '%'" :aria-valuenow="ratings[1]['percent']" aria-valuemin="0" aria-valuemax="100" aria-label="Anzahl ein Stern"></div>
                                </div>
                            </div>
                            <div class="col-2">
                                <p class="mb-0 review-star-txt">({{ ratings['1']['count'] }})</p>
                            </div>
                        </div>
                    </div>
                </div>

                <transition name="fade" tag="div" class="row">
                    <div v-if="initialLoading" class="col d-flex justify-content-center py-5">
                        <div  class="spinner-grow" style="width: 3rem; height: 3rem;" role="status">
                            <span class="visually-hidden">Lädt...</span>
                        </div>
                    </div>
                </transition>


                <transition-group name="fade" tag="div" class="row pt-4">
                    <div v-if="!initialLoading" v-for="(review, index) in reviews" :key="review.id" class="col-12 col-lg-6">
                        <div class="card review-box-bg p-3 mb-3" itemprop="review" itemscope itemtype="https://schema.org/Review">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-12 mb-2">
                                        <i v-for="index in review.rating" class="fas fa-star review-star-full"></i>
                                        <i v-for="index in (5 - review.rating)" class="fas fa-star review-star-empty"></i>

                                        <div class="visually-hidden" itemprop="reviewRating" itemscope itemtype="https://schema.org/Rating">
                                            <meta itemprop="worstRating" content="1">
                                            <meta itemprop="ratingValue" :content="review.rating">
                                            <meta itemprop="bestRating" content="5">
                                        </div>
                                    </div>
                                    <div class="col-12 mb-2">
                                            <template v-if="review.text.length > 280">
                                                <p class="mb-0" itemprop="reviewBody">{{ review.text.substring(0, 280) }}...</p>
                                            </template>
                                            <template v-else>
                                                <p class="mb-0" itemprop="reviewBody">{{ review.text }}</p>
                                            </template>
                                    </div>
                                    <div class="col-12">
                                        <div class="row">
                                            <div class="col">
                                                <p class="mb-0 fw-bold">{{ review.created_at }} / <span itemprop="author" itemscope itemtype="https://schema.org/Person"><span itemprop="name">{{ review.name }}</span></span></p>
                                                <meta itemprop="datePublished" :content="review.created_at">
                                            </div>
                                            <div class="col-auto text-end" v-if="review.text.length > 280">
                                                <button type="button" class="btn btn-secondary review-read-btn mt-2" @click.prevent="readReview(index)">Ganze Bewertung lesen</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition-group>


                <div class="row">
                    <div class="col flex-column text-center pt-4">
                        <template v-if="countFetchable > 0">
                            <div class="row">
                                <div class="col-12">
                                    <p class="mb-0 h5">Mehr Bewertungen laden <span class="fw-light">{{ actualFetch }} von {{ countReviews }}</span></p>
                                </div>
                                <div class="col-12">
                                    <button class="btn btn-lg btn-outline-secondary text-white rounded-4 border-0" style="font-size: 2rem" @click.prevent="fetchReviews()">
                                        <i class="fas fa-chevron-down"></i>
                                    </button>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal -->
        <div :id="'modal-new-review'" class="modal modal-xl fade" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style="background-color: #101010">
                    <div class="modal-body py-0">
                        <div class="row">
                            <div class="d-none d-xl-block col-xl-6 bg-primary"></div>
                            <div class="col-12 col-xl-6 pt-3">
                                <div class="row">
                                    <div class="col-6 ps-4">
                                        <p class="fw-bold my-2 h4">Deine Bewertung</p>
                                    </div>
                                    <div class="col-6 text-end">
                                        <button type="button" class="btn-close h4" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="d-none d-xl-flex flex-column col-xl-6 bg-primary align-items-center p-5 pt-0">
                                <div class="card mb-4 col-7">
                                    <div class="card-body">
                                        <img :data-src="imgLink" alt="" class="lazy img-fluid">
                                    </div>
                                </div>
                                <div class="card review-box-bg p-3 review-card">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-12 mb-2">
                                                <i v-for="index in newReview.rating" class="fas fa-star review-star-full"></i>
                                                <i v-for="index in (5 - newReview.rating)" class="fas fa-star review-star-empty"></i>
                                            </div>
                                            <div class="col-12 mb-2">
                                                <p class="mb-0">{{ newReview.text }}</p>
                                            </div>
                                            <div class="col-12">
                                                <p class="mb-0 fw-bold">{{ today }} / {{ newReview.name }}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <template v-if="!isSending && !submitted">
                                <div class="col-12 col-xl-6 px-4 pt-3">
                                    <p class="mb-0 fw-bold">Wie viele Sterne gibst dem Produkt?</p>
                                    <div class="ac-star-rating_container p-2 mb-3">
                                        <div class="ac-star-rating">
                                            <label class="ac-star-rating-star" v-for="rating in ratingRange"
                                                   :class="{'is-selected': ((ratingHoverValue >= rating) && ratingHoverValue != null)}"
                                                   v-on:click="setRating(rating)" v-on:mouseover="starOver(rating)" v-on:mouseout="starOut">
                                                <i class="fas fa-star ac-star-rating-star"></i>
                                            </label>
                                        </div>
                                    </div>
                                    <p class="mb-0 mb-2 fw-bold">Beschreibe deine Produkt-Erfahrung!</p>
                                    <div class="form-floating mb-3">
                                        <textarea
                                            placeholder="Bewertungstext"
                                            class="form-control is-required"
                                            :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('text'))}"
                                            name="text"
                                            id="inputText"
                                            style="height: 10rem"
                                            v-model="newReview.text"
                                        ></textarea>
                                        <label class="required fw-bold" for="inputText">Bewertungstext</label>
                                        <div v-if="hasErrors && errors.hasOwnProperty('text')" class="invalid-feedback">
                                            {{ errors['text'][0] }}
                                        </div>
                                    </div>

                                    <div class="form-floating mb-3">
                                        <input type="text" id="inputName" class="form-control is-required" autocomplete="full_name"
                                               :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('name'))}" placeholder="Ihr Name oder Pseudonym" name="name"
                                               v-model="newReview.name">
                                        <label for="inputName" class="required fw-bold">Ihr Name oder Pseudonym</label>
                                        <div v-if="hasErrors && errors.hasOwnProperty('name')" class="invalid-feedback">
                                            {{ errors['name'][0] }}
                                        </div>
                                        <p class="text-tiny text-info px-2">Ihr Name wird nur in der Bewertung angezeigt.</p>
                                    </div>
                                    <div class="form-floating mb-3">
                                        <input type="text" id="inputEmail" class="form-control is-required" autocomplete="email_address"
                                               :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('email'))}" placeholder="E-Mail-Adresse (Wird nicht veröffentlicht)" name="email"
                                               v-model="newReview.email">
                                        <label for="inputEmail" class="required fw-bold">E-Mail-Adresse (Wird nicht veröffentlicht)</label>
                                        <div v-if="hasErrors && errors.hasOwnProperty('email')" class="invalid-feedback">
                                            {{ errors['email'][0] }}
                                        </div>
                                        <p class="text-tiny text-info px-2">Deine Email-Adresse wird nur zur Bestätigung der Bewertung genutzt.</p>
                                    </div>
                                    <div class="row pb-5 pt-3">
                                        <div class="col text-center">
                                            <button type="button" class="btn btn-primary btn-lg rounded-4 fw-bold text-uppercase" @click.prevent="submitReview">Absenden</button>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-if="isSending">
                                <div class="col-12 col-xl-6 px-4 pt-3 d-flex flex-column justify-content-center text-center">
                                    <div class="row">
                                        <div class="col">
                                            <div  class="spinner-grow" style="width: 6rem; height: 6rem;" role="status">
                                                <span class="visually-hidden">Lädt...</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <p class="mb-0 fw-bold h5">Deine Bewertung wird übermittelt...</p>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <template v-if="!isSending && submitted">
                                <div class="col-12 col-xl-6 px-4 pt-3 d-flex flex-column justify-content-center text-center">
                                    <div class="row mb-3">
                                        <div class="col">
                                            <p class="mb-5 text-center h4 fw-bold">Vielen Dank für deine Bewertung!</p>
                                            <p class="mb-2 px-3 fw-bold">Wir haben dir eine E-Mail an “{{ newReview.email }}” gesendet.</p>
                                            <p class="mb-2 px-3">
                                                Bitte bestätige deine Bewertung durch einfaches Klicken des Bestätigungslink in dieser E-mail. Die Bestätigung ist notwendig, um eine missbräuchliche Verwendung deiner Mailaddresse zu verhindern.
                                            </p>
                                            <p class="mb-4 px-3">
                                                Wir benachrichtigen dich per E-Mail, sobald deine Bewertung veröffentlicht wurde. Wir behalten uns die redaktionelle Prüfung und Onlinestellung der Produktbewertungen vor.
                                            </p>
                                        </div>
                                    </div>
                                    <div class="row text-center">
                                        <div class="col">
                                            <button type="button" class="btn btn-primary btn-lg rounded-4 fw-bold text-uppercase" @click.prevent="closeNewReviewModal">weiter bei AC-Foto einkaufen</button>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div :id="'modal-read-review'" class="modal modal-xl fade" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content" style="background-color: #AD2928"  v-if="readReviewIndex !== null">
                    <div class="modal-header border-0 px-5 py-4 fs-4">
                        <p class="mb-0 fw-bold">
                            <i v-for="index in this.reviews[readReviewIndex].rating" class="fas fa-star review-star-full"></i>
                            <i v-for="index in (5 - this.reviews[readReviewIndex].rating)" class="fas fa-star review-star-empty"></i>
                            <span class="ps-2"> Bewertung von {{ this.reviews[readReviewIndex].name }}</span>
                        </p>
                        <button type="button" class="btn-close" @click.prevent="closeReadReviewModal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body p-5 pt-0 fs-5">
                        <p class="mb-0">{{ this.reviews[readReviewIndex].text }}</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- Modal -->
    </div>
</template>
<script>
    export default {
        props: [
            'ratings',
            'rating',
            'itemId',
            'images'
        ],
        data() {
            return {
                initialLoading: false,

                isSending: false,
                submitted: false,

                imgLink: null,
                reviews: [],

                ratingRange: [1, 2, 3, 4, 5],
                ratingHoverValue: 5,
                tmpRatingValue: null,
                newReview: {
                    name: '',
                    email: '',
                    text: '',
                    rating: 5
                },
                readReviewIndex: null,

                hasErrors: false,
                errors: [],

                modalNewReview: null,
                bsModalNewReview: null,
                modalReadReview: null,
                bsModalReadReview: null,
                reviewsCollapsible: null,
                firstFetch: true,
                countReviews: 0,
                countFetchable: 0,
                countFetch: 5,
                currentFetch: 0,
                actualFetch: 0,

            }
        },
        mounted() {
            this.modalNewReview   = document.getElementById('modal-new-review')
            this.modalReadReview   = document.getElementById('modal-read-review')
            this.bsModalNewReview = new bootstrap.Modal(this.modalNewReview, {backdrop: 'static'})
            this.bsModalReadReview = new bootstrap.Modal(this.modalReadReview)

            if (this.images.length) {
                this.imgLink = '/storage/img/uploads/products/' + this.itemId + '/' + this.images[0].filename
            }

            this.reviewsCollapsible = new bootstrap.Collapse('#reviewsCollapse',{toggle: false})

            if (this.firstFetch && this.rating > 0) {
                this.reviewsCollapsible.show()
                this.initialLoading = true
                this.fetchReviews()
            }
        },
        methods: {
            openNewReviewModal() {
                this.bsModalNewReview.show()
            },
            closeNewReviewModal() {
                this.bsModalNewReview.hide()
                this.newReview = {
                    name: '',
                    email: '',
                    text: '',
                    rating: 1
                }
            },
            submitReview() {
                this.isSending = true
                this.hasErrors = false

                axios.post('/api/item/reviews/new', {
                    name: this.newReview.name,
                    email: this.newReview.email,
                    text: this.newReview.text,
                    rating: this.newReview.rating,
                    itemID: this.itemId,
                })
                .then(response => {
                    if (response.status === 200 && !response.data.error) {
                        setTimeout(() => {
                            this.submitted = true
                            this.isSending = false
                        }, 1500)
                    }
                })
                .catch(error => {
                    if (error.response.data.hasOwnProperty('errors')) {
                        this.hasErrors = true
                        this.errors = error.response.data.errors
                        this.isSending = false
                    }
                })
                .finally(() => {
                })
            },
            fetchReviews() {
                axios.post('/api/item/reviews', {
                    itemID: this.itemId,
                    firstFetch: this.firstFetch,
                    currentFetch: this.currentFetch,
                    countFetch: this.countFetch
                })
                .then(response => {
                    if (this.firstFetch) {
                        this.initialLoading = false
                    }
                    this.firstFetch = false
                    this.currentFetch = this.currentFetch + this.countFetch

                    this.reviews = this.reviews.concat(response.data.data)
                    this.countFetchable = response.data.count - this.currentFetch
                    this.countReviews = response.data.count
                    this.actualFetch = this.reviews.length
                })
            },
            starOver(rating) {
                this.tmpRatingValue = this.ratingHoverValue
                this.ratingHoverValue = rating

            },
            starOut() {
                this.ratingHoverValue = this.tmpRatingValue
            },
            setRating(rating) {
                this.tmpRatingValue = rating
                this.ratingHoverValue = rating
                this.newReview.rating = rating
            },
            readReview(reviewIndex) {
                this.readReviewIndex = reviewIndex
                this.bsModalReadReview.show()
            },
            closeReadReviewModal() {
              this.bsModalReadReview.hide()
            }
        },
        watch: {

        },
        computed: {
            today() {
                let date = new Date()
                return ('0' + date.getDate()).slice(-2) + '.' + ('0' + (date.getMonth()+1)).slice(-2) + '.' + date.getFullYear()
            }
        }
    }
</script>
<style scoped>
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
.list-enter-active, .list-leave-active {
    transition: all 1s;
}
.list-enter, .list-leave-to /* .list-leave-active below version 2.1.8 */ {
    opacity: 0;
    transform: translateY(30px);
}
</style>
