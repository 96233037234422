<template>
    <div class="container">
        <div class="row">
            <div :class="{'col-sm-12 col-md-6 col-12 pb-4': !onlyWorkshops, 'col-12 pb-4': onlyWorkshops}">
                <div class="card">
                    <div class="card-header">
                        <div class="row align-items-center">
                            <div class="col"><h4 class="mt-2">Rechnungsadresse</h4></div>
                            <div class="col-auto text-end">
                                <button v-if="auth" @click.prevent="setTestData" class="btn btn-info btn-sm">Testdaten eintragen</button>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <input v-if="onlyWorkshops" type="hidden" name="delivery_address_type" value="billing-address">
                        <div class="form-floating mb-3">
                            <input type="email" id="inputEmail" class="form-control is-required" autocomplete="email"
                                   :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('billing.email'))}" placeholder="E-Mail-Adresse" name="billing[email]"
                                   v-model="billing.email" :readonly="partnerDisable">
                            <label for="inputEmail" class="required">E-Mail-Adresse</label>
                            <div v-if="hasErrors && errors.hasOwnProperty('billing.email')" class="invalid-feedback">
                                {{ errors['billing.email'][0] }}
                            </div>
                        </div>
                        <div class="form-floating mb-3">
                            <input type="text" id="inputCustomerNo" class="form-control" placeholder="Kundennummer" name="billing[customer_no]"
                                   v-model="billing.customer_no" maxlength="254">
                            <label for="inputCustomerNo">Kundennummer</label>
                        </div>
                        <div class="form-floating mb-3">
                            <input type="text" id="inputTelephone" class="form-control" autocomplete="tel"
                                   :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('billing.phone'))}" placeholder="Telefonnummer" name="billing[phone]"
                                   v-model="billing.phone">
                            <label for="inputTelephone" class="required">Telefonnummer</label>
                            <div v-if="hasErrors && errors.hasOwnProperty('billing.phone')" class="invalid-feedback">
                                {{ errors['billing.phone'][0] }}
                            </div>
                        </div>
                        <div class="form-floating mb-3">
                            <select class="form-select is-required"
                                    :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('billing.salutation'))}" id="inputSalutation" name="billing[salutation]"
                                    v-model="billing.salutation" :readonly="salutationLock">
                                <option value="null">Keine Anrede</option>
                                <option value="female">Frau</option>
                                <option value="male">Herr</option>
                            </select>
                            <label for="inputSalutation" class="required">Anrede</label>
                            <div v-if="hasErrors && errors.hasOwnProperty('billing.salutation')" class="invalid-feedback">
                                {{ errors['billing.salutation'][0] }}
                            </div>
                        </div>
                        <div class="row mb-3 g-3">
                            <div class="col-md">
                                <div class="form-floating">
                                    <input data-was="pattern-" type="text" id="inputFirstName" class="form-control is-required" autocomplete="given-name"
                                           :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('billing.first_name'))}" placeholder="Vorname" name="billing[first_name]"
                                           :regReplace="''"
                                           :regExp="/[^a-zA-ZäöüÄÖÜß\- .]/"
                                           v-model="billing.first_name" value="" :readonly="partnerDisable">

                                    <label for="inputFirstName" class="required">Vorname</label>
                                    <div v-if="hasErrors && errors.hasOwnProperty('billing.first_name')" class="invalid-feedback">
                                        {{ errors['billing.first_name'][0] }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="form-floating">
                                    <input data-was="pattern-" type="text" id="inputLastName" class="form-control is-required" autocomplete="family-name"
                                           :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('billing.last_name'))}" placeholder="Nachname" name="billing[last_name]"
                                           :regReplace="''"
                                           :regExp="/[^a-zA-ZäöüÄÖÜß\- .]/"
                                           v-model="billing.last_name" value="" :readonly="partnerDisable">

                                    <label for="inputLastName" class="required">Nachname</label>
                                    <div v-if="hasErrors && errors.hasOwnProperty('billing.last_name')" class="invalid-feedback">
                                        {{ errors['billing.last_name'][0] }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-floating mb-3">
                            <input type="text" id="inputCompany" class="form-control" placeholder="Firma" name="billing[company]"
                                   v-model="billing.company">
                            <label for="inputCompany">Firma (optional)</label>
                        </div>
                        <div class="row mb-3 g-3">
                            <div class="col-md">
                                <div class="form-floating">
                                    <input type="text" id="inputStreet" class="form-control is-required"
                                           :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('billing.street'))}" placeholder="Straße" name="billing[street]"
                                           v-model="billing.street" :readonly="partnerDisable">
                                    <label for="inputStreet" class="required">Straße</label>
                                    <div v-if="hasErrors && errors.hasOwnProperty('billing.street')" class="invalid-feedback">
                                        {{ errors['billing.street'][0] }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-md">
                                <div class="form-floating">
                                    <input type="text" id="inputStreetNo" class="form-control is-required"
                                           :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('billing.house_no'))}" placeholder="Hausnummer" name="billing[house_no]"
                                           v-model="billing.house_no" :readonly="partnerDisable">
                                    <label for="inputStreetNo" class="required">Hausnummer</label>
                                    <div v-if="hasErrors && errors.hasOwnProperty('billing.house_no')" class="invalid-feedback">
                                        {{ errors['billing.house_no'][0] }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-floating mb-3">
                            <input type="text" id="inputAddressAdditional" class="form-control" placeholder="Adresszusatz (optional)" name="billing[additional_address_information]" autocomplete="new-AddressAdditional_1"
                                   v-model="billing.additional_address_information">
                            <label for="inputAddressAdditional">Adresszusatz (optional)</label>
                        </div>
                        <div class="row mb-3 g-3">
                            <div class="col-md">
                                <div class="form-floating">
                                    <input data-was="pattern-" type="text" id="inputPostcode" class="form-control is-required" autocomplete="postal-code"
                                           :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('billing.zip'))}" placeholder="Postleitzahl" name="billing[zip]"
                                           :regReplace="''"
                                           :regExp="patternZip"
                                           v-model="billing.zip" value="" :readonly="partnerDisable">

                                    <label for="inputPostcode" class="required">Postleitzahl</label>
                                    <div v-if="hasErrors && errors.hasOwnProperty('billing.zip')" class="invalid-feedback">
                                        {{ errors['billing.zip'][0] }}
                                    </div>
                                </div>
                            </div>
                            <div class="col-md">
                                <auto-complete
                                    :setClass="'form-floating'"
                                    :name="'billing[city]'"
                                    :id="'inputCity'"
                                    :suggestions="billingCityEntries"
                                    v-model="billing.city"
                                    :label="'Ort'"
                                    :partnerDisable="partnerDisable"
                                    :hasError="hasErrors && errors.hasOwnProperty('billing.city')"
                                    :error="(hasErrors && errors.hasOwnProperty('billing.city')) ? errors['billing.city'][0] : ''">
                                </auto-complete>
                            </div>
                        </div>
                        <div class="form-floating mb-3">
                            <select class="form-select is-required"
                                    :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('billing.country'))}" id="inputCountry" name="billing[country]"
                                    v-model="billing.country" :disabled="partnerDisable">
                                <option disabled selected>Auswählen / Select</option>
                                <option v-for="country in countries" :value="country.id" :selected="country.id === billing.country" :data-eu="country.eu" :data-code="country.iso_code">{{ country.name }}</option>
                            </select>
                            <input v-if="partnerDisable" type="hidden" name="billing[country]" v-model="billing.country">
                            <label for="inputCountry" class="required">Land / Country</label>
                            <div v-if="hasErrors && errors.hasOwnProperty('billing.country')" class="invalid-feedback">
                                {{ errors['billing.country'][0] }}
                            </div>
                        </div>
                        <div class="form-floating mb-3" v-if="vatId">
                            <input placeholder="USt. ID / VAT No. (optional)" data-bs-toggle="tooltip" title="" name="billing[vat_id]" type="text" class="form-control" id="inputVatID"
                                   data-original-title="Bitte beachte, bei einer steuerfreien Lieferung in das EU Ausland sind wir verpflichtet an die Rechnungsadresse zu liefern. Eine Lieferung an eine abweichende Lieferanschrift ist in diesem Fall nicht möglich."
                                   v-model="billing.vat_id">
                            <label for="inputVatID" class="">USt. ID / VAT No. (optional)</label>
                        </div>
                        <div class="row">
                            <div class="col-12">
                                <p class="mb-0"><i class="fa fa-star required-color" aria-hidden="true"></i> Pflichtfeld</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-6 col-12 pb-4" v-if="!onlyWorkshops">
                <div class="card">
                    <div class="card-header">
                        <h4 class="mt-2">Abweichende Lieferanschrift</h4>
                    </div>
                    <div class="card-body">
                        <div class="form-floating mb-3">
                            <select class="form-select" id="inputDelivery" name="delivery_option" v-model="deliveryOption" :disabled="deliveryDisabled" :readonly="partnerDisable">
                                <option v-for="deliveryOption in deliveryOptions" :value="deliveryOption.index" :disabled="deliveryOption.disabled">{{ deliveryOption.name }}</option>
                            </select>
                            <label for="inputDelivery">Lieferadresse</label>
                        </div>
                        <template v-if="deliveryOption === 'billing'">
                            <div class="alert alert-info">
                                Die Lieferung erfolgt an die Rechnungsadresse.
                            </div>
                            <div class="alert alert-warning" v-if="deliveryDisabled">
                                Bei Angabe einer USt. ID muss an die Rechnungsadresse geliefert werden
                            </div>
                        </template>
                        <template v-if="deliveryOption === 'address'">
                            <div class="form-floating mb-3">
                                <select class="form-select is-required" id="inputSalutation_delivery" name="delivery[salutation]"
                                        :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.salutation'))}"
                                        v-model="delivery.salutation">
                                    <option value="null">Keine Anrede</option>
                                    <option value="female">Frau</option>
                                    <option value="male">Herr</option>
                                </select>
                                <label for="inputSalutation_delivery" class="required">Anrede</label>
                                <div v-if="hasErrors && errors.hasOwnProperty('delivery.salutation')" class="invalid-feedback">
                                    {{ errors['delivery.salutation'][0] }}
                                </div>
                            </div>
                            <div class="row mb-3 g-3">
                                <div class="col-md">
                                    <div class="form-floating">
                                        <input data-was="pattern-" type="text" id="inputFirstName_delivery" class="form-control is-required" placeholder="Vorname" name="delivery[first_name]"
                                               :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.first_name'))}"
                                               :regReplace="''"
                                               :regExp="/[^a-zA-ZäöüÄÖÜß\- .]/"
                                               v-model="delivery.first_name" value="">

                                        <label for="inputFirstName_delivery" class="required">Vorname</label>
                                        <div v-if="hasErrors && errors.hasOwnProperty('delivery.first_name')" class="invalid-feedback">
                                            {{ errors['delivery.first_name'][0] }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md">
                                    <div class="form-floating">
                                        <input data-was="pattern-" type="text" id="inputLastName_delivery" class="form-control is-required" placeholder="Nachname" name="delivery[last_name]"
                                               :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.last_name'))}"
                                               :regReplace="''"
                                               :regExp="/[^a-zA-ZäöüÄÖÜß\- .]/"
                                               v-model="delivery.last_name" value="">

                                        <label for="inputLastName_delivery" class="required">Nachname</label>
                                        <div v-if="hasErrors && errors.hasOwnProperty('delivery.last_name')" class="invalid-feedback">
                                            {{ errors['delivery.last_name'][0] }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-floating mb-3">
                                <input type="text" id="inputCompany_delivery" class="form-control" placeholder="Firma (optional)" name="delivery[company]"
                                       v-model="delivery.company">
                                <label for="inputCompany_delivery">Firma (optional)</label>
                            </div>
                            <div class="row mb-3 g-3">
                                <div class="col-md">
                                    <div class="form-floating">
                                        <input type="text" id="inputStreet_delivery" class="form-control is-required" placeholder="Straße" name="delivery[street]"
                                               :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.street'))}"
                                               v-model="delivery.street">
                                        <label for="inputStreet_delivery" class="required">Straße</label>
                                        <div v-if="hasErrors && errors.hasOwnProperty('delivery.street')" class="invalid-feedback">
                                            {{ errors['delivery.street'][0] }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md">
                                    <div class="form-floating">
                                        <input type="text" id="inputStreetNo_delivery" class="form-control is-required" placeholder="Hausnummer" name="delivery[house_no]"
                                               :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.house_no'))}"
                                               v-model="delivery.house_no">
                                        <label for="inputStreetNo_delivery" class="required">Hausnummer</label>
                                        <div v-if="hasErrors && errors.hasOwnProperty('delivery.house_no')" class="invalid-feedback">
                                            {{ errors['delivery.house_no'][0] }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-floating mb-3">
                                <input type="text" id="inputAddressAdditional_delivery" class="form-control" placeholder="Adresszusatz (optional)" name="delivery[additional_address_information]"
                                       v-model="delivery.additional_address_information">
                                <label for="inputAddressAdditional_delivery">Adresszusatz (optional)</label>
                            </div>
                            <div class="row mb-3 g-3">
                                <div class="col-md">
                                    <div class="form-floating">
                                        <input type="text" id="inputPostcode_delivery" class="form-control is-required" placeholder="Postleitzahl" name="delivery[zip]"
                                               :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.zip'))}"
                                               v-model="delivery.zip">
                                        <label for="inputPostcode_delivery" class="required">Postleitzahl</label>
                                        <div v-if="hasErrors && errors.hasOwnProperty('delivery.zip')" class="invalid-feedback">
                                            {{ errors['delivery.zip'][0] }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md">
                                    <div class="form-floating">
                                        <input type="text" id="inputCity_delivery" class="form-control is-required" placeholder="Ort" name="delivery[city]"
                                               :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.city'))}"
                                               v-model="delivery.city">
                                        <label for="inputCity_delivery" class="required">Ort</label>
                                        <div v-if="hasErrors && errors.hasOwnProperty('delivery.city')" class="invalid-feedback">
                                            {{ errors['delivery.city'][0] }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-floating mb-3">
                                <select class="form-select is-required" id="inputCountry_delivery" name="delivery[country]"
                                        :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.country'))}"
                                        v-model="delivery.country">
                                    <option disabled selected>Auswählen</option>
                                    <option v-for="country in countries" :value="country.id" :disabled="country.hasOwnProperty('deliveryDisabled') && country.deliveryDisabled" :data-eu="country.eu" :data-code="country.iso_code">{{ country.name }}</option>
                                </select>
                                <label for="inputCountry_delivery" class="required">Land</label>
                                <div v-if="hasErrors && errors.hasOwnProperty('delivery.country')" class="invalid-feedback">
                                    {{ errors['delivery.country'][0] }}
                                </div>
                            </div>
                        </template>
                        <template v-if="deliveryOption === 'packstation'">
                            <div class="alert alert-warning">
                                <strong>Bitte beachte:</strong> die Lieferung an eine Packstation ist nur bei Versand mit DHL möglich.
                                Weiterhin besteht die Möglichkeit, dass Sendungen an eine Postfiliale geliefert werden. Z.B. wenn das Paket zu groß ist für die
                                Fächer der Packstation, die Kapazität der Packstation erschöpft ist, oder die Packstation defekt ist.
                            </div>
                            <div class="form-floating mb-3">
                                <select class="form-select is-required" id="inputSalutation_delivery-packstation" name="delivery[salutation]"
                                        :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.salutation'))}"
                                        v-model="delivery.salutation">
                                    <option value="null">Keine Anrede</option>
                                    <option value="female">Frau</option>
                                    <option value="male">Herr</option>
                                </select>
                                <label for="inputSalutation_delivery-packstation" class="required">Anrede</label>
                                <div v-if="hasErrors && errors.hasOwnProperty('delivery.salutation')" class="invalid-feedback">
                                    {{ errors['delivery.salutation'][0] }}
                                </div>
                            </div>
                            <div class="row mb-3 g-3">
                                <div class="col-md">
                                    <div class="form-floating">
                                        <input data-was="pattern-" type="text" id="inputFirstName_delivery-packstation" class="form-control is-required" placeholder="Vorname" name="delivery[first_name]"
                                               :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.first_name'))}"
                                               :regReplace="''"
                                               :regExp="/[^a-zA-ZäöüÄÖÜß\- .]/"
                                               v-model="delivery.first_name" value="">

                                        <label for="inputFirstName_delivery-packstation" class="required">Vorname</label>
                                        <div v-if="hasErrors && errors.hasOwnProperty('delivery.first_name')" class="invalid-feedback">
                                            {{ errors['delivery.first_name'][0] }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md">
                                    <div class="form-floating">
                                        <input data-was="pattern-" type="text" id="inputLastName_delivery-packstation" class="form-control is-required" placeholder="Nachname" name="delivery[last_name]"
                                               :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.last_name'))}"
                                               :regReplace="''"
                                               :regExp="/[^a-zA-ZäöüÄÖÜß\- .]/"
                                               v-model="delivery.last_name" value="">

                                        <label for="inputLastName_delivery-packstation" class="required">Nachname</label>
                                        <div v-if="hasErrors && errors.hasOwnProperty('delivery.last_name')" class="invalid-feedback">
                                            {{ errors['delivery.last_name'][0] }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-floating mb-3">
                                <input type="text" id="inputCompany_delivery-packstation" class="form-control" placeholder="Firma (optional)" name="delivery[company]"
                                       v-model="delivery.company">
                                <label for="inputCompany_delivery-packstation">Firma (optional)</label>
                            </div>
                            <div class="row mb-3 g-3">
                                <div class="col-md">
                                    <div class="form-floating">
                                        <input type="text" id="inputPostNo_delivery-packstation" class="form-control is-required" placeholder="Postnummer" name="delivery[post_no]"
                                               :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.post_no'))}"
                                               v-model="delivery.post_no">
                                        <label for="inputPostNo_delivery-packstation" class="required">Postnummer</label>
                                        <div v-if="hasErrors && errors.hasOwnProperty('delivery.post_no')" class="invalid-feedback">
                                            {{ errors['delivery.post_no'][0] }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md">
                                    <div class="form-floating">
                                        <input type="text" id="inputPackstationNo_delivery-packstation" class="form-control is-required" placeholder="Packstation Nr." name="delivery[packstation_no]"
                                               :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.packstation_no'))}"
                                               v-model="delivery.packstation_no">
                                        <label for="inputPackstationNo_delivery-packstation" class="required">Packstation Nr.</label>
                                        <div v-if="hasErrors && errors.hasOwnProperty('delivery.packstation_no')" class="invalid-feedback">
                                            {{ errors['delivery.packstation_no'][0] }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3 g-3">
                                <div class="col-md">
                                    <div class="form-floating">
                                        <input type="text" id="inputPostcode_delivery-packstation" class="form-control is-required" placeholder="Postleitzahl" name="delivery[zip]"
                                               :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.zip'))}"
                                               v-model="delivery.zip">
                                        <label for="inputPostcode_delivery-packstation" class="required">Postleitzahl</label>
                                        <div v-if="hasErrors && errors.hasOwnProperty('delivery.zip')" class="invalid-feedback">
                                            {{ errors['delivery.zip'][0] }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md">
                                    <div class="form-floating">
                                        <input type="text" id="inputCity_delivery-packstation" class="form-control is-required" placeholder="Ort" name="delivery[city]"
                                               :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.city'))}"
                                               v-model="delivery.city">
                                        <label for="inputCity_delivery-packstation" class="required">Ort</label>
                                        <div v-if="hasErrors && errors.hasOwnProperty('delivery.city')" class="invalid-feedback">
                                            {{ errors['delivery.city'][0] }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-floating">
                                <select class="form-select is-required" id="inputCountry_delivery-packstation" name="delivery[country]"
                                        :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.country'))}"
                                        v-model="delivery.country">
                                    <option disabled selected>Auswählen</option>
                                    <option v-for="country in countries" :value="country.id" :disabled="country.hasOwnProperty('deliveryDisabled') && country.deliveryDisabled" :data-eu="country.eu" :data-code="country.iso_code">{{ country.name }}</option>
                                </select>
                                <label for="inputCountry_delivery-packstation" class="required">Land</label>
                                <div v-if="hasErrors && errors.hasOwnProperty('delivery.country')" class="invalid-feedback">
                                    {{ errors['delivery.country'][0] }}
                                </div>
                            </div>
                        </template>
                        <template v-if="deliveryOption === 'postoffice'">
                            <div class="form-floating mb-3">
                                <select class="form-select is-required" id="inputSalutation_delivery-post" name="delivery[salutation]"
                                        :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.salutation'))}"
                                        v-model="delivery.salutation">
                                    <option value="null">Keine Anrede</option>
                                    <option value="female">Frau</option>
                                    <option value="male">Herr</option>
                                </select>
                                <label for="inputSalutation_delivery-post" class="required">Anrede</label>
                                <div v-if="hasErrors && errors.hasOwnProperty('delivery.salutation')" class="invalid-feedback">
                                    {{ errors['delivery.salutation'][0] }}
                                </div>
                            </div>
                            <div class="row mb-3 g-3">
                                <div class="col-md">
                                    <div class="form-floating">
                                        <input data-was="pattern-" type="text" id="inputFirstName_delivery-post" class="form-control is-required" placeholder="Vorname" name="delivery[first_name]"
                                               :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.first_name'))}"
                                               :regReplace="''"
                                               :regExp="/[^a-zA-ZäöüÄÖÜß\- .]/"
                                               v-model="delivery.first_name" value="">

                                        <label for="inputFirstName_delivery-post" class="required">Vorname</label>
                                        <div v-if="hasErrors && errors.hasOwnProperty('delivery.first_name')" class="invalid-feedback">
                                            {{ errors['delivery.first_name'][0] }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md">
                                    <div class="form-floating">
                                        <input data-was="pattern-" type="text" id="inputLastName_delivery-post" class="form-control is-required" placeholder="Nachname" name="delivery[last_name]"
                                               :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.last_name'))}"
                                               :regReplace="''"
                                               :regExp="/[^a-zA-ZäöüÄÖÜß\- .]/"
                                               v-model="delivery.last_name" value="">

                                        <label for="inputLastName_delivery-post" class="required">Nachname</label>
                                        <div v-if="hasErrors && errors.hasOwnProperty('delivery.last_name')" class="invalid-feedback">
                                            {{ errors['delivery.last_name'][0] }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-floating mb-3">
                                <input type="text" id="inputCompany_delivery-post" class="form-control" placeholder="Firma (optional)" name="delivery[company]"
                                       :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.company'))}"
                                       v-model="delivery.company">
                                <label for="inputCompany_delivery-post">Firma (optional)</label>
                            </div>
                            <div class="row mb-3 g-3">
                                <div class="col-md">
                                    <div class="form-floating">
                                        <input type="text" id="inputPostNo_delivery-post" class="form-control is-required" placeholder="Postnummer" name="delivery[post_no]"
                                               :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.post_no'))}"
                                               v-model="delivery.post_no">
                                        <label for="inputPostNo_delivery-post" class="required">Postnummer</label>
                                        <div v-if="hasErrors && errors.hasOwnProperty('delivery.post_no')" class="invalid-feedback">
                                            {{ errors['delivery.post_no'][0] }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md">
                                    <div class="form-floating">
                                        <input type="text" id="inputPostOfficeNo_delivery-post" class="form-control is-required" placeholder="Postfiliale Nr." name="delivery[post_office_no]"
                                               :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.post_office_no'))}"
                                               v-model="delivery.post_office_no">
                                        <label for="inputPostOfficeNo_delivery-post" class="required">Postfiliale Nr.</label>
                                        <div v-if="hasErrors && errors.hasOwnProperty('delivery.post_office_no')" class="invalid-feedback">
                                            {{ errors['delivery.post_office_no'][0] }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mb-3 g-3">
                                <div class="col-md">
                                    <div class="form-floating">
                                        <input type="text" id="inputPostcode_delivery-post" class="form-control is-required" placeholder="Postleitzahl" name="delivery[zip]"
                                               :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.zip'))}"
                                               v-model="delivery.zip">
                                        <label for="inputPostcode_delivery-post" class="required">Postleitzahl</label>
                                        <div v-if="hasErrors && errors.hasOwnProperty('delivery.zip')" class="invalid-feedback">
                                            {{ errors['delivery.zip'][0] }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md">
                                    <div class="form-floating">
                                        <input type="text" id="inputCity_delivery-post" class="form-control is-required" placeholder="Ort" name="delivery[city]"
                                               :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.city'))}"
                                               v-model="delivery.city">
                                        <label for="inputCity_delivery-post" class="required">Ort</label>
                                        <div v-if="hasErrors && errors.hasOwnProperty('delivery.city')" class="invalid-feedback">
                                            {{ errors['delivery.city'][0] }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-floating">
                                <select class="form-select is-required" id="inputCountry_delivery-post" name="delivery[country]"
                                        :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('delivery.country'))}"
                                        v-model="delivery.country">
                                    <option disabled selected>Auswählen</option>
                                    <option v-for="country in countries" :disabled="country.hasOwnProperty('deliveryDisabled') && country.deliveryDisabled" :value="country.id" :data-eu="country.eu" :data-code="country.iso_code">{{ country.name }}</option>
                                </select>
                                <label for="inputCountry_delivery-post" class="required">Land</label>
                                <div v-if="hasErrors && errors.hasOwnProperty('delivery.country')" class="invalid-feedback">
                                    {{ errors['delivery.country'][0] }}
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <h4>Paketverfolgung</h4>
                    </div>
                    <div class="card-body">
                        <div v-if="hasErrors && errors.hasOwnProperty('tracking')" class="alert alert-danger">
                            {{ errors['tracking'][0] }}
                        </div>
                        <div class="form-check">
                            <input type="radio" id="inputTracking_yes" name="tracking" value="1" class="form-check-input"
                                   :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('tracking'))}"
                                   v-model="tracking">
                            <label class="form-check-label fw-bold" for="inputTracking_yes">Ich möchte eine Trackingnummer und die Paketverfolgungsdaten vom Transportdienstleister erhalten.</label>
                            <div class="alert alert-info">
                                Ich bin damit einverstanden, dass meine E-Mail-Adresse an das beauftragte Transportunternehmen übermittelt wird und willige daher in die Übermittlung dieser Daten an das Transportunternehmen ein.
                                Diese Einwilligung kann ich jederzeit und ohne Angabe von Gründen mit Wirkung für die Zukunft widerrufen.
                                Die Rechtmäßigkeit der bis zum Widerruf erfolgten Verarbeitung bleibt im Falle des Widerrufs unberührt.
                            </div>
                        </div>
                        <div class="form-check">
                            <input type="radio" id="inputTracking_no" name="tracking" value="0" class="form-check-input"
                                   v-model="tracking"
                                   :class="{'is-invalid': (hasErrors && errors.hasOwnProperty('tracking'))}">
                            <label class="form-check-label fw-bold" for="inputTracking_no">Nein, ich bin nicht einverstanden. Ich erhalte keine Tracking Nummer und Paketverfolgungsdaten vom Transportdienstleister. </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div :id="'modal-country-info'" class="modal fade" tabindex="-1">
            <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Lieferung nach Österreich</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <p>Leider können wir nicht mehr nach Österreich liefern. Nach aktueller österreichischer Rechtslage wären wir gezwungen, einen Bevollmächtigten nach Verpackungsverordnung in Österreich zu beauftragen. Dies wäre mit hohen Kosten und Aufwand verbunden.</p>
                        <p>Solange dieses Gesetz Gültigkeit hat, sehen wir uns leider gezwungen, die Lieferung nach Österreich zu deaktivieren.</p>
                        <p>Du hast aber die Möglichkeit, deine Bestellung an eine deutsche Anschrift mit automatisierter Weiterleitung nach Österreich liefern zu lassen. Diesen kostengünstigen Service bieten beispielsweise folgende Dienstleister an:</p>
                        <ul>
                            <li><a target="_blank" href="https://www.logoix.com/lagerung.html">https://www.logoix.com/lagerung.html</a></li>
                            <li><a target="_blank" href="https://www.d-a-packs.at/wie-funktionierts.html">https://www.d-a-packs.at/wie-funktionierts.html</a></li>
                        </ul>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" data-bs-dismiss="modal" :disabled="isLoading">Verstanden</button>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
import AutoComplete from "@/InertiaComponents/AutoComplete.vue";

export default {
    components: {AutoComplete},
    props: [
        'onlyWorkshops',
        'bulkyFreight',
        'partnerLogin',
        'isFiDomain',
        'cart',
        'errors',
        'auth',
        'old'
    ],

    data() {
        return {
            hasErrors: false,
            isLoading: false,
            hasOld: false,
            vatId: false,

            firstTryBilling: true,
            firstTryDelivery: true,

            countries: {},
            countryCode: 'DE',

            partnerDisable: false,
            salutationLock: false,

            billing: {
                email: '',
                customer_no: '',
                phone: '',
                salutation: null,
                first_name: '',
                last_name: '',
                company: '',
                street: '',
                house_no: '',
                additional_address_information: '',
                additional_address_information_2: '',
                zip: '',
                city: '',
                country: 1,
                vat_id: '',
                pattern: {
                    zip: /[^0-9]/
                }
            },

            delivery: {
                salutation: null,
                first_name: '',
                last_name: '',
                company: '',
                street: '',
                house_no: '',
                additional_address_information: '',
                additional_address_information_2: '',
                zip: '',
                city: '',
                country: 1,
                post_no: '',
                packstation_no: '',
                post_office_no: ''
            },

            tracking: "1",
            deliveryOption: 'billing',
            deliveryBilling: 'billing',
            deliveryDisabled: false,
            deliveryOptions: [
                { index: 'billing',  name: 'Lieferung an Rechnungsadresse', disabled: false },
                { index: 'address', name: 'Lieferung an abweichende Hausadresse', disabled: false },
                { index: 'packstation',     name: 'Lieferung an Packstation (nur DHL)', disabled: false },
                { index: 'postoffice',     name: 'Lieferung an Postfiliale (nur DHL)', disabled: false }
            ],

            cityEntry: '',
            billingCityEntries: [],

            modalCountryInfo: null,
            bsModalCountryInfo: null

        }
    },

    mounted() {
        this.billing.country = this.isFiDomain ? 25 : 1
        this.delivery.country = this.isFiDomain ? 25 : 1
        this.fetchCountries();

        this.modalCountryInfo = document.getElementById('modal-country-info')
        this.bsModalCountryInfo   = new bootstrap.Modal(this.modalCountryInfo, {backdrop: 'static'})

        this.countryCode = this.isFiDomain ? 'FI' : 'DE'


        if (this.bulkyFreight) {
            this.deliveryOptions = [
                { index: 'billing',  name: 'Lieferung an Rechnungsadresse' },
                { index: 'address', name: 'Lieferung an abweichende Hausadresse' }
            ]
        }

        if(this.notEmptyObject(this.errors)) {
            this.hasErrors = true;
        }

        if (this.partnerLogin != null && !this.partnerLogin.free_access) {
            this.deliveryOptions = [
                { index: 'billing',  name: 'Lieferung an Rechnungsadresse' }
            ]
            this.partnerDisable = true;
            if (this.customer != null) {
                this.tracking = this.customer.tracking;
                this.deliveryOption = this.customer['delivery-to'];
            }
            if (this.partnerLogin.member.salutation !== null) {
                this.salutationLock = true;
                switch (this.partnerLogin.member.salutation) {
                    case 'Herr':
                        this.billing.salutation = 'male';
                        break;
                    case 'Frau':
                        this.billing.salutation = 'female';
                        break;
                    default:
                        this.salutationLock = false;
                        break;
                }
            }
            this.billing.email = this.partnerLogin.member.email;
            this.billing.prename = this.partnerLogin.member.prename;
            this.billing.name = this.partnerLogin.member.name;
            this.billing.street = this.partnerLogin.member.street;
            this.billing.house_no = this.partnerLogin.member.house_no;
            this.billing.zip = this.partnerLogin.member.zip;
            this.billing.city = this.partnerLogin.member.city;
            this.billing['country'] = parseInt(this.partnerLogin.member.country_id);
        }
        else if (this.cart != null) {
            this.tracking = this.cart.tracking;
            this.deliveryOption = this.cart.delivery_option;

            for (let property in this.billing) {
                if (this.cart.address_billing.hasOwnProperty(property)) {
                    if (property === 'salutation') {
                        switch (this.cart.address_billing.salutation) {
                            case 'Herr':
                                this.billing.salutation = 'male';
                                break;
                            case 'Frau':
                                this.billing.salutation = 'female';
                                break;
                        }

                    } else if (property === 'country') {
                        this.billing['country'] = parseInt(this.cart.address_billing.country_id);
                    } else {
                        this.billing[property] = this.cart.address_billing[property];
                    }
                }
            }

            if (this.cart.address_delivery != null) {
                for (let property in this.delivery) {
                    if (this.cart.address_delivery.hasOwnProperty(property)) {
                        if (property === 'salutation') {
                            switch (this.cart.address_delivery.salutation) {
                                case 'Herr':
                                    this.delivery.salutation = 'male';
                                    break;
                                case 'Frau':
                                    this.delivery.salutation = 'female';
                                    break
                            }

                        } else if (property === 'country') {
                            this.delivery.country = this.cart.address_delivery.country_id
                        } else {
                            this.delivery[property] = this.cart.address_delivery[property]
                        }
                    }
                }
            }
        }
        if(this.notEmptyObject(this.old)) {
            this.hasOld = true;

            if(typeof this.old.delivery === 'undefined') {
                this.old.delivery = {}
            } else {
                for ( let property in this.old.delivery){
                    if (this.delivery.hasOwnProperty(property)) {
                        this.delivery[property] = (this.old.delivery[property] === null) ? '' : this.old.delivery[property]
                    }
                }
            }

            for ( let property in this.billing) {
                if (this.old.billing.hasOwnProperty(property)) {
                    this.billing[property] = (this.old.billing[property] === null) ? '' : this.old.billing[property]
                }
            }

            this.tracking = this.old.tracking
            this.deliveryOption = this.old.delivery_option
        }
    },
    computed: {
        patternZip () {
            if (this.billing.country !== 1) {
                return /[]/
            } else {
                return this.billing.pattern.zip
            }
        }
    },
    methods: {
        fetchCountries() {
            axios.get('/api/countries')
                .then(response => {
                    let respCountries = response.data

                    respCountries.push({
                        id: 2,
                        name: 'Österreich'
                    })

                    respCountries.sort( function (w1, w2) {
                        return w1.name.localeCompare (w2.name);
                    })

                    this.countries = respCountries
                })
                .catch(error => {})
                .finally(() => (this.isLoading = false))
        },
        getCity(zipCode, countryCode = 'de') {
            let city = '';
            axios.get('https://api.ac-foto.com/geodata/city-list', {
                params: {
                    zipCode: zipCode,
                    countryCode: countryCode
                }
            })
                .then(response => {

                    let entryData = response.data
                    let entries = []

                    Object.keys(entryData).forEach(key => {
                        entries.push(key)
                        if (Array.isArray(entryData[key])) {
                            entryData[key].forEach(key2 => {
                                entries.push(key + ', ' + key2)
                            })
                        }
                    })

                    this.billingCityEntries = entries
                })
        },
        getDeliveryCity(zipCode, countryCode = 'de') {
            let city = '';
            axios.get('https://api.ac-foto.com/geodata/city-list', {
                params: {
                    zipCode: zipCode,
                    countryCode: countryCode
                }
            })
                .then(response => {
                    if (response.data.length)
                        this.delivery.city = response.data
                })
        },
        setTestData() {
            this.billing.email = 'entwicklung@ac-foto.com';
            this.billing.phone = '+49 122 345 67 89';
            this.billing.salutation = 'male';
            this.billing.prename = 'Max';
            this.billing.name = 'Mustermann';
            this.billing.company = 'AC-Foto Handels GmbH';
            this.billing.street = 'Musterstraße';
            this.billing.house_no = '123';
            this.billing.zip = '10115';
            this.billing.city = 'Berlin';
            this.billing.country = 1;
            this.tracking = 1;
        },
        setCountry() {
            for (let i = 0; i < this.countries.length; i++) {
                if (this.countries[i].id === this.billing.country) {
                    this.countryCode = this.countries[i].iso_code;
                    if(this.countries[i].eu && this.countries[i].id !== 1) {
                        this.vatId = true
                    } else {
                        this.vatId = false
                    }
                }
            }
        }
    },

    watch: {
        'billing.zip' () {
            if (this.billing.zip.length < 4 || this.billing.zip.length > 5) return;
            this.getCity(this.billing.zip, this.countryCode)
        },
        'billing.city' () {
            if (this.billing.city !== this.cityEntry) {
                // this.cityEntries = {}
            }
        },
        'billing.country' () {
            if (this.billing.country == 2) {
                this.bsModalCountryInfo.show()
                this.deliveryOption ='address'
                this.deliveryOptions[0].disabled = true

                for (let i = 0; i < this.countries.length; i++) {
                    if (this.countries[i].id === 2) {
                        this.countries[i].deliveryDisabled = true
                    }
                }
                return
            } else {
                if (!this.firstTryBilling) {
                    this.deliveryOption = 'billing'
                    this.deliveryOptions[0].disabled = false
                }
            }

            if (!this.firstTryBilling) {
                this.billing.zip = '';
                this.billing.city = '';
            }
            //this.setCountry()
            this.firstTryBilling = false;
        },
        'delivery.zip' () {
            if (this.delivery.zip.length < 4 || this.delivery.zip.length > 5) return;
            this.getDeliveryCity(this.delivery.zip, this.countryCode)
        },
        'delivery.country' () {
            if (this.delivery.country === 2) {


                return
            }

            if (!this.firstTryDelivery) {
                this.delivery.zip = '';
                this.delivery.city = '';
            }
            this.firstTryDelivery = false;
            for (let i = 0; i < this.countries.length; i++) {
                if (this.countries[i].id === this.delivery.country) {
                    this.countryCode = this.countries[i].iso_code;
                    this.vatId = this.countries[i].eu;
                }
            }
        },
        'countries.length' () {
            this.setCountry();
        },
        'billing.vat_id' () {
            if (this.billing.vat_id !== '' && this.billing.vat_id !== null) {
                this.deliveryOption = this.deliveryBilling;
                this.deliveryDisabled = true;
            } else {
                this.deliveryDisabled = false;
            }
        },
    },
}
</script>
